import React, { useState, useRef, useEffect } from 'react';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';

import http from '../common/http';
const WhatToBring = ({ whatToBring, setWhatToBring, tripId, landingPage = false, cityName = null }) => {
  const { id } = useParams();
  const toast = useRef(null);
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const InitialState = [
    {
      category: "clothes",
      items_list:
        [
          {
            name: "Shirts",
            value: false
          },
          {
            name: "Jacket",
            value: false
          },
          {
            name: "Hiking Shoes",
            value: false
          },
          {
            name: "Clubbing Attire",
            value: false
          },
        ]
    },
    {
      category: "techs",
      items_list:
        [
          {
            name: "Laptop",
            value: false
          },
          {
            name: "Headphones",
            value: false
          },
        ]
    },
    {
      category: "miscellaneous",
      items_list:
        [
          {
            name: "Visa",
            value: false
          },
          {
            name: "First Aid Kit",
            value: false
          },
          {
            name: "Hat",
            value: false
          },
          {
            name: "Lip Balm",
            value: false
          },
        ]
    }
  ];

  const [inputFieldVisible, setInputFieldVisible] = useState([]);
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    if (!whatToBring.length) {
      const newInputFieldVisible = InitialState.map(item => ({ category: item.category, value: false }));
      setInputFieldVisible(newInputFieldVisible);
      setWhatToBring(InitialState);
    } 
    const newInputFieldVisible = whatToBring.map(item => ({ category: item.category, value: false }));
    setInputFieldVisible(newInputFieldVisible);
  }, [whatToBring]);

  const getInputFieldVisible = (categoryName) => {
    const category = inputFieldVisible.find(item => item.category === categoryName);
    return category ? category.value : null;
  };

  const updateInputFieldVisible = (categoryName) => {
    setInputFieldVisible(prevState =>
      prevState.map(item =>
        item.category === categoryName ? { ...item, value: !item.value } : item
      )
    );
  };

  const handleChange = (category, keyName, name, newValue) => {
    var whatToBringItems = null;    
    setWhatToBring(prevState =>
      prevState.map(item => {
        if (item.category === category) {
          const updatedItem = {
            ...item,
            items_list: item.items_list.map(item => {
              if (item.name === name) {
                return {
                  ...item,
                  [keyName]: newValue
                };
              }
              return item;
            })
          };
          whatToBringItems = updatedItem;
          return updatedItem;
        }
        return item;
      })
    );

    updateBringItems(whatToBringItems, name, category);
  };

  const updateBringItems = (whatToBringItems, name, category) => {
    if (id || landingPage) {
      const itemsListStringified = JSON.stringify(whatToBringItems.items_list);
      const updatedWhatToBring = { ...whatToBringItems, items_list: itemsListStringified, trip_id: tripId };
        http
        .put(`/what_to_brings/${whatToBringItems.id}`, { what_to_bring: updatedWhatToBring })
        .then(() => {
          showMessage('success', 'Success', `${name} has been saved in ${category}`, 'bg-teal-100 text-teal-500');
        })
        .catch(() => {

        }); 
    }
  };

  const handleAddItem = (category, value) => {
    var whatToBringItems = null;
    if (value.trim() == '') {
      showMessage("warn", 'Warning', `Please fill in the field`, 'bg-amber-100 text-amber-500');
      inputRef.current.style.border = "2px solid red";
      return;
    }

    if (ifLabelExists(category, value)) {
      showMessage("warn", 'Warning', `Already Exists`, 'bg-amber-100 text-amber-500');
      return;
    }

    updateInputFieldVisible(category);
    setWhatToBring(prevState =>
      prevState.map(item => {
        if (item.category === category) {
          const updatedItem = {
            ...item,
            items_list: [
              ...item.items_list,
              {
                name: value,
                value: false
              }
            ]
          };
          whatToBringItems = updatedItem;
          return updatedItem;
        }
        return item;
      })
    );

    updateBringItems(whatToBringItems, value, category);
  };

  const ifLabelExists = (category, newValue,) => {
    return whatToBring.some(item =>
      item.category === category &&
      item.items_list.some(item => item.name.toLowerCase() === newValue.toLowerCase())
    );
  };

  const deleteChip = (category, name) => {
    setWhatToBring(prevState =>
      prevState.map(item => {
        if (item.category === category) {
          return {
            ...item,
            items_list: item.items_list.filter(item => item.name !== name)
          };
        }
        return item;
      })
    );
  };

  const removeRedBorder = () => {
    inputRef.current.style.border = "";
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  return (
    <div className={`flex flex-col w-full ${landingPage ? "border mt-8 overflow-y-auto max-h-[390px]" : "mt-4 mb-10"}`}>
      <Toast ref={toast} position='bottom-right' />
      <div className={` ${landingPage ? "mx-4" : "mx-4 lg:mx-16"}`}>
        <div>
          { landingPage ? 
            <h1 className='font-playfair text-xl font-semibold mt-2'>What to pack for { cityName }</h1> :
            <h1 className='font-playfair text-2xl font-normal'>What to bring on your trip</h1>
          } 
        </div>
        <div className={`flex flex-col justify-between w-full mx-auto  ${landingPage ? "lg:flex-col overflow-y-auto gap-2" : "lg:flex-row gap-4"}`}>
          {whatToBring.length &&
            whatToBring.map((item, index) => (
              <div key={index} className={`flex-wrap text-center border w-full rounded-md ${landingPage ? "my-2" : "lg:w-2/5 mt-8 "}`}>
                <div className='justify-between items-center text-sm font-semibold'>
                  <div className={`flex flex-col pl-4 py-4 items-start`}>
                    <div className='text-lg font-semibold font-playfair mb-2'>
                      {capitalizeFirstLetter(item.category)}
                    </div>
                    <div
                      ref={containerRef}
                      id={item.category}
                      className='text-gray-400 w-full h-[180px] overflow-y-auto'
                    >
                      <FormGroup>
                        {item.items_list.map((label, subindex) =>
                          <div className='flex justify-between items-center text-sm font-normal' key={subindex}>
                            <FormControlLabel
                              className='items-start flex-1 text-left'
                              control={
                                <Checkbox
                                  checked={label.value}
                                  onClick={() =>
                                    handleChange(item.category, "value", label.name, !label.value)
                                  }
                                />
                              }
                              label={
                                <div className={`flex items-start truncate`}>
                                  {label.name}
                                </div>
                              }
                            />
                          </div>
                        )}
                      </FormGroup>
                    </div>
                    {
                      getInputFieldVisible(item.category) ?
                        <div className='flex flex-row items-center justify-between gap-x-2 w-full'>
                          <input
                            id={item.category}
                            ref={inputRef}
                            type="text"
                            placeholder="Type..."
                            className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleAddItem(item.category, e.target.value);
                              }
                            }}
                            onChange={() => removeRedBorder()}
                          />
                          <h1
                            className='text-periwinkle text-lg font-semibold cursor-pointer pr-4 mb-[3%]'
                            onClick={() => handleAddItem(item.category, inputRef.current.value)}
                          >
                            Add
                          </h1>
                          <h1
                            className='text-black text-lg font-semibold cursor-pointer pr-4 mb-[3%]'
                            onClick={() => updateInputFieldVisible(item.category)}
                          >
                            Cancel
                          </h1>
                        </div>
                        :
                        <></>
                    }
                    <h1
                      className={`text-base font-semibold text-periwinkle cursor-pointer mt-2  ${getInputFieldVisible(item.category) ? 'opacity-50 pointer-events-none' : ''}`}
                      onClick={() => updateInputFieldVisible(item.category)}
                    >
                      Add +
                    </h1>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default WhatToBring;
