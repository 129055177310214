import './button.scss';

import React from 'react';
import RightArrow from 'trending_flat.svg';
import loadingSpinner from 'loader.png';


export const BtnTransparent = ({ props, handleClick, disabled = false, rightArrow = false, isSaving = false , forwardRef }) => {
  return (
    <button
      ref = { forwardRef }
      type='button'
      className={ `${disabled ? 'cursor-not-allowed disabled-color' : '' } ${props.text} ${props.bg} ${props.border} border-2 rounded ${!rightArrow ? 'py-1 px-8' : ''} ${isSaving ? 'flex items-center' : ''} ` }
      disabled={ disabled }
      onClick={ handleClick }
    >
      { disabled && isSaving && (
        <img 
          src={ loadingSpinner }
          alt="spinner"
          className='w-[21px] h-[23px] animate-spin mr-2'
        />
      )}
      { !rightArrow ? props.buttonName :
        <img
          alt='Image 1'
          src={ RightArrow }
          className='h-[30px] w-[100px]'
          style={{ filter: 'brightness(0) invert(1)' }}
        /> 
      }
    </button>
  );
};

export const BtnTabs = ({ props, handleViewType }) => {
  return (
    <button
      type='button'
      className={ `${props.text} ${props.bg} ${props.border} ${props.rounded} border-2 w-32 px-2 py-2` }
      onClick={ handleViewType }
    >
      { props.buttonName }
    </button>
  );
};
