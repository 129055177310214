import React, { useState } from 'react';
import { Box, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { grey } from '@mui/material/colors';

const CheckBox = ({ handleInput }) => {
  const [hotelBooked,setHotelBooked] = useState(false);
  const [flightBooked,setFlightBooked] = useState(false);
  
  return (
    <div className='text-sm font-sans'>
      <Box>
        <FormGroup>
          <FormControlLabel control={<Checkbox onChange={ () => { setHotelBooked(!hotelBooked), handleInput("hotelBooked", !hotelBooked); } } checked={ hotelBooked } sx={{
            '&.Mui-checked': {
              color: grey[900],
            },
          }}/>} label='I booked my accommodation already' />
          <FormControlLabel control={<Checkbox onChange={ () => { setFlightBooked(!flightBooked), handleInput("flightBooked", !flightBooked); } } checked={ flightBooked } sx={{
            '&.Mui-checked': {
              color: grey[900],
            },
          }}/>} label='I booked my flight already' />
        </FormGroup>
      </Box>
    </div>
  );
};

export default CheckBox;
