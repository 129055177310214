// App.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from "react-router-dom";
import BrowserRouter from '../routes';
import { store } from "../redux/store";
import { Provider } from "react-redux";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={BrowserRouter} />
    </Provider>
  </React.StrictMode>
);
