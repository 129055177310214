import React, { useEffect, useState } from 'react';
import TripPlan from './TripPlan';
import FlightsInformation from './FlightsInformation/FlightsInformation';
import { useSelector } from 'react-redux';

const TripParentComponent = () => {
  const showFlights = useSelector(state => state.flightInformation.showFlights);
  const [showFlightsInfo, setShowFlightsInfo] = useState(false);

  useEffect(() => {
    setShowFlightsInfo(showFlights);
  }, [showFlights]);

  return (
    <div>
      {
        showFlightsInfo
        ? <FlightsInformation />
        : <TripPlan />
      }
    </div>
  );
};

export default TripParentComponent;
