import PriceMatch from './PriceMatch';

import React, { useState } from 'react';

const PriceMatchButton = () => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isPriceMatchVisible, setIsPriceMatchVisible] = useState(false);

  return (
    <div>
      <div
        className='ml-5 mb-5 pb-2 border-b-4 border-dashed border-green-700 inline-flex gap-2 items-center'
        onMouseEnter={ () => setIsTooltipVisible(true) }
        onMouseLeave={ () => setIsTooltipVisible(false) }
      >
        <div
          className='cursor-pointer w-8 h-8 p-2 bg-green-700 rounded-full flex items-center justify-center text-white'
          onClick={ () => setIsPriceMatchVisible(true) }
        >
          $
        </div>
        <h1 className='font-bold text-green-700 relative'>
          <span className='text-base cursor-pointer' onClick={ () => {} }>Price match guarantee</span>
          {isTooltipVisible && (
            <div className="border-shadow-darker absolute bg-white text-md text-black p-8 mt-2 z-[1] w-80 left-1/2 transform -translate-x-1/2 top-full">
              <h1 className='font-light text-base'>
                If you find a better price for a hotel, complete the price match form and we will honor the price upon verification. You can find this form:
              </h1>
              <h1 className='font-light mt-2 text-base'>
                <li>Go to the booking page of the hotel.</li>
                <li>Fill out the Request Price Match form.</li>
                <li>Submit the form, and come back to save the trip.</li>
                <li>And, we will get back to you promptly.</li>
              </h1>
            </div>
          )}
        </h1>
      </div>
      <PriceMatch
        isPriceMatchFormVisible = { isPriceMatchVisible }
        setIsPriceMatchFormVisible = { setIsPriceMatchVisible }
      />
    </div>
  );
};

export default PriceMatchButton;
