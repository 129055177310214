import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import getSymbolFromCurrency from 'currency-symbol-map';
import PlaceholderSkeleton from '../Skeletons/PlaceholderSkeleton';
import { snakeToTitelize } from '../../utils/helpers/string.jsx';
import { Toast } from 'primereact/toast';
import RightArrow from 'right_arrow_new.svg';
import { InputSwitch } from 'primereact/inputswitch';
import http from './../../common/http';
import { getUserId } from '../../common/auth';
import loadingSpinner from 'loader.png';
import Switch from '@mui/material/Switch';
import { capitalizeWords } from "../../utils/helpers/string.jsx";

const BookingCart = () => {
  const userId = getUserId();
  const [promoCode, setPromoCode] = useState('');
  const [nightCount, setNightCount] = useState(null);
  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');
  const [bedType, setBedType] = useState('');
  const [bedsCount, setBedsCount] = useState('');
  const [offerDetails, setOfferDetails] = useState('');
  const [checked, setChecked] = useState(false);
  const [isLoadingWallet, setIsLoadingWallet] = useState(false);
  const bookingInfo = useSelector(state => state.hotelBooking.hotelBooking);
  const [offerId, setOfferId] = useState('');
  const [walletInfo, setWalletInfo]=useState({});
  const toast = useRef(null);

  useEffect(() => {
    if (userId) {
      fetchWalletInfo();
    }
  }, []);

  const fetchWalletInfo = () => {
    setIsLoadingWallet(true);
    http
      .get('/credit_card/information')
      .then((res) => {
        if (res.data) {
          setIsLoadingWallet(false);
          setWalletInfo(res.data.user_wallet);
        }
      })
      .catch(() => {   
        showMessage('error', 'Error', 'Sorry, there was an error while fetching card information.', 'bg-red-100 text-red-700');
      });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('offer_id');
    setOfferId(id);
  }, []);
  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  useEffect(() => {
    if (bookingInfo.checkIn || bookingInfo.checkOut) {
      setCheckIn(formatDate(bookingInfo.checkIn));
      setCheckOut(formatDate(bookingInfo.checkOut));
      countNights();
    }

    const offer = bookingInfo?.offers?.find(offer => offer.id === offerId);
    setOfferDetails(offer);
    if (offer?.room?.typeEstimated) {
      setBedType(offer?.room?.typeEstimated?.bedType);
      setBedsCount(offer?.room?.typeEstimated?.beds);
    }
  }, [bookingInfo, offerId]);

  const formatDate = (date) => {
    // TODO: Need to move this method to shared file
    if (!(date instanceof Date)) {
      date = new Date(date);
    }

    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let year = date.getFullYear();
    return month + '/' + day + '/' + year;
  };

  const markupsSum = (array) => {
    return array.reduce((accumulator, currentItem) => {
        return accumulator + Number(currentItem.amount);
    }, 0);
  };

  const countNights = () => {
    let startDate = new Date(bookingInfo.checkIn);
    let endDate = new Date(bookingInfo.checkOut);

    let timeDifference = Math.abs(startDate.getTime() - endDate.getTime());
    let nights = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    setNightCount(nights);
  };

  return (
    <div className='bg-white'>
      <Toast ref={toast} position="bottom-right" />
      <div className='border border-gray79'>
        {
          bookingInfo?.hotel?.img_url ?
            <img
              src={ bookingInfo?.hotel?.img_url }
              alt='card picture'
            /> :
            <PlaceholderSkeleton applyClass = { 'w-full h-[250px] bg-gray-100' } />
        }
        <div className='mt-4 px-6'>
          <p className='text-2xl font-normal font-playfair'>
            { bookingInfo?.cityInfo?.hotelName && capitalizeWords(bookingInfo?.cityInfo?.hotelName) }
          </p>
          <p className='my-2 text-base font-normal'>
            { checkIn } - { checkOut }
          </p>
          { bedType && bedsCount && (
              <p className='mt-2 text-base font-normal'>
                { `${capitalizeWords(bedType)}x${bedsCount}` }
              </p>
            )}
          <hr className='my-4'/>
            <div>
              <h1 className='font-bold text-base font-playfair'>Price breakdown</h1>
              <div className='flex justify-between my-4'>
                <div className='font-normal text-base'>
                  1 room x { nightCount } nights
                </div>
                <div className='font-normal text-base'>
                  { getSymbolFromCurrency(offerDetails?.price?.currency) }{ offerDetails?.price?.total }
                </div>
              </div>

              <div className='flex justify-between my-4'>
                <div className='font-bold'>
                  Base
                </div>
                <div className='font-normal text-base'>
                { getSymbolFromCurrency(offerDetails?.price?.currency) }{ offerDetails?.price?.base }
                </div>
              </div>

              <div className='my-4'>
                <div className='font-bold'>
                  Taxes
                </div>
                <div className='font-normal text-xs w-full mt-2'>
                  {offerDetails?.price?.taxes?.map((tax, index) => (
                    <div className="flex justify-between"
                      key={index}
                    >
                      <span>{ snakeToTitelize(tax.code) }</span>
                      <span>{ getSymbolFromCurrency(tax?.currency) }{ tax?.amount }</span>
                    </div>
                  ))}
                </div>
              </div>

              <div className='my-4'>
                {offerDetails?.price?.markups && <div>
                  <div className='flex justify-between'>
                    <span className='font-bold'>Fees</span>
                    <span>{ markupsSum(offerDetails?.price?.markups) }</span>
                  </div>
                  <small className='flex text-xs text-gray-500'>This hotel fees not WePlan fees</small>
                </div>
                }
              </div>

              {/* <div className='flex justify-between my-4'>
                <div className='font-bold'>
                  Resort fee
                </div>
                <div className='font-normal text-base'>
                  -
                </div>
              </div> */}

              <div className='flex justify-between my-4 my-6 font-bold text-lg'>
                <div>
                  <p className='text-lg font-normal font-playfair'>Total</p>
                </div>
                <div>
                  { getSymbolFromCurrency(offerDetails?.price?.currency) }{ offerDetails?.price?.total }
                </div>
              </div>
              
            <div className='my-6 mb-4'>
              <label className='text-sm font-normal'>Promo code</label>
              <div className='flex relative items-center form-control-wrapper mt-2'>
                <input
                  type="text"
                  key='promoCode'
                  value={promoCode}
                  className={`text-black mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2 m-0 min-height`}
                  onChange={(e) => setPromoCode(e.target.value)}
                />
                <div className='bg-periwinkle h-12 w-20 p-4 flex justify-center items-center rounded-md mr-[-1px] cursor-pointer'>
                    <h1 className='text-white font-semibold text-base'>Apply</h1>
                </div>
                </div>
            </div>
            <div className='flex flex-col my-6'>
              <div className='flex items-center justify-between mt-8'>
                <h1 className='font-normal text-base'>Apply credits</h1> 
                <Switch 
                  checked = { checked } 
                  onClick = { () => setChecked(!checked)} 
                />
              </div>
              <div className='flex items-center gap-y-4 mt-4 mb-4'>
              <span className='mr-4 text-green-700 text-base font-normal'>Credit balance:</span>
                { !isLoadingWallet ? 
                  <span className='text-green-700 font-normal text-base'>$ { walletInfo.available_balance }</span>:
                    <img
                      src={ loadingSpinner }
                      alt="spinner"
                      className='w-[21px] h-[23px] animate-spin'
                    />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingCart;
