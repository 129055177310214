import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hotelBooking: {},
};

export const HotelBooking = createSlice({
  name: "hotelBooking",
  initialState,
  reducers: {    
    setHotelBooking: (state, action) => {
      state.hotelBooking = action.payload;
    },
  },
});

export const { setHotelBooking } = HotelBooking.actions;

export default HotelBooking.reducer;
