import './ProfilePage.scss';

import React, { useEffect, useState } from 'react';
import { logOut } from '../common/auth';
import { getUserId } from '../common/auth';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Trips from './Trips/Trips';
import Layout from './Layout/Layout';
import Profile from './Profile/Profile';
import Support from './Support/Support';
import AIInStay from './AIInsStay/AIInStay';
import BackgroundImage from 'profile_page_background.png';
import CustomModal from './UIComponents/Modal/customModal';
import Platforms from './SocialMedia/SocialMediaPlatforms';
import Preferences from './MessagePreference/MessagePreference';
import RewardsAndBilling from './RewardsAndBilling/RewardsAndBilling';
import SingleSelectDropdown from './UIComponents/Dropdowns/SingleSelectDropdown';
import ResetUsernameAndPassword from './ResetUsernamePassword/ResetUsernamePassword';
import { triggerMixpanelEvent } from '../common/mixpanel.js';

const ProfilePage = () => {
  const userId = getUserId();
  const navigate = useNavigate();
  const location = useLocation();
  const [isVisible,setIsVisible]=useState(false);
  const [modalType, setModalType] = useState('');
  const [isProfileDirty,setIsProfileDirty]=useState(false);
  const [selectedOption, setSelectedOption] = useState(localStorage.getItem('selectedOption'));

  useEffect(() => {
    const newValue = selecetdUrl(location.pathname.split('/').pop());
    if (newValue == "trips") {
      localStorage.setItem('selectedOption', "My Destinations");
      setSelectedOption("My Destinations");
    } else {
      localStorage.setItem('selectedOption', (newValue).charAt(0).toUpperCase() + newValue.slice(1));
      setSelectedOption((newValue).charAt(0).toUpperCase() + newValue.slice(1));
    }
  }, [location.pathname]);
  
  const backgroundImage = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
  };

  const buttons = {
    profile: [
      {
        text: 'Cancel',
        onPress: () => {
          setIsVisible(false);
          setSelectedOption('Profile');
        }
      },
      {
        text: 'Ok',
        onPress: () => {
          setIsProfileDirty(false);
          setIsVisible(false);
          localStorage.setItem("selectedOption",selectedOption);
          navigate(`/profile/${userId}/${selecetdUrl(selectedOption)}`);
        }
      },
    ],
  };

  const onLinkClick = (data) => {
    if(isProfileDirty && data != "Profile") {
      setIsVisible(true);
      setModalType('profile');
      setSelectedOption(data);
    } else {
      setSelectedOption(data);
      triggerMixpanelEvent(`Profile Page - ${data} click`);
      localStorage.setItem('selectedOption', data);
    }
  };

  const options = [
    'Profile',
    'Username',
    'Rewards',
    'My Destinations',
    'Social',
    'In-stay',
    'Preferences',
    'Support',
  ];

  const selecetdUrl = (value) => {
    switch (value.toLowerCase()) {
      case 'profile':
        return '';
      case 'username':
        return 'username';
      case 'rewards':
        return 'rewards';
      case 'my destinations':
        return 'trips';
      case 'trips':
        return 'trips';
      case 'in-stay':
        return 'in-stay';
      case 'social':
        return 'social';
      case 'preferences':
        return 'preferences';
      case 'support':
        return 'support';
      default:
        return 'profile';
    }
  };

  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  return (
    <Layout>
      <div className='flex w-full'>
        <div className='flex w-full'>
          <div className="flex flex-wrap w-full isolate lg:px-20 px-3 lg:pt-36 pt-14 justify-center" style={backgroundImage}>
            <div className='flex w-full flex-col flex-wrap'>
            <div className='flex'>
              <p className='text-3xl font-playfair font-normal py-4'>Your account</p>
            </div>
            {!isMobile && <div className='grid grid-cols-4 w-90% mb-4 card-container shadow-md'>
              <div className='flex flex-col flex-wrap justify-between bg-[#f4f4f4] p-16 text-base'>
                <div className='flex flex-wrap flex-col'>
                  <div className='mb-12'>
                    <Link to={`/profile/${userId}`} onClick={()=>onLinkClick('Profile')}>
                      <p className={`relative inline cursor-pointer ${[`/profile/${userId}`, 'Profile'].includes(selectedOption) ? 'selected' : 'font-medium'}`}>Profile</p>
                    </Link>
                  </div>
                  <div className='mb-12'>
                    <Link to={!isProfileDirty?`/profile/${userId}/username`:null} onClick={()=>onLinkClick('Username')}>
                      <p className={`relative inline cursor-pointer ${selectedOption?.includes('Username') ? 'selected' : 'font-medium'}`}>Username & password</p>
                    </Link>
                  </div>
                  <div className='mb-12'>
                    <Link to={!isProfileDirty?`/profile/${userId}/rewards`:null} onClick={()=>onLinkClick('Rewards')}>
                      <p className={`relative inline cursor-pointer ${selectedOption?.includes('Rewards') ? 'selected' : 'font-medium'}`}>Rewards and billing</p>
                    </Link>
                  </div>
                  <div className='mb-12'>
                    <Link to={!isProfileDirty?`/profile/${userId}/trips`:null} onClick={()=>onLinkClick('My Destinations')}>
                      <p className={`relative inline cursor-pointer ${selectedOption?.includes('My Destinations') ? 'selected' : 'font-medium'}`}>My Destinations</p>
                    </Link>
                  </div>
                  <div className='mb-12'>
                    <Link to={!isProfileDirty?`/profile/${userId}/social`:null} onClick={()=>onLinkClick('Social')}>
                      <p className={`relative inline cursor-pointer ${selectedOption?.includes('Social') ? 'selected' : 'font-medium'}`}>Social media accounts</p>
                    </Link>
                  </div>
                  <div className='mb-12'>
                    <Link to={!isProfileDirty?`/profile/${userId}/in-stay`:null} onClick={()=>onLinkClick('In-stay')}>
                      <p className={`relative inline cursor-pointer ${selectedOption?.includes('In-stay') ? 'selected' : 'font-medium'}`}>In-Stay</p>
                    </Link>
                  </div>
                  <div className='mb-12'>
                    <Link to={!isProfileDirty?`/profile/${userId}/preferences`:null} onClick={()=>onLinkClick('Preferences')}>
                      <p className={`relative inline cursor-pointer ${selectedOption?.includes('Preferences') ? 'selected' : 'font-medium'}`}>Message preferences</p>
                    </Link>
                  </div>
                  <div className='mb-12'>
                    <Link to={!isProfileDirty?`/profile/${userId}/support`:null} onClick={()=>onLinkClick('Support')}>
                      <p className={`relative inline cursor-pointer ${selectedOption?.includes('Support') ? 'selected' : 'font-medium'}`}>Support</p>
                    </Link>
                  </div>
                </div>
                <div className=''>
                  <a
                    className='cursor-pointer text-periwinkle font-medium hover:underline hover:font-bold hover:text-periwinkle'
                    onClick={async () => {
                      await logOut();
                    }}
                  >
                    Logout
                  </a>
                </div>
              </div>
              <div className='col-span-3 bg-white p-16'>
                {
                  location.pathname.includes("username")?<ResetUsernameAndPassword/>:
                  location.pathname.includes("rewards")?<RewardsAndBilling/>:
                  location.pathname.includes("social")?<Platforms/>:
                  location.pathname.includes("trips")?<Trips/>:
                  location.pathname.includes("in-stay")?<AIInStay/>:
                  location.pathname.includes("preferences")?<Preferences/>:
                  location.pathname.includes("support")?<Support/>:
                   <Profile setIsProfileDirty={setIsProfileDirty}/>
                }
              </div>
            </div>}
            {isMobile && (
              <div className='flex flex-col w-full flex-wrap block pt-3 lg:hidden'>
                <SingleSelectDropdown props={{ label: 'Select an option', options: options, value: selectedOption }} handleChanges={ (val) => {
                  navigate(`/profile/${userId}/${selecetdUrl(val.value)}`);
                  onLinkClick(val.value);
                }} />
                <div className='block sm:flex w-full sm:flex-wrap col-span-3 py-2 px-3 bg-white'>
                  {
                    location.pathname.includes("username")?<ResetUsernameAndPassword/>:
                    location.pathname.includes("rewards")?<RewardsAndBilling/>:
                    location.pathname.includes("social")?<Platforms/>:
                    location.pathname.includes("trips")?<Trips/>:
                    location.pathname.includes("in-stay")?<AIInStay/>:
                    location.pathname.includes("preferences")?<Preferences/>:
                    location.pathname.includes("support")?<Support/>:
                    <Profile setIsProfileDirty={setIsProfileDirty}/>
                  }
                </div>
                <div className='flex mt-10 mb-4'>
                  <a
                    className='cursor-pointer text-periwinkle font-medium hover:underline hover:font-bold hover:text-periwinkle'
                    onClick={async () => {
                      await logOut();
                    }}
                  >
                    Logout
                  </a>
                </div>
              </div>
            )}
            </div>
          </div>
        </div>
      </div>
      {isVisible &&
        <CustomModal
          type={ modalType }
          isVisible={ isVisible }
          setVisible={ setIsVisible }
          buttons={ buttons[modalType] }
        />
      }
    </Layout>
  );
};

export default ProfilePage;
