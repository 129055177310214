import React from 'react';
import { HiOutlineHandThumbUp } from "react-icons/hi2";
import { HiOutlineHandThumbDown } from "react-icons/hi2";
import loadingSpinner from 'loader.png';

import { Card } from 'primereact/card';
import { Rating } from 'primereact/rating';
import { useParams } from 'react-router-dom';
import ItineraryLoadingsSkeleton from '../Skeletons/ItineraryLoadingsSkeleton';
import PlaceholderSkeleton from '../Skeletons/PlaceholderSkeleton';

function ItineraryLoading({ placeName, price, rating, imageUrl, handleLoading }) {
  const { id } = useParams();
  const header = (
    <div className='relative'>
      { imageUrl ? 
          <img
            alt='Card'
            src={ imageUrl }
            className='h-[310px] w-[324px] object-cover hover:bg-black hover:bg-opacity-50 hover:inset-0'
          /> :
          <PlaceholderSkeleton applyClass = {'h-[310px] w-[324px] bg-light-gray'} />
      }
    </div>
  );

  return (
    <div className="flex items-center justify-center flex-col h-100 pt-20">
      <div className="flex flex-row items-center justify-center">
        <img 
          src={ loadingSpinner }
          alt="spinner"
          className='w-[21px] h-[23px] animate-spin'
        />
        { id ? (
          <div className="pl-4">We are fetching your saved itinerary...</div>
          ) : <div className="pl-4 font-playfair text-lg font-normal">We are generating your custom itinerary...</div> 
        }
      </div>

      <div className="mt-16 font-medium text-base">In the meantime...what do you think of this hotel?</div>

        

    { placeName ? 
      <>
        <div className="card-container relative overflow-hidden border w-30 mt-8">
          <Card header={ header }>
            <div className='p-3'>
              <div className='flex justify-between items-center min-h-[48px] text-sm font-medium'>
                <div>{placeName}</div>
                <div className='pl-3'> {price} </div>
              </div>
              <Rating
                value={rating}
                cancel={false}
                className='text-yellow-500'
                // onChange={(e) => setRating(e.value)}
              />
            </div>
          </Card>
        </div>

        <div className="flex items-center justify-between flex-row w-[100px] mt-6">
          <button onClick={ () => handleLoading("liked") }>
            <HiOutlineHandThumbUp className="h-[40px] w-[40px]" />
          </button>
          <button onClick={ () => handleLoading("disliked") }>
            <HiOutlineHandThumbDown className="h-[40px] w-[40px]" />
          </button>
        </div>
      </>
      :

      <ItineraryLoadingsSkeleton />
    }
    </div>
  );
}

export default ItineraryLoading;
