import './Form.scss';

import http from './../../common/http';
import CustomModal from '../UIComponents/Modal/customModal';
import BookingCheckBox from '../UIComponents/InputFields/Checkbox';
import SearchDropdown from '../UIComponents/Dropdowns/SearchDropdown';
import SingleSelectDate from '../UIComponents/Dates/SingleSelectDate';
import MultiSelectDropdown from '../UIComponents/InputFields/MultiselectDropdown';
import SingleSelectDropdown from '../UIComponents/Dropdowns/SingleSelectDropdown';
import { setFormStateChanged } from '../../redux/slices/formChanged';
import { triggerMixpanelEvent } from '../../common/mixpanel';

import React, { useEffect, useState, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { string, array, object } from 'yup';
import { useNavigate } from 'react-router-dom';
import { setVibes } from '../../redux/slices/vibes';
import { setCities } from '../../redux/slices/cities';
import { setHeader } from '../../redux/slices/header';
import { BtnTransparent } from '../UIComponents/buttons';
import { reset } from '../../redux/slices/tripPlan';
import { setPlacesName } from '../../redux/slices/placesName';
import { setOccasions } from './../../redux/slices/occassions';
import { setPriorities } from './../../redux/slices/priorities';
import { setThingsToAvoid } from '../../redux/slices/thingsToAvoid';
import { resetState } from '../../redux/slices/locationsCoordination';
import { IoClose } from "react-icons/io5";
import { compact } from 'lodash';
import { autoOpenCalendarDate } from '../../common/shared/calendarEventsFunctions';

const ItineraryForm = ({ activeOption, setActiveOption, onClose, isMobile, routeParams = {}, SetFromModify, fromModify = false, showInspiration = true }) => {
  const formStateChanged = useSelector(state => state.formChanged.formStateChanged);
  const toast = useRef(null);
  const budgetRef = useRef(null);
  const startDateRef = useRef(null);
  const endDateRefs = useRef([]);
  const destinationRef = useRef(null);
  const searchParams = new URLSearchParams(location.search);

  const header = useSelector(state => state.header.header.build_a_trip);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modalType, setModalType] = useState('');
  const [isVisible, setVisible] = useState(false);
  const [formData, setFormData] = useState({ dates: {}, departureAirports: {}, destinations: [] , hotelBooked: false, flightBooked: false });
  const [fieldOptions, setFieldOptions] = useState({ city: [], priority: [], occasion: [], things_to_avoid: [], flight_booking_cities: [] });
  const [travelersCout] = useState(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
   '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'
  ]);
  const [spendingValue] = useState(["Budget conscious ($)", "Reasonable ($$)", "Let’s splurge a bit ($$$)", "Let’s go crazy ($$$$)"]);

  const handleInput = (fieldName, newValue, index = null) => {
    setFormData((prevData) => {
      if (index !== null) {
        const updatedDestinations = [...prevData.destinations];
        const destinationName = updatedDestinations[index];
        if (fieldName == 'departureAirports') {
          return {
            ...prevData,
            departureAirports: {
              ...prevData.departureAirports,
              [destinationName]: newValue,
            },
          };
        } else {
          return {
            ...prevData,
            dates: {
              ...prevData.dates,
              [destinationName]: {
                ...prevData.dates[destinationName],
                [fieldName]: newValue,
              },
            },
          };
        }
      } else {
        return {
          ...prevData,
          [fieldName]: newValue,
        };
      }
    });
  };

  useEffect(() => {
    if (formData.destinations.length) {
      dispatch(setFormStateChanged(true));
      if (fromModify) {
        dispatch(setFormStateChanged(false));
        SetFromModify(false);
      }
    }    
      else if (formData.destinations.length == 0) {
        if (formStateChanged) {
          dispatch(setFormStateChanged(false));
        }
      }
  }, [formData.destinations]);

  useEffect(() => {
    if (formData.dates && formData.destinations.length) {
      dispatch(setFormStateChanged(true));
      if (fromModify) {
        dispatch(setFormStateChanged(false));
      }
    }
}, [formData.dates]);

  useEffect(() => {
    if (header) {
      destinationRef.current && destinationRef.current.focus();
      dispatch(setHeader({ build_a_trip: false }));
    }
  }, [header]);

  useEffect(() => {
    setRouteParams();
    const params = { fields: ['city', 'priority', 'vibe', 'things_to_avoid', 'occasion', 'flight_booking_cities'] };
    http
      .get('/field_options.json', { params: params })
      .then((res) => {
        dispatch(setPriorities(res.data.priority));
        dispatch(setOccasions(res.data.occasion));
        dispatch(setVibes(res.data.vibe));
        dispatch(setThingsToAvoid(res.data.things_to_avoid));
        dispatch(setCities(res.data.city));
        setFieldOptions(res.data);
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching field options.', 'bg-red-100 text-red-700');
      });
  }, []);

  const setRouteParams = () => {
    if (Object.keys(routeParams).length) {
      const newFormData = { ...formData };
      if(routeParams.destinations.selectedCities) {
        newFormData.destinations = routeParams.destinations.selectedCities;
      } else {
        newFormData.destinations = routeParams.destinations;
      }
      if (routeParams.occasions) newFormData.occasions = routeParams.occasions;
      if (routeParams.occasion) newFormData.occasions = [routeParams.occasion];

      if (routeParams.priorities) newFormData.priorities = routeParams.priorities;
      if (routeParams.prioritize ) newFormData.priorities = routeParams.prioritize;

      if (routeParams.things_to_avoid) newFormData.things_to_avoid = routeParams.things_to_avoid;
      if (routeParams.avoid ) newFormData.things_to_avoid = routeParams.avoid;
      if (routeParams.budget) newFormData.budget = routeParams.budget;
      if (routeParams.travelers_count) newFormData.travelers_count = routeParams.travelers_count;

      if(Array.isArray(routeParams.dates)) {
        routeParams.dates.forEach((dateObj) => {
          const destination = Object.keys(dateObj)[0];
          const startDate = dateObj[destination].start_date;
          const endDate = dateObj[destination].end_date;
          newFormData.dates[destination] = { start_date: startDate, end_date: endDate };
        });
      } else {
        routeParams.destinations.selectedCities.forEach(element => {
          const startDate = routeParams.dates.start_date;
          const endDate = routeParams.dates.end_date;
          newFormData.dates[element] = { start_date: startDate, end_date: endDate };
        }); 
      }
      setFormData(newFormData);
    }
  };

  const buttons = {
    change: [
      {
        text: 'Exit without generating itinerary',
        onPress: () => {
          dispatch(setFormStateChanged(false));
          activeOption == "generate" ? setActiveOption('inspiration') : setActiveOption('generate');
          setVisible(false);
        }
      },
      {
        text: 'Stay on page',
        onPress: () => {
          setVisible(false);
        }
      },
    ],
    date: [
      {
        disabled: true,
        text: 'AI Model Dates',
        onPress: () => setVisible(false)
      },
      {
        text: 'Enter Date',
        onPress: () => {
          setVisible(false);
          startDateRef.current && startDateRef.current.focus();
        }
      },
    ],
    destination: [
      {
        text: 'Inspiration',
        onPress: () => {
          setVisible(false);
          navigate("/results");
        }
      },
      {
        text: 'Enter City',
        onPress: () => {
          setVisible(false);
          destinationRef.current && destinationRef.current.focus();
        }
      },
    ],
    budget: [
      {
        text: 'OK',
        onPress: () => setVisible(false)
      },
    ],
  };

  const submit = async () => {
    try {
      if (formData.destinations.length === 0) {
        setModalType('destination');
        setVisible(true);
        return;
      } else if (Object.keys(formData.dates).length === 0) {
        setModalType('date');
        setVisible(true);
        return;
      } else if(formData.dates) {
        for (let cityName in formData.dates) {
        // Check if dates are provided for each city selected
          if (Object.keys(formData.dates).length != formData.destinations.length){
            setModalType('date');
            setVisible(true);
            return;
          }
          // check if each city has two dates
          if (formData.dates.hasOwnProperty(cityName)) {
            let cityObject = formData.dates[cityName];
              if(Object.keys(cityObject).length<2){
                setModalType('date');
                setVisible(true);
                return;
              }          
            }
        }
      }

      if (formData.priorities?.includes('Budget') && !formData.budget) {
        budgetRef.current && budgetRef.current.focus();
        return showMessage('error', 'Error', 'Please select the budget.', 'bg-red-100 text-red-700');
      }

      await fieldsSchema.validate({
          destinations: formData.destinations,
          dates: formData.dates,
        }, { abortEarly: false }
      );

      const queryString = Object.keys(formData).map((key) => {
        if (key === 'dates') {
          const dateArray = Object.entries(formData[key]).map(([destination, dates]) => ({
            [destination]: {
              start_date: dates.start_date,
              end_date: dates.end_date,
            }
          }));
          return `${key}=${encodeURIComponent(JSON.stringify(dateArray))}`;
        }
        if (key === 'dates') {
          const departureAirports = Object.entries(formData[key]).map(([destination, departureAirports]) => ({
            [destination]: departureAirports
          }));
          return `${key}=${encodeURIComponent(JSON.stringify(departureAirports))}`;
        }
        return `${key}=${encodeURIComponent(JSON.stringify(formData[key]))}`;
      }).join('&');
      dispatch(reset());
      dispatch(setPlacesName([]));
      dispatch(resetState());
      if (showInspiration) {
        navigate(`/trip-plan?${queryString}`);
      } else {
        window.location.href = `/trip-plan?${queryString}`;
      }
      triggerMixpanelEvent('LP - General Itinerary button');
    } catch (error) {
      if (error.inner) {
        const errorMessages = error.inner.map((e) => e.message);

        showMessage('error', 'Error', (
          <ul>
            {errorMessages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        ), 'bg-red-100 text-red-700');
      }
    }
  };

  const fieldsSchema = object({
    destinations: array()
      .of(string())
      .required('At least one destination is required.'),
    dates: object().required('Dates are required.'),
  }).test(
    'endDate',
    'Start date must be less than End date',
    ({ destinations, dates }) => {
      destinations.forEach((destination) => {
        const startDate = dates[destination]?.start_date;
        const endDate = dates[destination]?.end_date;
        if ( !startDate || !endDate || new Date(startDate) >= new Date(endDate)) {
          return false;
        }
      });
      return true;
    }
  );

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const travelDates = (name, index) => {
    endDateRefs.current[index] = React.createRef();
    const destinationData = formData.dates[name] || {};
    const startDate = destinationData.start_date || '';
    const endDate = destinationData.end_date || '';

    const getEndDate=(index)=>{
      return formData.dates?.[formData.destinations[index]]?.end_date;
    };

    const getStartDate=(index)=>{
      return formData.dates?.[formData.destinations[index]]?.start_date;
    };

    return (
      <div key={index} className='mb-2'>
        <label
          className={`text-[14px] letter-spacing block`}
          style={{ background: "linear-gradient(360deg, #fff, #fff, #fff, transparent, transparent)" }}
        >
          Travel dates &nbsp;
          { formData.destinations.length > 0 && (
            <span> ({ name }) </span>
          )}
        </label>
        <div className='flex justify-between items-center'>
          <div>
            <SingleSelectDate
              ref={startDateRef}
              selectedDate={startDate}
              minDate={new Date()}
              maxDate={(formData?.end_date && !multiDestinations() && new Date(formData.end_date) || getEndDate(index) && new Date(getEndDate(index)))}
              handleDateChange={(val) => { handleInput('start_date', val, index), autoOpenCalendarDate((formData?.end_date && !multiDestinations() && new Date(formData.end_date) || getEndDate(index) && new Date(getEndDate(index))), endDateRefs.current[index]); }}
            />
          </div>
          <div className='px-2'>
            <span> - </span>
          </div>
          <div>
            <SingleSelectDate
              ref={endDateRefs.current[index]}
              selectedDate={endDate}
              handleDateChange={(val) => handleInput('end_date', val, index)}
              minDate={(formData?.start_date && !multiDestinations() && new Date(formData.start_date) || getStartDate(index) && new Date(getStartDate(index))) || new Date()}
            />
          </div>
        </div>
        <div className='flex justify-between items-center'>
          <div className='w-full'>
            <SearchDropdown
              key='departure'
              props={{
                label: `Departure airport ${formData.destinations.length > 0 ? `(${name})` : ''}`,
                options: fieldOptions.flight_booking_cities ,
                chipsColor: 'blue-chips-background',
                selectedOptions: compact([formData.departureAirports?.[name]]) || [],
                placeHolder: 'Enter departure city',
                dropDownMargin: 'mt-[63px]'
              }}
              setSaveData={ (val) => handleInput('departureAirports', val.pop(), index) }
              removeDestination={ () => {} }
            />
          </div>
        </div>
      </div>
    );
  };

  const multiDestinations = () => {
    return formData.destinations.length > 0;
  };

  const handleRemoveDestination = (removedDestination) => {
    setFormData((prevData) => {
      const updatedDates = { ...prevData.dates };
      delete updatedDates[removedDestination];
      return {
        ...prevData,
        dates: updatedDates,
      };
    });
  };

  const changeNavigation = (where) => {
      if (formStateChanged) {
        setModalType('change');
        setVisible(true);
      } else {
        setActiveOption(`${where}`);
      }
  };
  
  return (
    <div className={`bg-white m-3 lg:p-8 p-2 rounded block gap-y-5 ${!showInspiration ? 'overflow-y-auto' : ''}`}>
      <Toast ref={toast} position="bottom-right" />

      <div className="relative">
        { !showInspiration &&
          <button
          className="absolute top-2 right-2 flex items-center justify-center"
          onClick={ onClose }
          >
            <IoClose className='w-4 h-4'/>
          </button>
        }
        <div className="flex flex-col">
          <div className="flex justify-between items-end w-full pb-3">
            <span
              className={`option cursor-pointer border-periwinkle text-periwinkle z-20 border-b-2 pb-3 mt-2  z-9999 font-bold ${activeOption === 'generate' ? 'active' : ''}`}
              onClick={() => changeNavigation('generate')}
            >
              Generate itinerary
            </span>
            { showInspiration &&
              <span
                className={`option pb-3 mt-2 cursor-pointer ${activeOption === 'inspiration' ? 'active' : ''}`}
                onClick={() => changeNavigation('inspiration')}
              >
                Look for inspiration
              </span>
            }
          </div>
          <hr className={`w-full border line-mrgin ${activeOption === 'generate' ? 'border-gray-400' : 'border-gray-400'}`} />
        </div>
      </div>

      {isVisible &&
        <CustomModal
          type={ modalType }
          isMobile={ isMobile }
          isVisible={ isVisible }
          setVisible={ setVisible }
          buttons={ buttons[modalType] }
        />
      }

      <div>
        <SearchDropdown
          key='destinations'
          ref={destinationRef}
          props={{
            label: 'Destination',
            options: fieldOptions.city,
            chipsColor: 'blue-chips-background',
            selectedOptions: formData.destinations,
            placeHolder: 'Enter a city',
            dropDownMargin: 'mt-[63px]'
          }}
          setSaveData={ (val) => handleInput('destinations', val) }
          removeDestination={ (removedDestination) => handleRemoveDestination(removedDestination) }
        />

        { !multiDestinations() ? (
          travelDates(null)
        ) : (
          formData.destinations.map((name, index) => (
            <div key={ index }>
              { travelDates(name, index) }
            </div>
          ))
        )}

        <MultiSelectDropdown
          ranking
          allowDrag
          subCategories
          key='priority'
          className={ 'mb-3' }
          Label={ 'Priorities' }
          dropdownWidth='sm:w-[300px]'
          items={fieldOptions['priority']}
          selectedItems={ formData.priorities }
          chipsColor='yellow-chips-background'
          setSaveData={ (val) => handleInput('priorities', val) }
        />

        <MultiSelectDropdown
          key='occasion'
          Label={'Occasion'}
          dropdownWidth={'w-[424px]'}
          items={fieldOptions['occasion']}
          selectedItems={ formData.occasions }
          chipsColor='pink-chips-background'
          setSaveData={(val) => handleInput('occasions', val) }
        />

        <MultiSelectDropdown
          subCategories
          key='things_to_avoid'
          className={ 'mb-3' }
          dropdownWidth={'w-[424px]'}
          Label={'Things I want to avoid'}
          items={fieldOptions['things_to_avoid']}
          selectedItems={ formData.things_to_avoid }
          chipsColor='violet-chips-background'
          setSaveData={(val) => handleInput('things_to_avoid', val) }
        />

        <div className='flex justify-between gap-x-2'>
          <div className='w-full'>
            <SingleSelectDropdown
              props={{
                label: '# of Travelers',
                options: travelersCout,
                dropDownwidth: 'w-full',
                value: formData.travelers_count,
                labelStyle: 'text-[14px] letter-spacing block'
              }}
              handleChanges={ (val) => handleInput('travelers_count', val.value) }
            />
          </div>

          <div className='w-full'>
            <SingleSelectDropdown
              ref={budgetRef}
              props={{
                label: 'Budget',
                options: spendingValue,
                value: formData.budget,
                dropDownwidth: 'w-full',
                labelStyle: 'text-[14px] letter-spacing block'
              }}
              handleChanges={ (val) => {
                setModalType('budget');
                setVisible(true);
                handleInput('budget', val.value);
              }}
            />
          </div>
        </div>

        <BookingCheckBox handleInput = { handleInput }/>

      </div>


        <BtnTransparent
          props={{
            bg: 'bg-periwinkle',
            border: 'border-periwinkle',
            buttonName: 'Generate itinerary',
            text: 'text-white mt-4 w-full h-[40px] font-semibold',
          }}
          handleClick={ submit }
        />
    </div>
  );
};

export default ItineraryForm;
