import './Destinations.scss';

import http from '../../common/http';
import Layout from '../Layout/Layout';
import UpcomingTrips from '../Upcoming/UpcomingTrips';
import Warning from '../../../assets/images/warning.svg';
import Checkmark from '../../../assets/images/checkmark.svg';
import CustomCard from '../UIComponents/Cards/CustomCard';

import { Card } from 'primereact/card';
import { Link, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Carousel } from 'primereact/carousel';
import React, { useEffect, useState, useRef } from 'react';
import DestinationsSkeleton from '../Skeletons/DestinationsSkeleton';
import PlaceholderSkeleton from '../Skeletons/PlaceholderSkeleton';
import { useDispatch } from 'react-redux';
import { reset } from '../../redux/slices/tripPlan';

const Destinations = ({ fromTripsSection = false, setIsDestinationsVisible }) => {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [favorites, setFavorites] = useState([]);
  const [savedTrips, setSavedTrips] = useState([]);
  const [upcomingTrips, setUpcomingTrips] = useState([]);
  const [isLoading, setIsLoading]=useState(false);

  useEffect(() => {
    setIsLoading(true);
    http
      .get('/upcoming_trips')
      .then((res) => {
        const upcoming = res.data.filter(trip => trip.saved);
        const saved = res.data.filter(trip => !trip.saved);

        setUpcomingTrips(upcoming);
        setSavedTrips(saved);
        setIsLoading(false);
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching trips.', 'bg-red-100 text-red-700');
      });

    http
      .get('/favorites')
      .then((res) => {
        setFavorites(res.data.favorite_cities);
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching trips.', 'bg-red-100 text-red-700');
      });
  }, []);

  const header = (trip) => (
    <div className="header-img">
      { trip?.imageUrl ? 
          <img
            alt='Card'
            src={ trip?.imageUrl }
            className='h-full w-full object-cover hover:bg-black hover:inset-0'
          /> :
          <PlaceholderSkeleton applyClass = { 'h-full w-full bg-light-gray object-cover' } />
      }
    </div>
  );

  const handleNavigation = (trip) => {
    dispatch(reset());
    navigate(`/trip-plan/${trip.id}`);
  };


  const itemTemplate = (trip) => (
    <div
      key={ trip.id }
      className='px-2'
    >
      <Link
        onClick={() => handleNavigation(trip)}
        className='text-center'
      >
        <div className='card-container relative overflow-hidden border w-30 mt-8'>
          <Card header={ header(trip) }>
            <div className='p-3'>
              <div className='flex justify-between items-center min-h-[48px] text-sm font-semibold'>
                <div className='flex flex-col items-start'>
                  <div className={`${ fromTripsSection ? 'font-monsterrat' : 'text-xl font-bold font-serif'}`}>
                    { trip?.city_name || 'Barcelona' }, { trip?.country_name || 'Spain' }
                  </div>
                  <div className='text-gray-400'>
                    { trip?.start_date || 'Nov. 11, 2023' } - { trip?.end_date || 'Dec 1, 2023' }
                  </div>
                </div>
                <div className='flex flex-col items-start'>
                  <div className='flex text-l lg:flex-row flex-col text-gray-700 items-center'>
                    <img src={ trip?.saved ? Checkmark : Warning } className="h-4 w-auto cursor-pointer pr-2" alt='WePlan logo'/>
                    { trip?.saved ? "Hotel booked" : "Hotel not booked" }
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </Link>
    </div>
  );

  const renderCarousel = (title, data, itemTemplate, items, itemWidth) => (
    <>
      {data.length > 0 && (
        <>
          <div className={` ${ fromTripsSection ? 'text-xl font-bold mb-4' : `text-2xl pl-5 font-semibold ${items === 4 ? 'mb-8' : 'mb-4'}`}`}>{title}</div>
          <Carousel
            value={ data }
            numScroll={ 1 }
            className='lg:mb-16 mb-8'
            itemTemplate={ itemTemplate }
            showNavigators={ data?.length > items }
            numVisible={ data?.length < items ? data?.length : items }
            style={{ paddingLeft: data?.length > items ? '' : '10px', width: itemWidth }}
          />
        </>
      )}
    </>
  );

  const favoritesTemplate = (trip, index) => (
    <div
      key={ index }
      className='card bg-white p-4 border mx-2'
    >
      <CustomCard
        props={ trip }
        detailButton={ false }
        cardType="CityPlaceCard"
      />
    </div>
  );

  const blogTemplate = (trip, index) => (
    <div
      key={ index }
      className='card bg-white p-4 h-[330px] border shadow-md mx-2'
    >
      <CustomCard
        props={ trip }
        detailButton={ false }
      />
    </div>
  );

  const largerCards = (trips) => trips?.length === 1 ? '50%' : '100%';
  const smallCards = (trips) => trips?.length === 3 ? '75%' : trips?.length === 2 ? '50%' : trips?.length === 1 ? '25%' : '100%';

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  return (
    <Layout headerCSS={ 'bg-[#FFFCF9]' } footerCSS={ 'hidden' } >
      <Toast ref={toast} position="bottom-right" />
    {
      isLoading ? 
      <DestinationsSkeleton /> : 
      <div className={`flex flex-col w-full bg-[#FFFCF9] ${ fromTripsSection ? '' : 'lg:pt-24 lg:px-8 px-3' }`}>
        <div className={`${ fromTripsSection ? 'flex flex-row justify-between items-center text-2xl font-normal mb-4 font-playfair' : 'lg:text-4xl text-3xl lg:mb-16 mb-8 lg:mt-8 mt-14 font-semibold pl-4' }`}>Destinations { fromTripsSection ? <div className='flex justify-end'>
        <h1
          className='lg:text-xl text-md font-bold mb-4 mt-4 cursor-pointer text-color'
          onClick={ () => setIsDestinationsVisible((prevValue) => !prevValue) }
        >
         &lt; back
          
        </h1>
      </div> : <></>}</div>

        { !fromTripsSection && renderCarousel('Upcoming Trips', upcomingTrips, itemTemplate, isMobile ? 1 : 2, largerCards(upcomingTrips))}
        {renderCarousel('Saved Trips', savedTrips, itemTemplate, isMobile ? 1 : 2, largerCards(savedTrips))}

        { !fromTripsSection && !isMobile && <UpcomingTrips />}

        { !fromTripsSection && renderCarousel('Favorites', favorites, favoritesTemplate, isMobile ? 1 : 4, isMobile ? '100%' : smallCards(favorites) )}
        { !fromTripsSection && renderCarousel('Check out our trending blogs', upcomingTrips, blogTemplate, isMobile ? 1 : 4, smallCards(upcomingTrips) )}
      </div>
    }
    </Layout>
  );
};

export default Destinations;
