import React, { useState, useEffect } from 'react';

const Steps = ({ totalSteps, stepIndex }) => {
  const [currentStep, setCurrentStep] = useState(stepIndex);

  useEffect(() => {
    setCurrentStep(stepIndex);
  }, [stepIndex]);

  return (
    <div>
      <div className="flex space-x-4">
        {[...Array(totalSteps)].map((_, index) => (
          <div
            key={index}
            className={`step flex items-center justify-center h-2 w-full ${
              index + 1 <= currentStep ? 'bg-periwinkle' : 'bg-gray-200'
            }`}
          >
          </div>
        ))}
      </div>
    </div>
  );
};

export default Steps;
