import { createBrowserRouter } from "react-router-dom";
import LandingPageRoutes from './LandingPageRoutes';
import TripPlanRoutes from './TripPlanRoutes';
import ResultsRoutes from './ResultsRoutes';
import ProfilePageRoutes from './ProfilePageRoutes';
import ReferralRoutes from "./ReferralRoutes";
import LocalExpertProgramRoutes from "./LocalExpertProgramRoutes";
import BlogPageRoutes from "./BlogPageRoutes";
import InStayPageRoutes from "./InStayPageRoutes";
import FlightPagesRoutes from "./FlightPagesRoutes";
import PriceMatchRoutes from "./PriceMatchRoutes";

const routes = [...LandingPageRoutes, ...TripPlanRoutes, ...ResultsRoutes,
  ...ProfilePageRoutes, ...LocalExpertProgramRoutes, ...ReferralRoutes, ...BlogPageRoutes, ...InStayPageRoutes, ...FlightPagesRoutes,
  ...PriceMatchRoutes
];

const BrowserRouter = createBrowserRouter(routes);

export default BrowserRouter;
