import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Rating } from 'primereact/rating';
import { BtnTransparent } from '../buttons';
import { useNavigate } from 'react-router-dom';
import HotelDetailModal from '../HotelDetailModal';
import PlaceholderSkeleton from '../../Skeletons/PlaceholderSkeleton';

export default function TripCard({ hotel }) {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isDialogVisible, setDialogVisible] = useState(false);
  const openDialog = () => {
    setDialogVisible(true);
  };
  const closeDialog = () => {
    setDialogVisible(false);
  };

  const header = (
    <div className='relative lg:w-full lg:h-[308px]'>
      { hotel.img_url ? 
          <img
            src={ hotel.img_url }
            className='max-w-full max-h-[308px] min-h-[308px] hover:bg-black hover:bg-opacity-50 hover:inset-0'
            alt='Card' 
          /> :
          <PlaceholderSkeleton applyClass = { 'w-full max-h-[308px] min-h-[308px] bg-light-gray hover:bg-black hover:bg-opacity-50 hover:inset-0' } />
      }
    </div>
  );

  const bookHotel = () => {
    navigate(`/trip-plan/show-hotel/${hotel.id}`);
  };

  const price = () => hotel.additional_detail;

  return (
    <div
      className={ 'card-container relative overflow-hidden shadow-md' }
      onMouseEnter={ () => setIsHovered(true) }
      onMouseLeave={ () => setIsHovered(false) }
    >
      <Card header={ header } className={ 'lg:w-full' } >
        { isHovered && <div className={ `bg-black bg-opacity-50 absolute inset-0 transition-opacity` } /> }
        <div className='lg:p-3 px-3 mb-2'>
          <div className='flex justify-between items-center min-h-[48px] text-base'>
            <div> { hotel.name } </div>
            <div>
              { price().estimated_price ? `${price().estimated_price}` :
                price().ai_suggested_price ? `${price().ai_suggested_price}` :
                price().price_level ? price().price_level : '-'
              }
            </div>
          </div>
          <Rating
            value={ hotel?.additional_detail?.rating }
            cancel={ false }
            className='text-yellow-500'
          />
        </div>
        { isHovered && (
          <div className='flex flex-col justify-center items-center absolute inset-0 gap-y-4'>
            <BtnTransparent
              props={{ 
                text: 'text-white text-base font-semibold',
                bg: 'bg-periwinkle',
                border: 'border-periwinkle mb-2 ',
                buttonName: 'Book now'
              }}
              handleClick={bookHotel}
            />

            <BtnTransparent
              props={{
                text: 'text-white text-base font-semibold',
                bg: 'bg-transparent ',
                buttonName: 'See details'
              }}
              handleClick={openDialog}
            />
          </div>
        )}
      </Card>
      {isDialogVisible && (
        <HotelDetailModal
          hotelId={ hotel.id }
          onClose={ closeDialog }
          isVisible={ isDialogVisible }
        />
      )}
    </div>
  );
}
