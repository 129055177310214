import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tripPlanParams: '',
};

export const itineraryPlanSlice = createSlice({
  name: "tripPlanParams",
  initialState,
  reducers: {
    setTripPlanParams: (state, action) => {
      state.tripPlanParams = action.payload;
    },
  },
});

export const { setTripPlanParams } = itineraryPlanSlice.actions;

export default itineraryPlanSlice.reducer;
