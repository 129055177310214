import React, { useEffect, useState } from 'react';
import MultiSelectDropdown from '../UIComponents/InputFields/MultiselectDropdown';
import Slider from '@mui/material/Slider';

import { BtnTransparent } from '../UIComponents/buttons';
import { Box, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useLocation } from 'react-router-dom';

const HotelFilterForm = ({ props, handleFilter }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [isMobile, setIsMobile] = useState(false);
  const [priceRange, setPriceRange] = useState([]);
  const [formData, setFormData] = useState({});

  const handleInput = (fieldName, newValue) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [fieldName]: newValue,
      };
    });
  };

  const updatePriceRange = (name, newValue) => {
    const newPriceRange = [...priceRange];
    newPriceRange[name] = newValue;
    setPriceRange(newPriceRange);
  };
  
  const handleSliderChange = (event) => {
    setPriceRange(event.target.value);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const applyFilters = () => {
    const params = { form_data: formData, price_range: priceRange, city_name: searchParams.get('city') };
    handleFilter(params);
  };

  return (
    <div className={`${isMobile ? 'flex' : ''} lg:col-span-3 sm:col-span-12 space-y-2 rounded h-full`}>
      <div className={`bg-light-gray border border-gray-300 ${isMobile ? 'p-3' : 'p-5'}`}>
        { !isMobile &&
          <div className='font-semibold text-xl my-3'>
            Filters
          </div>
        }
        { isMobile &&
          <div className='flex flex-between'>
            <div className='font-semibold text-xl my-3'>
              Filters
            </div>
            <div
              onClick={() => props.setShowModal(false)}
              className='absolute right-6 top-0 mt-10 font-semibold text-xl my-3'
            >
              X
            </div>
          </div>
        }
        <div className='flex flex-col flex-grow w-full'>
          <div className='flex flex-col'>
            <div className='mt-5 flex-1'>
              <label className='text-sm font-normal text-light-black'>
                Price Range
              </label>
              <div className='flex col justify-between items-center gap-x-4 mt-3'>
                <div className='flex flex-col'>
                  <label className='text-sm font-normal text-light-black'>
                    Min
                  </label>
                  <input
                    type="number"
                    className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                    value={ priceRange[0] }
                    min={ 0 }
                    onInput={ (e) => updatePriceRange(0, e.target.value) }
                  />
                </div>
                <h1 className='font-bold'> - </h1>
                <div className='flex flex-col'>
                  <label className='text-sm font-normal text-light-black'>
                    Max
                  </label>
                  <input
                    type="number"
                    className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                    value={ priceRange[1] }
                    min={ priceRange[0] }
                    onInput={ (e) => updatePriceRange(1, e.target.value) }
                  />
                </div>
              </div>
            </div>
            <div className='my-5 flex-1'>
              <Slider
                min={ 0 }
                max={ 1500 }
                style={{ color: "#D6A9E0", height: "10px" }}
                value={ priceRange.length ? priceRange : [0, 50] }
                valueLabelDisplay="auto"
                onChange={ handleSliderChange }
              />
            </div>
          </div>
          <div className='flex flex-col'>
            <label className='text-sm font-normal text-light-black'>
              View
            </label>
            <MultiSelectDropdown
              key='View'
              items={[]}
              dropdownWidth='sm:w-[300px]'
              className={ 'text-light-black' }
              chipsColor='yellow-chips-background'
              setSaveData={ (val) => handleInput('views', val) }
            />
          </div>
          <div className='flex flex-col '>
            <label className='text-sm font-normal text-light-black'>
              Style
            </label>
            <MultiSelectDropdown
              key='Style'
              items={ props.filterOptions.styles }
              dropdownWidth='sm:w-[300px]'
              className={ 'text-light-black' }
              chipsColor='yellow-chips-background'
              setSaveData={ (val) => handleInput('styles', val) }
            />
          </div>
          <div className='flex flex-col'>
            <label className='text-sm font-normal text-light-black'>
              Amenities
            </label>
            <MultiSelectDropdown
              items={ props.filterOptions.amenities }
              key='Amenities'
              className={'text-light-black'}
              chipsColor='yellow-chips-background'
              setSaveData={ (val) => handleInput('amenities', val) }
            />
          </div>
          <Box className='mb-5' >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                  />
                }
                label='Centrally Located'
              />
            </FormGroup>
          </Box>
          <div className='flex flex-col mb-5'>
            <label className='text-sm font-normal text-light-black'>
              Trip Type
            </label>
            <MultiSelectDropdown
              items={ props.filterOptions.trip_types }
              className={ 'text-light-black' }
              chipsColor='yellow-chips-background'
              setSaveData={ (val) => handleInput('types', val) }
            />
          </div>
          <BtnTransparent
            props={{
              text: 'text-periwinkle hover:text-white',
              bg: 'bg-transparent hover:bg-periwinkle',
              border: 'border-periwinkle',
              buttonName: 'Apply filters'
            }}
            handleClick={ applyFilters }
          />
        </div>
      </div>
    </div>
  );
};

export default HotelFilterForm;
