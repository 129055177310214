import React from 'react';

import { Dialog } from 'primereact/dialog';

const CostBreakdownModal = ({ isVisible, setVisible, props }) => {
  const { food, activity, accommodation, total } = props || {};

  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  return (
    <Dialog
      visible={isVisible}
      className={`${isMobile ? 'w-[300px]' : 'w-[400px]'} p-6 lg:p-30 bg-white`}
      setVisible={setVisible}
      onHide={() => setVisible(false)}
      header= {(
        <div className='flex justify-between mb-3'>
          <p className='font-playfair text-2xl'>
            Breakdown
          </p>
        </div>
      )}
      footer={
        <div className='flex justify-center flex-col p-0 lg:p-3'>
          <p className="text-center text-sm text-gray-500 mt-4 font-sans">
            These are assumed costs to give you a rough estimate of how much things will cost. Actual cost would vary greatly based on decisions the traveler(s) make.
          </p>
        </div>
      }
    >
      <div className="flex flex-col w-full space-y-4 border-b-2 pb-2 font-sans">
        <CostItem 
          label="Transport"
          value={ 'Not available' } 
        />
        <CostItem
          label="Accommodation"
          value={ '$' + accommodation } 
        />
        <CostItem
          label="Food & Dining"
          value={ '$' + food }
          innerText='(Lunch + Dinner)' 
        />
        <CostItem
          label="Activities & Entertainment"
          value={ '$' + activity }
        />
        <CostItem
          label="Total"
          value={ '$' + total } 
          bold 
        />
      </div>
    </Dialog>
  );
};

const CostItem = ({ label, value, bold = false, innerText }) => (
  <div className="flex justify-between mb-4">
    <div className='flex flex-col'>
      <p className={ `${bold ? 'font-bold' : ''} text-gray-900 font-sans text-base` }>
        { label }
      </p>
      { innerText &&
        <p className={ `text-sm text-gray-700` }>
          { innerText }
        </p>
      }
    </div>
    <p className={`${bold ? 'font-bold' : ''} text-gray-900 text-base font-sans`}>
      { value }
    </p>
  </div>
);

CostBreakdownModal.defaultProps = {
  isMobile: false,
  isVisible: false,
  onHide: () => {},
};

export default CostBreakdownModal;
