import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import http from '../common/http';
import HotelRecommendation from './HotelRecommendation';
import LocationDetail from './LocationDetail';
import TripPlaceSwapModal from './Modals/TripPlaceSwap/TripPlaceSwap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BtnTransparent } from './UIComponents/buttons';
import PriceMatchButton from './PriceMatch/PriceMatchButton';
import { isLoggedIn } from '../common/auth';
import { LiaEditSolid } from "react-icons/lia";
import { IoExitOutline } from "react-icons/io5";
import { Dialog } from 'primereact/dialog';
import { Carousel } from 'primereact/carousel';
import CustomCard from './UIComponents/Cards/CustomCard';
import { isEmpty } from 'lodash';
import { Toast } from 'primereact/toast';

export const DayView = forwardRef(({ itinerary, activeTab, hotelsData, suggestions, tripId, swap, activeTabIndex, removePlace, tabs, editItineraryProps, addToItinerary, markerProps }, ref) => {
  const navigate = useNavigate();
  const tripPlan = useSelector(state => state.tripPlan.tripPlan);
  const [hotel, setHotel] = useState({});
  const [hotelInstayInfo, setHotelInstayInfo] = useState({});
  const [hotelIndex, setHotelIndex] = useState(-1);
  const [checkInDate, setCheckInDate] = useState('');
  const [showEats, setShowEats] = useState(false);
  const toast = useRef(null);
  const isHotelBooked = () => {
    return tripPlan[hotelIndex]?.travelers_info?.isBooked || hotel?.is_booked;
  };

  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  const items = () => isMobile ? 1 : 4;
  const showIndicators = (data) => data?.length > items();

  const itemTemplate = (restaurant, index, type = '') => {
    const cardType = type || 'CityPlaceCard';
    return (
      <div
        key={ index }
        className='card bg-white p-4 h-[380px] shadow-md m-2'
      >
        <CustomCard
          props={ restaurant }
          cardType={ cardType }
          addToItinerary={addToItinerary}
          lateNightEats={true}
          setLateActivity={setLateActivity}
        />
      </div>
    );
  };

  const smallCards = (trips) => trips?.length === 3 ? '75%' : trips?.length === 2 ? '50%' : trips?.length === 1 ? '25%' : '100%';

  useEffect(() => {
    if (hotelsData && itinerary) {
      const plan = tripPlan.filter(plan => plan.cityName === itinerary.cityName)[0];
      if (plan.hotel_details) {
        const date = new Date(plan.hotel_details.checkIn);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const dateFormat = `${month}/${day}`;
        setCheckInDate(dateFormat);
      }
      setHotel(hotelsData[itinerary['cityName']]);
      const hotelIdx = Object.keys(hotelsData).findIndex((name) => name == itinerary['cityName'] );
      setHotelIndex(hotelIdx);
    }
  }, [activeTab]);

  useEffect(() => {
    const fetchHotelInstayInfo = () => {
      const params = {
        hotel_id: hotelsData[itinerary['cityName']].id,
        trip_id: tripId
      };
      http
        .get('/hotel_instay_info.json', { params })
        .then((res) => {
          if (res.data.hotel_instay_info && Object.keys(res.data.hotel_instay_info).length) {
            setHotelInstayInfo(res.data.hotel_instay_info);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    if (isLoggedIn()) {fetchHotelInstayInfo();}
  }, [tripId]);

  const [currentActivity, setCurrentActivity] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [timeSpecificSwapping, setTimeSpecificSwapping] = useState(false);
  const swapActivity = (activity, timeSpecific) => {
    setTimeSpecificSwapping(timeSpecific);
    setCurrentActivity(activity);
    setShowModal(true);
  };
  const handleOnDrop = (e) => {
    let activity = e.dataTransfer.getData('newActivity');
    activity = JSON.parse(activity);
    if (itinerary.cityName != activity.cityName) {
      showMessage('error', 'Error', 'City of activity and day plan should be same.', 'bg-red-100 text-red-700');
      return;
    }
    swapActivity(activity, true);
  };

  const [lateNightEatsData, setLateNightEatsData] = useState({});
  const setLateActivity = (data, time) => {
    const [startTime, endTime] = time.split(' - ');
    let swappingData = {
      currentActivity: data,
      newActivity: {},
      day: itinerary['cityDayNumber'],
      cityName: itinerary['cityName'],
      updatedTime: { startTime: startTime, endTime: endTime }
    };
    setLateNightEatsData(swappingData);
  };

  const footer = () => {
    return (
      <div className='flex justify-end my-4 px-8 gap-x-2'>
        <div className='w-[227px] h-[40px]'>
          <BtnTransparent
            props={{
              text: 'text-charcoal font-semibold',
              bg: 'bg-white',
              border: 'border-charcoal w-full',
              buttonName: 'Cancel',
            }}
            handleClick={() => setShowEats(false)}
          />
        </div>
        <div className='w-[227px] h-[40px]'>
          <BtnTransparent
            props={{
              text: 'text-white font-semibold',
              bg: 'bg-periwinkle',
              border: 'border-periwinkle w-full',
              buttonName: 'Confirm selection',
            }}
            handleClick={() => addLateNightEat()}
          />
        </div>
      </div>
    );
  };

  const addLateNightEat = () => {
    if (isEmpty(lateNightEatsData)) {
      showMessage('error', 'Error', 'Please select one activity.', 'bg-red-100 text-red-700');
    } else {
      setShowEats(false);
      swap(lateNightEatsData);
    }
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current?.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const doSwap = (newActivity, updatedTime) => {
    let swappingData = {
      currentActivity: currentActivity,
      newActivity: newActivity,
      day: itinerary['cityDayNumber'],
      cityName: itinerary['cityName'],
      updatedTime: updatedTime
    };
    swap(swappingData);
    markerProps.setSelectedMarker(null);
    markerProps.setMapPlaceChosen({});
  };

  const removeItinearyPlace = (activity, index) => {
    let removePlaceData = {
      currentActivity: activity,
      day: itinerary['cityDayNumber'],
      index: index,
      cityName: itinerary['cityName']
    };
    removePlace(removePlaceData);
    markerProps.setSelectedMarker(null);
    markerProps.setMapPlaceChosen({});

  };

  const navigateToItineraryForm = () => {
    const hotelsDataString = JSON.stringify(hotelsData[itinerary['cityName']]);
    const encodedHotelsData = encodeURIComponent(hotelsDataString);
    navigate(`/instayinitinerary?hotel=${encodedHotelsData}&tripId=${tripId}`);
  };


  useImperativeHandle (ref, ()=>{
    return {
      removeItinearyPlace,
      swapActivity,
      doSwap
    };
  });

  return (
    <>
      <div className='grid w-full bg-white items-center text-xl lg:px-11 px-3 pt-5'>
        <Toast ref={toast} position='bottom-right' />
        <TripPlaceSwapModal
          currentActivity={{ 'current': currentActivity, 'suggestions': suggestions[itinerary.cityName] }}
          open={showModal}
          date={tabs[activeTabIndex - 1]}
          timeSpecific={timeSpecificSwapping}
          onClose={() => setShowModal(false)}
          doSwap={(obj, updatedTime) => {
            setShowModal(false);
            doSwap(obj, updatedTime);
          }}
        />
        <div className='flex pt-6 justify-between'>
          <span className='mr-2 mb-2 text-2xl font-playfair'> { activeTab } </span>
          <div className='flex items-center gap-x-2'>
            <LiaEditSolid onClick={() => editItineraryProps.setIsEditItinerary(!(editItineraryProps.isEditItinerary))} className='w-10 h-10 cursor-pointer hover:scale-105 transition-all ease-in-out duration-300' />
            <IoExitOutline onClick={() => editItineraryProps.dayViewCollapseChange()} className='w-10 h-10 scale-x-[-1] cursor-pointer hover:scale-105 hover:scale-x-[-1] transition-all ease-in-out duration-300' />
          </div>
          {/* <div className='flex ml-auto'>
            <div className='w-12 h-12 bg-gray-300 rounded-full flex items-center justify-center ml-2'>
              <span className='text-xs'> Close </span>
            </div>
            <div className='w-12 h-12 bg-gray-300 rounded-full flex items-center justify-center ml-2'>
              <span className='text-xs'> Text </span>
            </div>
          </div> */}
        </div>
        {/* <div className='flex relative'>
          <div className='border-b border-dashed border-green-600 flex items-center z-10  mb-6'>
            <span className='w-4 h-4 bg-green-600 text-white rounded-full flex items-center justify-center ml-2'>
              <LuDollarSign />
            </span>
            <a href='#' className='font-monsterrat text-green-600 text-sm pl-1'>
              Price match guarantee
            </a>
          </div>
        </div> */}
        <PriceMatchButton/>
        {/* <div className='mt-6'>
          <CardHeader props={BarcelonaData} />
        </div>
        <div className='m-auto relative py-20'>
          <div
            className='border-black absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[100%]'
            style={{ border: 'dashed' }}
          ></div>
          <div className='relative z-10 inline-block bg-gray-300 rounded-md px-5 py-2'>
            <span className='flex'>
              <p>Uber ~ 32 min . 28 euros</p>
            </span>
          </div>
        </div> */}
        {
          !itinerary.isHotelBooked ?
            <>
              <div className='border mb-6 h-30'>
                <div className='bg-gray-300 text-base px-6 py-2'>
                  Hotel check-in
                </div>
                <div className='p-4 h-full overflow-y-auto'>
                  <HotelRecommendation
                    props={{ isDayView: true, }}
                    hotel={ hotel }
                    hotelIndex={ hotelIndex }
                    city={ itinerary.cityName }
                  />
                    { checkInDate.length ? (
                        <span className='text-sm px-7'>
                          Check-in starts at { checkInDate }
                        </span>
                      ) : null
                    }
                </div>
              </div>
              { isHotelBooked() && !Object.keys(hotelInstayInfo).length ? ( <div className='flex flex-col border-4 border-brown-orange  mb-6 h-30 p-4'>
                <h1 className="text-xl text-brown-orange font-bold mt-2">IN-STAY</h1>
                <h1 className='text-sm text-light-black font-bold mt-2 mb-4'>Tell us about your hotel!</h1>
                <BtnTransparent
                  props={{
                  text: 'text-white font-semibold',
                  bg: 'bg-periwinkle',
                  border: 'border-periwinkle w-full',
                  buttonName: 'Complete hotel Questions',
                  }}
                  handleClick={() => navigateToItineraryForm()}
                />
                </div> ) : <div></div>
              }
              <div className='m-auto relative py-20'>
                <div
                  className='border-black absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[100%]'
                  style={{ border: 'dashed' }}
                />
                <div className='relative z-10 inline-block bg-gray-300 rounded px-5 py-2 text-base'>
                  <p> Uber ~ 32 min . 28 euros </p>
                </div>
              </div>
            </>
             :
            <></>
        }
        {itinerary['places'].map((place, index) => {
          return (
            <>
              {index > 0 && (
                <div className='m-auto relative py-20'>
                  <div className='border-black absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[100%]' style={{ border: 'dashed' }}></div>
                  <div className='relative z-10 inline-block bg-gray-300 rounded px-5 py-2 text-base'>
                    <p>{place.travelTime}</p>
                    <p>{place.distance}</p>
                  </div>
                </div>
              )}
              <LocationDetail
                markerProps = { markerProps }
                props={ place }
                placeIndex={ index + 1 }
                swap={ swapActivity } 
                removePlace={ removeItinearyPlace }
                isEditItinerary = { editItineraryProps.isEditItinerary }
              />
            </>
          );
        })}
        {
          editItineraryProps.isEditItinerary
          ? 
            <div>
              <div className='relative py-20'>
                <div>
                  <div
                    className='border-black absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[100%]'
                    style={{ border: 'dashed' }}
                  />
                  <div
                    className='relative z-10 inline-block bg-white px-5 py-2 border border-grey-500 h-[150px] flex flex-col justify-center items-center'
                    onDrop={(e) => handleOnDrop(e)}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <p className='text-base'>Drag or add an activity</p>
                    <p
                      className='text-periwinkle text-base cursor-pointer'
                      onClick={() => setShowEats(true)}
                    >
                      Add +
                    </p>
                  </div>
                </div>
              </div>
              <div className="my-4">
                <div className='font-playfair relative z-10 inline-block bg-white rounded px-5 my-8 h-[150px]'>
                  Add a late night snack to close off the night. We have a whole list of places to choose from:
                  <BtnTransparent
                    props={{
                      text: 'text-periwinkle font-semibold mt-4',
                      bg: 'bg-white',
                      border: 'border-periwinkle w-full',
                      buttonName: 'Browse late night eats',
                      }}
                    handleClick={() => setShowEats(true)}
                  />
                </div>
              </div>
            </div>
          : <div></div>
        }
        <Dialog
          header={'Late night eats'}
          headerClassName="text-2xl px-8 pt-8 font-playfair"
          footer={footer}
          visible={showEats}
          dismissableMask={true}
          style={{ width: '1192px', height: '1817px', backgroundColor: 'white', overflow: 'hidden' }}
          draggable={false}
          closable={true}
          blockScroll={true}
          responsive={true}
          resizable={false}
          onHide={() => setShowEats(false)}
        >
          <div className='px-8'>
            <p className='text-base mt-4 font-sans'>
              Do you need a post club-snack? Does your late night hunger kick in? Add a late night eat to your itinerary.
            </p>
            <p className='text-2xl my-6 font-playfair'>
              { tabs[activeTabIndex - 1]?.title }
            </p>
            <div className={`mb-10 ${suggestions[itinerary.cityName] && showIndicators(suggestions[itinerary.cityName]['suggested_resturants']) ? '' : 'px-4'}`}>
              { suggestions[itinerary.cityName] && suggestions[itinerary.cityName]['suggested_resturants']?.length ? (
                <Carousel
                  numScroll={ 1 }
                  itemTemplate={ (item, index) => itemTemplate(item, index, 'suggestedRestaurantCard') }
                  value={ suggestions[itinerary.cityName]['suggested_resturants'] }
                  showNavigators={ showIndicators(suggestions[itinerary.cityName]['suggested_resturants']) }
                  numVisible={ suggestions[itinerary.cityName]['suggested_resturants']?.length < items() ? suggestions[itinerary.cityName]['suggested_resturants']?.length : items() }
                  style={{ paddingLeft: suggestions[itinerary.cityName]['suggested_resturants']?.length > items() ? '' : '10px', width: items() === 1 ? '100%' : smallCards(suggestions[itinerary.cityName]['suggested_resturants']) }}
                />
              ) : (
                <p className='pl-10 font-playfair'>No bars & clubs available</p>
              )}
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
});

DayView.displayName = 'DayView';

export default DayView;

