import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const Layout = ({ children, headerCSS, footerCSS = '' }) => {
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className='flex flex-col w-full'>
      <div className="flex flew-wrap w-full flex-col">
        <Header className={headerCSS}/>
        {children}
      </div>
      <Footer isMobile = { isMobile } footerCSS = { footerCSS } />
    </div>
  );
};
export default Layout;
