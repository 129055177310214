import React, { useEffect, useState } from 'react';

const AddHotelAirBnb = ({ handleAddMoreInput }) => {
  const [hotelAirBnbName, setHotelAirBnbName] = useState('');
  useEffect(() => {
   handleAddMoreInput(hotelAirBnbName, "setSuggestedHotelAirBnb");
  }, [hotelAirBnbName, handleAddMoreInput]);

  return (
    <input
      type="text"
      key='text'
      value={hotelAirBnbName}
      onInput={(e) => setHotelAirBnbName(e.target.value)}
      className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
    />
  );
};

export default AddHotelAirBnb;
