import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const TripCardSkeleton = () => {
  return (
    <div className='card-container shadow-md pb-10 w-full'>
    <Stack className='p-2' spacing={2}>
      <Skeleton variant="rectangular" width={"w-full"} height={250} />
      <Skeleton variant="rectangular" width={"w-full"} height={20} />
      <Skeleton variant="rectangular" width={50} height={20} />
    </Stack>
    </div>
  );
};

export default TripCardSkeleton;
