import React from 'react';
import { Carousel } from 'primereact/carousel';
import { formatDate } from '../../common/date';

export default function RewardHistoryCarousel({ rewardHistoryData }) {

  const isDaysMultiple = rewardHistoryData.length > 1 ? true : false;
  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  const rewardHistoryTemplate = (rewardData, index) => {
    return (
      <div
        className={`mt-6 flex ${ isDaysMultiple ? "justify-around" : "justify-center" }`}
        key={index}
      >
        {rewardData.map((reward, index) => (
          <div key={index}>
            <div className='lg:flex lg:w-full'>
              {rewardHistoryCardTemplate(reward)}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const rewardHistoryCardTemplate = (reward, index) => {
    return (
      <div
        key={ index }
        className='card bg-white p-4 h-[150px] overflow-y-auto min-w-[230px] rounded-lg shadow-md mx-auto mt-2 mb-2'
      >
        <p className="text-sm text-gray-600">
         { formatDate(reward.created_at) }
        </p>
        { reward.rewards_data.map(reward => (
          <div
            key={ index }
            className='flex flex-col mt-2'
          >
            { rewardAmount(reward) }
            <span className='pl-2'>{ reward.reward_details }</span>
          </div>
        ))}
      </div>
    );
  };

  const rewardAmount = (reward) => {
    if (reward.reward_type === 'credit') {
      return <p className='text-green-500'>+${reward.amount}</p>;
    } else if (reward.reward_type === 'debit') {
      return <p className='text-red-500'>-${reward.amount}</p>;
    }
  };

  const splitArrayIntoChunks = (array, value) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += value) {
      chunks.push(array.slice(i, i + value));
    }
    return chunks;
  };
  
  const showCarousel = (rewardHistoryData) => {
    return (
      <div>
        <Carousel
          containerClassName={`${isMobile ? "w-[280px]" : (isDaysMultiple ? "w-[630px]" : "w-[280px]")}`}
          value={splitArrayIntoChunks(rewardHistoryData, isDaysMultiple && isMobile ? 1 : 2)}
          numVisible={1}
          numScroll={1} 
          itemTemplate={rewardHistoryTemplate}
        />
      </div>
    );
  };
  
  return (
    <div>
      {showCarousel(rewardHistoryData)}
    </div>
  );
  
}
