import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import { Rating } from 'primereact/rating';
import Review from './Review/Review.jsx';
import { BtnTransparent } from './UIComponents/buttons.jsx';
import PlaceholderSkeleton from './Skeletons/PlaceholderSkeleton.jsx';
import { triggerMixpanelEvent } from '../common/mixpanel.js';
import http from '../common/http.js';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';

export default function ActivityModal({ props, onClose, isRestaurant, addToItinerary, cardType = '', isCityDetail = false }) {
  const [, setMap] = useState('');
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [placeDetail, setPlaceDetail] = useState({});
  const [isLoading, setisLoading] = useState(true);
  const toast = useRef(null);

  const imageCss = 'w-full h-full object-cover';

  useEffect(() => {
    setisLoading(true);
    setPlaceDetail({});
    fetchPlaceDetail();
  }, []);

  const fetchPlaceDetail = () => {
    const params = {
      id: props.id,
      is_restaurant: props.isRestaurant,
      place_type: props.placeType,
    };
    http
      .get('/place_detail.json', { params })
      .then(res => {
        const detail = res.data.city_detail;
        setPlaceDetail(detail);
        setCenter({ lat: detail.location.lat, lng: detail.location.lng });
        setisLoading(false);
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching details.', 'bg-red-100 text-red-700');
      });
  };

  const { isLoaded } = useJsApiLoader({
    id: 'we-plan-google-map-script',
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY
  });

  const containerStyle = {
    width: '100%',
    height: '100%'
  };

  const onLoad = useCallback((map) => {
    map.setZoom(12);
    map.setCenter(center);
    setMap(map);
  }, [center]);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const getOpeningHours = (time) => {
    const [hours, minutes] = time.split(':');
    const formattedHours = parseInt(hours, 10) % 12 || 12;
    return `${formattedHours}:${minutes}`;
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  const buttonName = () => {
    if (cardType === 'suggestedRestaurantCard' || isRestaurant) {
      return 'Book reservation';
    }
    return 'Book tickets';
  };

  const doesCityDetailExist = () => !isLoading && Object.keys(placeDetail).length;

  const showMessage = (severity, summary, detail, style) => {
    toast.current?.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  return isLoaded ? (
    <div>
      <Toast ref={toast} position='bottom-right' />
      <Dialog
        headerClassName='px-6 pt-6'
        visible={true}
        dismissableMask={true}
        style={{ width: '1192px', height: '1817px', backgroundColor: 'white', overflow: 'hidden' }}
        draggable={false}
        closable={true}
        blockScroll={true}
        responsive={true}
        resizable={false}
        onHide={onClose}
      >
        { doesCityDetailExist() ? (
          <div className='flex flex-col flex-wrap lg:px-8 px-3'>
            <div className='flex items-center gap-6'>
              <span className='text-3xl font-normal font-playfair'>{ placeDetail.name }</span>
              { placeDetail.placeType && <span className='blue-chips-background py-1 px-3.5 rounded-[20px]'>{ placeDetail.placeType}</span> }
            </div>
            <div className='text-2xl mt-2 font-playfair'>Overview</div>
            <div className='lg:grid flex flex-wrap grid grid-cols-4 mt-4 row-span-2'>
              <div className='mb-2 col-span-2 row-span-2 lg:w-[550px] lg:h-[393px]'>
                {
                  placeDetail.images[0] ? 
                    <img
                      className={ imageCss }
                      src={ placeDetail.images[0] }
                      alt='Image 1'
                    /> :
                    <PlaceholderSkeleton applyClass={'lg:w-[550px] lg:h-[393px] h-[100%] bg-gray-300'} />
                }
              </div>
              <div className='mb-2 col-span-1 row-span-1 lg:w-[269px] lg:h-[192px]'>
                {
                  placeDetail.images[1] ?
                    <img
                      className={ imageCss }
                      src={ placeDetail.images[1] }
                      alt='Image 1'
                    /> :
                    <PlaceholderSkeleton applyClass={'lg:w-[269px] lg:h-[192px] bg-gray-300'} />
                }
              </div>
              <div className='mb-2 col-span-1 row-span-1 lg:w-[269px] lg:h-[192px]'>
                {
                  placeDetail.images[2] ?
                    <img
                      className={ imageCss }
                      src={ placeDetail.images[2] }
                      alt='Image 1'
                    /> :
                    <PlaceholderSkeleton applyClass={'lg:w-[269px] lg:h-[192px] bg-gray-300'} />
                }
              </div>
              <div className='mb-2 col-span-1 row-span-1 lg:w-[269px] lg:h-[192px]'>
                {
                  placeDetail.images[3] ?
                    <img
                      className={ imageCss }
                      src={ placeDetail.images[3] }
                      alt='Image 1'
                    /> :
                    <PlaceholderSkeleton applyClass={'lg:w-[269px] lg:h-[192px] bg-gray-300'} />
                }
              </div>
              <div className='mb-2 col-span-1 row-span-1 lg:w-[269px] lg:h-[192px]'>
                {
                  placeDetail.images[4] ?
                    <img
                      className={ imageCss }
                      src={ placeDetail.images[4] }
                      alt='Image 1'
                    /> :
                    <PlaceholderSkeleton applyClass={'lg:w-[269px] lg:h-[192px] bg-gray-300'} />
                }
              </div>
            </div>
            <div className='text-base font-sans py-6'>
              { placeDetail.description }
            </div>
            <div className='flex mt-2 mb-4 lg:flex-row flex-col w-full'>
              <div className='flex lg:w-1/2 w-full'>
                <div className='w-full bg-gray-200 px-5 py-5'>
                  {placeDetail.address &&
                    <>
                      <p className='text-sm text-light-black font-sans'>Address</p>
                      <p className='text-base font-sans'>{ placeDetail.address }</p>
                    </>
                  }
                  {placeDetail.hours.length > 0 &&
                    <>
                      <p className='text-sm text-light-black mt-4 font-sans font-normal'>Hours</p>
                      <div className='text-base font-sans'>
                        {placeDetail.hours.map(item => (
                          <div key={item.id}>
                            {item.day}: {item.opening_time === 'Closed' ? 'Closed' : `${getOpeningHours(item.opening_time)} - ${getOpeningHours(item.closing_time)}`}
                          </div>
                        ))}
                      </div>
                    </>
                  }
                  <div className='flex w-full flex-wrap flex-col'>
                    {placeDetail.website &&
                      <>
                        <p className='flex w-full text-sm text-light-black mt-4 font-sans font-normal'>Website</p>
                        <p className='text-base font-sans'>{ placeDetail.website }</p>
                      </>
                    }
                    {placeDetail.phone &&
                      <>
                        <p className='text-sm text-light-black mt-4 font-sans font-normal'>Phone number</p>
                        <p className='text-base font-sans'>{ placeDetail.phone }</p>
                      </>
                    }
                    {/* <p className='text-sm text-light-black mt-4'>Ticket prices</p> */}
                  </div>
                  {/* <div className='flex justify-between'>
                    <div className='flex flex-col'>
                      <span>Student: 15 euros</span>
                      <span>Adult: 20 euros</span>
                    </div>
                    <div className='flex flex-col'>
                      <span>Senior (65+): 15 euros</span>
                      <span>5 and under: free</span>
                    </div>
                  </div> */}
                  {isRestaurant && placeDetail.cuisines.length > 0 &&
                    <div className='flex justify-between'>
                      <div className='flex flex-col'>
                        <p className='text-sm text-light-black mt-4 font-sans font-normal'>Cuisines</p>
                        <span className='text-base font-sans'>{ placeDetail.cuisines.map(c => capitalizeFirstLetter(c)).join(', ') }</span>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className='flex lg:w-1/2 w-full lg:min-h-[461px]'>
                <GoogleMap
                  center={ center }
                  onLoad={ onLoad }
                  onUnmount={ onUnmount }
                  mapContainerStyle={ containerStyle }
                >
                  <MarkerF position={ center }/>
                </GoogleMap>
              </div>
            </div>
            <div className='text-2xl font-normal mt-10 font-playfair'>Reviews</div>
            <div className='flex gap-x-2'>
              <Rating
                value={ placeDetail.rating }
                cancel={ false }
                className='text-yellow-500'
              />
              <span className='text-base'>{ placeDetail.rating } ({ placeDetail.reviewsCount } reviews)</span>
            </div>
            <div className='flex flex-wrap w-full'>
              {!isMobile && placeDetail?.reviews_data?.map((review, index) => (
                <div key={index} className={`flex w-full p-3 lg:w-1/2 gap-x-2`}>
                  <Review
                    review={ review }
                    isMobile={ isMobile }
                  />
                </div>
              ))}
              {isMobile &&
                <Review
                  isMobile={ isMobile }
                  review={ placeDetail?.reviews_data }
                />
              }
            </div>
            <div className='h-px bg-gray-300 w-full mb-2'></div>
            {
              !isCityDetail && (
                <div className='flex gap-x-4 justify-end mb-2 lg:flex-row lg:space-y-0 flex-col space-y-2'>
                  <BtnTransparent
                    props={{
                      bg: 'bg-[#FFFFFFe6] ',
                      border: 'border-periwinkle',
                      text: 'text-periwinkle',
                      buttonName: buttonName(),
                    }}
                  />
                  <BtnTransparent
                    props={{
                      bg: 'bg-periwinkle',
                      border: 'border-periwinkle',
                      text: 'text-white',
                      buttonName: 'Add to itinerary +',
                    }}
                    handleClick={() => { 
                      addToItinerary(placeDetail);
                      triggerMixpanelEvent('Itineray - activity modal click');
                    }
                  }
                  />
                </div>
              )
            }
          </div>
        ) : (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
            <div className="text-center">
              <ProgressSpinner />
            </div>
          </div>
        )}
      </Dialog>
    </div>
  ) : <></>;
  
}
