import React, { useState } from 'react';
import CardBlock from './CardBlock';
import PriceMatchButton from './PriceMatch/PriceMatchButton';
import CardHeader from './CardHeader';
import { BtnTransparent } from './UIComponents/buttons';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import BookAllOverviewModal from './OneClickBookAll/BookAllOverviewModal';

export default function AllView({ id, itineraries, tabs, handleTabs }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [isDialogVisible, setDialogVisible] = useState(false);
  const openDialog = () => {
    setDialogVisible(true);
  };
  const closeDialog = () => {
    setDialogVisible(false);
  };
  const showFlightSection = () => {
    let isFlightBooked = false;
    if (id) {
      isFlightBooked = itineraries.some((plan) => !isEmpty(plan.booked_flight));
    } else {
      isFlightBooked = searchParams.get('flightBooked') == "true";
    }

    return !isFlightBooked;
  };

  return (
    <div className='lg:px-5 px-3'>
      { showFlightSection() && <CardHeader /> }
      <div className='mt-2'>
        <PriceMatchButton/>
      </div>
      { !(searchParams.get('flightBooked') == "true" && searchParams.get('hotelBooked') == "true") &&
        <div className='lg:px-10 lg:py-6 mt-4 mb-4 bg-semi-trans-purple flex flex-col sm:flex-row gap-x-12'>
          <span className='mb-2 sm:mb-0 mt-2 sm:mt-0 font-sans font-bold'>Save time, book everything at once</span>
          <BtnTransparent
            props={{
              text: 'text-white text-bold',
              bg: 'bg-periwinkle',
              border: 'border-periwinkle',
              buttonName: 'Book all',
            }}
            handleClick={ openDialog }
          />
        </div>
      }
      <div className='lg:px-5 px-3'>
        { itineraries?.map((itinerary, index) => (
          <div key={ index }>
            <CardBlock
              tabs={ tabs }
              itineraryindex={index}
              props={ itinerary }
              handleTabs={handleTabs}
              hotelBooked={ itinerary.isHotelBooked }
            />
          </div>
        ))}
      </div>
      <BookAllOverviewModal
        isVisible={ isDialogVisible }
        onClose={ closeDialog }
      />
    </div>
  );
}
