import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  vibes: [],
};

export const vibesSlice = createSlice({
  name: "vibes",
  initialState,
  reducers: {    
    setVibes: (state, action) => {
      state.vibes = action.payload;
    },
  },
});

export const { setVibes } = vibesSlice.actions;

export default vibesSlice.reducer;
