import React, { useEffect, useState } from 'react';
import { HiArrowLongRight } from "react-icons/hi2";
import { BsDot } from "react-icons/bs";
import RadioInput from './RadioInput';
import http from './../common/http';
import loadingSpinner from 'loader.png';
import SingleSelectDropdown from './UIComponents/Dropdowns/SingleSelectDropdown';
import { cloneDeep, isEmpty } from 'lodash';

const TravelInfo = ({amenities, flight, flightOffer, dictionaries, addSelectedSeats, iataCodes}) => {
  const radioInputCss = 'appearance-none rounded-none bg-gray-300 border-transparent w-4 h-4 hover:bg-black checked:bg-black checked:focus:bg-black checked:focus:border-black checked:hover:bg-black checked:border-black focus:outline-none';
  const [availableSeats, setAvailableSeats] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [travelersSeat, setTravelersSeat] = useState({});
  const [selectedSeats, setSelectedSeats] = useState({});

  useEffect(() => {
    addSelectedSeats(travelersSeat);
  }, [travelersSeat]);
  
  useEffect(() => {
    if (!isEmpty(availableSeats)) return;

    if (flightOffer) {
      setIsLoading(true);
      const offer = JSON.stringify(flightOffer);
      const params = { flight_offer: offer };
      http
        .get('/get_flight_seats', { params })
        .then((res) => {
          const data = res.data.offer_and_seats;
          let seats = {};
          data['data'].forEach((obj) => {
            let segmentId = obj['segmentId'];
            seats[segmentId] = [];
            obj['decks'].forEach((deck) => {
              deck['seats'].forEach((seat) => {
                if (seat['travelerPricing'][0]['seatAvailabilityStatus'] == 'AVAILABLE') {
                  seats[segmentId].push(seat.number);
                }
              });
            });
          });
          setIsLoading(false);
          setAvailableSeats(seats);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [flightOffer]);

  const handleTravelersSeat = (number, val, segmentId) => {
    let updatedSeats = cloneDeep(selectedSeats);
    updatedSeats[`${segmentId}${number}`] = val;
    setSelectedSeats(updatedSeats);

    let seats = cloneDeep(travelersSeat);
    if (!seats[number]) {
      seats[number] = [];
    }
    const idx = seats[number].findIndex((seat) => {
      return seat.segmentId == segmentId;
    });
    if (idx > -1) {
      seats[number][idx] = { segmentId: segmentId, seatNumber: val };
    } else {
      seats[number] = [...seats[number], { segmentId: segmentId, seatNumber: val }];
    }
    setTravelersSeat(seats);
  };

  function parseDuration(durationString) {
    const regex = /PT(?:(\d+)D)?(?:(\d+)H)?(?:(\d+)M)?/; // Regex to match days, hours, and minutes
    const matches = durationString.match(regex);
    if (!matches) {
      return {}; // Invalid duration string
    }

    const days = matches[1] ? parseInt(matches[1]) : 0; // Extract days if present
    const hours = matches[2] ? parseInt(matches[2]) : 0; // Extract hours if present
    const minutes = matches[3] ? parseInt(matches[3]) : 0; // Extract minutes if present

    return {
      days: days,
      hours: hours,
      minutes: minutes
    };
  }

  const [itineraries, setItineraries] = useState([]);

  useEffect(() => {
    if (flightOffer) {
      setItineraries(flightOffer.itineraries);
    } 
  }, [flightOffer]);

  const timeUnits = {
    days: 'd',
    hours: 'hr',
    minutes: 'min'
  };

  const calculateDuration = (duration) => {
    if (!duration) return '';

    let durationValues = parseDuration(duration);
    let durationStr = '';
    Object.keys(durationValues).forEach((key) => {
      if (durationValues[key] > 0) {
        durationStr = `${durationStr} ${durationValues[key]} ${timeUnits[key]}`;
      }
    });
    return durationStr;
  };

  return (
    <div className='flex flex-col bg-gray-100'>
      <div className='p-4'>
        {
          itineraries.map((itinerary, idx) => {
            return (itinerary.segments.map((segment, sIdx) => {
              return (
                <div key={`${idx}${sIdx}`} className='my-2'>
                  <div className='flex flex-row justify-between mb-4'>
                    <div className='flex flex-row gap-2 items-center'>
                      <h1 className='text-sm font-semibold'>{iataCodes[segment.departure.iataCode] || segment.departure.iataCode}</h1>
                      <HiArrowLongRight className='text-black' />
                      <h1 className='text-sm font-semibold'>{iataCodes[segment.arrival.iataCode] || segment.arrival.iataCode}</h1>
                    </div>
                    <div className='flex justify-end'>
                      {/* <h1 className='text-sm text-grey font-monsterrat font-semibold underline'>Baggage fees and policies</h1> */}
                    </div>
                  </div>
                  <div className='flex flex-row items-center gap-x-2 mb-1'>
                    <h1 className='text-sm font-normal'>{new Date(segment.departure.at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}</h1>
                    <HiArrowLongRight className='text-black' />
                    <h1 className='text-sm font-normal'>{new Date(segment.arrival.at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}<sup className='text-periwinkle text-xs font-normal'></sup></h1>
                  </div>
                  <div className='flex flex-row justify-start mb-3'>
                    <h1 className='text-sm font-normal text-dark-grey'>{calculateDuration(segment.duration)} flight time</h1>
                  </div>
                  <div className='flex flex-row items-center gap-x-2 mb-3'>
                    <h1 className='text-xs font-normal'>{dictionaries.aircraft[segment.aircraft?.code]}</h1>
                  </div>
                  <div className='m-3'>
                    { isLoading ? (
                        <img
                          src={ loadingSpinner }
                          alt="spinner"
                          className='w-[50px] h-[55px] animate-spin'
                        />
                      ) : (
                      <div>
                        <div>
                          Please select seats:
                        </div>
                        <div className='grid grid-cols-4'>
                          {
                            availableSeats[segment.id]?.length ?
                            [...Array(flight.travelers)].map((number, idx) => {
                              return (
                                <div key={`${segment.id}${idx}`} className='w-2/5 m-3'>
                                  <div>
                                      Traveler { idx + 1 }
                                      <SingleSelectDropdown
                                        props={{
                                          dropDownwidth: 'w-full',
                                          options: availableSeats[segment.id],
                                          value: selectedSeats[`${segment.id}${idx+1}`],
                                          labelStyle: 'text-[11px] letter-spacing block'
                                        }}
                                        handleChanges={(val) => handleTravelersSeat(idx+1, val.value, segment.id)}
                                      />
                                  </div>
                                </div>
                              );
                            })
                            : <div className='text-sm'>No seats available to select</div>
                          }
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='border border-t-gray-300'>
                    {/* <div className='flex flex-col gap-y-1 px-4'>
                      <h1 className='text-sm font-semibold'>MUC (Munich International Airport)</h1>
                      <h1 className='text-xs font-normal text-dark-grey'>5hr 24m layover</h1>
                    </div> */}
                  </div>
                </div>
              );
            }));
          })
        }
        <div className='flex flex-row gap-x-2'>
          {
            amenities?.length &&
            amenities.map((amenity, index) => {
              return (
                <RadioInput
                  key={index}
                  flightObject={{}}
                  handleChange={{}}
                  attribute={"airline"}
                  labelName={ amenity.description }
                  radioInputCss={radioInputCss}
                />
              );
            }) 
          }
          {/* <RadioInput flightObject={{}} handleChange={{}} attribute={"airline"} labelName={"Carry on  + 2 checked bags included"} radioInputCss={radioInputCss} />
          <div className='h-auto border border-black'></div>
          <RadioInput flightObject={{}} handleChange={{}} attribute={"airline"} labelName={"Ticket change for a fee"} radioInputCss={radioInputCss} /> */}
        </div>
      </div>
    </div>
  );
};

export default TravelInfo;
