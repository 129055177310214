import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const ViewsSkeleton = () => {
  return (
    <div className='w-full h-[100%]'>
      <Stack className='p-2' spacing={2}>
        <Skeleton variant="rectangular" width={'w-full'} height={100} />
        <Skeleton variant="rectangular" width={'w-full'} height={50} />
      </Stack>
    </div>
  );
};

export default ViewsSkeleton;
