import React from 'react';
import { Skeleton } from '@mui/material';


const SocialMediaSkeleton = () => {
  const timesToRenderSkeleton = 4;
  
const miniSkeleton = () => (
  <div className='flex flex-col gap-y-3 mt-4 w-full'>
    <Skeleton className='rounded-md' variant="rectangular" width={100} height={40} />
    <Skeleton className='rounded-md' variant="rectangular" width={"w-full"} height={40} />
  </div>
);

  return (
    <div className=''>
      { Array.from({ length: timesToRenderSkeleton }).map((_, index) => (
        <div key={index}>
          { miniSkeleton() }
        </div>
      )) } 
    </div>
  );
};

export default SocialMediaSkeleton;
