import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  whatToBrings: [
    {
      category: "clothes",
      items_list:
        [
          {
            name: "Shirts",
            value: false
          },
          {
            name: "Jacket",
            value: false
          },
          {
            name: "Hiking Shoes",
            value: false
          },
          {
            name: "Clubbing Attire",
            value: false
          },
        ]
    },
    {
      category: "techs",
      items_list:
        [
          {
            name: "Laptop",
            value: false
          },
          {
            name: "Headphones",
            value: false
          },
        ]
    },
    {
      category: "miscellaneous",
      items_list:
        [
          {
            name: "Visa",
            value: false
          },
          {
            name: "First Aid Kit",
            value: false
          },
          {
            name: "Hat",
            value: false
          },
          {
            name: "Lip Balm",
            value: false
          },
        ]
    }
  ]
};

export const whatToBringsSlice = createSlice({
  name: "whatToBrings",
  initialState,
  reducers: {
    setWhatToBrings: (state, action) => {
      state.whatToBrings = action.payload;
    },
    reset: () => initialState,
  },
});

export const { setWhatToBrings, reset } = whatToBringsSlice.actions;

export default whatToBringsSlice.reducer;
