import http from '../../common/http';

import { Toast } from 'primereact/toast';
import { grey } from '@mui/material/colors';
import { BtnTransparent } from '../UIComponents/buttons';
import React, { useEffect, useState, useRef } from 'react';
import { Box, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

const Preferences = () => {
  const toast = useRef(null);

  const [preferences, setPreferences] = useState({});

  const labels = [{ key: 'weather_updates', value: 'Email me updates about weather forecasts for my destinations' },
    { key: 'safety_tips_updates', value: 'Email me updates about travel advisories and safety tips' },
    { key: 'transportation_updates', value: 'Email me updates about flight and transportation disruptions' },
    { key: 'hotel_promotions', value: 'Email me updates about hotel promotions and deals' }];

  useEffect(() => {
    http
    .get('/message_preference.json')
    .then((res) => {
      let preference = {};
      for (const key in res.data.message_preference) {
        if (res.data.message_preference[key] === true) {
          preference[key] = true;
        }
      }
      setPreferences(preference);
    })
    .catch(() => {
      showMessage('error', 'Error', 'Sorry, there was an error while fetching message preferences.', 'bg-red-100 text-red-700');
    });
  }, []);

  const handleChecked = (value) => {
    setPreferences(prevPreferences => {
      if (value in prevPreferences) {
        const { [value]: _, ...updatedPreferences } = prevPreferences;
        return updatedPreferences;
      } else {
        return { ...prevPreferences, [value]: true };
      }
    });
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  return (
    <>
      <div className='flex justify-between'>
        <Toast ref={toast} position="bottom-right" />
        <div className='text-2xl font-normal font-playfair mb-8'>Message preferences</div>
        {!isMobile && <div className='w-[185px] h-[40px]'>
          <BtnTransparent
            props={{
              text: 'text-white font-semibold w-full h-full',
              bg: 'bg-periwinkle',
              border: 'border-periwinkle',
              buttonName: 'Save changes',
            }}
            disabled={false}
            handleClick={() => {
              http
                .post('/message_preference.json', preferences)
                .then(() => {
                  showMessage('success', 'Success', 'Message preferences have been saved successfully!', 'bg-teal-100 text-teal-500');
                })
                .catch(() => {
                  showMessage('error', 'Error', 'Sorry, there was an error while saving message preferences.', 'bg-red-100 text-red-700');
                });
            }}
          />
        </div>}
      </div>

      <div>
        <Box>
          <FormGroup>
            {labels.map((label, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={label?.key in preferences}
                    onChange={() => handleChecked(label?.key)}
                    sx={{
                      '&.Mui-checked': {
                      color: grey[900],
                      }
                    }}
                  />
                }
                label={label?.value}
              />
            ))}
          </FormGroup>
        </Box>
      </div>
      {isMobile && <div className='flex mt-4 w-full h-[40px]'>
        <BtnTransparent
          props={{
            text: 'text-white font-semibold w-full h-full',
            bg: 'bg-periwinkle',
            border: 'border-periwinkle',
            buttonName: 'Save changes',
          }}
          disabled={false}
          handleClick={() => {
            http
              .post('/message_preference.json', preferences)
              .then(() => {
                showMessage('success', 'Success', 'Message preferences have been saved successfully!', 'bg-teal-100 text-teal-500');
              })
              .catch(() => {
                showMessage('error', 'Error', 'Sorry, there was an error while saving message preferences.', 'bg-red-100 text-red-700');
              });
          }}
        />
      </div>}
    </>
  );
};

export default Preferences;
