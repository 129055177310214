import React, { useEffect, useState } from 'react';

const ThingsToDoForm = ({ handleAddMoreInput }) => {
  const [formData, setFormData] = useState({
    attraction: '',
    description: '',
  });


  useEffect(() => {
    handleAddMoreInput(formData, "setThingsToDoFormData");
  }, [formData, handleAddMoreInput]);

  const handleChange = (fieldName, newValue) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [fieldName]: newValue,
      };
    });
  };
  return (
    <div className='grid grid-cols-2 gap-x-10'>
      <div className='flex gap-10 col-span-1'>
        <div className='mt-6 flex-1'>
          <label className='text-sm font-sans'>
            Attraction
          </label>
          <input
            type="text"
            value={formData.placesName}
            onInput={(e) => handleChange('attraction', e.target.value)}
            className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
          />
        </div>
      </div>
      <div className='flex gap-10 col-span-1'>
        <div className='mt-5 w-full'>
          <label className='text-sm font-sans'>
            Description
          </label>

          <input
            type="text"
            key='text'
            value={formData.placesDescription}
            onInput={(e) => handleChange('description', e.target.value)}
            className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
          />
        </div>
      </div>
    </div>
  );
};

export default ThingsToDoForm;
