import React from 'react';

export default function ResidentOptions({ handleInput, formData, label }) {
  return (
    <div>
      <div className="flex align-items-center mr-1">
        <input
          id={ `${label}` }
          name={ `${label}` }
          type='radio'
          style={{ color: 'black', border: '1px solid black' }} 
          value={ `${label}` } 
          checked={formData.type === `${label}`}
          onClick={ (e) => handleInput("type", e.target.value) } 
        />
        <label htmlFor="hotel" className="ml-1">
          { label}
        </label>
      </div>
    </div>
  );
}
