import CustomModal from '../UIComponents/Modal/customModal';
import FileUpload from '../UIComponents/FileHandling/FileUpload';
import SingleSelectDropdown from '../UIComponents/Dropdowns/SingleSelectDropdown';

import { Toast } from 'primereact/toast';
import { useDispatch } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import React, { useState, useRef } from 'react';
import { BtnTransparent } from '../UIComponents/buttons';
import { setPriceMatch } from '../../redux/slices/priceMatch';

const PriceMatch = ({
  hotel,
  roomOffers,
  isPriceMatchFormVisible,
  setIsPriceMatchFormVisible
}) => {
  const toast = useRef(null);

  const dispatch = useDispatch();

  const [selectedType, setSelectedType] = useState('');
  const [generalFormData, setGeneralFormData] = useState({});
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const hotelRoomTypes = roomOffers?.map(offer => {
    const { id } = offer;
    const { typeEstimated } = offer.room;
  
    const formattedBedType = typeEstimated?.bedType ? `${typeEstimated.bedType}`: "";
    const formattedCategory = typeEstimated?.category ? `${typeEstimated.category}`: "";

    if (!typeEstimated?.bedType) {
      return `${id}: ${formattedCategory}`;
    } else if (!typeEstimated.category) {
      return `${id}: ${formattedBedType}`;
    }
    return `${id}: ${formattedBedType}, ${formattedCategory}`;
  });

  const formFunctions = {
    setGeneralFormData: setGeneralFormData,
  };

  const handleInput = (formDataFunction, fieldName, newValue) => {
    formFunctions[formDataFunction]((prevData) => {

      return {
        ...prevData,
        [fieldName]: newValue,
      };
    });
  };

  const buttons = {
    price: [
      {
        text: 'Discard',
        onPress: () => {
          setIsConfirmationVisible(false);
        }
      },
      {
        text: 'Confirm',
        onPress: () => {
          setIsConfirmationVisible(false);
        }

      },
    ],
  };

  const onClose = () => setIsPriceMatchFormVisible(false);

  const submitForm = () => {
    const updatedFormData = { ...generalFormData };

    updatedFormData.hotel_id = hotel.id;

    dispatch(setPriceMatch(updatedFormData));
    showMessage('success', 'Success', 'Price Match request is added.', 'bg-teal-100 text-teal-500');
    onClose(false);
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  return (
    <>
      <Toast ref={toast} position="bottom-right" />
      <Dialog
        header="Price match request"
        headerClassName="text-2xl font-normal font-playfair px-6 pt-8"
        visible={isPriceMatchFormVisible}
        dismissableMask={true}
        closable={true}
        style={{ backgroundColor: 'white', overflow: 'hidden' }}
        className='lg:w-[700px] w-full'
        draggable={false}
        blockScroll={true}
        responsive={true}
        resizable={false}
        onHide={() => onClose()}
      >
        <div className='mt-4'>
          <div className='px-6'>
            <div>
              <h1 className='text-base font-normal font-sans text-light-black mt-5' >{ hotel?.name }</h1>
            </div>
            <div className='flex flex wrap border border-gray-200 p-2 my-5'>
              <h1 className='text-md font-monsterrat tracking-tight text-light-black'>
                <span className="font-bold text-sm">Disclaimer: </span>
                  Credits issued from price matching will be deposited into your account and must be utilized within 24 hours.
                  These credits can only be applied to the trip associated with the price match request.
                  Ensure your price match submission, including screenshots, is within 24 hours of the initial quote to allow for verification against historical hotel prices.
                  Note that the price match discount will not exceed 40% of the total trip cost.
              </h1>
            </div>
            <div className='flex flex-col'>
              <div className='flex flex-col lg:grid grid-cols-4 mt-4'>
                <div className='lg:col-span-1 flex items-center'>
                  <label className='text-light-black text-base my-4'>
                    Room Selected
                  </label>
                </div>
                <div className='w-full lg:col-span-3'>
                  <SingleSelectDropdown
                    props={{
                      value: selectedType,
                      options: hotelRoomTypes,
                      dropDownwidth: 'w-full',
                      labelStyle: 'text-[11px] letter-spacing block'
                    }}
                    handleChanges={ (val) => {
                      setSelectedType(val.value);
                      const selectedRoom = roomOffers.find(offer => offer.id === (val.value.split(':'))[0]);

                      handleInput('setGeneralFormData', 'room_details', selectedRoom.room);
                      handleInput('setGeneralFormData', 'old_price', selectedRoom.price.total);
                    }}
                  />
                </div>
              </div>

              <div className='flex flex-col lg:grid grid-cols-4 mt-4'>
                <div className='lg:col-span-1 flex items-center'>
                  <label className='flex flex-col text-base text-light-black my-4'>
                    Current Price
                    <span className='text-sm text-light-black'>(per night)</span>
                  </label>
                </div>
                <div className='w-full lg:col-span-3'>
                  <h1 className='flex gap-2 text-lg my-4 text-black'>
                    { generalFormData.old_price ? <h1>{ generalFormData.old_price }</h1> : <></> } $
                  </h1>
                </div>
              </div>
              <div className='flex flex-col lg:grid lg:grid-cols-4 mt-4'>
                <div className='lg:col-span-1 flex items-center'>
                  <label className='flex flex-col text-light-black text-base mb-4'>
                    New Price
                    <span className='text-sm text-light-black'>(per night)</span>
                  </label>
                </div>
                <div className='w-full lg:col-span-3'>
                  <input
                    type="number"
                    value={ generalFormData.suggested_price }
                    onChange={ (e) => { handleInput("setGeneralFormData", "suggested_price", e.target.value); } }
                    className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                  />
                </div>
              </div>
              <div className='flex flex-col gap-2 mt-6'>
                <h1 className='text-base tracking-tight text-black'>Upload any photos or files showing thew new price:</h1>
                <div>
                  <FileUpload
                    widthInput={"w-full"}
                    setUploadedFiles={ (files) => handleInput('setGeneralFormData', 'files', files) }
                  />
                </div>
              </div>
            </div>

            <BtnTransparent
              props={{
                text: 'text-white font-semibold w-full mt-6 mb-6',
                bg: 'bg-periwinkle',
                border: 'border-periwinkle',
                buttonName: 'Request price match',
              }}
              handleClick={ () => submitForm() }
            />

          </div>
        </div>
        {isConfirmationVisible &&
          <CustomModal
            type={'price'}
            isVisible={isConfirmationVisible}
            setVisible={setIsConfirmationVisible}
            buttons={buttons['price']}
          />
        }
      </Dialog>
    </>
  );
};

export default PriceMatch;
