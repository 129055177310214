import { createSlice } from '@reduxjs/toolkit';

const placesName = createSlice({
  name: 'placesName',
  initialState: [],
  reducers: {
    setPlacesName: (state, action) => {
      const { cityName, placesName } = action.payload;
      if (placesName?.length) {
        return { ...state, [cityName]: placesName };
      }
    }
  }
});

export const { setPlacesName } = placesName.actions;

export default placesName.reducer;
