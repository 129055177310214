import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { BtnTransparent } from './UIComponents/buttons.jsx';
import { Toast } from 'primereact/toast';
import { emailRegex } from '../utils/helpers/string.jsx';
import { clearUserSession } from '../common/auth';
import http from '../common/http.js';
import '../common/common_styles.scss';

const SignupModal = ({ isVisible, onClose, inStay = false, localExpertForm = false }) => {

  const redBox="2px solid red";
  const emailInput = useRef(null);
  const firstNameInput = useRef(null);
  const lastNameInput = useRef(null);
  const [username, setUsername] = useState(null);
  const toast = useRef(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState(null);


  useEffect(() => {
    if (firstName && lastName) {
      generateUsername();
    }
  }, [firstName, lastName]);

  const generateUsername = () => {
    const randomNumber = Math.floor(100000 + Math.random() * 900000);
    const userName = `${firstName}_${lastName}_${randomNumber}`;
    setUsername(userName);
    setPassword(userName);
  };

  const firstNameInputChanged = () => {
    firstNameInput.current.style.border='';
  };

  const lastNameInputChanged = () => {
    lastNameInput.current.style.border='';
  };

  const emailInputChange = () => {
    emailInput.current.style.border='';
  };

  const validateForm = () => {
    if (!firstName || !lastName || !email) {

      if (!firstName) {
        firstNameInput.current.style.border=redBox;
      }

      if (!lastName) {
        lastNameInput.current.style.border=redBox;
      }

      if (!email) {
        emailInput.current.style.border=redBox;
      }

      showMessage('error', 'Error', 'Please fill in all the required fields.', 'bg-red-100 text-red-700');
      return false;
    }

    if (!emailRegex.test(email)) {
      showMessage('error', 'Error', 'Please enter a valid email address.', 'bg-red-100 text-red-700');
      return false;
    }
    return true;
  };

  const logOut = async () => {
    http
      .delete('/users/sign_out.json')
      .then(() => {
        clearUserSession();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createAccount = () => {
    if (validateForm()) {
      const params = { user: {
          first_name: firstName,
          last_name: lastName,
          email: email,
          username: username,
          password: password
        },
        is_instay: inStay,
        is_local_expert: localExpertForm
      };
      http
        .post('/users', params)
        .then(() => {
          onClose({ accountCreated: true });
          if (inStay) {
            logOut();
            showMessage('success', 'Success', 'Account created successfully. Email has been sent to you.', 'bg-teal-100 text-teal-500');
            setTimeout(() => {
              window.location = "/in-stay";
            }, 2000);
          }
        })
        .catch(() => {
          showMessage('error', 'Error', 'Sorry, there was an error while creating account. Please try again.', 'bg-red-100 text-red-700');
        });
    }
  };


  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  return (
    <div className='flex w-full'>
      <Toast ref={ toast } position='bottom-right'/>
      <Dialog
        header="Create an account"
        headerClassName="text-2xl px-6 pt-8"
        visible={ isVisible }
        dismissableMask={ false }
        closable={ true }
        style={ { backgroundColor: 'white', overflow: 'hidden' } }
        className='lg:w-[705px] w-full'
        onHide={ () => onClose() }
      >
        <div className='mt-4'>
          <div className='px-6'>
            <div className='flex justify-between lg:flex-row flex-col'>
              <div className='mt-4 sm:w-[47%]'>
                <label className='text-sm my-4 text-light-black'>
                  First name*
                </label>
                <input
                  type="text"
                  key='firstName'
                  value={ firstName }
                  ref={ firstNameInput }
                  onChange={ firstNameInputChanged }
                  onInput={ (e) => setFirstName(e.target.value) }
                  className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                />
              </div>
              <div className='text-sm mt-4 sm:w-[47%]'>
                <label className='text-light-black'>
                  Last name*
                </label>
                <input
                  type="text"
                  key='lastName'
                  value={ lastName }
                  ref={ lastNameInput }
                  onChange={ lastNameInputChanged }
                  onInput={ (e) => setLastName(e.target.value) }
                  onBlur={ () => { generateUsername(); } }
                  className={ `form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2` }
                />
              </div>
            </div>
            <div className='mt-2'>
              <label className='text-sm my-4 text-light-black'>
                Email*
              </label>
              <input
                type="text"
                key='email'
                value={ email }
                onChange={ emailInputChange }
                ref={ emailInput }
                onInput={ (e) => setEmail(e.target.value) }
                className={ `form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2` }
              />
            </div>
            <BtnTransparent
              props={{
                text: 'text-white font-semibold w-full mt-6 mb-10',
                bg: 'bg-periwinkle',
                border: 'border-periwinkle',
                buttonName: 'Create',
              }}
              handleClick={ () => createAccount() }
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default SignupModal;
