import React from 'react';
import { BtnTransparent } from './UIComponents/buttons';
import HomeSvg from 'home.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setHotelSwapping } from '../redux/slices/hotelSwapping';
import getSymbolFromCurrency from 'currency-symbol-map';
import PlaceholderSkeleton from './Skeletons/PlaceholderSkeleton';
import { setRecommendedHotel } from '../redux/slices/recommendedHotel';
import { triggerMixpanelEvent } from '../common/mixpanel';
import { setFormStateChanged } from '../redux/slices/formChanged';

export default function HotelRecommendation({ city, hotel, hotelIndex }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tripPlan = useSelector(state => state.tripPlan.tripPlan);
  const { id } = useParams();
  dispatch(setRecommendedHotel(hotel));
  dispatch(setHotelSwapping({ hotelSwapping: false, hotelIndex: hotelIndex }));

  const bookHotelButton = {
    text: 'text-white',
    bg: 'bg-periwinkle',
    border: 'border-periwinkle',
    buttonName: 'Book hotel',
  };

  const seeOtherButton = {
    text: 'text-periwinkle',
    bg: 'bg-transparent',
    border: 'border-periwinkle',
    buttonName: 'See other'
  };

  const showAllHotels = () => {
    dispatch(setHotelSwapping({ hotelSwapping: true, hotelIndex: hotelIndex }));
    const queryString = `city=${city}`;
    navigate(`show-all-hotels?${queryString}`);
  };

  const isHotelBooked = () => {
    return tripPlan[hotelIndex]?.travelers_info?.isBooked || hotel?.is_booked;
  };

  const showHotel = () => {
    dispatch(setHotelSwapping({ hotelSwapping: false, hotelIndex: hotelIndex }));
    navigate(`/trip-plan/show-hotel/${hotel.id}`);
  };

  const priceUnit = () => {
    if (id && hotel?.currency) {
      const unit = getSymbolFromCurrency(hotel.currency);
      return unit;
    } else if (hotelIndex >= 0 && Object.keys(tripPlan[hotelIndex]).length) {
      const unit = getSymbolFromCurrency(getFilteredRoom()?.price?.currency);
      return unit;
    }
    return '$';
  };
  
  const tripPlanCheck =() => {
    return hotelIndex >= 0 && tripPlan.length && Object.keys(tripPlan[hotelIndex]).length;
  };

  const getFilteredRoom = () => {
    const offers = tripPlan[hotelIndex]?.['hotel_details']?.offer || tripPlan[hotelIndex]?.['hotel_details']?.offers;
    return offers?.find(offer => offer.id === tripPlan[hotelIndex]?.travelers_info?.offer_id);
  };

  const nightPrice = () => {
    if (id && hotel?.price) {
      return hotel.price;
    } else if (tripPlanCheck()) {
      return getFilteredRoom()?.price.total;
    }
  };

  const totalNights = () => {
    if (id && hotel?.nights) {
      return hotel?.nights;
    } else if (tripPlanCheck()) {
      const checkOut = new Date(getFilteredRoom()?.['checkOutDate']);
      const checkIn = new Date(getFilteredRoom()?.['checkInDate']);
      const diffTime = Math.abs(checkOut - checkIn);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    }
  };

  const getEstimatedPrice = () => {
    if (id) {
      return tripPlan[hotelIndex]?.['hotel']?.price;
    } else if (tripPlanCheck()) {
        const hotelObj = tripPlan[hotelIndex]?.['hotel']?.additional_detail;
        let offers;
        if (hotelObj?.estimated_price ) {
          offers = hotelObj.estimated_price;
        } else if (hotelObj?.ai_suggested_price ) {
          offers = hotelObj.ai_suggested_price;
        } else {
          offers = hotelObj?.price_level;
        }
      return offers;
    }
  };
    
  const getBookingDate =() => {
    if(tripPlanCheck() || id) {
        const dateStr = tripPlan[hotelIndex]?.['date'];

        const [checkOutStr, checkInStr] = dateStr.split('/').map(str => str.trim());
        const checkOut = new Date(Date.parse(checkOutStr));
        const checkIn = new Date(Date.parse(checkInStr));
  
        if (isNaN(checkOut) || isNaN(checkIn)) {
          return 0;
        }

        const diffTime = Math.abs(checkOut - checkIn);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      }
    };

  const renderPriceInformation = () => {
    if ((totalNights() && nightPrice())) {
      const perNightPrice = priceUnit() + (nightPrice() / totalNights()).toFixed(2);
      return (
        <span>
          {`${totalNights()} nights`} <span className='px-2'>•</span> {`${perNightPrice}/night`}
        </span>
      );
    } else if ((getEstimatedPrice() && !nightPrice() && isNaN(totalNights())) || (id && getEstimatedPrice())) {
        return (
          <span>
            {getBookingDate() !== 0 && `${getBookingDate()} nights`} <span className='px-2'>•</span> {`${getEstimatedPrice()}/night`}
          </span>
        );
      }
      
    return <span className='text-sm'>No price available</span>;
  };

  return (
    <div className='border border-neutral-100 flex flex-wrap w-full py-5 px-3 bg-neutral-100 lg:flex-row flex-col space-y-4 lg:justify-start justify-center' >
      <div className='flex-1'>
        <div>
          <div className='flex items-center gap-x-2 pb-4'>
            <span>
              <img src={ HomeSvg } alt='' />
            </span>
            { !isHotelBooked() && <span className='text-[#636363] pl-3 text-base font-normal'>Recommended Hotel</span> }
          </div>
          <div className='space-y-6'>
            <div className='text-sm px-10 '>
              <span className='text-[#161616] font-semibold text-base'> { hotel?.name || hotel?.hotel_name }</span>
              <div className='font-normal text-base'>
                { renderPriceInformation() }
              </div>
            </div>
            {
              isHotelBooked() ? (
                <div className='flex justify-between text-sm font-semibold px-10'>
                  <p className='text-periwinkle underline-offset-4 cursor-pointer' onClick={() => showHotel()}>
                    See details
                  </p>
                </div> ) : (
                <div className='flex flex-col gap-x-2 gap-y-2 lg:px-10 mb-2'>
                  <BtnTransparent 
                    props={bookHotelButton} 
                    handleClick={() => {
                      showHotel();
                      triggerMixpanelEvent('Itineray - Book Hotel button');
                    }} 
                  />
                  <BtnTransparent 
                    props={seeOtherButton} 
                    handleClick={() => {
                      showAllHotels();
                      dispatch(setFormStateChanged(false));
                      triggerMixpanelEvent('Itineray - See other hotels button');
                    }} 
                  />
                </div> )
            }
          </div>
        </div>
      </div>
      <div className='flex justify-center'>
        {
          hotel?.img_url ? 
            <img
              className='h-[180px] w-[175px] object-cover'
              src={ hotel?.img_url }
              alt='hotel'
            /> :
          <PlaceholderSkeleton applyClass={"h-[180px] w-[175px] bg-white"} />
        }
      </div>
    </div>
  );
}
