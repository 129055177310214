import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const DestinationsSkeleton = () => {
  const timesToRenderDestinationSkeleton = 2;
  const timesToRenderSkeleton = 4;
  return (
    <div className='flex flex-col w-full p-5'>
      <div className='my-5'>
        <h1 className='lg:text-4xl text-3xl lg:mb-16 mb-8 mt-6 font-semibold pl-4'>Destinations</h1>
      </div>
      <div className='p-5'>
        <Skeleton className='m-1' variant="rectangular" width={300} height={30} />
      </div>
      <div className='flex flex-col lg:flex-row gap-2 p-5'>
      { Array.from({ length: timesToRenderDestinationSkeleton }).map((_, index) => (
        <div className='w-full lg:w-1/2 h-[400px] flex flex-col card-container shadow-md p-2' key = { index }>
        <Skeleton variant="rectangular" width={"w-full"} height={'100%'} />
        <div className='flex justify-between  items-center'>
          <div className='flex flex-col m-1 gap-1 w-full lg:w-[200px]'>
            <div>
              <Skeleton className='m-1' variant="rectangular" width={'w-full'} height={20} />
            </div>
            <div>
              <Skeleton className='m-1' variant="rectangular" width={'w-full'} height={20} />
            </div>
          </div>
          <Skeleton className='m-1' variant="rectangular" width={200} height={20} />
        </div>
      </div>
      )) } 
      </div>
      <div className='p-5'>
        <Skeleton className='m-1' variant="rectangular" width={300} height={30} />
      </div>
      <div className='flex flex-col flex-wrap lg:flex-row gap-2 p-5'>
      { Array.from({ length: timesToRenderSkeleton }).map((_, index) => (
        <div className='w-full lg:w-2/5 h-[400px] flex flex-col card-container shadow-md p-2' key = { index }>
        <Skeleton variant="rectangular" width={"w-full"} height={'100%'} />
        <div className='flex justify-between'>
          <div className='flex flex-col m-1 gap-1 w-[200px]'>
            <div>
              <Skeleton className='m-1' variant="rectangular" width={'w-full'} height={20} />
            </div>
            <div>
              <Skeleton className='m-1' variant="rectangular" width={'w-full'} height={20} />
            </div>
          </div>
          <Skeleton className='mr-1 mt-2' variant="rectangular" width={50} height={20} />
        </div>
      </div>
      )) }   
      </div>
    </div>
  );
};

export default DestinationsSkeleton;
