import PlanePng from 'plane.png';
import TrainPng from 'train.png';
import arrowPng from 'right_arrow.png';
import Switch from '@mui/material/Switch';
import HotelRecommendation from './HotelRecommendation';
import CardHeader from './CardHeader';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BtnTransparent } from './UIComponents/buttons';
import { setPlacesName } from '../redux/slices/placesName';
import { setLocationsCoordination } from '../redux/slices/locationsCoordination';
import { BsSun } from "react-icons/bs";
import { getIconToDisplay } from '../common/shared/displayIconsInItinerary';
import { isBar } from '../common/shared/displayIconsInItinerary';
import { isEmpty } from 'lodash';

export default function CardBlock({ props, itineraryindex, hotelBooked, tabs, handleTabs }) {
  const dispatch = useDispatch();
  
  const [endDay, setEndDay] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startDay, setStartDay] = useState('');
  const [startDate, setStartDate] = useState('');

  const placesName = useSelector(state => state.placesName);

  const getTripDates = () => {
    if (props?.date) {
      const [startDateString, endDateString] = props.date.split("/");
      const newStartDate = new Date(startDateString);
      const newEndDate = new Date(endDateString);

      const startDayValue = String(newStartDate.getDate()).padStart(2, "0");
      setStartDay(startDayValue);
      const startMonth = String(newStartDate.getMonth() + 1).padStart(2, "0");
      setStartDate(`${startMonth}/${startDayValue}`);

      const endDayValue = String(newEndDate.getDate()).padStart(2, "0");
      setEndDay(endDayValue);
      const endMonth = String(newEndDate.getMonth() + 1).padStart(2, "0");
      setEndDate(`${endMonth}/${endDayValue}`);
    }
  };

  useEffect(() => {
    getTripDates();
  }, [props?.date]);

  useEffect(() => {
    let names = [];
    let locations = {};
    let finalLocations = []
    Object.entries(props.days_data).forEach(([key, obj]) => {
      const objNames = [];
      if (obj.places) {
        objNames.push(...obj.places.map((place, index) => ({ placeObj: place, placeIndex: index, placeName:place.name, location: place.location })));
      }
      names.push(...objNames);
    
      locations[key] = [];
      if (obj.places) {
        locations[key].push(...obj.places.map((place, index) => ({ iconUrl: getIconToDisplay(place.place_type), placeObj: place, placeIndex: index, placeName: place.name, location: place.location })));
      }}
    );
    // TODO Remove this method in the future; names should always be present.
    names = names.filter(function(element) {
      return element !== null;
    });

    for (let key in locations) {
      if(locations[key].length) {
        finalLocations.push(locations[key]);
      }
    }
    dispatch(setPlacesName({ placesName: names, cityName: props.cityName }));
    dispatch(setLocationsCoordination(finalLocations));
  }, [props]);

  const isPlacesPresent = () => {
    return Object.keys(placesName).length && Object.keys(placesName).includes(props.cityName);
  };

  const calculateTabIndex = (index, placesLength) => {
    const calculatedIndex = (Math.floor(index / placesLength) + 1);  
    const findIndexByCityAndItem = (cityIndex, itemIndex) => {
    return tabs.findIndex(item => item.cityIndex === cityIndex && item.itemIndex === itemIndex);
  };

  let tabIndex = findIndexByCityAndItem(itineraryindex, calculatedIndex);
  return ++tabIndex;
  };

  const iconCss = (placeType) => {
    return `flex ${ isBar(placeType) ? "w-12 h-12 items-end left-[-2rem] top-[0.18rem]" : "w-5 h-5 items-center left-[-1.25rem]" } justify-center absolute` ;
  };

  const bookHotelButton = {
    bg: 'bg-transparent',
    text: 'text-periwinkle',
    border: 'border-periwinkle',
    buttonName: 'Book tickets',
  };

  const mode = 'Train';

  if (props?.preArrival) {
    return (
      <div className='card-block border'>
        <div className='bg-[#D9D9D9] text-base flex-row lg:px-6 px-2 py-3 card-header'>
          <span className='font-semibold'>
            Pre-arrival
          </span>
          <span className='pl-5'>
            {`${startDate} ${endDate ? '- ' + endDate : ''}`}
          </span>
        </div>
        <div className='flex justify-between card-body lg:px-6 px-2 py-4 bg-white'>
          <div className='flex' >
            <span className='flex'>
              <img
                src={ PlanePng }
                className='h-[20px] w-[20px]'
              />
            </span>
            <div className='flex flex-col'>
              <span className='flex ml-2 font-semibold text-sm mb-3'>
                {'SFO - BCN' || props?.flightNumber}
              </span>
              <span className='flex ml-2 text-md items-center mb-3'>
                {'10:48pm' || props?.departure}
                <img
                  src={ arrowPng }
                  className=' flex h-3'
                />
                {'9:10pm' || props?.landing}
              </span>
              <span className='flex ml-2 text-sm items-center'>
                {'21 hr 45 min' || props.totalTime}  ● {'2 stops' || props.totalStops} ●
                  <Switch defaultChecked size="small" color="default" /> Multiple airlines
              </span>
            </div>
          </div>
          <div className='text-periwinkle font-semibold underline'>
            Full flight information
          </div>
        </div>
      </div>
    );
  }

  if (props?.travel) {
    return (
      <div className='card-block border'>
        <div className='bg-[#D9D9D9] text-base flex-row lg:px-6 px-2 py-3 card-header flex items-center'>
          <span className='font-semibold'>
            Day { props?.preArrival ? 'Pre-arrival' : `${startDay} - ${endDay}` }
          </span>
          <span className='pl-5'>
            {`${startDate} ${endDate ? '- ' + endDate : ''}`}
          </span>
          <span className='ml-auto flex items-center'>
            <BsSun className='text-yellow-400' />
            <span className='pl-1'> { props.weather } </span>
          </span>
        </div>
        <div className='flex justify-between card-body lg:px-6 px-2 py-4 bg-white'>
          <div className='flex items-center' >
            <span className='flex'>
              <img
                src={ props?.mode || mode == 'Fly' ? PlanePng : TrainPng }
                className='h-[20px] w-[20px]'
              />
            </span>
            <span className='flex ml-2'>
              {mode} from {props.cityName} to {props.cityName}
            </span>
          </div>
          <div className='flex' >
            <BtnTransparent
              handleClick={ () => {} }
              props={ bookHotelButton }
            />
          </div>
        </div>
      </div>
    );
  }


  return (
    <div className='card-block border'>
      {
        !isEmpty(props.booked_flight) && <CardHeader flight={ props.booked_flight } />
      }
      <div className='bg-[#D9D9D9] text-base lg:px-6 px-2 py-3 card-header font-sans flex items-center'>
        <span className='font-semibold'>
          Day { startDay } - { endDay }
        </span>
        <span className='pl-5'>
          {`${startDate} - ${endDate}`}
        </span>
        { props.weather &&
          <span className='ml-auto flex items-center'>
            <BsSun className='text-yellow-400' />
            <span className='pl-1'> { props.weather } </span>
          </span>
        }
      </div>
      <div className='card-body lg:px-6 px-2 py-4 bg-white'>
        <h3 className='font-playfair font-semibold text-2xl'> {props.cityName} </h3>
        <div>
          <ol start={1} className='grid grid-cols-1 lg:grid-cols-2 gap-4 pl-6 py-5'>
            { isPlacesPresent() && placesName[props.cityName].map((item, index) => (
              <span
                key={index + 1}
                className='ml-4 relative mb-2 cursor-pointer flex items-center'
                onClick={() => {
                  const tabIndex = calculateTabIndex(index, props?.days_data[1].places.length);

                  handleTabs(tabs[tabIndex - 1].title, tabIndex, 'dayView', item);
                }}
              >
                <div className = { iconCss(item.placeObj.place_type) }>
                  <img src = { getIconToDisplay(item.placeObj.place_type) } alt="" className='mx-3' />
                </div>
                <div className='pl-3 font-sans text-base font-normal'>{ item.placeName }</div>
              </span>
            ))}
          </ol>
        </div>
        { !hotelBooked ?
          <HotelRecommendation
            hotelIndex={ itineraryindex }
            hotel={ props.hotel }
            city={ props.cityName }
            props={{ isDayView: false }}
          /> : <></>
        }
      </div>
    </div>
  );
}
