import React from 'react';
import { Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';

const CityDetailsSkeleton = () => {
  return (
    <div className='flex w-full flex-col'>
      <div className='flex items-center lg:flex-row flex-col justify-between h-full bg-[#7B96AA] gap-2'>
        <div className='lg:w-2/5 w-full flex flex-col justify-center items-center'>
          <div className='flex w-full lg:pl-20 flex-col lg:items-start items-center py-10'>
            <h2 className='flex text-3xl font-bold mb-4 text-white'>
              <Skeleton variant="rectangular" width={200} height={30} />
            </h2>
            <p className='flex text-center text-white mb-10'>
              <Skeleton variant="rectangular" width={200} height={30} />
            </p>
            <Skeleton variant="rectangular" width={200} height={30} />
          </div>
        </div>
        <div className='lg:w-3/5 w-full'>
          <Skeleton variant="rectangular" width={'w-full'} height={200} />
        </div>
      </div>
      <div className='flex w-full flex-col lg:px-7 px-3'>
        <div className='flex flex-col gap-5 lg:flex-row lg:mx-4 lg:mt-32 mt-10 gap-x-7 lg:h-96'>
          <div className='lg:w-2/5 w-full lg:h-full h-[343px] mb-10'>
            <Skeleton variant="rectangular" className='w-full' height={400} />
          </div>
          <div className='flex flex-col gap-2 lg:w-3/5 w-full h-full'>
            <Skeleton variant="rectangular" width={200} height={30} />
            <Skeleton variant="rectangular" className='w-full' height={300} />
          </div>
        </div>
        <div className='flex bg-white lg:flex-row mt-2 flex-colmt-5 lg:mt-10 lg:mb-16 lg:mb-10 border-2 border-gray-100 lg:mx-4'>
          <Skeleton className='w-full' variant='rectangular' height={100} />
        </div>
        <div className='flex flex-col w-full px-3'>
          <Stack spacing={1}>
            <Skeleton height={50} width={200} />
            <Skeleton height={300} className='w-full' />
          </Stack>
          <Stack spacing={1}>
            <Skeleton height={50} width={200} />
            <Skeleton height={300} className='w-full' />
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default CityDetailsSkeleton;
