import React from 'react';

const PlaceholderSkeleton = ({ applyClass }) => {
  return (
    <div className={ `${ applyClass } flex items-center justify-center text-center font-semibold` }>
      Image Not Available
    </div>
  );
};

export default PlaceholderSkeleton;
