import SingleSelectDate from '../UIComponents/Dates/SingleSelectDate';
import SingleSelectDropdown from '../UIComponents/Dropdowns/SingleSelectDropdown';
import 'react-phone-number-input/style.css';
import PhoneField from '../UIComponents/PhoneField/PhoneInput';

import React, { useState, useEffect } from 'react';

const TravelerInfoForm = ({ width, onFormDataChange, travelersNumber, extraFields=[], onClose }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    phoneNumber: '',
    email: '',
  });

  const genderOptions = ['Male', 'Female', 'Other'];

  useEffect(() => {
    onFormDataChange(formData);
  }, [formData, onFormDataChange]);

  const handleChange = (value, fieldName ) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  return (
    <div className={`flex w-full flex-wrap bg-white border border-gray79 lg:p-6 p-3 flex-col ${width} mb-4`}>
      <div className='flex justify-between items-center h-[40px]'>
        <p className='py-4 font-normal text-2xl font-playfair'>Traveler {travelersNumber}</p>
        { !(travelersNumber === 1) && onClose &&
          <p
            className= "font-normal text-sm text-black font-sans cursor-pointer"
            onClick = {() => onClose(travelersNumber-1)} 
          >
            Remove
          </p>
        }
      </div>
      <p className='font-bold py-2 font-playfair text-base'>Personal Information</p>

      <form className='flex w-full flex-col'>
        <div className='flex w-full lg:flex-row flex-col gap-x-6'>
          <div className='flex flex-col w-full lg:w-[400px]'>
            <label className='text-sm font-normal'>First name*</label>
            <input
              id="firstName"
              type='text'
              value={formData.firstName}
              onChange={(e) => handleChange(e.target.value, 'firstName')}
              className={`form-control-wrapper mt-1 background lg:w-[400px] border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              required
            />
          </div>
          <div className='flex flex-col w-full lg:w-[400px]'>
            <label className='text-sm font-normal'>Middle name</label>
            <input
              id="middleName"
              type='text'
              value={formData.middleName}
              onChange={(e) => handleChange(e.target.value, 'middleName')}
              className={`form-control-wrapper mt-1 background border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
            />
          </div>
        </div>
        <div className='flex gap-x-6 w-full lg:flex-row flex-col'>
          <div className='flex flex-col w-full lg:w-[400px]'>
            <label className='text-sm font-normal'>Last name*</label>
            <input
              id="lastName"
              type='text'
              value={formData.lastName}
              onChange={(e) => handleChange(e.target.value, 'lastName')}
              className={`form-control-wrapper mt-1 background border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              required
            />
          </div>
          <div className='lg:w-[400px] w-full'>
            <SingleSelectDropdown
              className={`form-control-wrapper mt-1 background w-[400px] border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              props={{
                label: 'Gender*',
                options: genderOptions,
                value: formData.gender,
              }}
              handleChanges={ (e) => handleChange(e.target.value, 'gender') }
            />
          </div>
        </div>
        <div className='flex gap-x-6'>
          <div className='w-[400px]'>
            <label className='text-sm font-normal'>Date of birth*</label>
            <br/>
            <SingleSelectDate
              width="w-[400px]"
              maxDate={new Date()}
              date={formData.dateOfBirth}
              handleDateChange={ (val) => handleChange(val, 'dateOfBirth') }
            />
          </div>
          {
            extraFields.includes('travelerType') &&
            <div className='w-[400px] mt-1'>
              <SingleSelectDropdown
                className={`form-control-wrapper background w-[400px] border-0 focus:border-0 focus:outline-none focus:ring-0`}
                props={{
                  label: 'Traveler type*',
                  options: ['Adult', 'Infant', 'Child'],
                  value: formData.travelerType,
                }}
                handleChanges={ (e) => handleChange(e.target.value, 'travelerType') }
              />
            </div>
          }
        </div>

        <p className='my-6 font-bold font-playfair text-base'>
          Contact Information
        </p>

        <div className='flex gap-x-6 lg:flex-row flex-col'>
          <div className='flex w-full flex-col lg:w-[400px] lg:w-1/2'>
            <label className='text-sm font-normal'>Email address*</label>
            <input
              id="email"
              type='text'
              value={formData.email}
              onChange={(e) => handleChange(e.target.value, 'email')}
              className={`form-control-wrapper mt-1 background border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              required
            />
          </div>
          <div className='flex w-full flex-col lg:w-[400px]'>
            <label className='text-sm font-normal'>Phone number*</label>
            <div className='background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-1'>
              <PhoneField
                value={formData.phoneNumber}
                handlePhoneChange={(val) => {
                  if (val) {
                    handleChange(val, 'phoneNumber');
                  }
                }}
              />
            </div>
          </div>
        </div>

        {/* <div className='my-4 cursor-pointer'>
          <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <AccordionTab
              header="Additional information and requests"
              headerClassName="font-bold text-base"
            >
              N/A
            </AccordionTab>
          </Accordion>
        </div> */}
      </form>
    </div>
  );
};

export default TravelerInfoForm;
