import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MultiSelectDropdown from '../InputFields/MultiselectDropdown';

const Avoid = ({ onRespond, currentResponse }) => {
  const [selectedOption, setSelectedOption] = useState(currentResponse);
  const thingsToAvoid = useSelector(state => state.thingsToAvoid.avoids);

  const handleDropdownChange = (val) => {
    setSelectedOption(val);
    onRespond(val);
  };

  return (
    <div>
      <p>
        What are some things you want to avoid?
      </p>
      <div className='mb-20 mt-4'>
      <MultiSelectDropdown
        subCategories
        key='thingsToAvoid'
        className={ 'mb-3' }
        Label={ 'Things to avoid' }
        dropdownWidth='sm:w-[300px]'
        items={ thingsToAvoid }
        selectedItems={ selectedOption }
        chipsColor='yellow-chips-background'
        setSaveData={ (val) => handleDropdownChange(val) }
        />
      </div>
    </div>
  );
};

export default Avoid;
