import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MultiSelectDropdown from '../InputFields/MultiselectDropdown';

const Vibe = ({ onRespond, currentResponse }) => {
  const [selectedOption, setSelectedOption] = useState(currentResponse);
  const userVibes = useSelector(state => state.vibes);

  const handleDropdownChange = (val) => {
    setSelectedOption(val);
    onRespond(val);
  };

  return (
    <div>
      <p>
        What vibe are you going for?
      </p>
      <div className='mb-20 mt-4'>
      <MultiSelectDropdown
        key='vibe'
        className={ 'mb-3' }
        Label={ 'Vibes' }
        dropdownWidth='sm:w-[300px]'
        items={ userVibes.vibes }
        selectedItems={ selectedOption }
        chipsColor='yellow-chips-background'
        setSaveData={ (val) => handleDropdownChange(val) }
        />
      </div>
    </div>
  );
};

export default Vibe;
