import React, { useEffect, useRef, useState } from 'react';
import Layout from './Layout/Layout';
import { Toast } from 'primereact/toast';
import FlightDataSelection from './FlightDataSelection';
import { BtnTransparent } from './UIComponents/buttons';
import SelectedFlightsSection from './SelectedFlightsSection';
import { HiArrowLongRight } from "react-icons/hi2";
import { RiArrowUpDownFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import FlightData from './FlightData';
import './ReviewFlight.scss';
import FilterFlightsForm from './FilterFlightsForm';
import { useSelector } from 'react-redux';
import http from '../common/http';
import loadingSpinner from 'loader.png';
import { isEmpty } from 'lodash';
import { cloneDeep } from 'lodash';

const FilterFlights = ({}) => {
  const selectedFlights = useSelector(state => state.selectedFlights.selectedFlights);
  const navigate = useNavigate();
  const toast = useRef(null);

  const [flightsData, setFlightsData] = useState([]);
  const [flightOffers, setFlightOffers] = useState([]);
  const [duration, setDuration] = useState(0);
  const [priceRange, setPriceRange] = useState([200, 600]);
  const [stopsOptions, setStopsOptions] = useState([{ id: 1, name: "Stop" }, { id: 2, name: "Non-stop" }]);
  const [airlinesOptions, setAirlinesOptions] = useState([]);
  const [selectedStops, setSelectedStops] = useState([]);
  const [selectedAirlines, setSelectedAirlines] = useState([]);
  const [currentFlight, setCurrentFlight] = useState({});
  const flights = useSelector(state => state.flightBooking);
  const commonDetail = useSelector(state => state.flightBookingIntake.commonDetail);
  const [currentFlightIndex, setCurrentFlightIndex] = useState(0);
  const [iataCodes, setIATACodes] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [flightDictionaries, setFlightDictionaries] = useState({});

  const handleStopsChange = (values) => {
    setSelectedStops(values);
  };
  const handleAirlinesChange = (values) => {
    setSelectedAirlines(values);
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const updatePriceRange = (index, newValue) => {
    const newPriceRange = [...priceRange];
    newPriceRange[index] = newValue;
    setPriceRange(newPriceRange);
  };

  const [filteredOffers, setFilteredOffers] = useState([]); 

  const applyFilters = () => {
    setIsLoading(true);
    let filteredData = cloneDeep(flightOffers);

    // stop filter
    if (!isEmpty(selectedStops) && selectedStops.length != 2) {
      filteredData = filteredData.filter((offer) => {
        const stopIdx = offer.itineraries.findIndex((itinerary) => {
          return selectedStops.includes('Stop') ? itinerary.segments.length > 1 : itinerary.segments.length == 1;
        });
        return stopIdx > -1;
      });
    }
    
    // Cost Filter
    if (priceRange.length) {
      filteredData = filteredData.filter((offer) => {
        const cost = parseInt(offer.price.total);
        return (cost >= priceRange[0] && cost <= priceRange[1]);
      });
    }

    // duration filter
    if (duration > 0) {
      filteredData = filteredData.filter((offer) => {
        let itineraryDuration = convertPTtoHours(offer.itineraries[0].duration);
        return duration >= itineraryDuration;
      });
    }

    // Airlines filter
    if (selectedAirlines.length) {
      let airlinesCode = [];
      selectedAirlines.forEach((airline) => {
        let rec = airlinesOptions.find((opt) => {
          return opt.name == airline;
        });
        if (rec) {
          airlinesCode.push(rec.id);
        }
      });

      filteredData = filteredData.filter((offer) => {
        const itineraryIdx = offer.itineraries.findIndex((itinerary) => {
          const idx = itinerary.segments.findIndex((seg) => {
            return airlinesCode.includes(seg.carrierCode);
          });
          return idx > -1;
        });
        return itineraryIdx > -1;
      });
    }

    setFilteredOffers(filteredData);
    setIsLoading(false);
  };

  function convertPTtoHours(durationPT) {
    const daysHoursRegex = /P(?:(\d+)D)?T(\d+)H(\d+)M/;
    const match = durationPT.match(daysHoursRegex);
    if (!match) {
      return 0;
    }
    const days = match[1] ? parseInt(match[1]) : 0;
    const hours = parseInt(match[2]);
    const minutes = parseInt(match[3]);
    return days * 24 + hours + minutes / 60;
  }

  const fetchFlightOffers = () => {
    setIsLoading(true);
    const params = { flight_info: JSON.stringify(currentFlight), travelers_info: JSON.stringify(commonDetail) };
    http
      .get('/flight_offers', { params: params })
      .then((res) => {
        setIsLoading(false);
        setFlightOffers(res.data.offers.data);
        setFilteredOffers(res.data.offers.data);
        setFlightDictionaries(res.data.offers.dictionaries);
      })
      .catch(() => {
        const msg = `Sorry, there was an error while fetching flight offers. Please contact your administrator.`;
        setIsLoading(false);
        showMessage('error', 'Error', msg, 'bg-red-100 text-red-700');
      });
  };

  useEffect(() => {
    let airlines = [];
    if (flightDictionaries?.carriers) {
      airlines = Object.keys(flightDictionaries.carriers).map((key) => {
        return {
          id: key,
          name: flightDictionaries.carriers[key]
        };
      });
    }
    setAirlinesOptions(airlines);
  }, [flightDictionaries]);

  const fetchIATACodes = () => {
    http
      .get('iata_codes')
      .then((res) => {
        setIATACodes(res.data.iata_codes);
      });
  };

  useEffect(() => {
    if (flights.flightBooking.length) {
      fetchIATACodes();
      setFlightsData([flights.flightBooking[currentFlightIndex]] || []);
      setCurrentFlight(flights.flightBooking[currentFlightIndex] || []);
    } else {
      navigate('/reviewflight');
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(currentFlight)) {
      fetchFlightOffers();
    }
  }, [currentFlight]);

  const updateFlight = (flightName, attribute, value) => {
    const index = flightsData.findIndex(flight => flight.flightName === flightName);
    const updatedFlightsData = [...flightsData];
    updatedFlightsData[index] = {
      ...updatedFlightsData[index],
      [attribute]: value
    };
    setFlightsData(updatedFlightsData);
  };

  const updateSelectedSeats = (flightOffer) => {
    const offerIdx = filteredOffers.findIndex(flight => flight.id == flightOffer.id);
    let updatedFlightOffers = [...filteredOffers];
    updatedFlightOffers[offerIdx] = flightOffer;
    setFilteredOffers(updatedFlightOffers);
  };

  const deleteFlight = (flightName) => {
    const updatedFlightsData = flightsData.filter(flight => flight.flightName !== flightName);
    setFlightsData(updatedFlightsData);
  };
  
  const propsForFilter = {
    setStopsOptions,
    applyFilters,
    setAirlinesOptions,
    setDuration, 
    setPriceRange, 
    updatePriceRange, 
    handleAirlinesChange, 
    handleStopsChange, 
    selectedStops, 
    stopsOptions, 
    duration, 
    priceRange, 
    selectedAirlines, 
    airlinesOptions
  };

  const nextStep = async () => {
    if (flights.flightBooking.length == (currentFlightIndex + 1)) {
      let data = cloneDeep(selectedFlights);
      navigate('/reviewflightselection', { state: { selectedFlights: data } });
    } else {
      // show next flight
      const updatedIndex = currentFlightIndex + 1;
      setCurrentFlightIndex(updatedIndex);
      setFlightsData([flights.flightBooking[updatedIndex]]);
      setCurrentFlight(flights.flightBooking[updatedIndex] || {});
    }
  };

  return (
    <Layout className='flex w-full flex-col'>
      <Toast ref={toast} position="bottom-right" />
      <div className='flex w-full lg:mt-20 mt-20 lg:px-20 px-4'>
        <span
          className='font-bold text-md w-[60px] h-[30px] cursor-pointer py-2'
          onClick={() => navigate(-1) }
        >
          { '< Back' }
        </span>
      </div>
      <div className='flex flex-col w-full lg:mt-11 my-11 lg:px-20 px-4'>
        { flightsData && flightsData.length ?
          flightsData.map((flight, index) => {
            return (
              <div key = { index }>
                <FlightData
                  flight={flight}
                  updateFlight={updateFlight}
                  commonDetail={commonDetail}
                />
              </div>
            );
          })
          : <div className='text-md text-center mt-5'>No flight details available.</div>
        }
        <div className='grid grid-cols-10 gap-x-6 mt-10'>
          <div className='sm:col-span-2 col-span-10'>
            <FilterFlightsForm props = { propsForFilter } />
          </div>
          <div className='sm:col-span-6 col-span-10'>
            <div className='flex justify-between sm:mt-0 mt-8'>
              <div className='flex flex-row items-center gap-x-2'>
                <h1 className='sm:text-lg text-sm font-semibold'>{ flightsData.length > 0 ? flightsData[0].from[0].split(', ')[1] : null }</h1>
                <HiArrowLongRight className='text-periwinkle' />
                <h1 className='sm:text-lg text-sm font-semibold'>{ flightsData.length > 0 ? flightsData[0].to[0].split(', ')[1] : null }</h1>
              </div>
              {/* <div className='flex flex-row gap-x-2 cursor-pointer'>
                <h1 className='sm:ml-0 ml-8 sm:text-base text-sm font-semibold'>Sort by</h1>
                <RiArrowUpDownFill />
              </div> */}
            </div>
            { isLoading ? (
                <img
                  src={ loadingSpinner }
                  alt="spinner"
                  className='w-[50px] h-[55px] animate-spin'
                />
              ) : (
                <div className='col-span-6'>
                  {
                    filteredOffers.length ?
                    filteredOffers.map((offer, index) => (
                      <div key = { index }>
                        <FlightDataSelection
                          flightOffer={ offer }
                          dictionaries={flightDictionaries}
                          number={currentFlightIndex}
                          flight={currentFlight}
                          updateFlight={ updateFlight }
                          deleteFlight={ deleteFlight }
                          updateSelectedSeats={updateSelectedSeats}
                          iataCodes={iataCodes}
                          calledFrom='filterflights'
                        />
                      </div>
                    ))
                    : <div className='text-md text-center mt-5'>No flight details available.</div>
                  }
                </div>
              )
            }
          </div>
          <div className='sm:col-span-2 col-span-10'>
            <div className='col-span-10 justify-center text-center'>
              <SelectedFlightsSection />
              {/* <h1 className='text-dark-grey mt-2 mb-6 text-md text-center underline'>Rules, policies, cancellations</h1> */}
              <div className='sm:mb-0 mb-8'>
                <BtnTransparent
                  props={{
                    text: 'text-white',
                    bg: 'bg-periwinkle',
                    border: 'border-periwinkle',
                    buttonName: 'Continue to review'
                  }}
                  handleClick={ () => nextStep() }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FilterFlights;
