import React from 'react';
import { Paginator } from 'primereact/paginator';
import './Paginator.scss';

const PaginatorComponent = ({ rows, totalRecords, first, onPageChange, isMobile = false }) => {
  return (
    <div>
      <Paginator
        rows={rows}
        totalRecords={totalRecords}
        first={first}
        onPageChange={onPageChange}
        pageLinkSize = { isMobile ? 3 : 5 }
      />
    </div>
  );
};

export default PaginatorComponent;
