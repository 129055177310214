import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Layout from '../Layout/Layout';


const miniSkeleton = () => (
  <div className='flex justify-between items-center'>
    <div className='flex flex-col m-1 gap-1 w-[200px]'>
      <div>
        <Skeleton className='m-1' variant="rectangular" width={'w-full'} height={20} />
      </div>
      <div>
        <Skeleton className='m-1' variant="rectangular" width={'w-full'} height={20} />
      </div>
    </div>
    <Skeleton className='mr-1 mt-2' variant="circular" width={50} height={50} />
  </div>
);

const BlogsSkeleton = () => {
  return (
    <Layout>
      <div className='flex flex-col w-full pt-10 mt-10'>
        <div className='flex flex-col items-center lg:flex-row lg:justify-between '>
          <div className='flex justify-center items-center lg:h-[480px] lg:w-[800px] w-full '>
            <div className='flex flex-col gap-2 lg:ml-10  w-full p-5'>
              <div className='w-full lg:w-[200px]'>
                <Skeleton variant="rectangular" width={"w-full"} height={30} />
              </div>
              <div className='w-full lg:w-[550px]'>
                <Skeleton variant="rectangular" width={"w-full"} height={50} />
              </div>
              <div className='w-full lg:w-[250px]'>
                <Skeleton variant="rectangular" width={"w-full"} height={25} />
              </div>
              <div className='w-full lg:w-[150px]'>
                <Skeleton variant="rectangular" width={"w-full"} height={20} />
              </div>
            </div>
          </div>
          <div className='lg:h-[480px] h-[250px] lg:w-[650px] w-[250px] w-full flex justify-center items-center lg:mr-5  p-5'>
            <Skeleton variant="rectangular" className='w-full' height={'100%'} />
          </div>
        </div>
        <div className='p-5 mx-5'>
          <Skeleton className='m-1' variant="rectangular" width={300} height={30} />
        </div>
        <div className='flex flex-col lg:flex-row gap-2 p-5 mx-5'>
          <div className='w-full lg:w-1/2 h-[400px] flex flex-col card-container shadow-md p-2'>
            <Skeleton variant="rectangular" width={"w-full"} height={'100%'} />
            { miniSkeleton() }
          </div>
          <div className='w-full lg:w-1/2 h-[400px] flex flex-col card-container shadow-md p-2'>
            <Skeleton variant="rectangular" width={"w-full"} height={'100%'} />
            { miniSkeleton() }
          </div>
          <div className='w-full lg:w-1/2 h-[400px] flex flex-col card-container shadow-md p-2'>
            <Skeleton variant="rectangular" width={"w-full"} height={'100%'} />
            { miniSkeleton() }
          </div>
          <div className='w-full lg:w-1/2 h-[400px] flex flex-col card-container shadow-md p-2'>
            <Skeleton variant="rectangular" width={"w-full"} height={'100%'} />
            { miniSkeleton() }
          </div>
        </div>
        <div className='p-5 mx-5'>
          <Skeleton className='m-1' variant="rectangular" width={300} height={30} />
        </div>
        <div className='flex flex-col lg:flex-row gap-2 p-5 mx-5'>
          <div className='w-full lg:w-1/2 h-[400px] flex flex-col card-container shadow-md p-2'>
            <Skeleton variant="rectangular" width={"w-full"} height={'100%'} />
            { miniSkeleton() }
          </div>
          <div className='w-full lg:w-1/2 h-[400px] flex flex-col card-container shadow-md p-2'>
            <Skeleton variant="rectangular" width={"w-full"} height={'100%'} />
            { miniSkeleton() }
          </div>
          <div className='w-full lg:w-1/2 h-[400px] flex flex-col card-container shadow-md p-2'>
            <Skeleton variant="rectangular" width={"w-full"} height={'100%'} />
            { miniSkeleton() }
          </div>
          <div className='w-full lg:w-1/2 h-[400px] flex flex-col card-container shadow-md p-2'>
            <Skeleton variant="rectangular" width={"w-full"} height={'100%'} />
            { miniSkeleton() }
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogsSkeleton;
