import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hotelSwapping: { hotelSwapping: false, hotelIndex: null }
};

export const hotelSwappingSlice = createSlice({
  name: 'hotelSwapping',
  initialState,
  reducers: {
    setHotelSwapping: (state, action) => {
      state.hotelSwapping = action.payload;
    }
  }
});

export const { setHotelSwapping } = hotelSwappingSlice.actions;
export default hotelSwappingSlice.reducer;
