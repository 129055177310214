import './FeaturedDestinations.scss';

import http from '../../common/http';
import ItineraryForm from '../../components/ItineraryForm';

import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'primereact/carousel';
import { isLoggedIn } from '../../common/auth';
import { BtnTransparent } from '../UIComponents/buttons';
import React, { useEffect, useState, useRef } from 'react';
import FeaturedDestCardSkeleton from '../Skeletons/FeaturedDestCardSkeleton';
import PlaceholderSkeleton from '../Skeletons/PlaceholderSkeleton';
import { triggerMixpanelEvent } from '../../common/mixpanel';


const FeaturedDestinations = ({ interests, setInterests, isMobile }) => {
  const toast = useRef(null);

  const navigate = useNavigate();

  const [cityName, setCityName] = useState('');
  const [destinations, setDestinations] = useState([]);
  const [isDialogVisible , setIsDialogVisible] = useState(false);
  const [isFeaturedDestinationsLoading, setIsFeaturedDestinationsLoading]=useState(false);

  useEffect(() => {
    setIsFeaturedDestinationsLoading(true);
    http
      .get('/featured_destinations')
      .then((res) => {
        setDestinations(res.data);
        setIsFeaturedDestinationsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const isFavorite = (name) => interests.includes(name);

  const canShowfavoriteIcon = () => {
    return !isMobile && isLoggedIn();
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const itemTemplate = (destination) => (
    <div
      key={destination.id}
      className={`flex w-full ${isMobile ? 'flex-col items-center' : 'flex-row'} px-5`}
    >
      <div className={`flex flex-col justify-center ${isMobile ? 'items-center' : 'w-2/5'}`} >
        <div className='font-playfair font-normal text-2xl'>Featured Destinations</div>
        <div className={`font-bold font-monsterrat ${isMobile ? 'text-3xl' : 'text-4xl'} mt-1`}>{destination.name}, {destination.country_name}</div>
        <div className='font-sans my-6 text-base font-normal'>Food • Culture • Nightlife • Beaches</div>
        <div className={`flex ${isMobile ? 'flex-col space-y-2 mb-4' : 'flex-row items-center'}`} >
          <BtnTransparent
            key={`${isMobile ? 'Automate my trip' : 'Generate itinerary'}`}
            props={{
              bg: 'bg-periwinkle',
              border: 'border-periwinkle',
              text: 'text-white max-w-[225px] w-full mr-3',
              buttonName: `${isMobile ? 'Automate my trip' : 'Generate itinerary'}`
            }}
            handleClick={() => {
              setIsDialogVisible(true);
              setCityName(destination.name);
              triggerMixpanelEvent('Inpiration - Automate my trip button');
            }}
          />

          <BtnTransparent
            props={{
              bg: 'bg-[#FFFFFFe6] ',
              buttonName: 'See details',
              border: 'border-periwinkle',
              text: 'text-periwinkle max-w-[225px] w-full mr-3',
            }}
            handleClick={() => {
              navigate(`/results?city_name=${destination.name}&is_city_detail=true`);
            }}
          />

          { canShowfavoriteIcon() && 
            <svg
              className='h-7 2-9'
              viewBox="0 0 36 36"
              fill="none"
              onClick={() => {
                http
                  .post('/interests', { city_name: destination.name })
                  .then((res) => {
                    setInterests(res.data.interested_cities);
                    showMessage('success', 'Success', `${destination.name} is ${res.data.removed ? 'removed' : 'saved'} as favorite!`, 'bg-teal-100 text-teal-500');
                  })
                  .catch(() => {
                    showMessage('error', 'Error', 'Encountered an error while saving favorite.', 'bg-red-100 text-red-700');
                  });
              }}
              xmlns="http://www.w3.org/2000/svg"
            >
            <path
              d="M12.5272 21.238L18.2829 26C20.8609 24.0609 25.0519 20.8083 26.5261 17.6411C27.3216 15.9322 27.1139 14.0365 25.9155 12.8647C22.4961 9.52129 19.4023 11.8697 18.2829 13.4618C17.3671 10.775 12.7874 9.87946 10.6502 12.8647C8.47658 15.9009 9.65017 18.8576 12.5272 21.238Z"
              stroke="#C09DDE"
              strokeWidth="2"
              className='heart-path cursor-pointer'
              fill={isFavorite(destination.name) ? '#C09DDE' : 'none'}
            />
            <circle cx="18" cy="18" r="17" stroke="#C09DDE" strokeWidth="2" />
          </svg>}
        </div>
      </div>

      {isMobile &&
        <div className='flex'>
          { destination?.image_urls[0] ? 
            <img
              style={{ height: '258px', width: '328px' }}
              src={destination?.image_urls[0] }
            /> :
            <PlaceholderSkeleton applyClass = { 'h-[258px] w-[328px] bg-light-gray' } />
          }
        </div>
      }

      {!isMobile && <div className='flex w-3/5 justify-end p-3'>
        <div className='flex mr-10'>
          { destination?.image_urls[0] ? 
            <img
              className='shadow-right-top'
              style={{ height: '419px', width: '438px' }}
              src={destination?.image_urls[0] }
            /> :
            <PlaceholderSkeleton applyClass = { 'h-[419px] w-[438px] shadow-right-top bg-light-gray' } />
          }
        </div>
        <div className='flex flex-col'>
          { destination?.image_urls[1] ? 
            <img
              className='shadow-top-left-bottom mb-6'
              style={{ height: '190px', width: '248px' }}
              src={destination?.image_urls[1] }
            /> :
            <PlaceholderSkeleton applyClass = { 'h-[190px] w-[248px] shadow-top-left-bottom mb-6 bg-light-gray' } />
          }
          { destination?.image_urls[2] ? 
            <img
              className='shadow-top-right mt-4'
              style={{ height: '190px', width: '248px', marginLeft: '-10px' }}
              src={destination?.image_urls[2] }
            /> :
            <PlaceholderSkeleton applyClass = { 'h-[190px] w-[248px] -ml-[10px] shadow-top-right mt-4 bg-light-gray' } />
          }
        </div>
      </div>}
    </div>
  );

  if (isMobile && !isFeaturedDestinationsLoading) {
    if (destinations.length > 0) {
      return (
        <>
          {itemTemplate(destinations[0])}
          { isDialogVisible && <ItineraryForm
            city={ cityName }
            isVisible={ isDialogVisible }
            onClose={ () => setIsDialogVisible(false) }
          />}
        </>
      );
    }
  }

  return (
    <>
      <Toast ref={toast} position="bottom-right" />
      {
        isFeaturedDestinationsLoading ?
        <FeaturedDestCardSkeleton isMobile = { isMobile }
      /> :
      <Carousel
        numScroll={ 1 }
        numVisible={ 1 }
        value={ destinations }
        itemTemplate={ itemTemplate }
      />
      }

      { isDialogVisible && <ItineraryForm
        city={ cityName }
        isVisible={ isDialogVisible }
        onClose={ () => setIsDialogVisible(false) }
      />}
    </>
  );
};

export default FeaturedDestinations;
