export const passwordRegex = /^.{6,}$/;

export const usernameRegex = /^[a-zA-Z0-9_.]*$/;

export const cardsecuritycodeRegex = /^\d{3,4}$/;

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const cardnumberRegex = /\b(?:\d[ -]*?){13,16}\b/;

export const nameonCardRegex = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;

export const snakeToTitelize = (inputText) => {
  let formattedText = inputText.toLowerCase();
  formattedText = formattedText.replace('_', ' ');
  formattedText = formattedText.charAt(0).toUpperCase() + formattedText.slice(1);
  return formattedText;
};

export const capitalizeFirstLetter = (inputText) => {
  return inputText.charAt(0).toUpperCase() + inputText.slice(1).toLowerCase();
};

export const capitalizeWords = (str)=> {
  return str.toLowerCase().replace(/\b\w/g, function(char) {
      return char.toUpperCase();
  });
};
