import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  topOccasions: [],
};

export const occasionsSlice = createSlice({
  name: "occasions",
  initialState,
  reducers: {    
    setOccasions: (state, action) => {
      state.topOccasions = action.payload;
    },
  },
});

export const { setOccasions } = occasionsSlice.actions;

export default occasionsSlice.reducer;
