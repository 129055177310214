import React, { useEffect } from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Chip, Container } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import { grey } from '@mui/material/colors';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function ChipField({ items, selectedItems, chipsColors, setSaveData }) {
  const [options, setOptions] = useState(items);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [saveDataFlag, setSaveDataFlag] = useState(true);
  const [selectedNames, setSelectedNames] = useState([]);
  const toggleItem = (value) => {
    const idx = selectedOptions.findIndex((opt) => opt.name === value.name);
    if (idx === -1) {
      setSelectedNames([...selectedNames, value.name]);
      setSelectedOptions([...selectedOptions, value]);
    } else {
      setSelectedOptions(selectedOptions.filter((opt) => opt.name !== value.name));
      selectedNames.splice(idx, 1);
    }
  };
  useEffect(() => {
    if (saveDataFlag) {
      setSaveData(selectedOptions.map((s) => s.name));
    } else {
      setSaveDataFlag(true);
    }
  }, [selectedOptions]);
  useEffect(() => {
    const filteredOptions = items.filter(item => selectedItems.includes(item.name));
    setSelectedOptions(filteredOptions);
    setSelectedNames(selectedItems);
    setSaveDataFlag(false);
  }, [selectedItems]);

  useEffect(() => {
    setOptions(items);
  }, [items]);
  return (
    <Container
      sx={{
        display: 'flex',
        justifyCentend: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0,
        m: 0,
      }}
      disableGutters={true}
    >
      {
        options.map((opt) => {
          return (
            <ListItem
              key={opt.id}
              sx={{ listStyle: 'none' }}
            >
              <Chip
                sx={{
                  backgroundColor: selectedNames.includes(opt.name) ? chipsColors.primary : chipsColors.secondary,
                  "&:hover": {
                    backgroundColor: chipsColors.primary,
                  }
                }}
                size='small'
                label={opt.name}
                deleteIcon={ selectedNames.includes(opt.name) ? <DoneIcon style={{ color: grey[900] }} /> : <AddIcon style={{ color: grey[900] }} /> }
                onDelete={() => toggleItem(opt)}
                onClick={() => toggleItem(opt)}
              >
              </Chip>
            </ListItem>
          );
        })
      }
    </Container>
  );
}

ChipField.defaultProps = {
  selectedItems: []
};
