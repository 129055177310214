import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  form: {},
  flightForms: {},
};

export const priceMatchSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setPriceMatch: (state, action) => {
      state.form = action.payload;
    },
    setFlightPriceMatch: (state, action) => {
      state.flightForms = action.payload;
    }
  },
});

export const { setPriceMatch, setFlightPriceMatch } = priceMatchSlice.actions;

export default priceMatchSlice.reducer;
