import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const ItineraryLoadingsSkeleton = () => {
  return (
    <>
      <div className='flex flex-col border mt-8'>
        <div className='w-[324px] h-[310px] '>
          <Skeleton variant="rectangular" width={'w-full'} height={'100%'} />
        </div>
        <div className='flex flex-col p-2 my-2 gap-2'>
          <Skeleton variant="rectangular" width={'w-full'} height={30} />
          <Skeleton variant="rectangular" width={70} height={30} />
        </div>
      </div>
    </>
  );
};

export default ItineraryLoadingsSkeleton;
