import React, { useState } from 'react';
import SingleSelectDropdown from '../Dropdowns/SingleSelectDropdown';
import { useSelector } from 'react-redux';

const Occasion = ({ onRespond, currentResponse }) => {
  const [selectedOption, setSelectedOption] = useState(currentResponse);
  const occasions = useSelector(state => state.occasions.topOccasions);

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
    onRespond(event.target.value);
  };

  const occasionNames = occasions.map(occasion => occasion['name']);

  const dropdownData = {
    label: 'Occasion', 
    options: occasionNames,
    value: selectedOption,
    labelStyle: 'text-gray-600 text-sm',
  };

  return (
    <div>
      <p>
        Are you celebrating anything special?
      </p>
      <div className='mb-20 mt-4'>
        <SingleSelectDropdown
          props={ dropdownData }
          handleChanges={ handleDropdownChange }
        />
      </div>
    </div>
  );
};

export default Occasion;
