import LogoWhite from 'we_plan_new_new_logo.svg';
import Logo from 'we_plan_logo.svg';
import LogoPurple from 'we_plan_purple.svg';
import React, { useState, useEffect, useRef } from 'react';
import LoginForm from '../Login/LoginForm';
import SignupForm from '../SignupForm/SignupForm';
import { Link } from "react-router-dom";
import { getUserFullName, getUserId, isLoggedIn, getUserSession, logOut } from '../../common/auth';
import Avatar from '@mui/material/Avatar';
import http from '../../common/http';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
//New Images/Icons Imported
// +++++++++++++++++++++
import DownArrow from 'down_arrow.svg';
import DownArrowBlack from 'down_arrow_black.svg';
import BriefcaseWhite from 'briefcase_white.svg';
import BriefcaseBlack from 'briefcase_b.svg';
// +++++++++++++++++++++
import './Header.scss';
import { setHeader } from '../../redux/slices/header';
import { useDispatch } from 'react-redux';
import { Toast } from 'primereact/toast';
import ItineraryForm from '../ItineraryForm';
import { useSelector } from 'react-redux';
import CustomModal from '../UIComponents/Modal/customModal';
import { setFormStateChanged } from '../../redux/slices/formChanged';
import { handleNavigate } from '../../common/shared/navigationFunctions';
import { navigateToLink } from '../../common/shared/navigationFunctions';
import { triggerMixpanelEvent } from '../../common/mixpanel';


const Header = ({ openLoginForm, className }) => {
  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  const formStateChanged = useSelector(state => state.formChanged.formStateChanged);
  const saveButtonRef = useSelector(state => state.saveButton.saveButton);
  const [modalType, setModalType] = useState('');
  const [isVisible, setVisible] = useState(false);
  const [toUrl, setToUrl]=useState('');
  const toast = useRef(null);
  const dispatch = useDispatch();
  // States to set the colors of the header menus
  // ++++++++++++++++++++++++++++++s
  const [isTextWhite, setIsTextWhite] = useState(true);
  const location = useLocation();
  // ++++++++++++++++++++++++++++++
  const navigate = useNavigate();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  const [open, setOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [isSignupFormOpen, setIsSignupFormOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const closeSignupForm = () => setIsSignupFormOpen(false);

  const [cityName, setCityName] = useState('');
  const [referredBy, setReferredBy] = useState('');
  const [isDialogVisible , setIsDialogVisible] = useState(false);
  const [redIndicator, setRedIndicator] = useState(false);

  const paramObject = {
    toast, setOpen, setToUrl, setModalType, setVisible, navigate, formStateChanged, location
  };
  
  useEffect(() => {
    if (!getUserId()) return; 
    http
      .get('/upcoming_trips')
      .then((res) => {
        if (res.data.some(obj => obj.saved === false)) {
          setRedIndicator(true);
        }
      });
  }, []);

  //The followig code will update the variable to false if path is other than "\" to make the links appear black in color
  // ++++++++++++++++++++++++++++++
  useEffect(() => {
    window.scrollTo(0, 0);
    location.pathname == "/" ? setIsTextWhite(true) : setIsTextWhite(false);
  }, [location]);
  // ++++++++++++++++++++++++++++++

  const openSignupForm = () => {
    setOpen(false);
    setIsSignupFormOpen(true);
  };
  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: "#484c4c",
      },
      children: `${name?.split(' ')[0][0]}${name?.split(' ')[1][0]}`,
    };
  };
  const handleScroll = () => {
    window.scrollY > 0 ? setScrolling(true) : setScrolling(false);
  };
  const userId = getUserId();
  const userName = getUserFullName();
  useEffect(() => {
    if (userId || userName) {
      getUserSession();
    }
    referredSignup();
    if (openLoginForm) setOpen(true);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const referredSignup = () => {
    const pathname = window.location.pathname;
    if (pathname.includes('referralprogram/')) {
      http
      .get(`${pathname}/signup`)
      .then((res) => {
        setReferredBy(res.data.referred_by);
        openSignupForm();
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };
  const fetchTrendingLocation = () => {
    http
      .get('/trending_locations')
      .then((res) => {
        setIsDialogVisible(true);
        setCityName(res.data.locations[0]?.cityName);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const buttons = {
    savePlan: [
      {
        text: "Don't Save",
        onPress: () => {
          dispatch(setFormStateChanged(false));
          setVisible(false);
          navigate(`${ toUrl }`);
        }
      },
      {
        text: 'Save Itinerary',
        onPress: () => {
          setVisible(false);
          saveButtonRef.click();
        }
      },
    ],
    change: [
      {
        text: 'Exit without generating itinerary',
        onPress: () => {
          dispatch(setFormStateChanged(false));
          navigate(`${ toUrl }`);
          setVisible(false);
        }
      },
      {
        text: 'Stay on page',
        onPress: () => {
          setVisible(false);
        }
      },
    ],
  };
  
  const setActiveProfileMenu = (activeLink) => {
    localStorage.setItem("selectedOption",activeLink);
  };

  return (
    <div className={`bg-white ${className}`}>
      <Toast ref={ toast } position="bottom-right" className='w-[325px]'/>
      {/* before bg color #e39981e8 */}
      <header className={`${scrolling ? 'bg-periwinkle' : 'bg-transparent'} fixed inset-x-0 top-0 z-50 lg:h-[96px]`}>
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <Link
            onClick={ ()=> { navigateToLink('/', paramObject); } }
            >
              {/* Follwing Code changes logo at left in header to white and purple */}
              {/* <img src={Logo} className="h-[63px] w-[77px] w-auto cursor-pointer" alt='WePlan logo'/> */}
              { isTextWhite ? 
                <img 
                  src={ LogoWhite }
                  className="w-26 cursor-pointer"
                  alt='WePlan logo' 
                /> :
                ( scrolling ? 
                  <img
                    src={ LogoWhite }
                    className="w-26 w-auto cursor-pointer"
                    alt='WePlan logo'
                  /> : <img src={ LogoPurple } className="w-26 w-auto cursor-pointer" alt='WePlan logo' />
                )
              }
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" onClick={toggleMobileMenu}>
              <span className="sr-only">Open main menu</span>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-20">
            {/* Earlier the color was text-gray-900*/}
            <Link
              onClick={ ()=> { navigateToLink('/results', paramObject); } }
              className={`text-sm font-semibold leading-6 ${isTextWhite ? 'text-white' : 'text-gray-900'}`}
            >
              Inspiration
            </Link>
            <Link
              className={`menu text-sm font-semibold leading-6  ${isTextWhite ? 'text-white' : 'text-gray-900'}`}
            >
              <div className='flex cursor-pointer gap-x-2'>
                Build a trip
                {isTextWhite ? <img className="dropdown-icon" src={DownArrow} /> : <img className="dropdown-icon" src={DownArrowBlack} />}
              </div>
              <div className='menu absolute'>
                <ul className={`sub-menu pl-2 pr-2 gap-y-2 cursor-pointer ${scrolling ? 'bg-[#e39981e8]' : 'bg-transparent'}`}>
                  <li className='py-2 border-b' onClick={(() => fetchTrendingLocation())} >AI</li>
                  <li className='py-2 border-b' onClick={() => {
                    navigate('/');
                    dispatch(setHeader({ build_a_trip: true }));
                  }}>
                    Human assisted
                  </li>
                </ul>
              </div>
            </Link>
            <Link
              className={`text-sm font-semibold leading-6  ${isTextWhite ? 'text-white' : 'text-gray-900'}`}
              onClick={ () => { navigateToLink("/results?activeIndex=2", paramObject); }}
            >
              Survey
            </Link>
            {/* Earlier the color was text-gray-900*/}
            <div className={`menu text-sm font-semibold leading-6 ${isTextWhite ? 'text-white' : 'text-gray-900'}`}>
              <div className=''>
                <div className='flex cursor-pointer gap-x-2'>
                  Earn
                  { isTextWhite ? 
                    <img
                      className="dropdown-icon"
                      src={ DownArrow } 
                    /> : 
                    <img
                      className="dropdown-icon"
                      src={ DownArrowBlack } 
                    />
                  }
                </div>
                <div className='menu absolute'>
                  <ul className={`sub-menu pl-2 pr-2 gap-y-2 cursor-pointer ${scrolling ? 'bg-[#e39981e8]' : 'bg-transparent'}`}>
                    <li 
                      className='py-2 border-b'
                      onClick = { () => { 
                        navigateToLink("/localexpertprogram", paramObject);
                        triggerMixpanelEvent('Local expert - Header button');
                      } }
                    >
                        Local expert
                    </li>
                    <li
                      className='py-2 border-b'
                      onClick = { () => {
                        navigateToLink("/in-stay", paramObject);
                        triggerMixpanelEvent('Instay - Header button');
                      } }
                    >
                      In stay
                    </li>
                    <li
                      className='py-2 border-b'
                      onClick={ () => { handleNavigate("/referralprogram","referral program", paramObject); } }
                    >
                      Referral program
                    </li>
                    <li
                      className='py-2 border-b'
                      onClick={()=>{ navigateToLink("/price-match", paramObject); } }
                    >
                        Price Match
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={ `menu text-sm font-semibold leading-6 ${ isTextWhite ? 'text-white' : 'text-gray-900' }` }>
              <div className=''>
                <div className='flex cursor-pointer gap-x-2'>
                  Learn
                  { isTextWhite ? <img className="dropdown-icon" src={ DownArrow } /> : <img className="dropdown-icon" src={ DownArrowBlack } /> }
                </div>
                <div className='menu absolute'>
                  <ul className={ `sub-menu pl-2 pr-2 gap-y-2 cursor-pointer ${ scrolling ? 'bg-[#e39981e8]' : 'bg-transparent' }` }>
                    <li
                      className='py-2 border-b'
                      onClick = { () => { navigateToLink("/blogs", paramObject); } }
                    >
                      Blogs
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {
              isLoggedIn() &&
              <div className='flex gap-x-6'>
                {/* <a onClick={logOut} className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 pr-6 cursor-pointer">Logout</a> */}
                <div 
                  className='relative mt-2 h-[23px] w-[25px] cursor-pointer'
                  onClick={ () => {
                    setActiveProfileMenu("My Destinations");
                    navigateToLink(`/profile/${userId}/trips`, paramObject);
                  }}
                >
                  {isTextWhite ? <img src={BriefcaseWhite} /> : <img src={BriefcaseBlack} />}
                  { 
                    redIndicator && 
                    <div 
                      style={{ backgroundColor:"red", width:"9px", height:"9px", marginRight: "-0.2rem" }}
                      className="absolute top-0 right-0 rounded-full mt-1">
                    </div> 
                  }
                </div>
                <div className={`menu ${isTextWhite ? 'text-white' : 'text-gray-900'} leading-6 font-semibold`}>
                  <Avatar {...stringAvatar(userName)} />
                  <div className='menu absolute right-0'>
                    <ul className={`sub-menu pl-2 pr-2 cursor-pointer ${scrolling ? 'bg-light-blue' : 'bg-transparent'}`}>
                      <li 
                        className='py-2 border-b' 
                        onClick={ () => { 
                          setActiveProfileMenu("Profile");
                          navigateToLink(`/profile/${userId}`, paramObject);
                        }}
                      >
                        Profile
                      </li>
                      <li 
                        className='py-2 border-b' 
                        onClick={ () => { 
                          setActiveProfileMenu("Username");
                          navigateToLink(`/profile/${userId}/username`, paramObject);
                        }}
                      >
                        Username & Password
                      </li>
                      <li 
                        className='py-2 border-b' 
                        onClick={ () => {
                          setActiveProfileMenu("Rewards");
                          navigateToLink(`/profile/${userId}/rewards`, paramObject);
                        }}
                      >
                        Rewards & Billing
                      </li>
                      <li 
                        className='py-2 border-b' 
                        onClick={ () => { 
                          setActiveProfileMenu("Social");
                          navigateToLink(`/profile/${userId}/social`, paramObject);
                        }}
                      >
                        Social media account
                      </li>
                      <li 
                        className='py-2 border-b' 
                        onClick={ () => { 
                          setActiveProfileMenu("Profile");
                          navigateToLink(`/profile/${userId}`, paramObject);
                        }}
                      >
                        See more
                      </li>
                      <li className='py-2 border-b'onClick={async () => logOut()}>Logout</li>
                    </ul>
                  </div>
                </div>
              </div>
            }
            {
              !isLoggedIn() &&
              <div>
                <a onClick={openSignupForm} className={`text-sm font-semibold leading-6 text-gray-900 px-5 py-1 cursor-pointer ${isTextWhite ? 'text-white' : 'text-gray-900'}`}>Sign up</a>
                <a onClick={handleOpen} className="text-sm font-semibold leading-6 text-gray-900 bg-black text-white px-5 py-1 rounded cursor-pointer">Login</a>
              </div>
            }
          </div>
        </nav>
        {
          isMobileMenuOpen &&
          <div className="lg:hidden" role="dialog" aria-modal="true">
            <div className="fixed inset-0 z-50"></div>
            <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <Link
                  onClick={ ()=> { navigateToLink('/', paramObject); } }
                  >
                    <img src={Logo} className="h-8 w-auto cursor-pointer" alt='WePlan logo'/>
                  </Link>
                </a>
                <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700" onClick={toggleMobileMenu}>
                  <span className="sr-only">Close menu</span>
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-gray-500/10">
                    <div className="space-y-2 py-6">
                      <div onClick={() => {
                        navigateToLink('/results', paramObject);
                        toggleMobileMenu();
                      }
                      } className="-mx-3 mb-2 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Inspiration</div>
                      <div
                        className={`menu py-2 font-semibold leading-6  text-gray-900`}
                      >
                        <div className='flex cursor-pointer gap-x-2'>
                          Build a trip
                          {<img className="dropdown-icon" src={DownArrowBlack} />}
                        </div>
                        <div className='menu absolute'>
                          <ul className={`sub-menu pl-2 pr-2 gap-y-2 cursor-pointer bg-[#e39981e8]`}>
                            <li className='py-2 border-b' onClick={(() => {
                              navigate('/');
                              fetchTrendingLocation();
                              toggleMobileMenu();
                            })} >AI</li>
                            <li className='py-2 border-b' onClick={() => {
                              navigate('/');
                              dispatch(setHeader({ build_a_trip: true }));
                              toggleMobileMenu();
                            }}>Human assisted</li>
                          </ul>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          toggleMobileMenu();
                          navigateToLink('/results?activeIndex=2', paramObject);
                        }}
                        className={`font-semibold leading-6 pb-2 text-gray-900`}
                      >
                        Survey
                      </div>
                      <div className={`menu font-semibold leading-6 pb-2 text-gray-900`}>
                        <div className='flex cursor-pointer gap-x-2'>
                          Earn
                          <img className="dropdown-icon" src={DownArrowBlack} />
                        </div>
                        <div className='menu absolute'>
                          <ul className={`sub-menu pl-2 pr-2 gap-y-2 cursor-pointer bg-[#e39981e8]`}>
                            <li onClick={()=>{ navigateToLink("/localexpertprogram", paramObject); } } className='py-2 border-b'>Local expert</li>
                            <li onClick={()=>{ navigateToLink("/in-stay", paramObject); } } className='py-2 border-b'>In stay</li>
                            <li
                              className='py-2 border-b'
                              onClick={()=>{ handleNavigate("/referralprogram","referral program", paramObject); } }
                            >
                              Referral program
                            </li>
                            <li
                              className='py-2 border-b'
                              onClick={()=>{ navigateToLink("/price-match", paramObject); } }
                            >
                              Price Match
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className={`menu font-semibold leading-6 text-gray-900`}>
                        <div className='flex gap-x-2'>
                          Learn
                          <img className="dropdown-icon" src={DownArrowBlack} />
                        </div>
                        <div className='menu absolute'>
                          <ul className={`sub-menu pl-2 pr-2 gap-y-2 cursor-pointer ${scrolling ? 'bg-[#e39981e8]' : 'bg-transparent'}`}>
                            <li 
                              className='py-2'
                              onClick={ () => { navigateToLink("/blogs", paramObject); } }
                            >
                              Blogs
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="py-6">
                      {
                        isLoggedIn() &&
                        <div className='flex justify-between'>
                          <div
                            className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer"
                            onClick={async () => {
                              await logOut();
                            }} 
                          >
                            Logout
                          </div>
                          <Link 
                              onClick={ ()=>{navigateToLink(`/profile/${userId}`, paramObject); } }
                            >
                            <Avatar {...stringAvatar(userName)} />
                          </Link>
                        </div>
                      }
                      {
                        !isLoggedIn() &&
                        <div>
                          <div onClick={openSignupForm} className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer">Sign up</div>
                          <div onClick={handleOpen} className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer">Login</div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </header>
      <LoginForm isOpen={open} onClose={handleClose} openSignupForm={openSignupForm} />
      { isVisible &&
        <CustomModal
          type={ modalType }
          isVisible={ isVisible }
          isMobile={ isMobile }
          setVisible={ setVisible }
          buttons={ buttons[modalType] }
        />
      }
      <SignupForm
        isOpen={isSignupFormOpen}
        onClose={closeSignupForm}
        referredBy={ referredBy }
        openLoginForm={() => {
          setIsSignupFormOpen(false);
          setOpen(true);
        }}
      />
      { isDialogVisible && <ItineraryForm
        city={ cityName }
        isVisible={ isDialogVisible }
        onClose={ () => setIsDialogVisible(false) }
      />}
    </div>
  );
};

export default Header;
