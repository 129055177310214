import SingleSelectDate from "./UIComponents/Dates/SingleSelectDate";
import BookingCheckBox from './UIComponents/InputFields/Checkbox';
import React, { useEffect, useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { BtnTransparent } from "./UIComponents/buttons";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { reset } from "../redux/slices/tripPlan";
import { Toast } from 'primereact/toast';
import "../common/common_styles.scss";
import { setPlacesName } from "../redux/slices/placesName";
import { resetState } from "../redux/slices/locationsCoordination";
import { autoOpenCalendarDate } from "../common/shared/calendarEventsFunctions";

export default function ItineraryForm({ isVisible, onClose, city }) {
  const [allowConfirm, setAllowConfirm] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ dates: {}, destinations: [] , hotelBooked: false, flightBooked: false });
  const toast = useRef(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const endDateRef = useRef(null);

  useEffect(() => {
    const paramObject = {};
  
    const destinations = searchParams.getAll('destinations');
    if (destinations.length > 0 && destinations[0] != "undefined") {
      paramObject.destinations = destinations;
    }
  
    const priorities = searchParams.getAll('prioritize');
    if (priorities.length > 0 && priorities[0] != "undefined") {
      paramObject.priorities = priorities;
    }
  
    const occasions = searchParams.getAll('occasions');
    if (occasions.length > 0 && occasions[0] != "undefined") {
      paramObject.occasions = occasions;
    }
  
    const thingsToAvoid = searchParams.getAll('avoid');
    if (thingsToAvoid.length > 0 && thingsToAvoid[0] != "undefined") {
      paramObject.things_to_avoid = thingsToAvoid;
    }
  
    const averageAge = searchParams.get('average_age');
    if (averageAge) {
      paramObject.average_age = averageAge;
    }
  
    const budget = searchParams.get('budget');
    if (budget) {
      paramObject.budget = budget;
    }
  
    const vibe = searchParams.get('vibe');
    if (vibe) {
      paramObject.vibe = vibe;
    }
    const travelers_count = searchParams.get('travelers_count');
    if (travelers_count) {
      paramObject.travelers_count = travelers_count;
    }
  
 setFormData((prevData) => ({
  ...prevData,
  ...paramObject
}));

  }, []);
  

  const allowConfirmHandle = () => {
    if (isDatesPresent()) {
      setAllowConfirm(false);
    } else {
      setAllowConfirm(true);
    }
  };
  const isDatesPresent = () => {
    return formData.dates[city]
           && Object.prototype.hasOwnProperty.call(formData.dates[city], "start_date")
           && Object.prototype.hasOwnProperty.call(formData.dates[city], "end_date");
  };

  const handleCheckBoxChange = (checkboxName, isChecked) => {
    setFormData((prevData) => ({
      ...prevData,
      [checkboxName]: isChecked,
    }));
  };

  useEffect(() => {
    allowConfirmHandle();
  }, [formData]);

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  useEffect(() => {
    if (city) {
      setFormData((prevData) => ({
        ...prevData,
        ['destinations']: [city]
      }));
    }
  }, [city, isVisible]);


  const handleInput = (fieldName, newValue, nestedKey = null) => {
    setFormData((prevData) => {
      if (nestedKey) {
        return {
          ...prevData,
          dates: {
            ...prevData.dates,
            [city]: {
              ...prevData.dates[city],
              [nestedKey]: newValue,
            },
          },
        };
      } else {
        return {
          ...prevData,
          [fieldName]: newValue,
        };
      }
    });
  };

  const submitForm = () => {
    if (formData.destinations.length === 0) {
      return showMessage('error', 'Error', 'Destination must be present.', 'bg-red-100 text-red-700');
    } else if (!formData.dates[city].start_date && !formData.dates[city].end_date) {
      return showMessage('error', 'Error', 'Travel dates must be present.', 'bg-red-100 text-red-700');
    }

    const queryString = Object.keys(formData).map((key) => {
      if (key === 'dates') {
        const dateArray = Object.entries(formData[key]).map(([destination, dates]) => ({
          [destination]: {
            start_date: dates.start_date,
            end_date: dates.end_date,
          }
        }));
        return `${key}=${encodeURIComponent(JSON.stringify(dateArray))}`;
      }
      return `${key}=${encodeURIComponent(JSON.stringify(formData[key]))}`;
    }).join('&');
    dispatch(reset());
    dispatch(setPlacesName([]));
    dispatch(resetState());
    navigate(`/trip-plan?${queryString}`);
  };

  return (
    <div className="bg-[#FFFCF9] border border-[#FFFCF9] p-4">
      <Toast ref={toast} position="bottom-right" />

      {isVisible && (
        <div className="fixed inset-0 bg-black opacity-50 z-50 "></div>
      )}
      <Dialog
        header="Generate Itinerary"
        headerClassName="font-playfair text-2xl font-normal"
        visible={isVisible}
        dismissableMask={true}
        closable={true}
        className="p-4"
        style={{
          width: "24rem",
          backgroundColor: "#FFFCF9",
          border: "2px solid #000",
        }}
        onHide={onClose}
        modal
        contentStyle={{ padding: 0 }}
      >
        <div>
          <div>
            <label
              className={`text-[11px] letter-spacing block mt-8`}
              style={{ background: "linear-gradient(360deg, #fff, #fff, #fff, transparent, transparent)", }}
            >
              Travel dates
            </label>
            <div className="flex justify-between items-center">
              <div>
                <SingleSelectDate
                  date={formData.dates.start_date}
                  maxDate={(formData?.dates[city] && formData.dates[city].end_date && new Date(formData.dates[city].end_date))}
                  minDate={new Date()}
                  handleDateChange={(val) => {
                    handleInput("dates", val, "start_date");
                    autoOpenCalendarDate((formData?.dates[city] && formData.dates[city].end_date && new Date(formData.dates[city].end_date)), endDateRef);
                  }}
                />
              </div>
              <div className="px-2">
                <span> - </span>
              </div>
              <div>
                <SingleSelectDate
                  ref={endDateRef}
                  date={formData.dates.end_date}
                  minDate={(formData?.dates[city] && formData.dates[city].start_date && new Date(formData.dates[city].start_date)) || new Date()}
                  handleDateChange={(val) => {
                    handleInput("dates", val, "end_date");
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <BookingCheckBox handleInput={ handleInput } />

        <div className="flex mt-6 justify-between gap-2">
          <div className="flex flex-1 ">
            <BtnTransparent
              props={{
                text: "text-black w-full",
                bg: "bg-transparent",
                border: "border-black",
                buttonName: "Cancel",
              }}
              handleClick={onClose}
            />
          </div>

          <div className="flex flex-1">
            <BtnTransparent
              props={{
                text: "text-white w-full",
                bg: "bg-periwinkle",
                border: "border-periwinkle",
                buttonName: "Confirm",
              }}
              handleClick={submitForm}
              disabled={allowConfirm} 
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}
