import React from 'react';
import './SurveySection.scss';
import LandingSurvey from 'landing_survey2.png';
import { useNavigate } from 'react-router-dom';
import Person from "travel_survey.png";
import { BtnTransparent } from './../UIComponents/buttons';
import { triggerMixpanelEvent } from '../../common/mixpanel';

const surveySection = ({ isMobile }) => {
  const navigate = useNavigate();

   const backgroundImage = {
    backgroundImage: `url(${LandingSurvey})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
  };

  const moveToSurvey = () => {
    navigate('/results?activeIndex=2');
    triggerMixpanelEvent('LP - Survery Button');
  };

  return (
    <section className={`flex w-full ${isMobile ? '' : 'pt-60 mt-24'} pb-20`} style={backgroundImage}>
      <div className={`relative max-w-screen-xl mx-auto flex items-center gap-x-6  ${isMobile ? 'flex-col px-4' : ''}`} >
        {!isMobile &&
          <div className="w-full col-span-12 lg:col-span-5 flex justify-center mr-32">
            <div className={`${isMobile ? 'w-[200px]' : 'w-[514px]'}`}>
              <img src={ Person } alt='Dotted map'/>
            </div>
          </div>
        }

        <div className="w-full space-y-8 sm:space-y-6 mt-8 col-span-12 lg:col-span-12 flex justify-center">
          <div className={`flex flex-col lg:text-left text-center ${isMobile ? 'mb-10' : ''}`}>
            <p className="text-3xl font-playfair font-semibold my-4">Need Help Figuring Out Where to Go?</p>
            <p className="text-black text-sm font-sans mb-6">Let the power of AI match you with the perfect destination with our quick survey</p>
            <div className='flex justify-center lg:justify-start'>
              <BtnTransparent
                  props={{
                    text: 'text-white font-semibold font-sans w-[300px]',
                    bg: 'bg-black',
                    buttonName: 'Take survey',
                    border: 'border-none'
                  }}
                  handleClick={ moveToSurvey }
                />
            </div>
          </div>
        </div>
        {isMobile && <div className="w-full col-span-12 lg:col-span-5 flex justify-center">
          <div className={`${isMobile ? 'w-[200px]' : 'w-[344px]'}`}>
            <img src={Person} alt='Dotted map'/>
          </div>
        </div>}
      </div>
    </section>
  );
};

export default surveySection;
