import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import { Rating } from 'primereact/rating';
import { BtnTransparent } from './buttons';
import http from '../../common/http';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';

export default function HotelDetailModal({ hotelId, onClose }) {
  const navigate = useNavigate();
  const toast = useRef('');
  const [, setMap] = useState('');
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [hotel, setHotel] = useState({});
  const [images, setHotelImages] = useState([]);
  const imageCss = 'w-full h-full object-cover';

  useEffect(() => {
    fetchHotelDetail();
  }, []);

  const bookHotel = () => {
    navigate(`/trip-plan/show-hotel/${hotelId}`);
  };

  const { isLoaded } = useJsApiLoader({
    id: 'we-plan-google-map-script',
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY
  });

  const containerStyle = {
    width: '100%',
    height: '100%'
  };

  const onLoad = useCallback((map) => {
    map.setZoom(12);
    map.setCenter(center);
    setMap(map);
  }, [center]);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const showMessage = (severity, summary, detail, style) => {
    toast.current?.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const fetchHotelDetail = () => {
    http
      .get(`/hotel/${hotelId}`)
      .then((res) => {
        setHotel(res.data.hotel);
        const additionalDetail = res.data.hotel.additional_detail;
        setHotelImages(res.data.images);
        setCenter({ lat: additionalDetail.latitude, lng: additionalDetail.longitude });
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching hotel detail.', 'bg-red-100 text-red-700');
      });
  };

  return isLoaded ? (
    <div>
      <Dialog
        headerClassName='px-6 pt-6'
        visible={true}
        dismissableMask={true}
        style={{ width: '1192px', height: '1817px', backgroundColor: 'white', overflow: 'hidden' }}
        draggable={false}
        closable={true}
        blockScroll={true}
        responsive={true}
        resizable={false}
        onHide={onClose}
      >
        <div className='flex flex-col flex-wrap lg:px-8 px-3'>
          <div className='flex items-center gap-6'>
            <span className='text-[32px]'>{ hotel.name }</span>
          </div>
          <div className='text-[24px] mt-2'>
            <Rating
              value={ hotel.additional_detail?.rating }
              cancel={ false }
              className='text-yellow-500'
            />
          </div>
          <div className='lg:grid flex flex-wrap grid grid-cols-4 mt-4 row-span-2'>
            <div className='mb-2 col-span-2 row-span-2 lg:w-[550px] lg:h-[393px]'>
              <img
                className={ imageCss }
                src={ images[0] || 'https://placehold.co/647x550' }
                alt='Image 1'
              />
            </div>
            <div className='mb-2 col-span-1 row-span-1 lg:w-[269px] lg:h-[192px]'>
              <img
                className={ imageCss }
                src={ images[1] || 'https://placehold.co/337x266' }
                alt='Image 1'
              />
            </div>
            <div className='mb-2 col-span-1 row-span-1 lg:w-[269px] lg:h-[192px]'>
              <img
                className={ imageCss }
                src={ images[2] || 'https://placehold.co/337x266' }
                alt='Image 1'
              />
            </div>
            <div className='mb-2 col-span-1 row-span-1 lg:w-[269px] lg:h-[192px]'>
              <img
                className={ imageCss }
                src={ images[3] || 'https://placehold.co/337x266' }
                alt='Image 1'
              />
            </div>
            <div className='mb-2 col-span-1 row-span-1 lg:w-[269px] lg:h-[192px]'>
              <img
                className={ imageCss }
                src={ images[4] || 'https://placehold.co/337x266' }
                alt='Image 1'
              />
            </div>
          </div>
          <div className='flex mt-2 mb-4 h-[400px] lg:flex-row flex-col w-full'>
            <div className='flex lg:w-1/2 w-full'>
              <div className='w-full text-[24px] px-5 py-5'>
                <p className='text-2xl font-playfair'>Overview</p>
                <p className='text-sm pr-16 py-2 font-sans'>
                  { hotel?.additional_detail?.description }
                </p>
              </div>
            </div>
            <div className='flex lg:w-1/2 w-full'>
              <GoogleMap
                center={ center }
                onLoad={ onLoad }
                onUnmount={ onUnmount }
                mapContainerStyle={ containerStyle }
              >
                <MarkerF position={ center }/>
              </GoogleMap>
            </div>
          </div>
          <div className='h-px bg-gray-300 w-full mb-2'></div>
          <div className='flex gap-x-4 justify-end mb-2 lg:flex-row lg:space-y-0 flex-col space-y-2'>
            <BtnTransparent
              props={{
                bg: 'bg-periwinkle',
                border: 'border-periwinkle',
                text: 'text-white',
                buttonName: 'Book now',
              }}
              handleClick={bookHotel}
            />
          </div>
        </div>
      </Dialog>
      <Toast ref={toast} position='bottom-right' />
    </div>
  ) : <div></div>;
}
