import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showFlights: false,
  flightsInformation: []
};

export const flightInformation = createSlice({
  name: "flightInformation",
  initialState,
  reducers: {
    setShowFlightInformation: (state, action) => {
      state.showFlights = action.payload;
    },
    setFlightsInformation: (state, action) => {
      state.flightsInformation = action.payload;
    },
    reset: (state) => {
      state.showFlights = false;
      state.flightsInformation = [];
    }
  },
});

export const { setShowFlightInformation, setFlightsInformation, reset } = flightInformation.actions;

export default flightInformation.reducer;
