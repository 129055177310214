import React, { useEffect, useState, useRef } from 'react';
import { BtnTransparent } from '../UIComponents/buttons';
import http from './../../common/http';
import { Toast } from 'primereact/toast';

const ResetUsernameAndPassword = () => {
  const [emailInputEnabled, setEmailInputEnabled] = useState(false);
  const [passwordInputEnabled, setPasswordInputEnabled] = useState(false);
  const [passwordData, setPasswordData] = useState({});
  const [userEmailData, setUserEmailData] = useState({});
  const [email, setEmail] = useState(null);
  const [buttonEnabled, setButtonEnable] = useState(false);
  const [username, setUsername] = useState(null);
  const [usernameInputEnabled, setUsernameInputEnabled] = useState(false);
  const toast = useRef(null);
  const handlePasswordchanges = (fieldName, newValue) => {
    setButtonEnable(true);
    setPasswordData((prevUserData) => ({
      ...prevUserData,
      [fieldName]: newValue,
    }));
  };
  const handleEmailChanges = (fieldName, val) => {
    setButtonEnable(true);
    setUserEmailData((prevData) => ({
      ...prevData,
      [fieldName]: val
    }));
  };
  const fetchUserInfo = () => {
    http
      .get('/users/show')
      .then((res) => {
        handleEmailChanges('email', res.data.email);
        setUsername(res.data.user_name);
        setEmail(res.data.email);
        setButtonEnable(false);
      })
      .catch(() => {
        showMessage('error', 'Error', "Sorry, there was an error while fetching user info.", 'bg-red-100 text-red-700');
      });
  };
  const resetData = () => {
    userEmailData.email = email;
    userEmailData.password = '';
    passwordData.password = '';
    passwordData.current_password = '';
    passwordData.confirm_password = '';
  };
  const handleSubmit = () => {
    if (passwordInputEnabled && passwordData.password !== passwordData.confirm_password) {
      showMessage('error', 'Error', "Password didn't match.", 'bg-red-100 text-red-700');
    } else {
      const params = {
        user: {
          email: userEmailData.email,
          password_confirmation: passwordData.password_confirmation,
          password: passwordData.password,
          username: username
        }
      };
      if (passwordInputEnabled) {
        params.user.current_password = passwordData.current_password;
      } else {
        params.user.current_password = userEmailData.current_password;
      }
      http
        .put('/users', params)
        .then(() => {
          showMessage('success', 'Success', "Credentials changed successfully.", 'bg-teal-100 text-teal-500');
        })
        .catch((err) => {
          if (err.response.status === 422) {
            showMessage('error', 'Error', 'Failed to update account info due to wrong password.', 'bg-red-100 text-red-700');
          } else {
            showMessage('error', 'Error', 'Sorry, there was an error while updating account info.', 'bg-red-100 text-red-700');
          }
        });
    }
  };
  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  return (
    <div>
      <Toast ref={toast} position='bottom-right' />
      <div className='flex justify-between'>
        <div className='text-2xl font-normal mb-8 font-playfair'>Password</div>
        {!isMobile && <div className='w-[185px] h-[40px]'>
          <BtnTransparent
            props={{
              text: 'text-white font-semibold w-full h-full',
              bg: 'bg-periwinkle',
              border: 'border-periwinkle',
              buttonName: 'Save changes',
            }}
            disabled={!buttonEnabled}
            handleClick={handleSubmit}
          />
        </div>}
      </div>
      <div>
        <div className='w-100% sm:w-[600px] w-90%'>
          <label className='my-4 text-sm'>Username</label>
          <input
            type="text"
            key='username'
            value={username}
            className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2 ${usernameInputEnabled ? '' : 'disabled-input'}`}
            onChange={(e) => {
              setButtonEnable(true);
              setUsername(e.target.value);
            }}
            disabled={!usernameInputEnabled}
          />
          {
            usernameInputEnabled &&
              <div>
                <label className='my-4 text-sm'>Current password</label>
                <small className='flex text-xs text-gray-500'>(Please enter the password and hit Save changes)</small>

                <input
                  type="password"
                  key='password'
                  value={userEmailData.current_password}
                  className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                  onChange={(e) => handleEmailChanges('current_password', e.target.value)}
                  disabled={!usernameInputEnabled}
                />
              </div>
          }
          <div className='text-right text-periwinkle font-bold'>
            <span
              className='cursor-pointer'
              onClick={() => {
                setEmailInputEnabled(false);
                setPasswordInputEnabled(false);
                setUsernameInputEnabled(!usernameInputEnabled);
                resetData();
              }}
            >
              Change
            </span>
          </div>
        </div>
        <div className='sm:w-[600px]'>
          <label className='my-4 text-sm'>Email address</label>
          <input
            type="text"
            key='email'
            value={userEmailData.email}
            className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2 ${emailInputEnabled ? '' : 'disabled-input'}`}
            onChange={(e) => handleEmailChanges('email', e.target.value)}
            disabled={!emailInputEnabled}
          />
          {
            emailInputEnabled && !passwordInputEnabled &&
            <div>
              <label className='my-4 text-sm'>Current password</label>
              <small className='flex text-xs text-gray-500'>(Please enter the password and hit Save changes)</small>
              <input
                type="password"
                key='password'
                value={userEmailData.current_password}
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2 ${emailInputEnabled ? '' : 'disabled-input'}`}
                onChange={(e) => handleEmailChanges('current_password', e.target.value)}
                disabled={!emailInputEnabled}
              />
            </div>
          }
          <div className='text-right text-periwinkle font-bold'>
            <span
              className='cursor-pointer'
              onClick={() => {
                setEmailInputEnabled(!emailInputEnabled);
                setPasswordInputEnabled(false);
                setUsernameInputEnabled(false);
                resetData();
              }}
            >
              Change
            </span>
          </div>
        </div>
        {
          passwordInputEnabled && !emailInputEnabled &&
          <div>
            <div className='sm:w-[600px]'>
              <label className='my-4 text-sm'>New password</label>
              <input
                type="password"
                key='password'
                value={passwordData.password}
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                onChange={(e) => handlePasswordchanges('password', e.target.value)}
              />
            </div>
            <div className='sm:w-[600px]'>
              <label className='my-4 text-sm'>Password confirmation</label>
              <input
                type="password"
                key='confirmPassword'
                value={passwordData.confirm_password}
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                onChange={(e) => handlePasswordchanges('confirm_password', e.target.value)}
              />
            </div>
          </div>
        }
        <div className='sm:w-[600px]'>
          <label className='my-4 text-sm'>Current password</label>
          {passwordInputEnabled && <small className='flex text-xs text-gray-500'>(Please enter the password and hit Save changes)</small>}
          <input
            type='password'
            key='password'
            value={passwordData.current_password}
            className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2 ${emailInputEnabled ? 'disabled-input' : ''}`}
            onChange={(e) => handlePasswordchanges('current_password', e.target.value)}
            autoComplete='password'
            disabled={!passwordInputEnabled}
          />
        </div>
            <div className='sm:w-[600px] text-right text-periwinkle font-bold'>
              <span
                className='cursor-pointer'
                onClick={() => {
                  setPasswordInputEnabled(!passwordInputEnabled);
                  setEmailInputEnabled(false);
                  setUsernameInputEnabled(false);
                  resetData();
                }}
              >
                Change
              </span>
            </div>
      </div>
      {isMobile && <div className='flex w-full mt-4 h-[40px]'>
        <BtnTransparent
          props={{
            text: 'text-white font-semibold w-full h-full',
            bg: 'bg-periwinkle',
            border: 'border-periwinkle',
            buttonName: 'Save changes',
          }}
          disabled={!buttonEnabled}
          handleClick={handleSubmit}
        />
      </div>}
    </div>
  );
};

export default ResetUsernameAndPassword;
