import React, { useEffect, useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { BtnTransparent } from '../buttons';
import { useSelector } from 'react-redux';
import { addSelectedFlight } from '../../../redux/slices/selectedFlightsSlice';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import http from '../../../common/http';
import loadingSpinner from 'loader.png';
import { Toast } from 'primereact/toast';
import { setSelectedFlights } from '../../../redux/slices/selectedFlightsSlice';
import { cloneDeep } from 'lodash';
import { useNavigate } from 'react-router-dom';

const FaresModal = ({ flight, flightOffer, isVisible, setVisible }) => {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedFlights = useSelector(state => state.selectedFlights.selectedFlights);
  const timesToRender = 3;
  const [isLoading, setIsLoading] = useState(false);
  const [offers, setOffers] = useState([]);

  const addFlight = (offer) => {
    let currentFlightOffer = cloneDeep(flight);
    currentFlightOffer['selectedOffer'] = offer;
    dispatch(setSelectedFlights([currentFlightOffer]));
    setVisible(false);
    navigate('/reviewflightselection', { state: { selectedFlights: [currentFlightOffer] } });
    // dispatch(addSelectedFlight(flightBought));
  };

  const fetchBrandedFares = () => {
    setIsLoading(true);
    const params = { offer: JSON.stringify(flightOffer) };
    http
      .post('/branded_fare_upsell', params)
      .then((res) => {
        setOffers(res.data.offers.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current?.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  useEffect(() => {
    if (!isEmpty(flightOffer)) {
      fetchBrandedFares();
    }
  }, [flightOffer]);

  const cardTemplate = (obj) => {
    const segments = obj.travelerPricings[0].fareDetailsBySegment;
    const amenities = segments[0].amenities;
    const travelClass = segments[0].cabin;
    return (
      <div className='flex flex-col justify-between p-4 border lg:w-1/3 w-full border-gray-200 rounded-lg'>
        <div className='flex flex-row justify-between'>
          <div>
            <h1>{travelClass}</h1>
          </div>
          <div className='flex flex-col'>
            <h1 className='text-end text-2xl font-bold'>
              ${obj.travelerPricings[0].price.grandTotal || obj.travelerPricings[0].price.total}
            </h1>
            {/* <h1 className='text-end text-xs'>round trip</h1> */}
          </div>
        </div>

        <div className='flex flex-col text-sm mb-4'>
          {
            amenities.map((amen, index) => {
              return (
                <div
                  className='mt-2'
                  key={index}
                >
                  {amen.description}
                </div>
              );
            })
          }
        </div>

        <BtnTransparent
          props={{
            text: 'text-white',
            bg: 'bg-periwinkle',
            border: 'border-periwinkle',
            buttonName: 'Select',
            radius: 'rounded'
          }}
          handleClick={() => addFlight(obj)}
        />
      </div>

    );
  };
  return (
    <Dialog
      visible={isVisible}
      className={"lg:w-3/5 w-full mx-5 lg:mx-20 p-4 overflow-y-auto overflow-x-auto"}
      setVisible={setVisible}
      onHide={() => setVisible(false)}
      style={{ backgroundColor: "#ffffff" }}
      header={(

        <div className='flex flex-col p-4 w-full'>
          <div className='flex flex-start'>
            <h1 className='text-3xl font-playfair'>Select Class</h1>
          </div>
          <div className='flex flex-col gap-y-2 lg:flex-row lg:gap-x-4 w-full mt-4'>
            {
              isLoading
              ? <img 
                  src={ loadingSpinner }
                  alt="spinner"
                  className='w-[21px] h-[23px] animate-spin mr-2'
                />
              : offers.length ? offers.map((offer, index) => (
                  <React.Fragment key={index}>
                    {cardTemplate(offer)}
                  </React.Fragment>
                )) : <div className='text-sm'>No branded fare available for this flight offer.</div>
            }
          </div>
        </div>
      )}
    >
      <Toast ref={toast} position='bottom-right' />
    </Dialog>
  );
};

export default FaresModal;
