import React, { useEffect, useRef, useState } from 'react';
import Layout from './Layout/Layout';
import { Toast } from 'primereact/toast';
import { BtnTransparent } from './UIComponents/buttons';
import './ReviewFlight.scss';
import FlightDataSelection from './FlightDataSelection';
import { useNavigate } from 'react-router-dom';
import { deleteSelectedFlight } from '../redux/slices/selectedFlightsSlice';
import { useDispatch } from 'react-redux';
import loadingSpinner from 'loader.png';
import { useLocation } from 'react-router-dom';
import http from '../common/http';
import { setSelectedFlights } from '../redux/slices/selectedFlightsSlice';
import { cloneDeep } from 'lodash';
import { setFlightPriceMatch } from '../redux/slices/priceMatch';

const ReviewFlightSelection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedFlights = location.state ? location.state.selectedFlights : [];
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [flightsData, setFlightsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    selectedFlights.length
    ? flightOfferPrice(selectedFlights)
    : navigate('/reviewflight');
  }, [selectedFlights]);

  useEffect(() => {
    dispatch(setFlightPriceMatch({}));
  }, []);

  const deleteSpecificFlightOffer = (number) => {
    let flights = cloneDeep(selectedFlights);
    const updatedSelectedOffers = flights.splice(number, 1);
    dispatch(setSelectedFlights(updatedSelectedOffers));
    let updatedData = cloneDeep(flightsData);
    updatedData.splice(number, 1);
    setFlightsData(updatedData);
  };

  useEffect(() => {
    if (flightsData.length) setIsLoading(false);
  }, [flightsData]);

  const flightOfferPrice = (flights) => {
    setIsLoading(true);
    http
      .post('/flight_offer_price', { params: JSON.stringify(flights) })
      .then((res) => {
        setIsLoading(false);
        let data = [];
        let expiredOffer = 0;
        res.data.forEach((obj) => {
          if (obj.flight) {
            data.push(obj.flight);
          } else {
            expiredOffer += 1;
          }
        });
        if (expiredOffer) {
          showMessage('error', 'Error', `${expiredOffer} flight offer(s) expired. Please choose another flight offer or continue the proceed.`, 'bg-red-100 text-red-700');
        }
        setFlightsData(data);
        dispatch(setSelectedFlights(data));
      })
      .catch(() => {
        setIsLoading(false);
        const msg = `Sorry, there was an error while fetching flight offers price. Please try again or contact your administrator.`;
        showMessage('error', 'Error', msg, 'bg-red-100 text-red-700');
      });
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current?.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const updateFlight = (flightName, attribute, value) => {
    const index = flightsData.findIndex(flight => flight.flightName === flightName);
      const updatedFlightsData = [...flightsData];
      updatedFlightsData[index] = {
        ...updatedFlightsData[index],
        [attribute]: value
      };
      setFlightsData(updatedFlightsData);
  };

  const deleteFlight = (flightName) => {
    dispatch(deleteSelectedFlight(flightName));
  };

  const costTotal = () => {
    let totalCost = flightsData.reduce((accumulator, flight) => {
      const price = flight.selectedOffer.price;
      return accumulator + parseFloat(price.grandTotal || price.total);
    }, 0);
    return totalCost?.toFixed(2);
  };

  return (
    <Layout className='flex w-full flex-col'>
      <Toast ref = { toast } position="bottom-right" />
      <div className='flex w-full lg:mt-20 mt-20 lg:px-20 px-4'>
        <span
          className='font-bold text-md w-[60px] h-[30px] cursor-pointer py-2'
          onClick={() => navigate(-1) }
        >
          { '< Back' }
        </span>
      </div>
      <div className='flex flex-col w-full min-h-screen lg:mt-11 mt-11 lg:px-20 px-4'>
        <div className='mb-2'>
          <h1 className='text-3xl font-playfair'>Review flight selections</h1>
        </div>

        { isLoading ?
            <img
              src={ loadingSpinner }
              alt="spinner"
              className='w-[50px] h-[55px] animate-spin'
            />
          : flightsData.length ?
            flightsData.map((flight, index) => (
              <div key={index}>
                <FlightDataSelection
                  flightOffer={flight.selectedOffer}
                  dictionaries={flight.dictionaries}
                  flight={flight}
                  number={index}
                  updateFlight={updateFlight}
                  deleteFlight={deleteFlight}
                  deleteSpecificFlightOffer={deleteSpecificFlightOffer}
                  calledFrom='reviewFlightSelection'
                />
              </div>
            ))
          : <div className='text-md text-center mt-5'>No flight details available.</div>
        }

        <div className='flex justify-end mb-6 mt-10'>
          <div className='flex flex-col gap-y-2'>
            <div className='flex flex-row justify-between gap-2'>
              <h1 className='text-lg'>Flight Total : </h1>
              <div className='flex flex-col'>
                <h1 className='text-lg font-bold'>$ { costTotal() }</h1>
                {/* <span className='text-xs'>per traveler</span> */}
              </div>
            </div>
            <BtnTransparent
              props={{
                bg: 'bg-periwinkle',
                border: 'border-periwinkle w-56',
                text: 'text-white',
                buttonName: 'Continue'
              }}
              disabled={!flightsData.length}
              handleClick={() => navigate('/travelersinformation')}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReviewFlightSelection;
