import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { BtnTransparent } from './UIComponents/buttons.jsx';
import { Toast } from 'primereact/toast';
import http from '../common/http.js';
import '../common/common_styles.scss';
import SearchDropdown from './UIComponents/Dropdowns/SearchDropdown';
import SingleSelectDate from './UIComponents/Dates/SingleSelectDate';
import SingleSelectDropdown from './UIComponents/Dropdowns/SingleSelectDropdown';
import { setFlightBookingIntake, setFlightGeneralDetail } from '../redux/slices/flightBookingIntake.js';
import { useNavigate } from 'react-router-dom';
import { setSelectedFlights } from '../redux/slices/selectedFlightsSlice.js';
import { isEmpty, cloneDeep, compact } from 'lodash';
import { setFormStateChanged } from '../redux/slices/formChanged.js';
import { autoOpenCalendarDate } from '../common/shared/calendarEventsFunctions.js';

export default function FlightBookingIntakeModal({ onClose, isVisible }){

  const navigate = useNavigate();
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [childsOptions] = useState(['0', '1', '2', '3', '4', '5', '6']);
  const [adultsOptions] = useState(['1', '2', '3', '4', '5', '6', '7', '8', '9']);
  const [infanstOptions] = useState(['0', '1', '2', '3']);
  const [travelClassOptions] = useState(['ECONOMY', 'PREMIUM ECONOMY', 'BUSINESS', 'FIRST']);
  const [fieldOptions, setFieldOptions] = useState({});
  const tripPlan = useSelector(state => state.tripPlan.tripPlan);
  const [bookingFormData, setbookingFormData] = useState({ data: [], infants: '0', adults: '1', child: '0', trip_type: 'Round trip' });
  const searchParams = new URLSearchParams(location.search);
  const [departureAirports, setDepartureAirports] = useState({});
  const endDateRef = useRef(null);

  const fetchFieldOptions = () => {
    const params = { fields: ['flight_booking_cities'] };
    http
      .get('/field_options.json', { params: params })
      .then((res) => {
        setFieldOptions(res.data);
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching field options.', 'bg-red-100 text-red-700');
      });
  };
  
  useEffect(() => {
    fetchFieldOptions();
    if (searchParams.getAll('departureAirports').length) {
      let airports = JSON.parse(searchParams.getAll('departureAirports'));
      setDepartureAirports(airports);
    }
  }, []);


  const handleRemoveDestination = (cityType, removedDestination) => {
    setbookingFormData((prevData) => {
      const updatedDates = cityType === "from" ? { ...prevData.from } : { ...prevData.to };
      delete updatedDates[removedDestination];
      return {
        ...prevData,
        dates: updatedDates,
      };
    });
  };

  useEffect(() => {
    let travelers = parseInt(bookingFormData.child) || 0;
    travelers += parseInt(bookingFormData.adults) || 0;
    travelers += parseInt(bookingFormData.infants) || 0;
    bookingFormData.travelers = travelers;
  }, [bookingFormData.child, bookingFormData.adults, bookingFormData.infants]);


  const departureCitiesOptions = (dest) => {
    if (dest) {
      return fieldOptions?.flight_booking_cities?.filter(city => city.name !== dest[0]);
    }
  };

  const handleInput = (fieldName, newValue, index, nested=true) => {
    setbookingFormData((prevData) => {
      if (((fieldName === "from" && prevData.data[index]?.from?.length == 1) ||
          (fieldName === "to" && prevData.data[index]?.to?.length == 1)) &&
          newValue.length > 1 && nested) {
        showMessage('error', 'Error', 'Select one city.', 'bg-red-100 text-red-700');
        return prevData;
      }
      if (nested) {
        let newData = cloneDeep(prevData);
        if (!newData.data[index]) {
          newData.data[index] = {};
        }
        newData.data[index][fieldName] = newValue;
        return newData;
      } else {
        return {
          ...prevData,
          [fieldName]: newValue
        };
      }
    });
  };

  const fetchIATACode = (cityName, idx) => {
    http
      .get('/city_iata_code', { params: { city_name: cityName } })
      .then((res) => {
        if (res?.data?.iata_code) {
          handleInput('to', [`${cityName}, ${res.data.iata_code}`], idx);
        }
      })
      .catch(() => {
        handleInput("to", [`${cityName}, `]);
      });
  };

  useEffect(() => {
    let airports = {};
    if (tripPlan.length > 0) {
      if (searchParams.getAll('departureAirports').length) {
        airports = JSON.parse(searchParams.getAll('departureAirports'));
      }
      tripPlan.map((plan, index) => {
        const cityName = plan.cityName;
        fetchIATACode(cityName, index);
        const trip = tripPlan.filter(trip => trip.cityName == cityName)[0];
        if (airports[cityName]) {
          handleInput('from', [airports[cityName]], index);
        }
        const [startDateString, endDateString] = trip.date.split("/");
        const newStartDate = new Date(startDateString);
        const newEndDate = new Date(endDateString);

        const startDayValue = String(newStartDate.getDate()).padStart(2, "0");
        const startMonth = String(newStartDate.getMonth() + 1).padStart(2, "0");
        const startYear = String(newStartDate.getFullYear());

        const endDayValue = String(newEndDate.getDate()).padStart(2, "0");
        const endMonth = String(newEndDate.getMonth() + 1).padStart(2, "0");
        const endYear = String(newStartDate.getFullYear());

        handleInput('departure', `${startYear}-${startMonth}-${startDayValue}`, index);
        handleInput('return', `${endYear}-${endMonth}-${endDayValue}`, index);
      });
      handleInput("travel_class", "ECONOMY", 0, false);
    }
  }, [tripPlan]);


  const submitBookingFormData = () => {
    if (!validateData()) {
      return;
    }
    dispatch(setSelectedFlights([]));
    dispatch(setFlightBookingIntake(bookingFormData.data));
    dispatch(setFlightGeneralDetail({
      adults: bookingFormData.adults,
      infants: bookingFormData.infants,
      child: bookingFormData.child,
      travelers: bookingFormData.travelers,
      trip_type: bookingFormData.trip_type,
    }));
    onClose();
    dispatch(setFormStateChanged(false));
    navigate("/reviewflight");
    showMessage('success', 'Success', 'Booking details submitted successfully.', 'bg-teal-100 text-teal-500');
  };

  const validateData = () => {
    const isDepartureEmpty = bookingFormData.data.findIndex((obj) => isEmpty(obj.from) );
    if (isDepartureEmpty > -1) {
      showMessage('error', 'Error', 'Please select departue city.', 'bg-red-100 text-red-700');
      return false;
    }
    const infantsCount = parseInt(bookingFormData.infants);
    const adultsCount = parseInt(bookingFormData.adults);
    const childrenCount = parseInt(bookingFormData.child);
    if ((adultsCount + childrenCount) > 9) {
      showMessage('error', 'Error', 'Sorry, the total number of seated travelers (adult and children) can not exceed 9.', 'bg-red-100 text-red-700');
      return false;
    }
    if (adultsCount < 1) {
      showMessage('error', 'Error', 'Sorry, adults count must be between 1-9.', 'bg-red-100 text-red-700');
      return false;
    }
    if (infantsCount > adultsCount) {
      showMessage('error', 'Error', 'Sorry, infants count cannot be greater than adults count.', 'bg-red-100 text-red-700');
      return false;
    }
    return true;
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  return (
    <div className='flex w-full'>
      <Toast ref={ toast } position='bottom-right'/>
      <Dialog
        header="Please enter your booking information"
        headerClassName="text-2xl font-normal font-playfair px-6 pt-8"
        visible={ isVisible }
        dismissableMask={ false }
        closable={ true }
        style={ { backgroundColor: 'white', overflow: 'hidden' } }
        className='lg:w-[705px] w-full'
        onHide={ () => onClose() }
      >
        <div className='mt-4'>
          <div className='px-6'>
            <div>
              {
                tripPlan.length ?
                (Array.from({ length: tripPlan.length }).map((_, i) => {
                  return (
                    <div key={i}>
                      <div className='flex justify-between lg:flex-row flex-col'>
                        <div className='mt-4 sm:w-[47%]'>
                          <label className='text-sm text-light-black'>Departure city</label>
                          <SearchDropdown
                            key='departure'
                            props={{
                              options: departureCitiesOptions(bookingFormData.data[i]?.to),
                              chipsColor: 'blue-chips-background',
                              selectedOptions: bookingFormData.data[i]?.from || [],
                              placeHolder: 'Enter departure city',
                              dropDownMargin: 'mt-[40px]'
                            }}
                            setSaveData={ (val) => handleInput('from', compact([val.pop()]), i) }
                            removeDestination={ (departureCity) => handleRemoveDestination('from', departureCity) }
                          />
                        </div>
                        <div className='text-sm mt-5 sm:w-[47%]'>
                          <label className='text-sm text-light-black'>Arrival city</label>
                          <SearchDropdown
                            key='destinations'
                            props={{
                              options: fieldOptions.flight_booking_cities,
                              chipsColor: 'opacity-50 text-base',
                              selectedOptions: bookingFormData.data[i]?.to || [],
                              placeHolder: 'Enter destination city',
                              disabled: !!bookingFormData.data[i]?.to,
                              dropDownMargin: 'mt-[40px]'
                            }}
                            setSaveData={ (val) => handleInput('to', compact([val.pop()]), i) }
                            removeDestination={ (destinationCity) => handleRemoveDestination('from', destinationCity) }
                          />
                        </div>
                      </div>
                      <div className='mt-2'>
                        <label className='text-sm my-4 text-light-black'>Travel dates</label>
                        <div className="flex justify-between items-center gap-6 mt-2">
                          <div className='w-full'>
                            <label className='text-sm my-4 text-light-black'>Departure</label>
                            <SingleSelectDate
                              selectedDate={bookingFormData.data[i]?.departure}
                              minDate={new Date()}
                              maxDate={(new Date(bookingFormData.data[i]?.return) || new Date())}
                              handleDateChange={(val) => {
                                 handleInput('departure', val, i), 
                                 autoOpenCalendarDate(bookingFormData.data[i]?.return, endDateRef);
                              }}
                            />
                          </div>
                          <div className='w-full'>
                            <label className='text-sm my-4 text-light-black'>Return</label>
                            <SingleSelectDate
                              ref={endDateRef}
                              selectedDate={bookingFormData.data[i]?.return}
                              minDate={new Date(bookingFormData.data[i]?.departure) || new Date()}
                              handleDateChange={(val) => handleInput('return', val, i)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })) : <div></div>
              }
            </div>
            <div className='mt-4'>
              <label className='text-sm my-4 text-light-black'>Travelers</label>
              <div className="flex justify-between items-center gap-4 mt-2">
                <div className='w-full'>
                  <SingleSelectDropdown
                    props={{
                      label: 'Child',
                      options: childsOptions,
                      dropDownwidth: 'w-full',
                      value: bookingFormData?.child,
                      labelStyle: 'text-[14px] text-sm text-light-black'
                    }}
                    handleChanges={ (val) => handleInput('child', val.value, 0, false) }
                  />
                </div>
                <div className='w-full'>
                  <SingleSelectDropdown
                    props={{
                      label: 'Adult',
                      options: adultsOptions,
                      dropDownwidth: 'w-full',
                      value: bookingFormData?.adults,
                      labelStyle: 'text-[14px] text-sm text-light-black'
                    }}
                    handleChanges={ (val) => handleInput('adults', val.value, 0, false) }
                  />
                </div>
                <div className='w-full'>
                  <SingleSelectDropdown
                    props={{
                      label: 'Infants',
                      options: infanstOptions,
                      dropDownwidth: 'w-full',
                      value: bookingFormData?.infants,
                      labelStyle: 'text-[14px] text-sm text-light-black'
                    }}
                    handleChanges={ (val) => handleInput('infants', val.value, 0, false) }
                  />
                </div>
              </div>
            </div>
            <div className='mt-4'>
              <label className='text-sm my-4 text-light-black'>Travel Class</label>
              <SingleSelectDropdown
                props={{
                  options: travelClassOptions,
                  dropDownwidth: 'w-full',
                  value: bookingFormData?.travel_class,
                  labelStyle: 'text-[14px] letter-spacing block'
                }}
                handleChanges={ (val) => handleInput('travel_class', val.value, 0, false) }
              />
            </div>
            <div className='mt-6'>
              <label className='text-sm text-light-black'>Trip type</label>
              <div className='mt-2'>
                <input
                  type='radio'
                  value={bookingFormData?.trip_type}
                  checked={bookingFormData?.trip_type === "One way"}
                  onChange={() => handleInput("trip_type", "One way", 0, false)}
                />
                <label className='text-sm ml-1 font-sans'>One way</label>
              </div>
              <div>
                <input
                  type='radio'
                  value={bookingFormData?.trip_type}
                  checked={bookingFormData?.trip_type === "Round trip"}
                  onChange={() => handleInput("trip_type", "Round trip", 0, false)}
                />
                <label className='text-sm ml-1 font-sans'>Round trip</label>
              </div>
            </div>
            <BtnTransparent
              props={{
                text: 'text-white font-semibold w-full mt-6 mb-10',
                bg: 'bg-periwinkle',
                border: 'border-periwinkle',
                buttonName: 'Continue',
              }}
              handleClick={ () => submitBookingFormData() }
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}
