import React, { useEffect, useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import SingleSelectDropdown from '../../UIComponents/Dropdowns/SingleSelectDropdown';
import arrow from 'swap_right_arrow.svg';
import { BtnTransparent } from '../../UIComponents/buttons';
import { generateTimeOptions } from '../../Common/TripPlanCommon';
import { isEmpty } from 'lodash';
import { Toast } from 'primereact/toast';
import PlaceholderSkeleton from '../../Skeletons/PlaceholderSkeleton';

const TripPlaceSwapModal = ({ open=false, onClose, currentActivity, doSwap, timeSpecific, date }) => {
  const [newActivties, setNewActivities] = useState([]);
  const [newSelectedActivity, setNewSelectedActivity] = useState({});
  const [timeOptions, setTimeOptions] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [updatedTime, setUpdatedTime] = useState({});
  const toast = useRef(null);

  useEffect(() => {
    const options = generateTimeOptions();
    setTimeOptions(options);
  }, []);
  useEffect(() => {
    if (open) {
      setNewSelectedActivity({});
      setStartTime(null);
      setEndTime(null);
      setUpdatedTime({});
    }
  }, [open]);
  
  useEffect(() => {
    if (startTime || endTime) {
      setUpdatedTime({ startTime: startTime, endTime: endTime });
    }
  }, [startTime, endTime]);

  useEffect(() => {
    let activities = [];
    if (currentActivity.suggestions) {
      activities = [...currentActivity.suggestions.suggested_resturants, ...currentActivity.suggestions.suggested_attractions];
      activities = activities.map((act) => act.name);
      setNewActivities([...activities]);
    }
  }, [currentActivity]);

  const handleNewSelectedActivity = (val) => {
    let activity = null;
    activity = currentActivity.suggestions.suggested_resturants.find((obj) => obj.name === val);
    if (!activity) {
      activity = currentActivity.suggestions.suggested_attractions.find((obj) => obj.name === val);
    }
    setNewSelectedActivity(activity);
  };

  const footer = () => {
    return (
      <div className='flex justify-end gap-x-2 px-8 mb-6'>
        <BtnTransparent
          props={{
            text: 'text-charcoal font-semibold w-[227px] h-[40px]',
            bg: 'bg-white',
            border: 'border-charcoal',
            buttonName: 'Cancel',
          }}
          handleClick={() => onClose()}
        />
        <BtnTransparent
          props={{
            text: 'text-white font-semibold w-[227px] h-[40px]',
            bg: 'bg-periwinkle',
            border: 'border-periwinkle',
            buttonName: `${timeSpecific ? 'Update' : 'Confirm swap'}`,
          }}
          handleClick={() => {
            if (timeSpecific) {
              if (isEmpty(updatedTime.startTime) || isEmpty(updatedTime.endTime)) {
                showMessage('error', 'Error', 'Please select start time and end time both.', 'bg-red-100 text-red-700');
              } else {
                doSwap(newSelectedActivity, updatedTime);
              }
            } else {
              if (!isEmpty(newSelectedActivity)) {
                doSwap(newSelectedActivity, updatedTime);
                return;
              }
              showMessage('error', 'Error', 'Please select new activity.', 'bg-red-100 text-red-700');
            }
          }}
        />
      </div>
    );
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current?.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  return (
    <div>
      <Toast ref={toast} position='bottom-right' />
      <Dialog
        header={ timeSpecific ? 'Edit time' : 'Swap' }
        headerClassName="text-2xl px-8 pt-8 font-playfair"
        footer={footer}
        visible={open}
        dismissableMask={false}
        style={{
          width: '910px',
          height: timeSpecific ? '500px' : '814px',
          backgroundColor: 'white',
          overflow: 'hidden'
        }}
        draggable={false}
        blockScroll={true}
        resizable={false}
        onHide={() => onClose()}
      >
        <div className='px-8'>
          <p className='text-base mt-4 font-sans'>
            { timeSpecific ? 'Select the time you would like to edit for restaurant/activity.' : 'Select the restaurant/activity you would like to swap' }
          </p>
          <p className='text-2xl my-6 font-playfair'>
            { date.title }
          </p>
          {
            !timeSpecific &&
            <div className='flex justify-between items-center'>
              <div>
                <div className='w-[312px] h-[30px]'>
                  <SingleSelectDropdown
                    props={{
                      label: 'Activity to be swapped',
                      options: [currentActivity?.current?.name],
                      value: currentActivity?.current?.name,
                    }}
                    handleChanges={() => {}}
                  />
                </div>
                <div className='w-[312px] h-[324px] border border-gray79 mt-16'>
                  <div className='w-[312px] h-[236px]'>
                    {
                      currentActivity?.current?.images? 
                        <img
                          src={ currentActivity?.current?.images[0] }
                          alt="Place picture"
                        /> :
                        <PlaceholderSkeleton applyClass={'w-[312px] h-[236px] bg-light-gray'} />
                    }
                  </div>
                  <div className='p-4'>
                    <p>{ currentActivity?.current?.time }</p>
                    <p className='mt-1'>{ currentActivity?.current?.name }</p>
                  </div>
                </div>
              </div>
              <div>
                <img src={arrow} className='w-[131px]'/>
              </div>
              <div>
                <div className='w-[312px] h-[30px]'>
                  <SingleSelectDropdown
                    props={{
                      label: 'New activity',
                      options: newActivties,
                      value: newSelectedActivity?.name,
                    }}
                    handleChanges={(val) => handleNewSelectedActivity(val.target.value)}
                  />
                </div>
                <div className='w-[312px] h-[324px] border border-gray79 mt-16 grid place-items-center'>
                  { newSelectedActivity.name ?
                    <div className='w-[312px] h-[324px]'>
                      <div className='w-[312px] h-[236px]'>
                        {
                          newSelectedActivity.images?
                            <img
                              src={ newSelectedActivity.images[0] }
                              alt="Place picture"
                            /> :
                            <PlaceholderSkeleton applyClass={'w-[312px] h-[236px] bg-light-gray'} />
                        }
                      </div>
                      <div className='p-4'>
                        <p>{ currentActivity?.current?.time }</p>
                        <p className='mt-1'>{ newSelectedActivity.name }</p>
                      </div>
                    </div>
                    :
                    <p className='text-[#ADADAD]'>
                      No activity selected
                    </p>
                  }
                </div>
              </div>
            </div>
          }
          <div className='flex justify-between'>
            <div className='my-4 w-[312px] h-[30px]'>
              <SingleSelectDropdown
                props={{
                  label: `Select a new start time for ${newSelectedActivity?.name || currentActivity?.current?.name}:`,
                  options: timeOptions,
                  value: startTime,
                }}
                handleChanges={(e) => setStartTime(e.value)}
              />
            </div>
            <div className='my-4 w-[312px] h-[30px]'>
              <SingleSelectDropdown
                props={{
                  label: `Select a new end time for ${newSelectedActivity?.name || currentActivity?.current?.name}:`,
                  options: timeOptions,
                  value: endTime,
                }}
                handleChanges={(e) => setEndTime(e.value)}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default TripPlaceSwapModal;
