import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import http from '../../common/http';
import CancelationPolicySection from '../CancelationPolicySection/CancelationPolicySection';
import { BtnTransparent } from '../UIComponents/buttons';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useNavigate } from 'react-router-dom';
import { reset } from '../../redux/slices/tripPlan';
import { useDispatch } from 'react-redux';

const BookingConfirmation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const [bookingInfos, setBookingInfos] = useState([]);
  const [offerPolicy, setOfferPolicy] = useState(null);
  const [cancellationPolicies] = useState({
    guarantee: "The hotel will save credit card information during booking but not make any charges to the account. In the case of a no-show or out-of-policy cancellation, the hotel may charge penalties to the card.",
    deposit: "At the time of booking or by a given deadline, the hotel will charge the guest a percentage of the total amount of the reservation. The remaining amount is paid by the traveler directly at the hotel.",
    prepay: "The total amount of the reservation fee must be paid by the traveler when making the booking.",
  });

  useEffect(() => {
    if (id) {
      fetchBookingInformation();
    }
  }, [id]);

  const formateDate = (date) => {
    date = new Date(date);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const fetchBookingInformation = () => {
    http
      .get(`/booking/${id}.json`)
      .then((res) => {
        const paymentType = res.data[0].cancellation_policy;
        setBookingInfos(res.data);
        setOfferPolicy({ type: paymentType, policy: cancellationPolicies[paymentType] });
      })
      .catch(() => {

      });
  };

  const goToItinerary = () => {
    dispatch(reset());
    navigate(`/trip-plan/${id}`);
  };

  return (
    <div>
      {
        bookingInfos.map((data, index) => {
          return (
            <div
              key={index}
              className='flex justify-center items-center mb-16'
            >
              <div className='w-3/5'>
                <div>
                  <h1 className='text-3xl'>
                    You’re booked!
                  </h1>
                  <p className='my-4'>
                    You will receive an email at { data.email } with the booking info.
                  </p>
                  <p className='font-semibold'>
                    Confirmation ID: { data.confirmation_id }
                  </p>
                </div>
                <div className='border'>
                  <img
                    src='https://i.imgur.com/ez2L55U.jpeg'
                    alt='hotel picture'
                  />
                  <div className='m-12'>
                    <h1 className='text-2xl'>
                      { data.hotel_name }
                    </h1>
                    <p className='my-6'>
                      { formateDate(data.checkin) } - { formateDate(data.checkout) }
                    </p>
                    <p className='my-6'>
                      { data.rooms_detail.room_info.typeEstimated.bedType } x{ data.rooms_detail.room_info.typeEstimated.beds }
                    </p>
      
                    <div className='flex justify-between'>
                      <div className='font-semibold'>
                        Total
                      </div>
                      <div className='font-semibold'>
                        { getSymbolFromCurrency(data.currency) }{ data.price }
                      </div>
                    </div>
                    <div className='my-4'>
                      Paid with credit card ending in { data.ending_card_digits }
                    </div>
      
                    { offerPolicy && <CancelationPolicySection content={offerPolicy} /> }
                  </div>
                </div>
                <div className='flex justify-center mt-10'>
                  <BtnTransparent
                    props={{
                      text: 'text-white',
                      bg: 'bg-periwinkle',
                      border: 'border-periwinkle',
                      buttonName: 'Go to itinerary'
                    }}
                    handleClick={ goToItinerary }
                  />
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default BookingConfirmation;
