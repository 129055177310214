import React, { useEffect, useState, useRef } from 'react';
import { HiArrowLongRight } from "react-icons/hi2";
import { IoIosArrowDown } from "react-icons/io";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { BsDot } from "react-icons/bs";
import { Toast } from 'primereact/toast';
import http from '../../common/http.js';
import RadioInput from '../RadioInput';
import SearchDropdown from '../UIComponents/Dropdowns/SearchDropdown';
import { BtnTransparent } from '../UIComponents/buttons.jsx';
import { setSelectedFlights } from '../../redux/slices/selectedFlightsSlice.js';
import { setRecommendedFlightOffer } from '../../redux/slices/recommendedFlightOffer.js';
import { setFlightBookingIntake, setFlightGeneralDetail } from '../../redux/slices/flightBookingIntake.js';
import { isEmpty, cloneDeep, compact } from 'lodash';
import loadingSpinner from 'loader.png';

export default function BookAllRecomendedFlight({ setGrandTotal, isFlightOffer=false, recommendedOffer={}, isBookingConfirmation=false }) {
  const radioInputCss = 'appearance-none bg-gray-300 border-transparent w-4 h-4 rounded-full hover:bg-black checked:bg-black checked:focus:bg-black checked:focus:border-black checked:hover:bg-black checked:border-black focus:outline-none';
  const [fieldOptions, setFieldOptions] = useState({});
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [departureAirports, setDepartureAirports] = useState({});
  const tripPlan = useSelector(state => state.tripPlan.tripPlan);
  const [flightData, setFlightData] = useState([]);
  const [recommendedFlightOffer, setRcmdFlightOffer] = useState(recommendedOffer);
  const [isLoading, setIsLoading] = useState(true);
  const searchParams = new URLSearchParams(location.search);
  const [flightDictionaries, setFlightDictionaries] = useState({});
  const [offerAirlines, setOfferAirlines] = useState([]);
  const [duration, setDuration] = useState('');
  const [totalStops, setTotalStops] = useState(0);
  const [isOneWay, setIsOneWay] = useState(true);
  const [travelTime, setTravelTime] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const timeUnits = {
    days: 'd',
    hours: 'hr',
    minutes: 'min'
  };
  const [bookingFormData, setbookingFormData] = useState({ data: [], infants: '0', adults: '1', child: '0', trip_type: 'One way' });
  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const validateData = () => {
    const isDepartureEmpty = bookingFormData.data.findIndex((obj) => isEmpty(obj.from) );
    if (isDepartureEmpty > -1) {
      showMessage('error', 'Error', 'Please select departue city.', 'bg-red-100 text-red-700');
      return false;
    }
    return true;
  };

  const handleRemoveDestination = (cityType, removedDestination) => {
    setbookingFormData((prevData) => {
      const updatedDates = cityType === "from" ? { ...prevData.from } : { ...prevData.to };
      delete updatedDates[removedDestination];
      return {
        ...prevData,
        dates: updatedDates,
      };
    });
  };

  const fetchFieldOptions = () => {
    const params = { fields: ['flight_booking_cities'] };
    http
      .get('/field_options.json', { params: params })
      .then((res) => {
        setFieldOptions(res.data);
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching field options.', 'bg-red-100 text-red-700');
      });
  };

  const fetchBookAllFlightDetails = () => {
    const params = { travelers_info: JSON.stringify(bookingFormData) };
    http
      .get('/book_all_flight_details.json', { params: params })
      .then((res) => {
        setIsLoading(false);
        isFlightOffer(true);
        setFlightData(res.data);
        setRcmdFlightOffer(res.data?.offer?.token?.data?.flightOffers[0]);
        dispatch(setRecommendedFlightOffer(res.data?.offer?.token?.data?.flightOffers[0]));
        setFlightDictionaries(res.data?.offer?.token?.dictionaries);
        dispatch(setSelectedFlights([{ ...bookingFormData.data[0], selectedOffer: res.data.offer.token.data.flightOffers[0] }]));
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, no flight offer available.', 'bg-red-100 text-red-700');
      });
  };

  const fetchIATACode = (cityName, idx) => {
    http
      .get('/city_iata_code', { params: { city_name: cityName } })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.iata_code) {
          handleInput('to', [`${cityName}, ${res.data.iata_code}`], idx);
        }
      })
      .catch(() => {
        handleInput("to", [`${cityName}, `]);
      });
  };

  const submitBookingFormData = () => {
    if (!validateData()) {
      return;
    }
    setIsLoading(true);
    dispatch(setSelectedFlights([]));
    dispatch(setFlightBookingIntake(bookingFormData.data));
    dispatch(setFlightGeneralDetail({
      adults: bookingFormData.adults,
      infants: bookingFormData.infants,
      child: bookingFormData.child,
      travelers: bookingFormData.travelers,
      trip_type: bookingFormData.trip_type,
    }));
    fetchBookAllFlightDetails();
    showMessage('success', 'Success', 'Booking details submitted successfully.', 'bg-teal-100 text-teal-500');
  };

  const handleInput = (fieldName, newValue, index, nested=true) => {
    setbookingFormData((prevData) => {
      if (((fieldName === "from" && prevData.data[index]?.from?.length == 1) ||
          (fieldName === "to" && prevData.data[index]?.to?.length == 1)) &&
          newValue.length > 1 && nested) {
        showMessage('error', 'Error', 'Select one city.', 'bg-red-100 text-red-700');
        return prevData;
      }
      if (nested) {
        let newData = cloneDeep(prevData);
        if (!newData.data[index]) {
          newData.data[index] = {};
        }
        newData.data[index][fieldName] = newValue;
        return newData;
      } else {
        return {
          ...prevData,
          [fieldName]: newValue
        };
      }
    });
  };

  useEffect(() => {
    if (recommendedFlightOffer?.itineraries) {
      const segments = recommendedFlightOffer.itineraries[0].segments;
      const carriers = recommendedFlightOffer.itineraries.map((itinerary) => {
        return itinerary.segments.map((seg) => seg.carrierCode);
      });
      setOfferAirlines([...new Set(...carriers)]);
      if (recommendedFlightOffer.itineraries[0].duration) {
        let durationValues = parseDuration(recommendedFlightOffer.itineraries[0].duration);
        let durationStr = '';
        Object.keys(durationValues).forEach((key) => {
          if (durationValues[key] > 0) {
            durationStr = `${durationStr} ${durationValues[key]} ${timeUnits[key]}`;
          }
        });
        setDuration(durationStr);
      }
      setTotalStops(segments.length);
      setTotalAmount(recommendedFlightOffer.price.grandTotal || recommendedFlightOffer.price.total);
      if(!isBookingConfirmation) {
        setGrandTotal(recommendedFlightOffer.price.grandTotal || recommendedFlightOffer.price.total);
      }
      setIsOneWay(recommendedFlightOffer.oneWay);

      const departureTime = new Date(segments[0].departure.at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
      const departureDate = new Date(segments[0].departure.at).toLocaleDateString();
      const arrivalTime = new Date(segments[segments.length - 1].arrival.at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
      setTravelTime({ departureTime: departureTime, arrivalTime: arrivalTime, departureDate });
      setIsLoading(false);
    }
  }, [recommendedFlightOffer]);

  function parseDuration(durationString) {
    const regex = /PT(?:(\d+)D)?(?:(\d+)H)?(?:(\d+)M)?/;
    const matches = durationString.match(regex);
    if (!matches) {
      return {};
    }

    const days = matches[1] ? parseInt(matches[1]) : 0;
    const hours = matches[2] ? parseInt(matches[2]) : 0;
    const minutes = matches[3] ? parseInt(matches[3]) : 0;

    return {
      days: days,
      hours: hours,
      minutes: minutes
    };
  }

  const departureCitiesOptions = (dest) => {
    if (dest) {
      return fieldOptions?.flight_booking_cities?.filter(city => city.name !== dest[0]);
    }
  };

  useEffect(() => {
      fetchFieldOptions();
      if (searchParams.getAll('departureAirports').length) {
        let airports = JSON.parse(searchParams.getAll('departureAirports'));
        setDepartureAirports(airports);
      }
  }, []);

  useEffect(() => {
      setIsLoading(true);
      let airports = {};
      if (tripPlan.length > 0) {
        if (searchParams.getAll('departureAirports').length) {
          airports = JSON.parse(searchParams.getAll('departureAirports'));
        }
        tripPlan.map((plan, index) => {
          if (index) return;

          const cityName = plan.cityName;
          fetchIATACode(cityName, index);
          const trip = tripPlan.filter(trip => trip.cityName == cityName)[0];
          if (airports[cityName]) {
            handleInput('from', [airports[cityName]], index);
          }
          const [startDateString, endDateString] = trip.date.split("/");
          const newStartDate = new Date(startDateString);
          const newEndDate = new Date(endDateString);

          const startDayValue = String(newStartDate.getDate()).padStart(2, "0");
          const startMonth = String(newStartDate.getMonth() + 1).padStart(2, "0");
          const startYear = String(newStartDate.getFullYear());

          const endDayValue = String(newEndDate.getDate()).padStart(2, "0");
          const endMonth = String(newEndDate.getMonth() + 1).padStart(2, "0");
          const endYear = String(newStartDate.getFullYear());

          handleInput('departure', `${startYear}-${startMonth}-${startDayValue}`, index);
          handleInput('return', `${endYear}-${endMonth}-${endDayValue}`, index);
        });
        handleInput("travel_class", "ECONOMY", 0, false);
      }
  }, [tripPlan]);

  return (
    <div className='flex flex-col gap-2 mt-2 mb-6'>
      <Toast ref={ toast } position='bottom-right'/>
      {
        isLoading ? (
          <div className='flex justify-center items-center w-full'>
            <img
              src={ loadingSpinner }
              alt="spinner"
              className='w-[21px] h-[23px] animate-spin'
            />
            <p className='pl-2'>Getting the recommended offer....</p>
          </div>
        ) : (
          <div className ="border border-gray bg-silver shadow-md shadow-right mt-2 p-4">
            <div className='flex'>
              <div className='text-l mr-2'>
                ✈️
              </div>
              <div className='mb-1 text-sm font-bold mr-1'>{ bookingFormData.data[0]?.from?.length ? `${ bookingFormData.data[0]?.from } -` : 'To' }</div>
              <div className='mb-1 text-sm font-bold'>{ bookingFormData.data[0]?.to }</div>
            </div>
            { recommendedFlightOffer?.id &&
              <div>
                <div className = 'flex flex-row items-center'>
                  <div className = 'flex flex-row items-center gap-x-2'>
                    <h1 className = 'text-lg font-normal'>{ travelTime.departureTime }</h1>
                    <HiArrowLongRight className='text-periwinkle' />
                    <h1 className='text-lg font-normal'>{ travelTime.arrivalTime }</h1>
                    {/* <div className='flex justify-end'>
                      <IoIosArrowDown className='text-periwinkle' />
                    </div> */}
                  </div>
                </div>
                <div className='flex flex-row justify-between'>
                  <div className='flex flex-row items-center gap-x-2'>
                    <h1 className='text-sm font-normal'>{ duration }</h1>
                    <h1 className='text-sm font-normal'>● { totalStops } stops</h1>
                  </div>
                </div>
                <div className='flex flex-row justify-between'>
                  <div className='flex flex-wrap flex-row gap-x-5 my-4'>
                    {
                      flightDictionaries?.carriers && offerAirlines.map((airline, index) => {
                        return (
                          <RadioInput
                            key={index}
                            flightObject={ recommendedFlightOffer }
                            attribute={ "airline" }
                            labelName={ flightDictionaries?.carriers[airline] }
                            radioInputCss={ radioInputCss }
                          />
                        );
                      })
                    }
                  </div>
                </div>
              </div>
            }
            { !recommendedFlightOffer?.id &&
              <div className='flex gap-x-4 lg:flex-row flex-col'>
                <div className='mt-4 sm:w-[47%]'>
                  <label className='text-sm text-light-black'>Departure city</label>
                  <SearchDropdown
                    key='departure'
                    props={{
                      options: departureCitiesOptions(bookingFormData.data[0]?.to),
                      chipsColor: 'blue-chips-background',
                      selectedOptions: bookingFormData.data[0]?.from || [],
                      placeHolder: 'Enter departure city',
                      dropDownMargin: 'mt-[40px]'
                    }}
                    setSaveData={ (val) => handleInput('from', compact([val.pop()]), 0) }
                    removeDestination={ (departureCity) => handleRemoveDestination('from', departureCity) }
                  />
                </div>
                <div className='mt-9 lg:w-[20%] sm:w-[47%]'>
                  <BtnTransparent
                    props={{
                      text: 'text-white font-semibold w-full mt-6 mb-10',
                      bg: 'bg-periwinkle',
                      border: 'border-periwinkle',
                      buttonName: 'Continue',
                    }}
                    handleClick={ () => submitBookingFormData() }
                  />
                </div>
              </div>
            }
            {recommendedFlightOffer?.price &&
              <div className='flex justify-end'>
                <h1 className='text-lg font-bold text-end'>Total ${ totalAmount }</h1>
              </div>
            }
          </div>
        )
      }
    </div>
  );
}
