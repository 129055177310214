import http from '../../../common/http';
import MultiSelectDropdown from '../InputFields/MultiselectDropdown';

import { Toast } from 'primereact/toast';
import React, { useState, useEffect, useRef } from 'react';
import loadingSpinner from 'loader.png';

const Destination = ({ setIsCity, onRespond, currentResponse }) => {
  const toast = useRef(null);
  const [fieldOptions, setFieldOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { selectedCities = [], selectedCountires = [], selectedContinents = [] } = currentResponse || [];

  const options = [
    'Yes, I think I have it sorted by city',
    'Yes, I think I have it sorted by country',
    'Yes, I think I have it sorted by continent',
  ];

  const fieldLabel = [ 'Choose City', 'Choose Country', 'Choose Continent' ];
  const selectedOption = [selectedCities, selectedCountires, selectedContinents];

  useEffect(() => {
    setLoading(true);
    const params = { fields: ['survey_countries_array', 'survey_cities_array', 'survey_continents_array'] };
    http
      .get('/field_options.json', { params: params })
      .then((res) => {
        const { survey_countries_array, survey_cities_array, survey_continents_array } = res.data;

        setFieldOptions([
          { label: options[0], value: survey_cities_array },
          { label: options[1], value: survey_countries_array },
          { label: options[2], value: survey_continents_array },
        ]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        showMessage('error', 'Error', 'Sorry, there was an error while fetching field options.', 'bg-red-100 text-red-700');
      });
    }, []);


  const handleCountriesChange = (option, index) => {
    switch(index) {
      case 0:
        !option.length ? setIsCity(false) : setIsCity(true);
        onRespond({
          ...currentResponse,
          selectedCities: option.map(opt => opt.name )
        });
        break;
      case 1:
        onRespond({
          ...currentResponse,
          selectedCountires: option.map(opt => opt.name )
        });
        break;
      case 2:
        onRespond({
          ...currentResponse,
          selectedContinents: option.map(opt => opt.name )
        });
        break;
    }
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  return (
    <div className='flex flex-col w-full mb-10'>
      <Toast ref={toast} position="bottom-right" />
      <p className='mb-3'>
        Do you have any idea where you want to go?
      </p>
      {
        isLoading ? (
          <img
            src={loadingSpinner}
            alt="spinner"
            className='w-[21px] h-[23px] animate-spin'
          />
        ) : (
          fieldOptions.map((option, index) => (
            <div key={index} className='flex flex-col items-start'
            >
              <label className='text-sm' htmlFor={option}>
                {option.label}
              </label>
              <div className='w-3/5'>
                <MultiSelectDropdown
                  survey
                  key={index}
                  subCategories
                  items={option.value }
                  placeholder={fieldLabel[index]}
                  dropdownWidth='sm:w-[300px]'
                  selectedItems={selectedOption[index]}
                  chipsColor='yellow-chips-background'
                  setSaveData={(opt) => handleCountriesChange(opt, index)}
                />
              </div>
            </div>
          ))
        )
      }
    </div>
  );
};

export default Destination;
