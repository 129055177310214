import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  saveButton: null,
};

export const saveButtonSlice = createSlice({
  name: "saveButton",
  initialState,
  reducers: {
    setSaveButton: (state, action) => {
      state.saveButton = action.payload;
    },
  },
});

export const { setSaveButton } = saveButtonSlice.actions;

export default saveButtonSlice.reducer;
