import React, { useState, useRef } from 'react';
// New Imports 
import phone_icon from 'phone.png';
import support_icon from 'support.png';
// Before Logo
// import Logo from 'we_plan_new_logo.svg';
import Logo from 'we_plan_purple.png';
// Before Right Arrow
// import RightArrow from 'right_arrow.svg';
import RightArrow from 'right_arrow_new.svg';
import { FaArrowRight } from "react-icons/fa";
import RightButton from 'next_button.png';
import './Footer.scss';
import IL from 'InstagramIcon.svg';
import TTL from 'TikTokIcon.svg';
import { FaXTwitter } from "react-icons/fa6";
import http from './../../common/http';
import { Toast } from 'primereact/toast';

const Footer = ({ isMobile, footerCSS = '' }) => {
  const toast = useRef(null);
  const [phone, setPhone] = useState('');

  const savePhoneNumber = () => {
    if (phone.length < 4) {
      return showMessage('error', 'Error', 'Please enter phone number.', 'bg-red-100 text-red-700');
    }
    const params = { phone: phone };
    http
      .post('/phone', params)
      .then(() => {
        showMessage('success', 'Success', 'Thank you for providing your phone number. WePlan will be in touch with you soon.', 'bg-teal-100 text-teal-500');
        setPhone('');
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while attempting to save your phone number. Please try again later.', 'bg-red-100 text-red-700');
      });
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current?.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  return (
    <div className={`flex flex-col lg:flex-row py-16 ${ footerCSS } ${isMobile ? 'px-4' : 'px-24'} bg-charcoal justify-between text-white`} >
      <Toast ref={toast} position='bottom-right' />
      <div className='mb-10'>
        <div className='flex justify-center'>
        <img src={Logo}/>
          {/* <img src={Logo} className='w-[77px] h-[63px]' /> */}
        </div>
        <div className='flex items-center justify-center mt-6'>
          <div className="rounded-full w-12 mt-2 h-12 lg:mx-auto">
            <a href="https://www.instagram.com/weplan.ai?igsh=cmV5MGcwZHh6bm80&utm_source=qr" target="_blank" rel="noopener noreferrer">
              <img src = { IL } alt="IG" />
            </a>
          </div>
          <div className="rounded-full w-12 mt-2 h-12 lg:mx-auto">
            <a href="https://www.tiktok.com/@weplanai?_t=8jch92YbNQE&_r=1" target="_blank" rel="noopener noreferrer">
              <img src = { TTL } alt="TT" />
            </a>
          </div>
          <div className="flex items-center justify-center rounded-full w-10 mt-2 h-10 lg:mx-auto">
            <a href="https://x.com/weplanai?s=21&t=aoqnaoxxDOcdP5KJdtoLWQ" target="_blank" rel="noopener noreferrer">
              <FaXTwitter className='bg-black text-white w-9 h-9 rounded-md p-2' />
            </a>
          </div>
        </div>
      </div>
      <div className='text-sm mb-10 mx-auto'>
        <p className='font-semibold text-lg font-playfair mb-2'>
          Customer Service and Support
        </p>
        <p className='mb-2 font-sans font-normal text-base flex gap-1'>
          <img src={phone_icon} alt="contact" />
          <a href="tel:+6502730693">(650)-273-0693</a>
        </p>
        <p className='mb-2 font-sans font-normal text-base flex gap-1'>
        <img src={support_icon} alt="contact" />
          <a href="mailto:support@weplan.com">support@weplan.com</a>
        </p>
      </div>
      <div>
        <div className='text-lg font-playfair font-semibold lg:mx-0 mx-16'>
          <p>
            Questions, comments, concerns? 
          </p>
          <p>
            Leave your phone number and WePlan will contact you.
          </p>
        </div>

        <div className='mt-4 lg:mx-0 mx-16'>
          <label className='text-sm'>
            Phone Number
          </label>
          <div className='flex items-center form-control-wrapper'>
            <input
              type="text"
              key='phoneNumber'
              value={phone}
              className={`text-black mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2 m-0 min-height`}
              onChange={(e) => setPhone(e.target.value)}
            />
            {/* <div className='absolute right-0'> */}
             <div className='bg-periwinkle h-12 w-13 p-4 flex justify-center rounded-md mr-[-1px]'>
                <img
                  src={RightArrow}
                  className='cursor-pointer'
                  alt=""
                  onClick={() => savePhoneNumber()}
                />
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
