import React from 'react';
import HotelBooking from '../components/HotelBooking/HotelBooking';
import HotelIndex from '../components/Hotels/HotelIndex';
import HotelShowPage from '../components/DetailPage';
import BookingConfirmation from '../components/BookingConfirmation/BookingConfirmation';
import TripParentComponent from '../components/TripParentComponent';

const TripPlanRoutes = [
  {
    path: "/trip-plan",
    element: <TripParentComponent />,
  },
  {
    path: "/trip-plan/:id",
    element: <TripParentComponent />,
  },
  {
    path: '/hotel_booking',
    element: <HotelBooking />
  },
  {
    path: 'trip-plan/show-all-hotels',
    element: <HotelIndex />
  },
  {
    path: 'trip-plan/:id/show-all-hotels',
    element: <HotelIndex />
  },
  {
    path: 'trip-plan/show-hotel/:id',
    element: <HotelShowPage />
  },
  {
    path: '/booking/:id',
    element: <BookingConfirmation />
  }
];

export default TripPlanRoutes;
