import React, { useEffect, useState } from 'react';

const BarClubForm = ({ handleAddMoreInput }) => {

  const [formData, setFormData] = useState({
    bar: '',
  });

  useEffect(() => {
    handleAddMoreInput(formData, "setBarClubFormData");
  }, [formData, handleAddMoreInput]);

  const handleChange = (fieldName, newValue) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [fieldName]: newValue,
      };
    });
  };
  return (
    <div>
      <div className='flex gap-10 '>
        <div className='mt-5 flex-1'>
          <label className='text-sm font-sans'>
            Bar/Club
          </label>
          <input
            type="text"
            value={formData.bar}
            onInput={(e) => handleChange('bar', e.target.value)}
            className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
          />
        </div>
      </div>
    </div>
  );
};

export default BarClubForm;
