import React, { useEffect, useState, useRef } from 'react';
import http from '../common/http';
import BlogsCarousel from './Blogs/BlogsCarousel';
import CustomCard from './UIComponents/Cards/CustomCard';
import SingleCarousalSkeleton from './Skeletons/SingleCarousalSkeleton';
import { updateTripPlan } from '../redux/slices/tripPlan';
import { Toast } from 'primereact/toast';
import { Carousel } from 'primereact/carousel';
import { useDispatch, useSelector } from 'react-redux';

export default function TripPlanCarousel({ addToItinerary, showCarousalsNames = [], cityNames = [] }) {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [citiesNames, setCitiesNames] = useState([]);
  const [carousels, setCarousels] = useState([]);
  const tripPlan = useSelector(state => state.tripPlan.tripPlan);
  const [responsesReceived, setResponsesReceived] = useState(0);
  const [showSkeleton, setSkeleton] = useState(false);

  useEffect(() => {
    if (responsesReceived === citiesNames.length * showCarousalsNames.length) {
      responsesReceived && setSkeleton(true);
      if (tripPlan.length) {
        dispatch(updateTripPlan(carousels));
      }
    } 
  }, [responsesReceived, tripPlan]);


  useEffect(() => {
    setCitiesNames(cityNames);
  }, []);

  useEffect(() => {
    citiesNames.length && fetchCityData();
  }, [citiesNames]);

  const fetchCityData = () => {
    const carouselNames = showCarousalsNames;
    citiesNames.map((cityName, cityIndex ) => {
      carouselNames.map(carouselName => {
        fetchData('/fetch_city_carousel_data', carouselName, cityIndex, { city_name: cityName, carousal_name: carouselName });
      });
    });
  };

  const fetchData = async (endpoint, stateKey, cityIndex, params) => {
    try {
      const response = await http.get(endpoint, { params });
      updateCityState(stateKey, response.data[stateKey], cityIndex);
    } catch (error) {
      setResponsesReceived(prevCount => prevCount + 1);
      updateCityState(stateKey, [], cityIndex);
      showMessage('error', 'Error', ` Error fetching ${stateKey} `, 'bg-red-100 text-red-700');
    }
  };

  const updateCityState = (key, value, objectIndex) => {
    setCarousels(prevCity => {
      if (prevCity[objectIndex]) {
        return prevCity.map((city, index) => {
          if (index === objectIndex) {
            return { ...city, [key]: value };
          }
          return city;
        });
      } else {
        const newCity = { [key]: value };
        const updatedCity = [...prevCity.slice(0, objectIndex), newCity, ...prevCity.slice(objectIndex + 1)];
        return updatedCity;
      }
    });
    setResponsesReceived(prevCount => prevCount + 1);
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const carouselData = (carouselType) => {
        let carouselData = [];
        carousels.forEach((item) => {
          if (item && item[carouselType]) {
            carouselData = carouselData.concat(
              item[carouselType]
            );
          }
        });
        return carouselData;
  };

  const displayCarousal = (carousalName) => {
    return showCarousalsNames.length ? showCarousalsNames.includes(carousalName) : true;
  };

  const itemTemplate = (restaurant, index, hotelCard = '') => {
    const cardType = hotelCard || 'CityPlaceCard';
    return (
      <div
        key={index}
        className='card bg-white p-4 h-[380px] shadow-md m-2'
      >
        <CustomCard props={restaurant} cardType={cardType} addToItinerary={addToItinerary} />
      </div>
    );
  };

  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  const items = () => isMobile ? 1 : 4;

  const showIndicators = (data) => data?.length > items();

  const smallCards = (trips) => trips?.length === 3 ? '75%' : trips?.length === 2 ? '50%' : trips?.length === 1 ? '25%' : '100%';


  return (
    <div className='w-full'>
      <Toast ref={toast} position="bottom-right" />
      {
        displayCarousal("city_hotels") ?
          <>
            <div className='mt-10 mb-5 text-2xl pl-8 font-playfair font-normal'>Hotels</div>
            <div className={`mb-10 ${showIndicators('city_hotels') ? '' : 'px-4'}`}>
            {(carouselData('city_hotels')?.length > 0 || showSkeleton) ? (
              carouselData('city_hotels')?.length ? (
                <Carousel
                  numScroll={1}
                  itemTemplate={(item, index) => itemTemplate(item, index, 'hotelCard')}
                  value={carouselData('city_hotels')}
                  showNavigators={showIndicators(carouselData('city_hotels'))}
                  numVisible={ items() }
                  style={{ paddingLeft: carouselData('city_hotels').length > items() ? '' : '10px', width: items() === 1 ? '100%' : smallCards(carouselData('city_hotels')) }}
                />
              ) : (
                <p className='pl-10 font-playfair'>No hotels available</p>
              )
            ) : <SingleCarousalSkeleton />}
            </div>
          </> :
          <></>
      }

      {
        displayCarousal("suggested_resturants") ?
          <>
            <div className='mt-10 mb-5 text-2xl pl-8 font-playfair font-normal'>Food and Drink</div>
            <div className={`${showIndicators('suggested_resturants') ? '' : 'px-4'}`}>
              {
                (carouselData('suggested_resturants')?.length > 0 || showSkeleton) ? (
                  carouselData('suggested_resturants')?.length ? (
                    <Carousel
                      numScroll={1}
                      itemTemplate={(item, index) => itemTemplate(item, index, 'suggestedRestaurantCard')}
                      value={carouselData('suggested_resturants')}
                      showNavigators={showIndicators(carouselData('suggested_resturants'))}
                      numVisible={ items() }
                      style={{ paddingLeft: carouselData('suggested_resturants')?.length > items() ? '' : '10px', width: items() === 1 ? '100%' : smallCards(carouselData('suggested_resturants')) }}
                    />
                  ) : (
                    <p className='pl-10'>No popular places</p>
                  )
                ) : <SingleCarousalSkeleton />
              }
            </div>
          </> :
          <></>
      }
      {
        displayCarousal("suggested_attractions") ?
          <>
            <div className='mt-10 mb-5 text-2xl pl-8 font-playfair font-normal'>Attractions</div>
            <div className={`mb-10 ${showIndicators('suggested_attractions') ? '' : 'px-4'}`}>
            {
              (carouselData('suggested_attractions')?.length > 0 || showSkeleton) ? (
                carouselData('suggested_attractions')?.length ? (
                  <Carousel
                    numScroll={1}
                    itemTemplate={(item, index) => itemTemplate(item, index, 'suggestedAttractionCard')}
                    value={carouselData('suggested_attractions')}
                    showNavigators={showIndicators(carouselData('suggested_attractions'))}
                    numVisible={ items() }
                    style={{ paddingLeft: carouselData('suggested_attractions')?.length > items() ? '' : '10px', width: items() === 1 ? '100%' : smallCards(carouselData('suggested_attractions')) }}
                  />
                ) : (
                  <p className='pl-10 font-playfair'>No attractions available</p>
                )
              ) : <SingleCarousalSkeleton />
            }
            </div>
          </> :
          <></>
      }
      {
        displayCarousal("clubs_and_bars") ?
          <>
            <div className='mt-10 mb-5 text-2xl pl-8 font-playfair font-normal'>Bars & Clubs</div>
            <div className={`mb-10 ${showIndicators('clubs_and_bars') ? '' : 'px-4'}`}>
              { 
                (carouselData('clubs_and_bars')?.length > 0 || showSkeleton) ? (
                  carouselData('clubs_and_bars')?.length ? (
                    <Carousel
                    numScroll={ 1 }
                    itemTemplate={ itemTemplate }
                    value={ carouselData('clubs_and_bars') }
                    showNavigators={ showIndicators(carouselData('clubs_and_bars')) }
                    numVisible={ items() }
                    style={{ paddingLeft: carouselData('clubs_and_bars')?.length > items() ? '' : '10px', width: items() === 1 ? '100%' : smallCards(carouselData('clubs_and_bars')) }}
                    />
                  ) : (
                    <p className='pl-10 font-playfair'>No bars & clubs available</p>
                  )
                ) : <SingleCarousalSkeleton />
              }
            </div>
          </> :
          <></>
      }
      {
        displayCarousal("tours") ?
          <>
            <div className='mt-10 mb-5 text-2xl pl-8 font-playfair font-normal'>Tours</div>
            <div className={`mb-10 `}>
              {
                (carouselData('tours')?.length > 0 || showSkeleton) ? (
                  carouselData('tours')?.length ? (
                    <Carousel
                    numScroll={ 1 }
                    itemTemplate={ itemTemplate }
                    value={ carouselData('tours') }
                    showNavigators={ showIndicators(carouselData('tours')) }
                    numVisible={ items() }
                    style={{ paddingLeft: carouselData('tours')?.length > items() ? '' : '10px', width: items() === 1 ? '100%' : smallCards(carouselData('tours')) }}
                    />
                  ) : (
                    <p className='pl-10 font-playfair'>No tour places available</p>
                  )
                ) : <SingleCarousalSkeleton />
              }
            </div>
          </> :
          <></>
      }
      {
        displayCarousal("city_blogs") ?
          <div className='mb-10'>
            {
              (carouselData('city_blogs')?.length > 0 || showSkeleton) ? (
                carouselData('city_blogs')?.length ? (
                  <div>
                  <div className='mt-10 mb-5 text-2xl pl-8 font-playfair font-normal'>Blogs</div>
                  <BlogsCarousel
                    blogsData={carouselData('city_blogs')}
                    cityNames={cityNames}
                    />
                </div>
                ) : (<div></div>)
              ) : <SingleCarousalSkeleton />
            }
          </div> : 
        <></>
      }
    </div>
  );
}
