import './AiInStay.scss';
import http from '../../common/http';

import { Toast } from 'primereact/toast';
import { grey } from '@mui/material/colors';
import { BtnTransparent } from '../UIComponents/buttons';
import React, { useState, useEffect, useRef } from 'react';
import { Box, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

const AIInStay = () => {
  const toast = useRef(null);

  const [preferences, setPreferences] = useState({});

  const labels = [{ key: 'post_social_media', value: 'Select best photo to post to social media' },
    { key: 'create_short_videos', value: 'Create short videos' },
    { key: 'post_real_time', value: 'Post in real time' },
    { key: 'post_at_dayend', value: 'Post at the end of the day' }];

  useEffect(() => {
    http
      .get('/preference.json')
      .then((res) => {
        let preference = {};
        for (const key in res.data.preference) {
          if (res.data.preference[key] === true) {
            preference[key] = true;
          }
        }
        setPreferences(preference);
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching message preferences.', 'bg-red-100 text-red-700');
      });
  }, []);

  const handleChecked = (value) => {
    setPreferences(prevPreferences => {
      if (value in prevPreferences) {
        const { [value]: _, ...updatedPreferences } = prevPreferences;
        return updatedPreferences;
      } else {
        return { ...prevPreferences, [value]: true };
      }
    });
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  return (
    <>
      <div className='flex justify-between'>
        <Toast ref={toast} position="bottom-right" />
        <div className='text-2xl font-normal font-playfair mb-8'>In-Stay </div>
        {!isMobile && <div className='w-[185px] h-[40px]'>
          <BtnTransparent
            props={{
              text: 'text-white font-semibold w-full h-full',
              bg: 'bg-periwinkle',
              border: 'border-periwinkle',
              buttonName: 'Save changes',
            }}
            disabled={false}
            handleClick={() => {
              http
                .post('/preference.json', preferences)
                .then(() => {
                  showMessage('success', 'Success', 'Message preferences have been saved successfully!', 'bg-teal-100 text-teal-500');
                })
                .catch(() => {
                  showMessage('error', 'Error', 'Sorry, there was an error while saving message preferences.', 'bg-red-100 text-red-700');
                });
            }}
          />
        </div>}
      </div>
      <div className='mt-12 md:mt-0'>
        <div className='text-sm mb-2'>
          During in-stay, allow WePlan to:
        </div>
        <div>
          <Box>
            <FormGroup>
              {labels.map((label, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={label?.key in preferences}
                      onChange={() => handleChecked(label?.key)}
                      sx={{
                        '&.Mui-checked': {
                        color: grey[900],
                        }
                      }}
                    />
                  }
                  label={label?.value}
                />
              ))}
            </FormGroup>
          </Box>
        </div>
      </div>
      {isMobile && <div className='flex w-full mt-4 h-[40px]'>
        <BtnTransparent
          props={{
            text: 'text-white font-semibold w-full h-full',
            bg: 'bg-periwinkle',
            border: 'border-periwinkle',
            buttonName: 'Save changes',
          }}
          disabled={false}
          handleClick={() => {
            http
              .post('/preference.json', preferences)
              .then(() => {
                showMessage('success', 'Success', 'Message preferences have been saved successfully!', 'bg-teal-100 text-teal-500');
              })
              .catch(() => {
                showMessage('error', 'Error', 'Sorry, there was an error while saving message preferences.', 'bg-red-100 text-red-700');
              });
          }}
        />
      </div>}
    </>
  );
};

export default AIInStay;
