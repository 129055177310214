import './Card.scss';

import http from '../../../common/http';

import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { Rating } from 'primereact/rating';
import { BtnTransparent } from '../buttons';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../../../common/auth';
import React, { useEffect, useState, useRef } from 'react';
import PlaceholderSkeleton from '../../Skeletons/PlaceholderSkeleton';

export default function TripCard({ booking, data, props, handleClick, interests, setInterests }) {
  const toast = useRef(null);

  const navigate = useNavigate();

  const [cityName, setCityName] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  // const [showTooltip, setShowTooltip] = useState(false);

  // const toggleTooltip = (e) => {
  //   e.stopPropagation();
  //   setShowTooltip(!showTooltip);
  // };

  useEffect(() => {
    if (props.cityName) setCityName(props.cityName);
  }, [props.cityName]);

  const cityDetails = () => {
    navigate(`/results?city_name=${props.cityName}&is_city_detail=true`);
  };

  // const handleImageLoad = () => {
  //   setIsImageLoaded(true);
  // };

  const header = (
    <div className='relative'>
      { props.imageUrl ? 
        <img
          src={ props.imageUrl }
          className={`p-4 w-full object-cover hover:bg-black hover:bg-opacity-50 h-[300px] hover:inset-0`}
          alt='Card'
        /> :
        <PlaceholderSkeleton applyClass = { 'bg-light-gray p-4 w-full object-cover hover:bg-black hover:bg-opacity-50 h-[300px] hover:inset-0' } />
      }
    </div>
  );

  const bookHotel = () => {
    navigate(`trip-plan/show-hotel/${data.id}`);
  };

  const isFavorite = (name) => interests.includes(name);

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  return (
    <div
      className={ 'card-container relative overflow-hidden shadow-md' }
      onMouseEnter={ () => setIsHovered(true) }
      onMouseLeave={ () => setIsHovered(false) }
    >
      <Toast ref={toast} position="bottom-right" />
      <Card header={ header }>
        { isHovered && !booking && <div className={ `bg-black bg-opacity-50 absolute inset-0 transition-opacity` } /> }
        <div className='p-3'>
          <div className='flex justify-between items-center min-h-[48px] text-sm font-semibold'>
            <div> { cityName }, { props.countryName }</div>
            <div>{props?.price_level !== null ? props?.price_level : '$$$$'}</div>
          </div>

          <Rating
            cancel={ false }
            value={ props.rating }
            className='text-yellow-500'
            // onChange={ (e) => setRating(e.value) }
          />
        </div>

        { isHovered && !booking && (
          <div className='flex-col justify-center items-center absolute inset-0'>
            { isLoggedIn() &&
              <svg
                width="28"
                height="25"
                fill="none"
                viewBox="0 0 28 25"
                onClick={() => {
                  http
                    .post('/interests', { city_name: cityName })
                    .then((res) => {
                      setInterests(res.data.interested_cities);
                      showMessage('success', 'Success', `${cityName} is ${res.data.removed ? 'removed' : 'saved'} as favorite!`, 'bg-teal-100 text-teal-500');
                    })
                    .catch(() => {
                      showMessage('error', 'Error', 'Encountered an error while saving favorite', 'bg-red-100 text-red-700');
                    });
                }}
                className="absolute mt-2 right-2"
              >
                <path
                  d="M5.662 16.0077L14.1538 23C17.9529 20.156 24.1292 15.3855 26.3017 10.7403C27.4739 8.23385 27.1679 5.45354 25.4018 3.73495C20.3626 -1.16877 15.8034 2.27552 14.1538 4.61063C12.8041 0.670051 6.05507 -0.643464 2.90556 3.73495C-0.296715 8.18671 1.42861 12.5219 5.662 16.0077Z"
                  stroke="white"
                  strokeWidth="2"
                  fill={isFavorite(cityName) ? "white" : "transparent"}
                  className="heart-path"
                />
              </svg>
            }
            <div className='flex flex-col items-center' style={{ marginTop: '150px' }}>
              <BtnTransparent props={{
                text: 'text-white max-w-[212px] w-full',
                bg: 'bg-periwinkle',
                border: 'border-periwinkle mb-2 ',
                buttonName: 'Generate itinerary' }}
                handleClick={ ()=> handleClick(cityName) }
              />

              <BtnTransparent props={{
                text: 'text-white max-w-[212px] w-full',
                bg: 'bg-tranparent ',
                border: 'border-white',
                buttonName: 'See details' }}
                handleClick={ cityDetails }

              />
            </div>
          </div>
        )}

        { isHovered && booking && (
          <div className='flex flex-col justify-center items-center absolute inset-0'>
            <BtnTransparent
              props={{
                text: 'text-white',
                bg: 'bg-tonys-pink',
                border: 'border-tonys-pink mb-2 ',
                buttonName: 'Book now',
              }}
              handleClick={bookHotel}
            />

            <BtnTransparent
              props={{
                text: 'text-white',
                bg: 'bg-transparent ',
                buttonName: 'Book later',
              }}
            />
          </div>
        )}
      </Card>
    </div>
  );
}
