import React, { useEffect, useState } from 'react';
import { BtnTransparent } from './UIComponents/buttons';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import FlightBookingIntakeModal from './FlightBookingIntakeModal';
import { setShowFlightInformation, setFlightsInformation } from '../redux/slices/flightInformation';

export default function CardHeader({ flight={} }) {
  const dispatch = useDispatch();
  const tripPlan = useSelector(state => state.tripPlan.tripPlan);
  const destination = tripPlan[0] ? tripPlan[0]['cityName'] : '';
  const [isDialogVisible, setDialogVisible] = useState(false);

  const openDialog = () => {
    setDialogVisible(true);
  };

  const closeDialog = () => {
    setDialogVisible(false);
  };

  const [travelTime, setTravelTime] = useState({});

  useEffect(() => {
    if (isEmpty(flight)) return;

    const segments = flight['selectedOffer']['itineraries'][0]['segments'];
    const startTime = new Date(segments[0]['departure']['at']).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    const endTime = new Date (segments[segments.length - 1]['arrival']['at']).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    setTravelTime({ start: startTime, end: endTime });
  }, [flight]);

  return (
    <div className='border mb-6'>
      <div className='bg-periwinkle text-base px-6 py-2'>
        { flight?.date }
      </div>
      <div className='px-6 py-4 flex items-center'>
        <div className='text-xl mr-4'>
          ✈️
        </div>

        { isEmpty(flight) ?
            <div>To {destination}</div>
          :
            <div className='text-sm'>
              <div className='mb-1'> { flight.from } - { flight.to }</div>
              <div className='mb-1'>{ travelTime.start } - { travelTime.end }</div>
              <div> { flight.hours } </div>
            </div>
        }

        <div className='ml-auto'>
          { !isEmpty(flight) ? (
            <p
              className='text-periwinkle underline-offset-4 cursor-pointer'
              onClick={() => {
                dispatch(setShowFlightInformation(true));
                dispatch(setFlightsInformation([flight]));
              }}
            >
              Full flight information
            </p>
          ) : (
            <BtnTransparent
              props={{
                text: 'text-periwinkle',
                bg: 'bg-transparent',
                border: 'border-periwinkle',
                buttonName: 'Book Ticket',
              }}
              handleClick={ openDialog }
            />
          )}
        </div>
      </div>
      <FlightBookingIntakeModal
        isVisible={ isDialogVisible }
        onClose={ closeDialog }
      />
    </div>
  );
}
