export const flightsSelectionData = [
  {
    id:100,
    flightName: "Flight 1",
    from: "SFO",
    to: "BCN",
    departureDate : "2024-03-15", 
    tripType : "Round Trip",
    departureTime : "10:48pm",
    arrivalTime : "9:10pm",
    flightTime : "21 hr 45 min",
    customData : "2 stops (MUC, ZAG)",
    airline : "",
    cost: 180,
    flightId : 10
  },
  {
    id:200,
    flightName: "Flight 2",
    from: "SFO",
    to: "BCN",
    departureDate : "2024-03-15", 
    tripType : "Round Trip",
    departureTime : "10:48pm",
    arrivalTime : "9:10pm",
    flightTime : "21 hr 45 min",
    customData : "2 stops (MUC, ZAG)",
    cost: 2,
    flightId : 10
  },
  {
    id:300,
    flightName: "Flight 3",
    from: "SFO",
    to: "BCN",
    departureDate : "2024-03-15", 
    tripType : "Round Trip",
    departureTime : "10:48pm",
    arrivalTime : "9:10pm",
    flightTime : "21 hr 45 min",
    customData : "2 stops (MUC, ZAG)",
    cost: 2,
    flightId : 10
  },
  {
    id:400,
    flightName: "Flight 4",
    from: "SFO",
    to: "BCN",
    departureDate : "2024-03-15", 
    tripType : "Round Trip",
    departureTime : "10:48pm",
    arrivalTime : "9:10pm",
    flightTime : "21 hr 45 min",
    customData : "2 stops (MUC, ZAG)",
    cost: 2,
    flightId : 10
  },
];
