import './SingleSelectDate.scss';

import { selectStyle } from '../Style';
import { Calendar } from 'primereact/calendar';
import React, { useEffect, useState, forwardRef } from 'react';

const SingleSelectTime = forwardRef(({ selectedDate, handleDateChange, minDate, maxDate }, ref) => {
  const [date, setDate] = useState('');

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = new Date(selectedDate);
      setDate(formattedDate);
    }
  }, [selectedDate]);

  const handleCalendarChange = (e) => {
    if (e.value instanceof Date) {
      const formattedDate = e.value.toLocaleString();
      handleDateChange(formattedDate);
    }
  };

  const dateTemplate = (date) => {
    if (!date.selectable) {
      return (
        <div className='disabled-date-style'>
          {date.day}
        </div>
      );
    }
    return date.day;
  };

  return (
    <div>
      <Calendar
        hourFormat="12"
        showTime
        ref={ref}
        value={date}
        // minDate={minDate}
        // maxDate={maxDate}
        className='w-full'
        iconPos='w-10 h-10'
        dateTemplate={dateTemplate}
        inputClassName={selectStyle}
        onChange={handleCalendarChange}
        panelClassName='bg-gray-100 p-2 text-xs'       
      />
    </div>
  );
});

SingleSelectTime.displayName = 'SingleSelectTime';

SingleSelectTime.defaultProps = {
  minDate: null,
  maxDate: null,
};

export default SingleSelectTime;
