import { grey } from '@mui/material/colors';
import React from 'react';
import { Box, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

const CreditCard = ({ className, formData, setFormData }) => {

  return (
    <div className='gap-y-14'>
      <div className={`border border-gray79 bg-white p-6 ${className}`}>
        <p className='text-2xl font-playfair font-normal'>
          Card information
        </p>

        <form>
          <div className='w-full my-4'>
            <label className='my-4 text-sm font-normal'>
              Name on card
            </label>
            <input
              type="text"
              key='nameOnCard'
              value={formData.name ? formData.name : ''}
              className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              onChange={(e) => setFormData('name', e.target.value)}
            />
          </div>
          <div className='flex w-full lg:flex-row flex-col justify-between mb-4'>
            <div className='lg:w-[50%] w-full'>
              <label className='my-4 text-sm font-normal'>
                Card number
              </label>
              <input
                type="text"
                key='cardNumber'
                value={formData.card_number ? formData.card_number : ''}
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                onChange={(e) => setFormData('card_number', e.target.value)}
              />
            </div>
            <div className='lg:w-[20%] w-full'>
              <label className='my-4 text-sm font-normal'>
                Expiration date
              </label>
              <select
                key='expirationMonth'
                value={formData.expiration_month ? formData.expiration_month : ''}
                onChange={(e) => setFormData('expiration_month', e.target.value)}
                className="form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2"
              >
                {!formData.expiration_month && <option value="" disabled>Month</option>}
                {Array.from({ length: 12 }, (_, index) => {
                  const value = (index + 1).toString().padStart(2, '0');
                  return <option key={index} value={value}>{value}</option>;
                })}
              </select>
            </div>
            <div className='lg:w-[20%] w-full'>
              <select
                key='expirationYear'
                value={formData.expiration_year ? formData.expiration_year : ''}
                onChange={(e) => setFormData('expiration_year', e.target.value)}
                className="form-control-wrapper mt-6 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2"
              >
                {!formData.expiration_year && <option value="" disabled>Year</option>}
                {Array.from({ length: 21 }, (_, index) => {
                  const currentYear = new Date().getFullYear();
                  const displayYear = currentYear + index;
                  return <option key={displayYear} value={displayYear}>{displayYear}</option>;
                })}
              </select>
            </div>
          </div>
          <div>
            <div className='lg:w-[20%] w-1/2'>
              <label className='my-4 text-sm font-normal'>
                Security code
              </label>
              <input
                type="text"
                key='securityCode'
                value={formData.encrypted_security_code ? formData.encrypted_security_code : ''}
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                onChange={(e) => setFormData('encrypted_security_code', e.target.value)}
              />
            </div>
          </div>
        </form>
      </div>

      <div className={`border border-gray79 bg-white mt-8 p-6 ${className}`}>
        <p className='text-2xl font-playfair font-normal mb-7'>
          Billing Information
        </p>
        <div className='w-full'>
          <label className='my-4 text-sm font-normal'>
            Street Address
          </label>
          <input
            type="text"
            key='address1'
            value={formData.address_line_1 ? formData.address_line_1 : ''}
            placeholder='Address line 1'
            className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
            onChange={(e) => setFormData('address_line_1', e.target.value)}
          />
          <input
            type="text"
            key='address2'
            value={formData.address_line_2 ? formData.address_line_2 : ''}
            placeholder='Address line 2'
            className={`form-control-wrapper mt-4 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
            onChange={(e) => setFormData('address_line_2', e.target.value)}
          />
        </div>
        <div className='flex justify-between lg:flex-row flex-col mt-4'>
          <div className='lg:w-[30%] w-full'>
            <label className='my-4 text-sm font-normal'>
              City
            </label>
            <input
              type="text"
              key='city'
              value={formData.city ? formData.city : ''}
              className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              onChange={(e) => setFormData('city', e.target.value)}
            />
          </div>
          <div className='lg:w-[30%] w-full'>
            <label className='my-4 text-sm font-normal'>
              State
            </label>
            <input
              type="text"
              key='state'
              value={formData.state ? formData.state : ''}
              className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              onChange={(e) => setFormData('state', e.target.value)}
            />
          </div>
          <div className='lg:w-[30%] w-full'>
            <label className='my-4 text-sm font-normal'>
              Postal code
            </label>
            <input
              type="text"
              key='postalCode'
              value={formData.postal_code ? formData.postal_code : ''}
              className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              onChange={(e) => setFormData('postal_code', e.target.value)}
            />
          </div>
        </div>

        {/* Commenting following code because it is in Figma design but functionality is not implmeneted
        <Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                  '&.Mui-checked': {
                    color: grey[900],
                  } }}
                />
              }
              label='Save your Credit card(s) for Quick and easy checkout next time you visit.'
            />
          </FormGroup>
        </Box> */}
      </div>
    </div>
  );
};

export default CreditCard;
