import React from 'react';
import Bars from 'Bars.svg';
import Resturants from 'Resturants.svg';
import Beaches from 'Beaches.svg';
import Hikes from 'Hikes.svg';
import NotInItinerary from 'NotInItinerary.svg';
import InItinerary from 'InItinerary.svg';
import Clubs from 'Clubs.svg';
import Sights from 'Sights.svg';
import { Checkbox } from '@mui/material';
import { grey } from '@mui/material/colors';
import FormControlLabel from '@mui/material/FormControlLabel';

const checkboxes = [
  { label: 'Bars', icon: Bars },
  { label: 'Clubs', icon: Clubs },
  { label: 'Sights', icon: Sights },
  { label: 'In Itinerary', icon: InItinerary },
  { label: 'Beaches', icon: Beaches },
  { label: 'Hikes', icon: Hikes },
  { label: 'Resturants', icon: Resturants },
  { label: 'Not In Itinerary', icon: NotInItinerary },
];

const MapCheckBoxes = () => {
  return (
    <div >
      <p className='font-sans mt-[36px] ml-[25px] font-normal text-base text-black'>Show me:</p>
      <div className='justify-between pl-6 grid sm:grid-cols-4 grid-cols-2 border-rose-500'>
        {checkboxes.map((checkbox, index) => ( 
          <div className='w-[200px]' key={index}>
            <FormControlLabel
              label={
                <div className='flex'>
                  <img src={checkbox.icon} alt={checkbox.label} className='w-[16px] h-[20px]' />
                  <span className='ml-[6px] text-base font-normal'>{checkbox.label}</span>
                </div>
              }
              control={
                <Checkbox
                  sx={{
                    color: grey[900],
                    '& .MuiSvgIcon-root': { fontSize: 24 },
                    '&.Mui-checked': { color: grey[900] },
                    '& .MuiCheckbox-root': {
                      borderRadius: '0',
                      borderWidth: '1px',
                    },
                  }}
                />
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MapCheckBoxes;