import React from 'react';
import MultiSelectDropdown from './UIComponents/InputFields/MultiselectDropdown';
import { BtnTransparent } from './UIComponents/buttons';
import Slider from '@mui/material/Slider';

const FilterFlightsForm = ({ props }) => {
  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  return (
    <div className = { `${ isMobile ? 'flex' : '' } lg:col-span-3 sm:col-span-12 space-y-2 shadow-md shadow-right` }>
    <div className={`border border-gray-300 bg-white ${ isMobile ? 'p-8' : 'p-3' }`}>
      { !isMobile && <div className='font-semibold text-xl my-3'>Filters</div>}
      { isMobile &&
        <div className='flex flex-between'>
          <div className='font-semibold text-lg my-3 font-monsterrat'>Filters</div>
          <div
            className='absolute right-6 top-0 mt-10 font-semibold text-xl my-3'
            onClick = { () => props.setOpenFilters(false) }
          >X</div>
        </div>
      }
      <div className='flex flex-col  flex-grow'>
        <div>
          <label className='text-sm font-normal text-light-black'>
            Stops
          </label>
          <MultiSelectDropdown
            subCategories
            items={props.stopsOptions}
            chipsColor='yellow-chips-background'
            selectedItems={props.selectedStops}
            setSaveData={props.handleStopsChange}
            dropdownWidth='sm:w-[300px]'
          />
        </div>
        <div className='flex flex-col '>
          <label className='text-sm font-normal text-light-black'>
            Airlines
          </label>
          <MultiSelectDropdown
            items={props.airlinesOptions}
            chipsColor='yellow-chips-background'
            selectedItems={props.selectedAirlines}
            setSaveData={props.handleAirlinesChange}
            dropdownWidth='sm:w-[300px]'
          />
        </div>
        <div className='mt-5 flex-1'>
          <label className='text-sm font-normal text-light-black'>
            Price Range
          </label>
          <div className='flex col justify-between items-center gap-x-4'>
            <div className='flex flex-col'>
              <label className='text-sm font-normal text-light-black'>
                Min
              </label>
              <input
                type="number"
                min={0}
                value={props.priceRange[0]}
                onInput={(e) => props.updatePriceRange(0, e.target.value)}
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              />
            </div>
            <h1 className='font-bold'> - </h1>
            <div className='flex flex-col'>
              <label className='text-sm font-normal text-light-black'>
                Max
              </label>
              <input
                type="number"
                value={props.priceRange[1]}
                min={props.priceRange[0]}
                onInput={(e) => props.updatePriceRange(1, e.target.value)}
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              />
            </div>
          </div>
        </div>
        <div className='my-5 flex-1'>
          <Slider
            max={1000}
            style={{ color: "#C09DDE", height: "10px" }}
            value={props.priceRange}
            onChange={(e) => props.setPriceRange(e.target.value)}
            valueLabelDisplay="auto"
          />
        </div>
        <div className='flex flex-col my-5'>
          <label className='text-sm font-normal text-light-black'>
            Duration
          </label>
          <label className='text-xs font-normal text-light-black'>
            Flight duration: Any
          </label>
          <Slider
            style={{ color: "#C09DDE", height: "10px" }}
            value={props.duration}
            onChange={(e) => props.setDuration(e.target.value)}
            valueLabelDisplay="auto"
          />
        </div>
        <BtnTransparent
          props={{
            text: 'text-white',
            bg: 'bg-periwinkle',
            border: 'border-periwinkle',
            buttonName: 'Apply filters'
          }}
          handleClick={props.applyFilters}
        />
      </div>
    </div>
  </div>
  );
};

export default FilterFlightsForm;
