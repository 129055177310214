import React from 'react';

const RadioInput = ({ flightObject, handleChange, labelName, attribute, radioInputCss }) => {
  
  return (
    <div
    className = 'flex flex-row gap-x-2 items-center'
    onClick={ () => handleChange(flightObject.flightName, attribute, labelName) }
  >
    <input
      type='radio'
      value = { flightObject[attribute] }
      checked = { flightObject[attribute] == labelName }
      className = { radioInputCss }
      onChange={() => {}}
    />
    <label className = 'text-sm font-normal'>
      { labelName }
    </label>
  </div>
  );
};

export default RadioInput;
