import React from 'react';
import LocalExpertProgram from '../components/LocalExpertProgram';
import LocalExpertProgramForm from '../components/LocalExpertProgramForm';

const LocalExpertProgramRoutes = [
  {
    path: "/localexpertprogram",
    element: <LocalExpertProgram />,
  },
  {
    path: "/localexpertprogram/form",
    element: <LocalExpertProgramForm/>
  },
];

export default LocalExpertProgramRoutes;
