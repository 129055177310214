import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recommendedHotel: {},
  recommendedHotelOffer: {},
  recommendedHotelCity: "",
};

export const recommendedHotelSlice = createSlice({
  name: 'recommendedHotel',
  initialState,
  reducers: {

    setRecommendedHotel: (state, action) => {
      state.recommendedHotel = action.payload;
    },
    setRecommendedHotelOffer: (state, action) => {
      state.recommendedHotelOffer = action.payload;
    },
    setRecommendedHotelCity: (state, action) => {
      state.recommendedHotelCity = action.payload;
    },
  }
});

export const { setRecommendedHotel, setRecommendedHotelOffer, setRecommendedHotelCity } = recommendedHotelSlice.actions;
export default recommendedHotelSlice.reducer;
