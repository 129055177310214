import React, { useEffect, useState } from 'react';
import SingleSelectDropdown from './UIComponents/Dropdowns/SingleSelectDropdown';
import { useSelector } from 'react-redux';

const MustDosForm = ({ handleAddMoreInput }) => {

  const [formData, setFormData] = useState({
    placesType: '',
    placesName: '',
    placesDescription: '',
  });

  const placeTypes = useSelector(state => state.priorities.priorityOptions);

  useEffect(() => {
    handleAddMoreInput(formData, "setMustDosFormData");
  }, [formData, handleAddMoreInput]);

  const handleChange = (fieldName, newValue) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [fieldName]: newValue,
      };
    });
  };
  return (
    <div>
      <div className='flex gap-10 '>
        <div className='mt-5 flex-1'>
          <label className='text-sm font-sans'>
            Type
          </label>
          <SingleSelectDropdown
            props={{
              options: placeTypes,
              dropDownwidth: 'w-full',
              value: formData.placesType,
            }}
            handleChanges={(val) => handleChange('placesType', val.value)}
          />
        </div>

        <div className='mt-6 flex-1'>
          <label className='text-sm font-sans'>
            Name
          </label>
          <input
            type="text"
            value={formData.placesName}
            onInput={(e) => handleChange('placesName', e.target.value)}
            className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
          />
        </div>
      </div>
      <div className='flex gap-10 '>
        <div className='mt-5 w-full'>
          <label className='text-sm font-sans'>
            Description
          </label>

          <input
            type="text"
            key='text'
            value={formData.placesDescription}
            onInput={(e) => handleChange('placesDescription', e.target.value)}
            className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
          />
        </div>
      </div>
    </div>
  );
};

export default MustDosForm;
