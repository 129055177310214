import { isLoggedIn } from "../auth";

const showLoginForm = (setOpen) => {
  setTimeout(() => {
    setOpen(true);
  }, 2000);
};

const showMessage = (toast, severity, summary, detail, style) => {
  toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
};

export const handleNavigate = (navigateToUrl, pageName, paramObject) => {
  if (isLoggedIn()) {
    navigateToLink(navigateToUrl, paramObject);
  } else {
    showMessage(paramObject.toast, 'warn', 'Warn', `Please login to use the ${ pageName }`, 'bg-amber-100 text-amber-500');
    showLoginForm(paramObject.setOpen);
  }
};

export const navigateToLink = (navigateToUrl, paramObject) => {

  if(paramObject.location.pathname.includes("trip-plan") && paramObject.formStateChanged && isLoggedIn()) {
    paramObject.setModalType("savePlan");
    paramObject.setVisible(true);
    paramObject.setToUrl(navigateToUrl);
  }
  else {
    if (paramObject.formStateChanged) {
      paramObject.setToUrl(navigateToUrl);
      paramObject.setModalType("change");
      paramObject.setVisible(true);
    } else {
      paramObject.navigate(`${navigateToUrl}`);
    }
  }
 
};
