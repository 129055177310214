import { Card } from 'primereact/card';
import React, { useEffect, useState, useRef } from 'react';
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { BtnTransparent } from "./UIComponents/buttons";
import { Dialog } from 'primereact/dialog';
import SingleSelectDate from './UIComponents/Dates/SingleSelectDate';
import { InputNumber } from 'primereact/inputnumber';
import { selectStyle } from './UIComponents/Style';
import { useNavigate } from 'react-router-dom';
import { snakeToTitelize, capitalizeFirstLetter } from '../utils/helpers/string.jsx';
import { setHotelBooking } from '../redux/slices/hotelBooking';
import '../common/common_styles.scss';
import { object, number, string } from 'yup';
import CancelationPolicySection from './CancelationPolicySection/CancelationPolicySection';
import { Toast } from 'primereact/toast';
import { useDispatch, useSelector } from 'react-redux';
import ActivityModal from './ActivityModal';
import getSymbolFromCurrency from 'currency-symbol-map';
import PlaceholderSkeleton from './Skeletons/PlaceholderSkeleton';
import { getIconToDisplay } from '../common/shared/displayIconsInItinerary';
import { isBar } from '../common/shared/displayIconsInItinerary';
import deleteIcon from 'deleteIcon.png';
import { autoOpenCalendarDate } from '../common/shared/calendarEventsFunctions.js';

export default function LocationDetail({ props, placeIndex, swap, removePlace, isEditItinerary, markerProps }) {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const bookingInfo = useSelector(state => state.hotelBooking.hotelBooking);
  const [showAdditionalDetail, setShowAdditionalDetail] = useState(false);
  const [checkIn, setCheckIn] = useState(null);
  const [checkOut, setCheckOut] = useState(null);
  const [travelers, setTravelers] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [bedType, setBedType] = useState('');
  const [roomType, setRoomType] = useState('');
  const [boardType, setBoardType] = useState('');
  const [isDialogVisible, setDialogVisible] = useState(false);
  const endDateRef = useRef(null);

  const openDialog = () => {
    setDialogVisible(true);
  };

  const closeDialog = () => {
    setDialogVisible(false);
  };
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [listening, setListening] = useState(false);
  const menuOptions = useRef(null);
  const handleCheckInDate = (value) => {
    autoOpenCalendarDate(checkOut, endDateRef);
    setCheckIn(value);
  };

  const handleCheckOutDate = (value) => {
    setCheckOut(value);
  };
  const [offerPolicy, setOfferPolicy] = useState(null);

  const [cancellationPolicies] = useState({
    guarantee: "The hotel will save credit card information during booking but not make any charges to the account. In the case of a no-show or out-of-policy cancellation, the hotel may charge penalties to the card.",
    deposit: "At the time of booking or by a given deadline, the hotel will charge the guest a percentage of the total amount of the reservation. The remaining amount is paid by the traveler directly at the hotel.",
    prepay: "The total amount of the reservation fee must be paid by the traveler when making the booking.",
  });

  const showHotelBooking = async () => {
    try {
      await reservationDetailSchema.validate({ checkIn, checkOut, travelers }, { abortEarly: false });

      dispatch(setHotelBooking({
        ...bookingInfo,
        'checkIn': checkIn,
        'checkOut': checkOut,
        'travelers': travelers,
        'cityInfo': { cityId: props.city_id, hotelName: props.name }
      }));
      setShowAdditionalDetail(false);
      let offerId = props?.offer?.id;
      navigate(`/hotel_booking?offer_id=${offerId}&hotel_id=${props.id}`);
    } catch(error) {
      if (error.inner) {
        const errorMessages = error.inner.map((e) => e.message);

        showMessage('error', 'Error', (
          <ul>
            {errorMessages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        ), 'bg-red-100 text-red-700');
      } else {
        showMessage('error', 'Error', `${error}`, 'bg-red-100 text-red-700');
      }
    }
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const reservationDetailSchema = object({
    checkIn: string().required('Check-in date is required.'),
    checkOut: string().required('Check-out date is required.'),
    travelers: number().required('Travelers count is required.'),
  });

  const cancellationPolicy = () => {
    const paymentType = props?.offer?.policies?.paymentType;
    if (paymentType) {
      setOfferPolicy({ type: paymentType, policy: cancellationPolicies[paymentType] });
    }
  };

  useEffect(() => {
    setCheckIn(props.checkIn);
    setCheckOut(props.checkOut);
    setTravelers(props.travelers);
    setHotelBooking((prevData) => {
      return {
        ...prevData,
        'checkIn': checkIn,
        'checkOut': checkOut,
        'travelers': travelers,
        'cityInfo': { cityId: props.city_id, hotelName: props.name }
      };
    });
  }, [props.checkIn, props.checkOut, props.travelers]);

  useEffect(() => {
    cancellationPolicy();
    addtionDetails();
  }, [props.offer]);

  useEffect(listenForOutsideClicks(
    listening,
    setListening,
    menuOptions,
    setShowMenu,
  ), []);

  function listenForOutsideClicks(listening, setListening, menuOptions, setShowMenu) {
    return () => {
      if (listening || !menuOptions.current) return;

      setListening(true);
      document.addEventListener('click', (evt) => {
        if (menuOptions?.current?.contains(evt.target)) return;

        setShowMenu(false);
      });
    };
  }

  const convertNewLinesToBreaks = (text) => {
    if (text) {
      return text.split('\n').map((line, index) => (
        index === text.split('\n').length - 1 ? (
          <React.Fragment key={index}>
            <span>&#8226; {line}</span>
          </React.Fragment>
        ) : (
          <React.Fragment key={index}>
            <span>&#8226; {line}</span><br />
          </React.Fragment>
        )
      ));
    } else {
      return '';
    }
  };

  const footer = (
    <div
      className='grid justify-center bg-periwinkle cursor-pointer h-12 text-white text-2xl content-center'
      onClick={() => showHotelBooking()}
    >
      Book
    </div>
  );

  const isRestaurant = () => {
    return props.place_type === 'Breakfast' || props.place_type === 'Dinner'; 
  };

  const imageSrc = () => {
    if (props.imageUrl) {
      return props.imageUrl;
    } else if (props.images?.length) {
      return props.images[0];
    }
  };

  const header = (
    <div
      className='relative'
      onMouseEnter={ () => setIsHovered(true) }
      onMouseLeave={ () => setIsHovered(false) }
    >
      {
        imageSrc() ?
          <img
            src={ imageSrc() }
            className='w-full h-64 object-cover'
            alt='Card'
          /> :
          <PlaceholderSkeleton applyClass={'w-full h-64 object-cover bg-light-gray'} />
      }
      {
        isHovered && !props.isHotelRoom && (
          <div className={ `flex flex-col justify-center items-center absolute inset-0 bg-black bg-opacity-50` } >
            <BtnTransparent props={{
              text: 'text-periwinkle max-w-[225px] w-full',
              bg: 'bg-transparent ',
              buttonName: 'See details' }}
              handleClick={ openDialog }
            />
          </div>
        )
      }
      <div className={ `bg-pinkish-gray absolute top-0 right-0 rounded-full m-3 font-monsterrat` }>
        { !props.isHotelRoom ? (
          <p className='text-sm px-3'> { props.place_type }</p>
        ) : ('')}
      </div>
    </div>
  );

  const placeInfo = {
    id: props.id,
    isRestaurant: props.is_restaurant,
    placeType: props.place_type,
  };

  const { currency, variations } = props?.offer?.price || {};
  const symbol = getSymbolFromCurrency(currency);
  const price = variations?.average?.base || variations?.average?.total;

  const cardName = () => {
    if (props.offer?.id) {
      const category = props.offer?.room?.typeEstimated?.category?.toLowerCase();
      const bedType = props.offer?.room?.typeEstimated?.bedType?.toLowerCase();
      if (category && bedType) {
        let name = `${bedType}, ${category}`;
        name = name.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
        return name;
      } else if (bedType) {
        return bedType.charAt(0).toUpperCase() + bedType.substring(1);
      }
      return props.name;
    }
    return props.name;
  };

  const addtionDetails = () => {
    if (props.offer?.id) {
      setBedType(props.offer?.room?.typeEstimated?.bedType?.toLowerCase());
      setRoomType(props.offer?.room?.typeEstimated?.category?.toLowerCase());
      setBoardType(props.offer.boardType);
    }
  };

  return (
    <div className='my-4'>
      <Toast ref={toast} position="bottom-right" />
      <div className='flex items-center gap-x-2'>
      <div className={ 'border border-gray79 w-full card-container relative shadow-md' }>
        <Card header={ header }>
          <div className='p-4'>
            <div className='flex justify-between'>
              { !props.isHotelRoom ? (
                <>
                  <div className='flex items-center justify-center'>
                    <img src = { getIconToDisplay(props.place_type) } alt="" className = {`${isBar(props.place_type) ? "h-12 mt-3" : "mx-3 h-6" }` } />
                    <div>
                      <div className='text-sm font-normal font-sans'> { props.time } </div>
                      <div className={`font-monsterrat text-sm`}>
                        <span className={`text-center font-semibold text-base`}>{ props.name}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    ref={menuOptions}
                    className={`relative p-3 ${ showMenu ? 'border border-dark-gray' : '' }`}
                  >
                  {
                    markerProps.viewType == "dayView" &&
                    <PiDotsThreeOutlineVerticalFill
                      className='cursor-pointer'
                      onClick={() => setShowMenu(!showMenu)}
                    />
                  }
                    {showMenu && (
                      <div
                        key={ placeIndex }
                        className='absolute block bg-white border border-dark-gray text-base mr-6 -right-[25px] top-[44px] py-2 pl-3 pr-8 gap-y-2'
                        style={{ 'width': 'max-content' }}
                      >
                        <div
                          className='mb-2 cursor-pointer'
                          onClick={() => swap(props, true)}
                        >
                          Edit time
                        </div>
                        <div
                          className='mb-2 cursor-pointer'
                          onClick={() => swap(props)}
                        >
                          Swap
                        </div>
                        {/* <div 
                          className='text-red-600 cursor-pointer'
                          onClick={() => removePlace(props, (placeIndex - 1))}
                        >
                          Delete
                        </div> */}
                      </div>
                    )}
                  </div>
                </>
              ) : ( 
                <div className='flex items-center justify-between w-full'>
                  <div className='text-sm'> { props.time } </div>
                  <div className={ `font-semibold ${props.prize === 'Sold out' ? 'text-red-500' : ''} ml-auto` }>
                    { props.prize }
                  </div>
                </div>
              )}
            </div>
            
            { !props.isHotelRoom ? (
              <>
                {/* <div className='flex items-center mx-8 mt-4 font-monsterrat'>
                  <span className='rounded-full px-4 py-1 bg-sky-200 text-sm'> { props.requirement } </span> 
                </div> */}
              </>
              ) : (
                <div className='flex w-full flex-col space-y-2'>
                  <div className='flex justify-between items-center'>
                    <p>
                      { cardName() }
                    </p>
                    <p className='font-semibold'>
                      {`${getSymbolFromCurrency(props?.offer?.price?.currency)}${price}/night`}
                    </p>
                  </div>
                  <div className='flex justify-between items-center'>
                    <p
                      className='text-periwinkle cursor-pointer'
                      onClick={() => setShowAdditionalDetail(true)}
                    >
                      <u>See details</u>
                    </p>
                    <BtnTransparent
                      props={{
                        text: 'text-white',
                        bg: 'bg-periwinkle' ,
                        buttonName: 'Book',
                        border: 'border-periwinkle'
                      }}
                      handleClick={() => {
                        setHotelBooking((prevData) => {
                          return {
                            ...prevData,
                            'offerId': props?.offer?.id,
                            'cityInfo': { cityId: props.city_id, hotelName: props.name }
                          };
                        });
                        showHotelBooking();
                      }}
                    />
                  </div>
                </div>
              )
            }
          </div>
        </Card>
        </div>
        {
          isEditItinerary ? 
            <div>
              <img
                className='cursor-pointer hover:scale-105 transition-all ease-in-out duration-300'
                src={ deleteIcon }
                onClick={() => removePlace(props, (placeIndex - 1))}
              />
            </div> :
            <></>
        }
      </div>
      <Dialog
        visible={showAdditionalDetail}
        style={{ backgroundColor: 'white', overflow: 'hidden' }}
        footer={footer}
        dismissableMask={true}
        draggable={false}
        responsive={true}
        className='lg:w-[813px] p-3'
        resizable={false}
        onHide={() => setShowAdditionalDetail(false)}
      >
        <div className='lg:p-6 p-3 flex flex-col flex-wrap w-full'>
          <div className='flex flex-col w-full justify-between'>
            <div className='flex flex-col flex-wrap'>
              <div className='text-2xl font-playfair'>
                { cardName() }
              </div>
              <div className='font-semibold'>
                {`${symbol} ${price}/night`}
              </div>
            </div>

            <div className='flex w-full gap-x-4 mt-6'>
              <div className='w-40'>
                <label className='text-sm font-sans text-light-black'>Check in</label>
                <small className='flex text-xs text-gray-500'>Enter hotel check in date</small>
                <SingleSelectDate
                  date={ checkIn }
                  minDate={ new Date() }
                  selectedDate={ checkIn }
                  handleDateChange={ handleCheckInDate }
                />
              </div>

              <div className='w-40'>
                <label className='text-sm font-sans text-light-black'>Check out</label>
                <small className='flex text-xs text-gray-500'>Enter hotel check out date</small>
                <SingleSelectDate
                  ref={ endDateRef }
                  date={ checkOut }
                  selectedDate={ checkOut }
                  minDate={ new Date() }
                  handleDateChange={ handleCheckOutDate }
                />
              </div>

              <div className='w-40'>
                <label className='text-sm font-sans text-light-black'>Travelers</label>
                <div className='mt-4'>
                  <InputNumber
                    value={ travelers }
                    inputClassName={ selectStyle }
                    onValueChange={ (e) => setTravelers(e.value) }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='my-8'>
            {
              props?.imageUrl ? 
                <img
                  src={ props?.imageUrl }
                  className='w-full h-64 object-cover'
                  alt='Card'
                /> :
                <PlaceholderSkeleton applyClass={'w-full h-64 object-cover bg-light-gray'} />
            }
          </div>

          <div>
            { roomType && <div>
                <p className='font-semibold font-playfair'>
                  Room type
                </p>
                <p className='pl-4 text-base'>
                  { snakeToTitelize(roomType) }
                </p>
              </div>
            }
            { bedType &&
              <div>
                <p className='font-semibold font-playfair'>
                  Bed type
                </p>
                <p className='pl-4 text-base'>
                  { bedType }
                </p>
              </div>
            }
            { boardType && <div>
                <p className='font-semibold font-playfair'>
                  Board Type
                </p>
                <p className='pl-4 text-base'>
                  { capitalizeFirstLetter(boardType) }
                </p>
              </div>
            }
            <div>
              <p className='font-semibold font-playfair'>
                Hightlights
              </p>
              <p className='pl-4 text-base'>
                { convertNewLinesToBreaks(props?.offer?.room?.description?.text) }
              </p>
            </div>
          </div>

          <div className='my-4'>
            { offerPolicy && <CancelationPolicySection content={offerPolicy}/> }
          </div>
          {/* TODO: Utilize following code when we will have hotels data in exact form */}
          {/* <div>
            <div>
              <p className='font-semibold'>
                Hightlights
              </p>
              <p>
                { props?.offer?.room?.description?.text }
              </p>
              <div className='flex justify-between px-8 py-4'>
                <div>
                  <ul>
                    <li>Sleep 2</li>
                    <li>65 inch TV</li>
                  </ul>
                </div>
                <div>
                  <ul>
                    <li>Sleep 2</li>
                    <li>65 inch TV</li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <p className='font-semibold'>
                Room amenities
              </p>
              <div className='flex justify-between px-8 py-4'>
                <div>
                  <ul>
                    <li>Sleep 2</li>
                    <li>65 inch TV</li>
                  </ul>
                </div>
                <div>
                  <ul>
                    <li>Sleep 2</li>
                    <li>65 inch TV</li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <p className='font-semibold'>
                Entertainment
              </p>
              <div className='flex justify-between px-8 py-4'>
                <div>
                  <ul>
                    <li>Sleep 2</li>
                    <li>65 inch TV</li>
                  </ul>
                </div>
                <div>
                  <ul>
                    <li>Sleep 2</li>
                    <li>65 inch TV</li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </Dialog>

      {isDialogVisible && (
        <ActivityModal
          props={ placeInfo }
          onClose={ closeDialog }
          isVisible={ isDialogVisible }
          isRestaurant={ isRestaurant() }
        />
      )}
    </div>
  );
}
