import React from 'react';
import Blogs from '../components/Blogs/Blogs';
import BlogShow from '../components/Blogs/BlogShow';

const BlogPageRoutes = [
  {
    path: "/blogs",
    element: <Blogs />
  },
  {
    path: "/blogs/show/:id",
    element: <BlogShow />
  }
];

export default BlogPageRoutes;
