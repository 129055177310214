import React, { useRef } from 'react';
import SingleSelectDate from '../Dates/SingleSelectDate';
import { autoOpenCalendarDate } from '../../../common/shared/calendarEventsFunctions';

export default function TravelingDates ({ onRespond, currentResponse }) {
  const endDateRef = useRef(null);
  const { start_date = '', end_date = '' } = currentResponse || {};

  const handleStartDateChange = (value) => {
    autoOpenCalendarDate(end_date, endDateRef);
    onRespond({ ...currentResponse, start_date: value });
  };

  const handleEndDateChange = (value) => {
    onRespond({ ...currentResponse, end_date: value });
  };

  return (
    <div className="my-custom-calendar-container">
      <p>
        Select your trip dates
      </p>
      <div className='mt-4 text-gray-600 text-sm'> Travel dates</div>
      <div className='flex items-center mb-20'>
        <div className='w-1/2'>
          <SingleSelectDate
            date={ start_date }
            maxDate={(end_date && new Date(end_date))}
            minDate={ new Date() }
            handleDateChange={ handleStartDateChange }
          />
        </div>
        <div className='text-center w-1/6'> - </div>
        <div className='w-1/2'>
          <SingleSelectDate
            ref={ endDateRef }
            date={ end_date }
            minDate={(start_date && new Date(start_date)) || new Date()}
            handleDateChange={ handleEndDateChange }
          />
        </div>
      </div>
    </div>
  );
}
