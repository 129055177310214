import React from 'react';
import PriceMatch from '../components/PriceMatch';

const PriceMatchRoutes = [
  {
    path: "/price-match",
    element: <PriceMatch />
  },
];

export default PriceMatchRoutes;
