import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MultiSelectDropdown from '../InputFields/MultiselectDropdown';

const Prioritize = ({ onRespond, currentResponse }) => {
  const [selectedOptions, setSelectedOptions] = useState(currentResponse);
  const priorities = useSelector(state => state.priorities.topPriorities);

  const handleDropdownChange = (val) => {
    setSelectedOptions(val);
    onRespond(val);
  };

  return (
    <div>
      <p>
        What sorts of activities would you like prioritized?
      </p>
      <div className='mb-20 mt-4'>
      <MultiSelectDropdown
        ranking
        allowDrag
        subCategories
        key='priority'
        className={ 'mb-3' }
        Label={ 'Priorities' }
        dropdownWidth='sm:w-[300px]'
        items={priorities}
        selectedItems={ selectedOptions }
        chipsColor='yellow-chips-background'
        setSaveData={ (val) => handleDropdownChange(val) }
        />
      </div>
    </div>
  );
};

export default Prioritize;
