import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  details: []
};

export const flightBookingIntakeSlice = createSlice({
  name: 'flightBookingIntake',
  initialState,
  reducers: {
    setFlightBookingIntake: (state, action) => {
      state.details = action.payload;
    },
    setFlightGeneralDetail: (state, action) => {
      state.commonDetail = action.payload;
    }
  }
});

export const { setFlightBookingIntake, setFlightGeneralDetail } = flightBookingIntakeSlice.actions;
export default flightBookingIntakeSlice.reducer;
