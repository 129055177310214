import React from 'react';

const Support = () => {
  return (
    <div>
      <div className='text-2xl font-normal font-playfair mb-4'>Support</div>
      <div className='text-sm font-normal mb-4'>Have questions?</div>
      <div className='text-sm font-normal'>
        Email us at <a href="mailto:support@weplan.com" className='text-periwinkle underline'>support@weplan.com</a> or text <a href="tel:+16502730693">+1 (650)-273-0693</a>
      </div>
    </div>
  );
};

export default Support;
