import React from 'react';
import { Box, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { grey } from '@mui/material/colors';

const TripInsuranceSection = ({ className }) => {
  return (
    <div className = { `border p-6 mb-6 bg-white ${ className }` }>
      <p className = 'text-2xl font-normal font-playfair'>
        Trip insurance
      </p>
      <p className = 'mt-4 text-base font-normal'>
        Cover your trip in case of cancellation, illness, etc. idk what else is included..do we even want to do this?
      </p>
      <Box>
        <FormGroup>
          <FormControlLabel
            control = {
              <Checkbox
                sx = { {
                '&.Mui-checked': {
                  color: grey[900],
                } } }
              />
            }
            label='Add flight protection for only $10'
          />
        </FormGroup>
      </Box>
    </div>
  );
};

export default TripInsuranceSection;
