import './MultiselectDropdown.scss';
import DragIcon from 'drag-icon.svg';
import DropdownIcon from 'dropdown-icon.svg';

import { Checkbox } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useState, useEffect, useRef } from 'react';

export default function MultiSelectDropdown({
  items,
  Label,
  survey,
  ranking,
  allowDrag,
  className,
  chipsColor,
  placeholder,
  setSaveData,
  subCategories,
  selectedItems,
}) {
  const dragItem = useRef();
  const dragOverItem = useRef();
  const dropdownOptions = useRef(null);

  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState(items);
  const [listening, setListening] = useState(false);
  const [subCategory, setSubCategory] = useState([]);
  const [showSelector, setShowSelector] = useState(false);
  const [saveDataFlag, setSaveDataFlag] = useState(false);
  const [dropdown, setDropdown] = useState(options[0]?.id);
  const [subdropdown, setSubdropdown] = useState(null);
  const [subSubCategory, setSubSubCategory] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  function listenForOutsideClicks(listening, setListening, dropdownOptions, setShowSelector) {
    return () => {
      if (listening || !dropdownOptions.current) return;

      setListening(true);
      document.addEventListener('click', (evt) => {
        if (dropdownOptions?.current?.contains(evt.target)) return;

        setShowSelector(false);
      });
    };
  }

  useEffect(listenForOutsideClicks(
    listening,
    setListening,
    dropdownOptions,
    setShowSelector,
  ), []);

  const goSearch = (value) => {
    setSearch(value);
    if (value) {
      const loweredCaseValue = value.toLowerCase();
      const searchedOptions = items.filter((i) => i.name.toLowerCase().includes(loweredCaseValue));
      setOptions(searchedOptions);
      setShowSelector(true);
    } else {
      setOptions(items);
      setShowSelector(false);
    }
  };

  const addSelectedOption = (value) => {
    setSearch("");
    const idx = selected.findIndex((opt) => opt.name === value.name);

    if (idx < 0) {
      setSelected([...selected, value]);
    } else {
      const updatedOptions = selected.filter((opt) => opt.name != value.name);
      setSelected(updatedOptions);
    }
  };

  const addSubCategoryOption = (value) => {
    const idx = subCategory.findIndex((opt) => opt === value);

    if (idx < 0) {
      setSubCategory([...subCategory, value]);
    } else {
        const updatedOptions = subCategory.filter((opt) => opt != value);
        setSubCategory(updatedOptions);
    }
  };

  const addSubCategory = (value) => {
    const idx = subCategory.findIndex((opt) => opt === value);
    if (idx < 0) {
      setSubCategory([...subCategory, value]);
    } 
  };

  const addSubSubCategoryOption = (id_input, option_input, suboption_input) => {
    const objectCreated = { id:id_input, option: option_input, suboption: suboption_input };
    const idx = subSubCategory.findIndex((opt) => opt.id === id_input && opt.option === option_input && opt.suboption === suboption_input);
    if (idx < 0) {
      setSubSubCategory([...subSubCategory, objectCreated]);
    } else {
      const updatedOptions = subSubCategory.filter((opt) => !(opt.id === id_input && opt.option === option_input && opt.suboption === suboption_input));
      setSubSubCategory(updatedOptions);
    }
  };

  useEffect(() => {
    if (saveDataFlag) {
      survey ? setSaveData(selected.map((s) => s)) : setSaveData(selected.map((s) => s.name));
    } else {
      setSaveDataFlag(true);
    }
  }, [selected]);

  useEffect(() => {
    const filteredOptions = items.filter(item => selectedItems.includes(item.name));
    setSelected(filteredOptions);
    setSaveDataFlag(false);
  }, [selectedItems]);

  useEffect(() => {
  }, []);

  useEffect(() => {
    setOptions(items);
    const filteredOptions = items.filter(item => selectedItems.includes(item.name));
    setSelected(filteredOptions);
    setSaveDataFlag(false);
  }, [items]);

  const isSelected = (name) => {
    const isSelected = selected.find((opt) => opt.name === name);
    return !!isSelected;
  };

  const isSelectedSubCategory = (name) => {
    const isSelected = subCategory.find((opt) => opt === name);
    return !!isSelected;
  };

  const isSelectedSubSubCategory = (id_input, option_input, suboption_input) => {
    const isSelected = subSubCategory.find((opt) => opt.id === id_input && opt.option === option_input && opt.suboption === suboption_input);
    return !!isSelected;
  };

  const removeSelectedOption = (option) => {
    const updatedSelected = selected.filter((opt) => opt.id != option.id);
    setSelected(updatedSelected);
  };

  const addParentOption = (value) => {
    const idx = selected.findIndex((opt) => opt.name === value.name);

    if (idx < 0) {
      setSelected([...selected, value]);
    }
  };

  const dragEnter = (e, position) => dragOverItem.current = position;

  const dragStart = (e, position) => dragItem.current = position;

  const drop = () => {
    const copyListItems = [...options];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setOptions(copyListItems);
  };

  const renderSelectedOptions = () => {
    const newItems = selected.map(item => ({ name: item.name, id: item.id }));
    const displayedOptions = newItems.slice(0, 2);
    const remainingOptions = newItems.slice(2);
    const tagStyle = 'custom-scrollbar max-h-32 overflow-y-auto absolute bg-black shadow-md z-50 before:content-[""] before:absolute before:top-[-2px] before:left-[40px] before:w-3 before:h-3 before:bg-black before:rotate-45';
    
    const toggleDropdownVisibility = () => {
      setIsDropdownVisible(prev => !prev);
    };
  
    return (
      <>
        {displayedOptions.map((opt, index) => (
          <div
            key={index}
            className={`mt-2 px-1 option-style ${chipsColor} flex items-center`}
          >
            <div className='p-2'> {opt.name} </div>
              <div
                key={index}
                className='px-1 select-none rounded cursor-pointer'
                onClick={() => removeSelectedOption(opt)}
              >
                <svg
                  width='8'
                  height='8'
                  viewBox='0 0 14 14'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M12.5745 1L1 12.5745'
                    stroke='#000000'
                    strokeWidth='2'
                    strokeLinecap='round'
                  />
                  <path
                    d='M1.00024 1L12.5747 12.5745'
                    stroke='#000000'
                    strokeWidth='2'
                    strokeLinecap='round'
                  />
                </svg>
              </div>
          </div>
        ))}
        {remainingOptions.length > 0 && (
          <div className='mt-2 absolute right-[15px]'>
            <div>
              <div 
                className='w-[72px] p-2 font-medium cursor-pointer'
                onMouseEnter={ ()=>toggleDropdownVisibility() }
                onMouseLeave={ ()=>toggleDropdownVisibility() }
              >
                {`+${remainingOptions.length} more`}
              </div>
              {isDropdownVisible && (
                <div className='mr-8'>
                  <ul
                    className= {tagStyle}
                    onMouseEnter={ ()=>toggleDropdownVisibility() }
                    onMouseLeave={ ()=>toggleDropdownVisibility() }
                  > 
                    {remainingOptions.map((opt, index) => (
                      <li 
                        key={index} 
                        className='flex justify-between bg-black text-white items-center px-3 hover:bg-gray-700 relative'
                      >
                        {opt.name}
                        <span 
                          className='ml-2 cursor-pointer text-white text-2xl right-2'
                          onClick={() => removeSelectedOption(opt)}
                        >
                          &times;
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          )
        }
      </>
    );
  };

  return (
    <div className={ `relative ${className} mb-2` } >
      <div>
        <label
          className={ `text-[14px] letter-spacing mb-2 block ${className}` }
          style={{ background: 'linear-gradient(360deg, #fff, #fff, #fff, transparent, transparent)' }}
        >
          {Label}
        </label>
      </div>

      <div ref={dropdownOptions} className='relative mx-auto text-xs'>
        <div className={ `pl-1 form-control-wrapper rounded-md flex gap-1 flex-wrap px-3` }>
          { renderSelectedOptions() }
          <div className='flex-1'>
            <div
              className='flex items-center'
              onClick={ () => { setShowSelector(!showSelector); }}
            >
              <div className='w-[95%]'>
                <input
                  type="text"
                  value={ search }
                  onChange={(e) => goSearch(e.target.value)}
                  className={ `bg-white w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2` }
                  placeholder={(!showSelector && placeholder.length > 0 && selected.length === 0) ? placeholder : ''}
                />
              </div>

              <div className='w-[16px] mx-2 cursor-pointer mt-2' >
                <img
                  src={DropdownIcon}
                  className={`ml-2 cursor-pointer hover:bg-red ${showSelector ? 'rotated' : ''}`}
                />
              </div>
            </div>

            { showSelector && (
              <div className='absolute left-0 z-30 w-full rounded-b-md font-medium overflow-y-scroll max-h-[275px]'>
                <div className='border-shadow rounded-md border-b border-t'>
                  { options.map((opt, index) => (
                    <div
                      key={ `options_${opt?.id}` }
                      className='flex w-full bg-light-gray flex-col'
                    >
                      <div
                        key={ `parent_options_${opt?.id}` }
                        onDragEnd={drop}
                        draggable={allowDrag}
                        onClick={() => addSelectedOption(opt)}
                        onDragStart={(e) => dragStart(e, index)}
                        onDragEnter={(e) => dragEnter(e, index)}
                        className={`flex bg-light-gray cursor-pointer hover:bg-dark-gray w-full items-center`}
                      >
                        <Checkbox
                          checked={ isSelected(opt?.name) }
                          sx={{
                            '&.Mui-checked': {
                              color: grey[900],
                            }
                          }}
                        />

                        <div key={ `sub_options_${opt.id}` }>
                          { <div className='py-4'>{opt?.name}</div> }
                        </div>

                        {
                          opt?.options?.length > 0 &&
                          <div
                            className='p-2'
                            onClick={(e) => {
                              e.stopPropagation();
                              setDropdown((val) => val === opt?.id ? null : opt?.id);
                            }}
                          >
                            <img
                                width='10px'
                                height='10px'
                                src={DropdownIcon}
                                className={`ml-2 cursor-pointer hover:bg-red ${opt?.id === dropdown ? 'rotated' : ''}`}
                            />
                          </div>
                        }

                        {selected.findIndex(item => item?.name === opt?.name) !== -1 && ranking && (
                          <div className='flex absolute px-2 py-1 text-white index-background min-w-[24px] rounded-full right-6 items-center justify-center'>
                            {selected.findIndex(item => item?.name === opt?.name) + 1}
                          </div>
                        )}

                        {allowDrag && <div
                          key={`drag_${opt?.id}`}
                          className="flex items-center justify-center"
                        >
                          <img
                            width='7px'
                            height='18px'
                            src={DragIcon}
                            className='flex absolute right-2 items-center justify-center'
                          />
                        </div>}
                      </div>

                      {subCategories && opt?.id === dropdown && opt?.options?.map(value => (
                        <div
                          key={ `sub_category ${opt?.id}` }
                          className='flex flex-col bg-light-gray cursor-pointer hover:bg-dark-gray w-full items-center'
                          onClick={(e) => {
                            e.stopPropagation();
                            addParentOption(opt);
                            addSubCategoryOption(value.name);
                          }}
                        >
                          <div className='flex w-full pl-8'>
                            <Checkbox
                              checked = { isSelectedSubCategory(value.name) }
                              sx={{
                                '&.Mui-checked': {
                                  color: grey[900],
                                }
                              }}
                            />

                            <div key={ `checkbox_${opt?.id}` }>
                              { <div className='py-4'>{ value.name }</div> }
                            </div>
                            {
                              value?.suboptions?.length > 0 &&
                              <div
                                className='p-2 flex items-center justify-center'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSubdropdown((val) => val === value.name ? null : value.name);
                                }}
                              >
                                <img
                                  width='10px'
                                  height='10px'
                                  src={ DropdownIcon }
                                  className={ `ml-2 cursor-pointer hover:bg-red ${ value.name === subdropdown ? 'rotated' : '' }` }
                                />
                              </div>
                            }
                          </div>

                          {
                            value.name === subdropdown ?

                              <div className='w-full'>

                                { value?.suboptions.map(subValue => (
                                  <div
                                    key={ `subdropdown_${opt?.id}` }
                                    className='flex bg-light-gray cursor-pointer hover:bg-dark-gray w-full pl-16'
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      addSubSubCategoryOption(opt.id, value.name, subValue);
                                      addParentOption(opt);
                                      addSubCategory(value.name);
                                    }}
                                  >
                                    <div className='flex justify-center'>
                                      <Checkbox
                                        checked = { isSelectedSubSubCategory(opt.id, value.name, subValue)}
                                        sx={{
                                          '&.Mui-checked': {
                                            color: grey[900],
                                          }
                                        }}
                                      />
                                      <div key={ `subdropdown_checkbox_${opt?.id}` }>
                                        { <div className='py-4'>{ subValue }</div> }
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div> : <></>
                          }
                        </div>
                      ))}
                    </div>
                  ))}
                  { options.length === 0 && <div className='text-gray-500'>No result</div> }
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

MultiSelectDropdown.defaultProps = {
  survey: false,
  ranking: false,
  placeholder: [],
  allowDrag: false,
  selectedItems: [],
  subCategories: false,
};
