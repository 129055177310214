import React, { useEffect, useState, useRef } from 'react';
import http from 'common/http';
import Layout from '../Layout/Layout';
import BlogsCarousel from './BlogsCarousel';
import { GoArrowRight } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import BlogsSkeleton from '../Skeletons/BlogsSkeleton';
import PlaceholderSkeleton from '../Skeletons/PlaceholderSkeleton';
import SingleCarousalSkeleton from '../Skeletons/SingleCarousalSkeleton';

export default function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [userDestBlogs, setUserDestBlogs] = useState([]);
  const [mostLikedBlog, setMostLikedBlog] = useState([]);
  const [discoverMoreBlogs, setDiscoverMoreBlogs] = useState([]);
  const [searchText, setSearchText] = useState('');
  const searchInput = useRef(null);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isSearchingBlog, setIsSearchingBlog] = useState(false);

  useEffect(() => {
    const fetchBlogs = () => {
      http
        .get('/all_blogs')
        .then((res) => {
          setBlogs(res.data.discover_blogs);
          setUserDestBlogs(res.data.user_dest_blogs);
          setDiscoverMoreBlogs(res.data.discover_blogs);
          setMostLikedBlog(res.data.most_liked_blog);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            navigate('/');
          } else {
            console.error('An error occurred:', err);
          }
        });
    };
    fetchBlogs();
  }, []);

  const showMostLikedBlog = (blogId) => {
    navigate(`/blogs/show/${blogId}`);
  };

  const handleSearch = () => {
    setIsSearchingBlog(true);
    setDiscoverMoreBlogs([]);
    const params = { search_text: searchText };
    http
      .get('/all_blogs', { params: params })
      .then((res) => {
        setIsSearchingBlog(false);
        setDiscoverMoreBlogs(res.data.discover_blogs);
      })
      .catch((err) => {
        setIsSearchingBlog(false);
        console.log(err);
      });
  };

  return (
    <div>
      <div>
        { 
          isLoading ? (
          <BlogsSkeleton />
        ) : (
          <div>
            <Layout>
            { blogs.length ? (
              <div className='flex w-full flex-col lg:px-14 px-3'>
                <div className='flex flex-col mt-32 lg:flex-row lg:justify-between lg:items-center lg:mt-32 lg:mb-10'>
                  <div className='flex flex-col lg:items-start items-center w-full lg:w-[40%] lg:px-5 lg:mb-0 mb-2'>
                    <p className='font-playfair text-2xl font-normal'>Featured blog</p>
                    <p className='flex font-playfair font-bold text-center lg:text-left justify-center lg:justify-start items-start lg:text-4xl text-3xl w-3/4 lg:w-full mt-2 sm:flex sm:text-xl sm:justify-center sm:items-center'>{mostLikedBlog[0].topic} - {mostLikedBlog[0].cityName}</p>
                    <p className='font-sans text-sm mt-2'>3 min read • posted {mostLikedBlog[0].createdSince} days ago</p>
                    <p className='font-sans text-sm mt-2 text-periwinkle cursor-pointer'
                      onClick={() => showMostLikedBlog(mostLikedBlog[0].blogId) }>Read now</p>
                  </div>
                  <div className='w-full lg:w-[35%] px-5'>
                    {
                        mostLikedBlog[0].imageUrl ?
                        <img
                          src={ mostLikedBlog[0].imageUrl }
                          alt='Image 1'
                        /> :
                        <PlaceholderSkeleton applyClass={'bg-light-gray lg:h-[386px] w-full h-[300px]'} />
                    }
                  </div>
                </div>
                <div className='h-px bg-gray-300 w-full mb-2 my-3'></div>
                <div className='flex w-full flex-col'>
                  {userDestBlogs.length > 0 && (
                    <div className='flex w-full flex-col'>
                      <div className='font-playfair mt-10 mb-5 text-2xl pl-8'>Blogs for your destination</div>
                        <BlogsCarousel 
                          blogsData={userDestBlogs} 
                        />
                      <div className='h-px bg-gray-300 w-full mb-2'></div>
                    </div>
                  )}
                  <div className='font-playfair flex w-full mt-10 mb-5 text-2xl pl-8 '>Blogs by destination</div>
                    <div className='flex w-full'>
                      <BlogsCarousel
                        blogsData={ blogs }
                      />
                    </div>
                  <div className='h-px bg-gray-300 w-full mb-2'></div>

                  <div className='flex w-full lg:justify-between justify-center lg:flex-row flex-col items-center lg:pr-5 pr-0 mt-10 mb-5'>
                    <div className='font-playfair flex text-2xl w-full px-8 lg:px-0 lg:pl-8 mb-4 lg:mb-0 lg:w-[30%]'>Discover more</div>
                    <div className='flex lg:pl-8 w-full px-8 lg:px-0 lg:w-[50%] lg:justify-end'>
                      <div className='flex flex-row justify-between items-center border-2 border-gray-300 bg-gray-50 text-gray-500 shadow rounded w-full'>
                        <input
                          type="text"
                          key='email'
                          placeholder='Enter a blog name, destination, etc.'
                          value={searchText}
                          ref={searchInput}
                          onInput={(e) => {
                            setSearchText(e.target.value);
                          }}
                          className={`appearance-none border-0 bg-gray-100 outline-none focus:border-0 focus:outline-none focus:ring-0 w-full`}
                        />
                        <span className='h-[43px] w-[70px]'>
                          <GoArrowRight onClick={() => handleSearch() } className='bg-periwinkle text-white w-full h-full rounded-md ml-1'/>
                        </span>
                      </div>
                    </div>
                  </div>
                  {
                    !isSearchingBlog ? (
                      discoverMoreBlogs.length ? (
                        <div className='flex w-full'>
                          <BlogsCarousel blogsData={discoverMoreBlogs} />
                        </div>
                      ) : (
                        <div className='flex w-full justify-center py-5'>
                          No blog found, search something else.
                        </div>
                      )
                    ) : (
                      <SingleCarousalSkeleton skeletonFor = { "blogs" } />
                    )
                  }
                </div>
              </div>) : <div className='flex justify-between items-center mt-32 mb-10'> No blogs available</div>}
            </Layout>
          </div>
        )}
      </div>
    </div>
  );
}
