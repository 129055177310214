import './SingleSelectDate.scss';
import { selectStyle } from '../Style';
import { Calendar } from 'primereact/calendar';
import React, { useEffect, useState, forwardRef } from 'react';

const SingleSelectDate = forwardRef(({ selectedDate, handleDateChange, minDate, maxDate }, ref) => {
  const [date, setDate] = useState('');
  const [manualDate, setManualDate] = useState('');

  useEffect(() => {
    if (selectedDate) {
      const formattedDate = new Date(selectedDate);
      setDate(formattedDate);
    }
  }, [selectedDate]);

  const handleCalendarChange = (e) => {
    if (e.value instanceof Date) {
      const formattedDate = e.value.toDateString();
      handleDateChange(formattedDate);
    }
  };

  const handleInput = (e) => {
    const { value } = e.target;
    const formattedDate = value.replace(/\D/g, '');
    setManualDate(formattedDate);
  };

  const dateTemplate = (date) => {
    if (!date.selectable) {
      return (
        <div className='disabled-date-style'>
          {date.day}
        </div>
      );
    }
    return (
      <div className='calendar-date-style'>
        {date.day}
      </div>
    );
  };

  const pressedKey = () => {
    if (manualDate.length === 8) {
      const month = manualDate.slice(0, 2);
      const day = manualDate.slice(2, 4);
      const year = manualDate.slice(4, 8);
      
      const parsedDate = new Date(`${month}/${day}/${year}`);
  
      if (!isNaN(parsedDate.getTime())) {
        handleDateChange(parsedDate.toDateString());
        setDate(parsedDate);
      }
    } else {
      const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

      const parsedDate = new Date(`${mm}/${dd}/${yyyy}`);
      handleDateChange(parsedDate.toDateString());
      setDate(parsedDate);
    }
  };

  return (
    <div>
      <Calendar
        className='w-full'
        iconPos='w-10 h-10'
        placeholder='MM/DD/YYYY'
        panelClassName='bg-gray-100 p-2 text-xs'
        ref={ ref }
        dateTemplate={ dateTemplate }
        inputClassName={ selectStyle }
        minDate={ minDate }
        maxDate={ maxDate }
        value={ date }
        onChange={ handleCalendarChange }
        onInput={ (e) => handleInput(e) }
        onKeyPress={ (e) => e.key == 'Enter' && pressedKey(e) }
      />
    </div>
  );
});

SingleSelectDate.displayName = 'SingleSelectDate';

SingleSelectDate.defaultProps = {
  minDate: null,
  maxDate: null,
};

export default SingleSelectDate;
