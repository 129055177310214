import React from 'react';
import { GrClose } from "react-icons/gr";
import SingleSelectTime from '../UIComponents/Dates/SingleSelectTime';
import SatisfactionEmojis from '../UIComponents/SatisfactionEmojis';
import { LuInfo } from "react-icons/lu";
import { autoOpenCalendarDate } from '../../common/shared/calendarEventsFunctions';

const FlightForm = ({ formData2, handleInput2, handleClose, isHotelBookedVisible, isFlightBookedVisible, props }) => {

  const onAirlineChange = () => {
    props.airlineRef.current.style.border = '';
  };
  const onFlightNumberChange = () => {
    props.flightNumberRef.current.style.border = '';
  };
  const onOutboundAirportChange = () => {
    props.outboundAirportRef.current.style.border = '';
  };
  const onInboundAirportChange = () => {
    props.inboundAirportRef.current.style.border = '';
  };
  const onArrivalTimeChange = () => {
    props.arrivalTimeRef.current.getElement().querySelector('input').style.border = '';
  };
  const onDepartureTimeChange = () => {
    props.departureTimeRef.current.getElement().querySelector('input').style.border = '';
  };
  const onPriceChange = () => {
    props.priceRefFlight.current.style.border = '';
  };

  
  return (
    <div className='pt-8 pr-8 pl-8'>
      <div className='flex justify-between'>
        <h2 className='text-left font-playfair text-2xl pb-4'>Enter your flight information</h2>
        { !(isFlightBookedVisible && isHotelBookedVisible) ? 
          <GrClose
            className='cursor-pointer'
            onClick={handleClose} 
          /> : <></>
        }
      </div>
      <div className='mt-4'>
        <div className='px-6'>
            <div className='flex flex-col lg:flex-row justify-between gap-x-8'>
              <div className='text-sm mt-4 w-full'>
                <label className='text-light-black'>
                  Airline
                </label>
                <input
                  ref = { props.airlineRef }
                  type="text"
                  key='lastName'
                  className='form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2'
                  value={formData2.airline}
                  onInput={ (e) => { handleInput2("airline", e.target.value), onAirlineChange(); } }
                />
              </div>
              <div className='text-sm mt-4 w-full'>
                <label className='text-light-black'>
                  Flight Number
                </label>
                <input
                  ref = { props.flightNumberRef }
                  type="text"
                  key='lastName'
                  value={ formData2.flight_number }
                  onInput={ (e) => { handleInput2("flight_number", e.target.value), onFlightNumberChange(); } }
                  className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                />
              </div>
            </div>

            <div className='flex flex-col lg:flex-row justify-between gap-x-8'>
              <div className='w-full'>
                <label className='text-sm text-light-black'>
                  Outbound airport
                </label>
                <input
                  ref = { props.outboundAirportRef }
                  type="text"
                  key='lastName'
                  className='form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2'
                  value={ formData2.outbound_airport }
                  onInput={ (e) => { handleInput2("outbound_airport", e.target.value), onOutboundAirportChange(); } }
                />
              </div>

              <div className='w-full'>
                <label className='text-sm text-light-black'>
                  Inbound airport
                </label>
                <input
                  ref = { props.inboundAirportRef }
                  type="text"
                  key='lastName'
                  className='form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2'
                  value={ formData2.inbound_airport }
                  onInput={ (e) => { handleInput2("inbound_airport", e.target.value), onInboundAirportChange(); } }
                />
              </div>
            </div>

            <div className='flex flex-col lg:flex-row justify-between gap-x-8 '>
              <div className='w-full '>
                <label className='text-sm text-light-black'>
                  Arrival Time
                </label>
                <SingleSelectTime
                  ref = { props.arrivalTimeRef }
                  date={ formData2.arrival_time && new Date(formData2.arrival_time) || new Date() }
                  minDate={ new Date() }
                  handleDateChange={ (val) => {
                    handleInput2('arrival_time', val),
                    onArrivalTimeChange();
                    autoOpenCalendarDate(formData2.departure_time, props.departureTimeRef);
                  }}
                />
              </div>

              <div className='w-full '>
                <label className='text-sm text-light-black'>
                  Departure Time
                </label>
                <SingleSelectTime
                  ref = { props.departureTimeRef }
                  date={ formData2.departure_time && new Date(formData2.departure_time) || new Date() }
                  minDate={ new Date() }
                  handleDateChange={ (val) => { handleInput2('departure_time', val), onDepartureTimeChange(); } }
                />
              </div>
            </div>
            <div className='flex flex-col lg:flex-row justify-between gap-x-8 '>
              <div className='w-full '>
                <label className='text-sm text-light-black'>
                  Price
                </label>
                <input
                ref = { props.priceRefFlight }
                type="number"
                key='price'
                value={formData2.price}
                onInput={ (e) => { handleInput2("price", e.target.value), onPriceChange(); } }
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              />
              </div>

              <div className='w-full'>
              <div>
                <div className='flex items-center gap-x-2'>
                  <label className='text-sm text-light-black flex gap-x-2'>
                    Price Satisfaction
                  </label>    
                  <div>
                    <LuInfo className='w-5 h-5' />
                  </div>
                </div>
              </div>
              <SatisfactionEmojis handleInput = { handleInput2 }/>
            </div>
            </div>
          </div>
      </div>
    </div>
  );
};
export default FlightForm;
