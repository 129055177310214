import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cityDetail: {},
};

export const cityDetailSlice = createSlice({
  name: "cityDetail",
  initialState,
  reducers: {    
    setCityDetail: (state, action) => {
      state.cityDetail = action.payload;
    },
  },
});

export const { setCityDetail } = cityDetailSlice.actions;

export default cityDetailSlice.reducer;
