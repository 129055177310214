import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import topCountriesSlice from "./slices/countries";
import placesName from './slices/placesName';
import locationsCoordination from './slices/locationsCoordination';
import prioritiesSlice from './slices/priorities';
import occasionsSlice from './slices/occassions';
import vibesSlice from './slices/vibes';
import thingsToAvoidSlice from './slices/thingsToAvoid';
import cityDetailSlice from "./slices/cityDetail";
import citiesSlice from "./slices/cities";
import hotelBookingSlice from './slices/hotelBooking';
import travelersSlice from './slices/travelers';
import itineraryPlanSlice from './slices/ItineraryPlanParams';
import tripPlanSlice from "./slices/tripPlan";
import hotelSwappingSlice from "./slices/hotelSwapping";
import headerSlice from "./slices/header";
import formChangedSlice from "./slices/formChanged";
import continentSlice from "./slices/continents";
import priceMatchSlice from './slices/priceMatch';
import saveButtonSlice from "./slices/saveButtonSlice";
import selectedFlightsReducer from "./slices/selectedFlightsSlice";
import recommendedHotelSlice from "./slices/recommendedHotel";
import recommendedFlightOfferSlice from "./slices/recommendedFlightOffer";
import flightBookingSlice from "./slices/flightBooking";
import flightBookingIntake from "./slices/flightBookingIntake";
import flightInformation from "./slices/flightInformation";
import whatToBrings from "./slices/whatToBrings";

export const store = configureStore({
  reducer: {
    selectedFlights: selectedFlightsReducer,
    formChanged: formChangedSlice,
    countries: topCountriesSlice,
    placesName: placesName,
    locationsCoordination: locationsCoordination,
    priorities: prioritiesSlice,
    occasions: occasionsSlice,
    vibes: vibesSlice,
    hotelBooking: hotelBookingSlice,
    thingsToAvoid: thingsToAvoidSlice,
    cityDetail: cityDetailSlice,
    cities: citiesSlice,
    travelers: travelersSlice,
    tripPlanParams: itineraryPlanSlice,
    tripPlan: tripPlanSlice,
    hotelSwapping: hotelSwappingSlice,
    header: headerSlice,
    continents: continentSlice,
    priceMatch: priceMatchSlice,
    saveButton: saveButtonSlice,
    recommendedHotel: recommendedHotelSlice,
    recommendedFlightOffer: recommendedFlightOfferSlice,
    flightBooking: flightBookingSlice,
    flightBookingIntake: flightBookingIntake,
    flightInformation: flightInformation,
    whatToBrings: whatToBrings
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
  devTools: process.env.NODE_ENV !== "production",
});
