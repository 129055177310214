import React, { useState, useRef, useEffect } from 'react';
import { Toast } from 'primereact/toast';
import http from '../../common/http';
import { getUserId } from '../../common/auth';
import { BtnTransparent } from '../UIComponents/buttons';
import { validateProfileUrls } from '../../common/shared/profileLinksFunctions';
import SocialMediaSkeleton from '../Skeletons/SocialMediaSkeleton';

const Platforms = () => {
  const toast = useRef('');
  const [socialMediaInfo, setSocialMediaInfo] = useState({ Instagram: '', Tiktok: '', Twitter: '', Pinterest: '' });
  const [enableButton, setButtonEnable] = useState(true);
  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  const [showSkeleton, setShowSkeleton] = useState(true);
  const inputRefs = useRef([]);

  const handleLinks = (platformName, link) => {
    setSocialMediaInfo((prevData) => ({
      ...prevData,
      [platformName]: link
    }));
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current?.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };


  const updateSocialMediaLinks = () => {
    if (!validateProfileUrls(socialMediaInfo, inputRefs)) {
      return showMessage('error', 'Error', `Please enter correct social media links.`, 'bg-red-100 text-red-700');
    }
    
    const params = { user_id:  getUserId(), social_media_info: socialMediaInfo };
    http
    .put(`/update_socail_media_info`, params )
    .then(() => {
      showMessage('success', 'Success', 'Social media links updated successfully.', 'bg-teal-100 text-teal-500');
    })
    .catch(() => {
      showMessage('error', 'Error', `Sorry, there was an error while updating your social media links.`, 'bg-red-100 text-red-700');
    });
  };

  const fetchSocailMediaInfo = () => {
    setShowSkeleton(true);
    const params = { user_id:  getUserId() };
    http
      .get('/user_socail_media_info', { params })
      .then((res) => {
        res.data.forEach(item => {
          switch (item.media_type) {
            case 'instagram':
              handleLinks('Instagram', item.profile_link);
              break;
            case 'tiktok':
             handleLinks('Tiktok', item.profile_link);
              break;
            case 'twitter':
              handleLinks('Twitter', item.profile_link);
              break;
            case 'pinterest':
              handleLinks('Pinterest', item.profile_link);
              break;
            default:
              break;
          }
        });
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching social media detail.', 'bg-red-100 text-red-700');
      }).finally(() => {
        setShowSkeleton(false);
      });
  };

  useEffect(() => {
    fetchSocailMediaInfo();
  }, []);

  return (
    <div>
      <Toast ref={toast} position='bottom-right' />
      <div className='flex justify-between'>
      <div className='text-2xl font-normal mb-4 font-playfair'>Social media accounts</div>
      {!isMobile && <div className='w-[185px] h-[40px]'>
          <BtnTransparent
            props={{
              text: 'text-white font-semibold w-full h-full',
              bg: 'bg-periwinkle',
              border: 'border-periwinkle',
              buttonName: 'Save changes',
            }}
            disabled = { enableButton }
            handleClick = { updateSocialMediaLinks }
          />
        </div>}
      </div>

      <div className='text-sm mb-8'>Paste your social media profile links to connect them to WePlan.</div>
      { !showSkeleton ? (
        Object.keys(socialMediaInfo).map((name, index) => (
          <div key={name}>
            <label className='text-sm'>
              { name }
            </label>
            <input
              ref={(el) => (inputRefs.current[index] = el)}
              type='text'
              key={name}
              placeholder={`www.${ name.toLowerCase() }.com/profile/handle`}
              value={ socialMediaInfo[name] }
              onInput={(e) => {
                inputRefs.current[index].style.border='';
                setSocialMediaInfo((prevData) => ({
                  ...prevData,
                  [name]: e.target.value
                }));
                setButtonEnable(false);
              }}
              className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
            />
          </div>
        ))
      ) : ( <SocialMediaSkeleton /> )
      }
      {isMobile && <div className='flex w-full mt-4 h-[40px]'>
        <BtnTransparent
          props={{
            text: 'text-white font-semibold w-full h-full',
            bg: 'bg-periwinkle',
            border: 'border-periwinkle',
            buttonName: 'Save changes',
          }}
          disabled = { enableButton }
          handleClick = { updateSocialMediaLinks }
        />
      </div>}
    </div>
  );
};

export default Platforms;
