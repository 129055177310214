export const validateProfileUrls = (socialMediaInfo, inputRefs) => {
  let isValid = true;
  Object.keys(socialMediaInfo).forEach((platform, index) => {
    const socialMediaLink = socialMediaInfo[platform];
    if (!isValidUrl(socialMediaLink) && socialMediaLink != "") {
      isValid = false;
      inputRefs.current[index].style.border = "2px solid red";
    }
  });
  if (!isValid) {
    return false;
  } else {
    return true;
  }
};

export const isValidUrl = (url) => {
  const urlPattern = /^(?:https?:\/\/)?(?:www\.)[a-zA-Z0-9-]+\.(?:com)\/.+/;
  if (url.match(urlPattern)) {
    return true;
  } else {
    return false;
  }
};
