import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { BtnTransparent } from '../UIComponents/buttons.jsx';
import { Box, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Toast } from 'primereact/toast';
import { flightsSelectionData } from '../customData';
import { useSelector } from 'react-redux';
import BookAllRecomendedFlight from './BookAllRecomendedFlight.jsx';
import BookAllRecomendedHotel from './BookAllRecomendedHotel.jsx';
import BookAllCheckoutModal from './BookAllCheckoutModal.jsx';
import { isLoggedIn } from '../../common/auth.js';

export default function BookAllOverviewModal({ isVisible, onClose }) {
  const toast = useRef(null);
  const [isFlightOffer, setIsFlightOffer] = useState(false);
  const [isHotelOffer, setIsHotelOffer] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [bookAllData, setBookAllData] = useState({ flightCheckBox: true, hotelCheckBox: true });
  const hotel = useSelector(state => state.recommendedHotel.recommendedHotel);
  const [isCheckoutVisible, setIsCheckoutVisible] = useState(false);
  const [grandTotal, setGrandTotal] = useState('');
  const openDialog = () => {
    if (isLoggedIn()) {
      if (bookAllData.flightCheckBox && !isFlightOffer) {
        return showMessage('error', 'Error', 'Select the flight details for booking.', 'bg-red-100 text-red-700');
      } else if (bookAllData.hotelCheckBox && !isHotelOffer) {
        return showMessage('error', 'Error', 'No recommended hotel offer available, try again later.', 'bg-red-100 text-red-700');
      }
      onClose();
      setIsCheckoutVisible(true);
    } else {
      showMessage('warn', 'Warning!', 'To proceed with bookings, please log in or register an account.', 'bg-yellow-100 text-yellow-500');
    }
  };
  const closeCheckoutDialog = () => {
    setIsCheckoutVisible(false);
  };
  useEffect(() => {
    let flag = false;
    if (
      (bookAllData.flightCheckBox && isFlightOffer) ||
      (bookAllData.hotelCheckBox && isHotelOffer)
    ) {
      flag = true;
    }

    setIsDisable(flag);
  }, [bookAllData.flightCheckBox, bookAllData.hotelCheckBox, isFlightOffer, isHotelOffer]);
  const handleCheckBoxChange = (checkboxName, isChecked) => {
    setBookAllData((prevData) => ({
      ...prevData,
      [checkboxName]: isChecked,
    }));
  };
  const setTotalCost = (amount) => {
    const sum = +grandTotal + +amount;
    setGrandTotal(sum);
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  return (
    <div className='flex w-full'>
      <Toast ref={ toast } position='bottom-right'/>
      <Dialog
        headerClassName="px-4 pt-4"
        visible={ isVisible }
        dismissableMask={ false }
        closable={ true }
        style={ { backgroundColor: 'white', overflow: 'hidden' } }
        className='lg:w-[800px] w-full'
        onHide={ () => onClose() }
      >
        <div className='mt-4'>
          <div className='text-sm mt-4 sm:w-[47%]'>
            <label className='text-2xl px-6 pt-8 font-playfair'>
              Overview of bookings
            </label>
            <p className='font-sans px-6'>Confirm your bookings before proceeding</p>
          </div>
          <div className='px-6'>
            <div className='mt-4 lg:w-[100%] sm:w-[47%]'>
              <div className='flex items-center'>
                <Box
                  width={30}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => handleCheckBoxChange('flightCheckBox', !bookAllData.flightCheckBox)}
                          checked={bookAllData.flightCheckBox}
                          sx={{
                            '&.Mui-checked': {
                              color: grey[900],
                            },
                          }}
                        />
                      }
                    />
                  </FormGroup>
                </Box>
                <label className='text-2xl font-playfair'>Flight</label>
              </div>
                <div>
                  <BookAllRecomendedFlight
                    setGrandTotal={ setTotalCost }
                    isFlightOffer={ (val) => setIsFlightOffer(val) }
                  />
                </div>
            </div>
            <div className='text-sm lg:w-[100%] mt-4 sm:w-[47%]'>
              <div className='flex items-center'>
                  <Box
                    width={30}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onClick={() => handleCheckBoxChange('hotelCheckBox', !bookAllData.hotelCheckBox)}
                            checked={bookAllData.hotelCheckBox}
                            sx={{
                              '&.Mui-checked': {
                                color: grey[900],
                              },
                            }}
                          />
                        }
                      />
                    </FormGroup>
                  </Box>
                  <label className='text-2xl font-playfair'>Accomodation</label>
              </div>
              { hotel ?
                <div>
                  <BookAllRecomendedHotel
                    hotelId={ hotel.id }
                    setGrandTotal={ setTotalCost }
                    isHotelOffer={ setIsHotelOffer }
                  />
                </div>
                : <div className='text-md text-center mt-5'>No hotel Details available</div>
              }
            </div>
          </div>
          <div className='flex items-cenetr justify-end gap-x-4 px-10 py-6'>
            <span>Grand total</span>
            <h1 className='text-lg font-bold text-end'>
              ${grandTotal}
            </h1>
          </div>
          <div className='flex w-full justify-center'>
            <div className='lg:w-[20%] sm:w-[47%]'>
              <BtnTransparent
                props={{
                  text: 'text-white font-semibold w-full mt-2 mb-4',
                  bg: 'bg-periwinkle',
                  border: 'border-periwinkle',
                  buttonName: 'Continue',
                }}
                disabled={ !isDisable }
                handleClick={ openDialog }
              />
            </div>
          </div>
        </div>
      </Dialog>
      <BookAllCheckoutModal
        isCheckoutVisible={ isCheckoutVisible }
        isHotelBooking={ bookAllData.hotelCheckBox }
        isFlightBooking={ bookAllData.flightCheckBox }
        onCheckoutClose={ closeCheckoutDialog }
      />
    </div>
  );
}
