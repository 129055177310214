import restaurantIcon from '../../../assets/images/rest2x.png';
import sightIcon from '../../../assets/images/sight2x.png';
import beachIcon from '../../../assets/images/beach2x.png';
import parkIcon from '../../../assets/images/park2x.png';
import clubIcon from '../../../assets/images/club2x.png';
import barIcon from '../../../assets/images/barLg.png';

export const getIconToDisplay = (place_type) => {
  switch(place_type) {
    case "Breakfast":
      return restaurantIcon;
    case "Brunch":
      return restaurantIcon;
    case "Dinner":
      return restaurantIcon;
    case "Shopping":
      return parkIcon;
    case "Sights & Landmarks":
      return sightIcon;
    case "Nightlife":
      return clubIcon;
    case "Bar":
      return barIcon;
    case "Nature & Parks":
      return parkIcon;
    case "Water & Amusement Parks":
      return parkIcon;
    case "Zoos & Aquariums":
      return sightIcon;
      
    default:
      return sightIcon;
  }
};

export const isBar = (place_type) => {
  switch(place_type) {
    case "Bar":
      return true;
    default:
      return false;
  }
};

export const mapMarkerSizeHeight = (place_type) => {
  switch(place_type) {
    case "Bar":
      return 50;
    default:
      return 20;
  }
};

export const mapMarkerSizeWidth = (place_type) => {
  switch(place_type) {
    case "Bar":
      return 58;
    default:
      return 28;
  }
};
