import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  topCountries: [],
};

export const topCountriesSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    reset: () => initialState,
    
    setTopCountries: (state, action) => {
      state.topCountries = action.payload;
    },
  },
});

export const { setTopCountries, reset } = topCountriesSlice.actions;

export default topCountriesSlice.reducer;
