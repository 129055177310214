import React from 'react';
import TripCardSkeleton from './TripCardSkeleton';

const InspirationCardsSkeleton = ({ isMobile }) => {
  const cardsPerRow = isMobile ? 1 : 3;
  const numberOfRows = isMobile ? 6 : 2;

  return (
    <>
      {[...Array(numberOfRows)].map((_, rowIndex) => (
        <div key={rowIndex} className="flex justify-center gap-y-2 gap-x-8 mb-6 w-full">
          {[...Array(cardsPerRow)].map((_, cardIndex) => (
            <TripCardSkeleton key={rowIndex * cardsPerRow + cardIndex} />
          ))}
        </div>
      ))}
    </>
  );
};

export default InspirationCardsSkeleton;
