import Layout from './Layout/Layout';
import CreditCard from './CreditCard/CreditCard';
import BookingCart from './BookingCart/BookingCart';
import TravelerInfoForm from './TravelerInfoForm/TravelerInfoForm';

import TripInsuranceSection from './TripInsuranceSection/TripInsuranceSection';
import { Toast } from 'primereact/toast';
import { object, string, array } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { BtnTransparent } from './UIComponents/buttons';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { emailRegex } from '../utils/helpers/string';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import SeatsForm from './TripInsuranceSection/SeatsForm';
import FlightBookingModal from './UIComponents/Modal/flightBookingModal';
import Steps from './Steps';
import { cloneDeep } from 'lodash';
import { setSelectedFlights } from '../redux/slices/selectedFlightsSlice';
import http from '../common/http';
import { Dialog } from 'primereact/dialog';
import { setFormStateChanged } from '../redux/slices/formChanged';
import { isEmpty } from 'lodash';
import loadingSpinner from 'loader.png';
import SelectedFlightsSection from './SelectedFlightsSection';
import { isLoggedIn } from '../common/auth';
import { getUserId } from '../common/auth';
import { setFlightPriceMatch } from '../redux/slices/priceMatch';

const TravelerInformation = () => {
  const userId = getUserId();
  const price_match_program_info = useSelector(state => state.priceMatch.flightForms);
  const whatToBrings = useSelector(state => state.whatToBrings.whatToBrings);
  const [pageNo, setPageNo] = useState(1);
  const toast = useRef(null);
  const navigate = useNavigate();
  const [modalType, setModalType] = useState('');
  const [isVisible, setVisible] = useState(false);
  const [travelersData, setTravelersData] = useState([{}]);
  const [disableBookingButton, setDisableBookingButton] = useState(true);
  const [confirmationIds, setConfirmationIds] = useState('');
  
  const bookingInfo = useSelector(state => state.hotelBooking.hotelBooking);
  const tripPlanParams = useSelector(state => state.tripPlanParams?.tripPlanParams);
  const tripPlan = useSelector(state => state.tripPlan.tripPlan);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const selectedFlights = useSelector(state => state.selectedFlights.selectedFlights);
  const itineraryPlan = useSelector(state => state.tripPlan.tripPlan);
  const flights = useSelector(state => state.flightBookingIntake.details);
  const travelerInfo = useSelector(state => state.flightBookingIntake.commonDetail);
  const [tripId, setTripId] = useState(null);

  const [cardData, setCardData] = useState({});

  useEffect(() => {
    if (userId) {
      // fetchCardInformation();
    }
  }, []);

  const fetchCardInformation = () => {
    http
      .get('/credit_card/card_info')
      .then((res) => {
        if (res.data) {
          setCardData(
            { 
              ...cardData,
             ...res.data.user_card
            });
        }
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching card.', 'bg-red-100 text-red-700');
      });
  };
  
  const handleCardDataChange = (labelName, value) => {
    setCardData(prevData => ({
      ...prevData,
      [labelName]: value
    }));
  };
  
  const navigateToTripPlan = () => {
    if (tripPlan.length && tripPlan[0]['id']) {
      navigate(`/trip-plan/${tripPlan[0]['id']}`);
    } else {
      navigate(`/trip-plan?${tripPlanParams}`);
    }
  };

  const buttons = {
    flight: [
      {
        text: 'Discard',
        onPress: () => {
          setVisible(false);
          navigate( navigateToTripPlan() );
        }
      },
      {
        text: 'Sign Up',
        onPress: () => {
          setVisible(false);
        }
      },
    ],
  };

  const renderTravelersForm = () => {
    let comps = [];
    for (let i = 0; i < travelerInfo?.travelers; i++) {
      comps.push(
        <TravelerInfoForm
          key={i}
          data={travelersData[i]}
          travelersNumber={ i+1 }
          extraFields={['travelerType']}
          onFormDataChange={(newData) => handleTravelerDataChange(i, newData)}
        />
      );
    }
    return comps;
  };
  const [inputList, setInputList] = useState(renderTravelersForm());

  const handleTravelerDataChange = (index, newData) => {
    setTravelersData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = newData;
      return updatedData;
    });
  };

  useEffect(() => {
    reuiredFieldsFilled();
  }, [travelersData, cardData]);

  useEffect(() => {
    if (isEmpty(itineraryPlan) || isEmpty(selectedFlights)) {
      navigate('/');
    }
  }, [itineraryPlan, selectedFlights]);

  const travelersInfoSchema = object({
    travelers_data: array().of(
      object().shape({
        firstName: string().required('First name is required.'),
        lastName: string().required('Last name is required.'),
        gender: string().required('Gender is required.'),
        dateOfBirth: string().required('Date of birth is required.'),
        email: string().required('Email is required.').test({
          name: 'email-validation',
          test: function (value) {
            if (!this.parent.email) return true;
            return emailRegex.test(value);
          },
          message: 'Invalid email.'
        }),
        travelerType: string().required('Traveler type is required'),
        phoneNumber: string().required('Phone number is required').test({
          name: 'phone-number validation',
          test: function (value) {
            return isPossiblePhoneNumber(value);
          },
          message: 'Please enter valid phone number.'
        })
      })
    ),
  });

  const reuiredFieldsFilled = () => {
    let hasEmptyField = false;

    for (const obj of travelersData) {
      if (!obj.firstName || !obj.lastName || !obj.gender ||
        !obj.dateOfBirth || !obj.email
      ) {
        hasEmptyField = true;
        break;
      }
    }
    if (!hasEmptyField) {
      if (!cardData.name || !cardData.card_number || !cardData.expiration_month ||
        !cardData.expiration_year || !cardData.encrypted_security_code || !cardData.address_line_1 ||
        !cardData.city || !cardData.state || !cardData.postal_code
      ) {
        hasEmptyField = true;
      }
    }

    setDisableBookingButton(hasEmptyField);
  };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const proceedBooking = (flightsData) => {
    const handleSuccess = (res) => {
      dispatch(setFlightPriceMatch({}));
      const bookingErrors = res.data.booking_errors;
      if (bookingErrors.length) {
        bookingErrors.forEach((error) => {
          showMessage('error', 'Error', `Sorry, there was an error while booking the flight of ${error.city_name}. ${error.message}`, 'bg-red-100 text-red-700');
        });
      }

      const flightBookingErrors = res.data.flight_errors;
      if (flightBookingErrors.length) {
        flightBookingErrors.forEach((err) => {
          let msg = `Sorry, there was an error while booking the flight(s) of ${err.city_name}. Please contact your administrator.`;
          showMessage('error', 'Error', msg, 'bg-red-100 text-red-700');
        });
      }
      if (res.data.flight_confirmation_ids?.length){
        setConfirmationIds(res.data.flight_confirmation_ids);
        setShowConfirmationModal(true);
      } else {
        setTimeout(() => {
          goToItineraryPage(res.data.trip.id);
        }, 5000);
      }

      dispatch(setFormStateChanged(false));
      showMessage('success', 'Success', `Trip Itinerary saved successfully.`, 'bg-teal-100 text-teal-500');
      setTripId(res.data.trip.id);
    };

    const handleError = () => {
      dispatch(setFlightPriceMatch({}));
      setIsLoading(false);
      let msg = `Sorry, there was an error while booking the flight. Please contact your administrator`;
      showMessage('error', 'Error', msg, 'bg-red-100 text-red-700');
    };

    const bringAttributes = whatToBrings.map(entry => ({
      ...entry,
      items_list: JSON.stringify(entry.items_list),
    }));
    const request = http.post('/trips', {
      itinerary_plan: itineraryPlan,
      flight_booking: JSON.stringify(flightsData),
      trip_bring_items: { what_to_brings_attributes: bringAttributes },
      flight_price_match: JSON.stringify(price_match_program_info),
    });

    request
      .then(handleSuccess)
      .catch(handleError);
  };

  const setTravelersInfo = async () => {
    try {
      const travelersInfo = { travelers_data: travelersData };
      await travelersInfoSchema.validate(travelersInfo, { abortEarly: false });
      let flights = cloneDeep(selectedFlights);
      proceedBooking({ flights: flights, travelers_info: travelersInfo });
    } catch (error) {
      setIsLoading(false);
      if (error.inner) {
        const errorMessages = error.inner.map((e) => e.message);

        showMessage('error', 'Error', (
          <ul>
            {errorMessages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        ), 'bg-red-100 text-red-700');
      } else {
        showMessage('error', 'Error', 'There is validation error, please try again.', 'bg-red-100 text-red-700');
      }
    }
  };


  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const steps = [
    'Enter traveler information',
    'Seats and trip protection',
    'Make a payment',
  ];

  const bookingConfirmed = () => {
    return (
      <div className='flex items-center gap-x-2'>
        <p className='place-content-center'>Your Flight Booking is Confirmed! 🎉</p>
      </div>
    );
  };

  const goToItineraryPage = (id = null) => {
    let planId = id || tripId;
    navigate(`/trip-plan/${planId}`, { state: { fetchItinerary: true } });
    setShowConfirmationModal(false);
  };

  return (
    <Layout className='flex w-full flex-col'>
      <Toast ref={toast} position="bottom-right" />
      <div className='flex w-full lg:mt-20 mt-20 lg:px-20 px-4'>
        <span
          className='font-bold text-md w-[60px] h-[30px] cursor-pointer py-2'
          onClick={() => navigate(-1) }
        >
          { '< Back' }
        </span>
      </div>
      <Dialog
        header={bookingConfirmed}
        headerClassName='text-2xl px-8 pt-8 mb-4'
        visible={showConfirmationModal}
        dismissableMask={false}
        className="sm:w-[677px] w-[400px]"
        style={{ width: '677px', backgroundColor: 'white' }}
        draggable={false}
        closable={false}
        blockScroll={true}
        responsive={true}
        resizable={false}
        onShow={() => {}}
        onHide={() => goToItineraryPage()}
      >
        <div className='px-8'>
          <div className='text-sm w-[400px] h-[200px] sm:w-[592px] sm:h-[85px]'>
            <div className='pb-4'>
              Exciting news! Your flight booking has been successfully confirmed, and we can't wait to help you take off on your upcoming adventure.
              <br />
              Booking Confirmation Number: { confirmationIds }
              <br />
              With this confirmation, you're all set to jet off to your destination hassle-free. Should you have any questions or need assistance, our friendly customer service team is just a message away.
            </div>
          </div>
          <div className='flex justify-end gap-x-4 mb-6 mt-8'>
            <BtnTransparent
              props={{
                text: 'text-white font-semibold sm:w-[177px] w-[140px]',
                bg: 'bg-periwinkle',
                border: 'border-periwinkle',
                buttonName: 'Close',
              }} 
              handleClick={() => goToItineraryPage()}
            />
          </div>
        </div>
      </Dialog>
      <div className='flex flex-col w-full lg:px-20 px-4'>
        <div className='mb-2 ml-4 mt-10 flex flex-row'>
          {/* <Steps steps = { steps } pageNo = { pageNo } setPageNo = { setPageNo } /> */}
        </div>
        <div className='flex w-full p-3 flex-col' >
          <div className='flex my-8 ml-10'>
            { pageNo == 1 ? <h1 className='text-3xl font-normal font-playfair'>Traveler Information</h1> : <></> }
            { pageNo == 2 ? <h1 className='text-3xl font-normal font-playfair'>Seats and trip protection</h1> : <></> }
            { pageNo == 3 ? <h1 className='text-3xl font-normal font-playfair'>Payment</h1> : <></> }
          </div>
          <div className='flex gap-x-4 justify-center lg:flex-row flex-col w-full '>
            <div className='flex flex-col lg:w-[70%] w-full'>
              {
                pageNo === 1 ? (
                  <>
                    { inputList }
                  </>
                ) : null
              }
              {
                pageNo == 2 ?
                  <>
                    <SeatsForm />
                    <TripInsuranceSection />
                  </> : null
              }

              {
                pageNo == 3 ?
                  <div className=''>
                    <CreditCard formData = { cardData } setFormData = { handleCardDataChange } />
                  </div> : <></>
              }
              {
                pageNo == 1 ?
                  <div className='flex w-full mb-14'>
                    <div className='flex w-full justify-center lg:justify-end float-right gap-x-3'>
                      {
                        isLoading
                        ? <div>
                            <img
                              src={ loadingSpinner }
                              alt="spinner"
                              className='w-[25px] h-[27px] animate-spin'
                            />
                          </div>
                        : <div></div>
                      }
                      <BtnTransparent
                        props={{
                          text: 'text-white',
                          bg: 'bg-periwinkle',
                          border: 'border-periwinkle',
                          buttonName: 'Submit',
                          radius: 'rounded', 
                        }}
                        disabled={isLoading}
                        handleClick={() => {
                          if (isLoggedIn()) {
                            setIsLoading(true);
                            setTravelersInfo();
                          } else {
                            showMessage('warn', 'Warning!', `To proceed with flight booking, please log in or register an account.`, 'bg-yellow-100 text-yellow-700');
                          }
                        }}
                      />
                    </div>
                  </div> : null
              }
              {
                pageNo == 2 ?
                  <div className='flex justify-end w-full mt-14 mb-14'>
                    <div className='flex gap-x-4'>
                    <BtnTransparent
                      props={{
                        text: 'text-black',
                        bg: 'bg-transparent',
                        border: 'border-black',
                        buttonName: 'Back',
                        radius: 'rounded'
                      }}
                      handleClick={() => {
                        setPageNo(1);
                      }}
                    />
                      <BtnTransparent
                        props={{
                          text: 'text-periwinkle',
                          bg: 'bg-transparent',
                          border: 'border-periwinkle',
                          buttonName: 'Book now, pay later',
                          radius: 'rounded'
                        }}
                        handleClick={() => { setModalType("flight"), setVisible(true);} }
                      />
                      <BtnTransparent
                        props={{
                          text: 'text-white',
                          bg: 'bg-periwinkle',
                          border: 'border-periwinkle',
                          buttonName: 'Continue to payment',
                          radius: 'rounded'
                        }}
                        handleClick={() => setPageNo(3)}
                      />
                    </div>
                  </div> : null
              }
              {
                pageNo == 3 ?
                  <div className='flex justify-end gap-x-4 w-full mb-14 mt-14'>
                    <BtnTransparent
                      props={{
                        text: 'text-black',
                        bg: 'bg-transparent',
                        border: 'border-black',
                        buttonName: 'Back',
                        radius: 'rounded'
                      }}
                      handleClick={() => {
                        setPageNo(2);
                      }}
                    />
                    <BtnTransparent
                      props={{
                        text: 'text-white',
                        bg: 'bg-periwinkle',
                        border: 'border-periwinkle',
                        buttonName: 'Confirm',
                        radius: 'rounded'
                      }}
                      handleClick={() => {
                        setModalType("flight");
                        setVisible(true);
                      }}
                    />
                  </div> : null
              }
            </div>
            <div className='lg:w-[25%] '>
              <SelectedFlightsSection />
            </div>
          </div>
        </div>
        { isVisible &&
          <FlightBookingModal
            type={modalType}
            isVisible={isVisible}
            setVisible={setVisible}
            buttons={buttons[modalType]}
          />
        }
      </div>
    </Layout>
  );
};

export default TravelerInformation;
