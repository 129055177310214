import React, { useState, useEffect, useRef } from 'react';
import { HiArrowLongRight } from "react-icons/hi2";
import { BsDot } from "react-icons/bs";
import { Accordion, AccordionTab } from 'primereact/accordion';
import RadioInput from './RadioInput';
import { BtnTransparent } from './UIComponents/buttons';
import TravelInfo from './TravelInfo';
import { setSelectedFlights } from '../redux/slices/selectedFlightsSlice';
import { useDispatch } from 'react-redux';
import { cloneDeep, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import FaresModal from './UIComponents/Modal/FaresModal';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import SingleSelectDropdown from './UIComponents/Dropdowns/SingleSelectDropdown';
import FileUpload from './UIComponents/FileHandling/FileUpload';
import CustomModal from './UIComponents/Modal/customModal';
import { Toast } from 'primereact/toast';
import { setFlightPriceMatch } from '../redux/slices/priceMatch';

const FlightDataSelection = ({ flightOffer, number, dictionaries, flight, updateFlight, deleteFlight, calledFrom = "", updateSelectedSeats, iataCodes, deleteSpecificFlightOffer }) => {
  const selectedFlights = useSelector(state => state.selectedFlights.selectedFlights)
  const toast = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const radioInputCss = 'appearance-none bg-gray-300 border-transparent w-4 h-4 rounded-full hover:bg-black checked:bg-black checked:focus:bg-black checked:focus:border-black checked:hover:bg-black checked:border-black focus:outline-none';

  const [travelTime, setTravelTime] = useState({});
  const [totalStops, setTotalStops] = useState(0);
  const [duration, setDuration] = useState('');
  const timeUnits = {
    days: 'd',
    hours: 'hr',
    minutes: 'min'
  };
  const [generalFormData, setGeneralFormData] = useState({});
  const formFunctions = {
    setGeneralFormData: setGeneralFormData,
  };
  const handleInput = (formDataFunction, fieldName, newValue) => {
    formFunctions[formDataFunction]((prevData) => {

      return {
        ...prevData,
        [fieldName]: newValue,
      };
    });
  };
  function parseDuration(durationString) {
    const regex = /PT(?:(\d+)D)?(?:(\d+)H)?(?:(\d+)M)?/; // Regex to match days, hours, and minutes
    const matches = durationString.match(regex);
    if (!matches) {
      return {}; // Invalid duration string
    }

    const days = matches[1] ? parseInt(matches[1]) : 0; // Extract days if present
    const hours = matches[2] ? parseInt(matches[2]) : 0; // Extract hours if present
    const minutes = matches[3] ? parseInt(matches[3]) : 0; // Extract minutes if present

    return {
      days: days,
      hours: hours,
      minutes: minutes
    };
  }
  const [totalAmount, setTotalAmount] = useState(0);
  const [isOneWay, setIsOneWay] = useState(true);
  const [amenities, setAmenities] = useState([]);
  const [offerAirlines, setOfferAirlines] = useState([]);
  useEffect(() => {
    const segments = flightOffer.itineraries[0].segments;
    const carriers = flightOffer.itineraries.map((itinerary) => {
      return itinerary.segments.map((seg) => seg.carrierCode);
    });
    setOfferAirlines([...new Set(...carriers)]);
    if (flightOffer.itineraries[0].duration) {
      let durationValues = parseDuration(flightOffer.itineraries[0].duration);
      let durationStr = '';
      Object.keys(durationValues).forEach((key) => {
        if (durationValues[key] > 0) {
          durationStr = `${durationStr} ${durationValues[key]} ${timeUnits[key]}`;
        }
      });
      setDuration(durationStr);
    }
    setTotalStops(segments.length);
    setTotalAmount(flightOffer.price.grandTotal || flightOffer.price.total);
    setIsOneWay(flightOffer.oneWay);

    const departureTime = new Date(segments[0].departure.at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    const departureDate = new Date(segments[0].departure.at).toLocaleDateString();
    const arrivalTime = new Date(segments[segments.length - 1].arrival.at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    setTravelTime({ departureTime: departureTime, arrivalTime: arrivalTime, departureDate });

    setAmenities(flightOffer.travelerPricings[0]['fareDetailsBySegment'][0]['amenities']);
  }, [flightOffer]);

  const addSelectedSeats = (travelerSeat) => {
    if (isEmpty(travelerSeat)) {
      return;
    }

    const offer = cloneDeep(flightOffer);
    const travelerPricings = offer.travelerPricings;
    let updatedTravelerPricings = [];

    const travelerSeatKeys = Object.keys(travelerSeat);
    updatedTravelerPricings = travelerPricings.map((travelerObj) => {
      if (travelerSeatKeys.includes(travelerObj.travelerId)) {
        travelerObj.fareDetailsBySegment = travelerObj.fareDetailsBySegment.map((seg) => {
          const idx = travelerSeat[travelerObj.travelerId].findIndex((info) => {
            return info.segmentId == seg.segmentId;
          });
          if (idx > -1) {
            const seat = travelerSeat[travelerObj.travelerId][idx].seatNumber;
            seg.additionalServices = { chargeableSeatNumber: seat };
          }
          return seg;
        });
        return travelerObj;
      } else {
        return travelerObj;
      }
    });
    offer.travelerPricings = updatedTravelerPricings;
    updateSelectedSeats(offer);
  };

  const addFlight = () => {
    let flights = cloneDeep(selectedFlights);
    let updatSelectedFlight = flights[number] || cloneDeep(flight);
    updatSelectedFlight.selectedOffer = cloneDeep(flightOffer);
    updatSelectedFlight.dictionaries = cloneDeep(dictionaries);
    flights[number] = updatSelectedFlight;

    dispatch(setSelectedFlights(flights));
  };
  const [showPriceMatchModal, setShowPriceMatchModal] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const buttons = {
    price: [
      {
        text: 'Discard',
        onPress: () => {
          setIsConfirmationVisible(false);
        }
      },
      {
        text: 'Confirm',
        onPress: () => {
          setIsConfirmationVisible(false);
        }

      },
    ],
  };
  const flightForms = useSelector(state => state.priceMatch.flightForms);
  const submitForm = () => {
    const updatedFormData = { ...generalFormData, flight_info: `${flight.from} - ${flight.to}`, old_price: totalAmount };
    dispatch(setFlightPriceMatch({ ...flightForms, [`${flight.from} - ${flight.to}`]: updatedFormData }));
    showMessage('success', 'Success', 'Price Match request is added.', 'bg-teal-100 text-teal-500');
    setShowPriceMatchModal(false);
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const checkCalledFromBoth = () => {
    return (["selectedflights","filterflights"].includes(calledFrom));
  };

  const checkCalledFromSelectedFlights = () => {
    return "selectedflights" == calledFrom ;
  };

  const checkCalledFromFilterFlights = () => {
    return "filterflights" == calledFrom ;
  };

  const checkCalledFromReviewFlightSelection = () => {
    return "reviewFlightSelection" == calledFrom;
  };

  const checkCalledFromFlightInformation = () => {
    return "flightInformation" == calledFrom;
  };


  return (
    <div className='flex flex-col gap-2 mt-6 mb-6'>
      <Toast ref={toast} position="bottom-right" />
      {
        checkCalledFromBoth() ?
          <></> :
          <div>
            <h1 className='text-2xl font-normal font-playfair'> Flight { number + 1 }</h1>
          </div>
      }
      {
        checkCalledFromFilterFlights() ?
          <></> :
          <div className='flex flex-row justify-between'>
            <div className = { `flex flex-wrap lg:flex-row items-center gap-x-2 ${ checkCalledFromSelectedFlights() ? "px-4" : "" }` }>
              <h1 className='text-lg font-semibold'>{ flight.from }</h1>
              <HiArrowLongRight className='text-periwinkle' />
              <h1 className='text-lg font-semibold'>{ flight.to }</h1>
              {
                checkCalledFromSelectedFlights() ?
                  <></> :
                  <>
                    <BsDot />
                    <h1 className='text-lg font-normal'>{ travelTime.departureDate }</h1>
                  </>
              }
            </div>
            {
              (checkCalledFromBoth() || checkCalledFromFlightInformation()) ?
                <></> :
                <div className='flex gap-x-2 lg:flex-row lg:gap-x-10'>
                  <h1
                    className='cursor-pointer font-semibold text-base'
                    onClick={() => setShowPriceMatchModal(true)}
                  >Price match request</h1>
                  <h1
                    className='cursor-pointer font-semibold text-base'
                    onClick={() => {
                      dispatch(setSelectedFlights([]));
                      navigate(-1);
                    }}
                  >Modify</h1>
                  <h1
                    className='cursor-pointer font-semibold text-base text-red-500'
                    onClick = { () => deleteSpecificFlightOffer(number)}
                  >
                    Delete
                  </h1>
                </div>
            }
          </div>
      }
      <div className = { `flex flex-col ${ checkCalledFromSelectedFlights() ? "px-4" : "border border-gray bg-silver shadow-md shadow-right mt-2 p-4" }` }>
        <div className = 'flex flex-row items-center justify-between'>
          <div className = 'flex flex-row items-center gap-x-2'>
            <h1 className = 'text-lg font-normal'>{ travelTime.departureTime }</h1>
            <HiArrowLongRight className='text-periwinkle' />
            <h1 className='text-lg font-normal'>{ travelTime.arrivalTime }</h1>
          </div>
          {
            checkCalledFromSelectedFlights() ?
              <></> :
              <div className='flex flex-col'>
                <h1 className='text-lg font-normal text-end'>${ totalAmount }</h1>
                {/* <span className='text-xs font-normal'>{ isOneWay ? 'One way' : 'Round trip' }</span> */}
              </div>
          }
        </div>
        <div className='flex flex-row justify-between'>
          <div className='flex flex-row items-center gap-x-2'>
            <h1 className='text-sm font-normal'>{ duration }</h1>
            <h1 className='text-sm font-normal'>● {totalStops} stops</h1>
            {/* <BsDot />
            <h1 className='text-sm font-normal'>{ flight.customData }</h1> */}
          </div>
        </div>
        {
          checkCalledFromSelectedFlights() ?
            <></> :
            <div className='flex flex-row justify-between'>
              <div className='flex flex-wrap flex-row gap-x-5 my-4'>
                {
                  dictionaries?.carriers && offerAirlines.map((airline, index) => {
                    return (
                      <RadioInput
                        key={index}
                        flightObject={ flightOffer }
                        handleChange={ updateFlight }
                        attribute={ "airline" }
                        labelName={ dictionaries?.carriers[airline] }
                        radioInputCss={ radioInputCss }
                      />
                    );
                  })
                }
              </div>
              {
                checkCalledFromFilterFlights() ?
                  <div className='mt-8 sm:mt-0 inline-grid gap-y-3'>
                    <BtnTransparent
                      props={{
                        text: 'text-black',
                        bg: 'bg-transparent',
                        border: 'border-black',
                        buttonName: 'Select'
                      }}
                      handleClick={() => addFlight()}
                    />
                    <BtnTransparent
                      props={{
                        text: 'text-black',
                        bg: 'bg-transparent',
                        border: 'border-black',
                        buttonName: 'Class'
                      }}
                      handleClick={() => setIsVisible(true)}
                    />
                  </div> :
                  <></>
              }
            </div>
        }
        {
          checkCalledFromSelectedFlights() || checkCalledFromReviewFlightSelection() || checkCalledFromFlightInformation() ?
            <></> :
            <div className='mt-4'>
              <Accordion>
                <AccordionTab headerClassName="text-sm font-normal" header="Flight details">
                  <div className='mt-2'>
                    <TravelInfo
                      amenities={amenities}
                      dictionaries={dictionaries}
                      flightOffer={flightOffer}
                      addSelectedSeats={addSelectedSeats}
                      flight={flight}
                      iataCodes={iataCodes}
                    />
                  </div>
                </AccordionTab>
              </Accordion>
            </div>
        }
      </div>
      {/* {
        checkCalledFromSelectedFlights() ?
          <div className='flex flex-row px-4'>
            <RadioInput
              flightObject={{}}
              handleChange={{}}
              attribute={"airline"}
              labelName={""}
              radioInputCss={radioInputCss}
            />
          </div> :
          <></>
      } */}
      {
        checkCalledFromSelectedFlights() ?
          <div className='w-full mx-auto border border-t-gray-100 mt-4'></div> :
          <></>
      }
      { isVisible &&
        <FaresModal
          flight={flight}
          flightOffer = { flightOffer }
          isVisible = { isVisible }
          setVisible = { setIsVisible }
        />
      }
      <Dialog
        header={'Price match request'}
        headerClassName="text-2xl px-6 pt-8"
        visible={showPriceMatchModal}
        dismissableMask={true}
        closable={true}
        style={{ backgroundColor: 'white', overflow: 'hidden' }}
        className='lg:w-[700px] w-full'
        draggable={false}
        blockScroll={true}
        responsive={true}
        resizable={false}
        onHide={() => setShowPriceMatchModal(false)}
      >
        <div className='mt-4'>
          <div className='px-6'>
            <div>
              <h1 className='text-lg text-light-black mt-5' >{`${flight.from} - ${flight.to}`}</h1>
            </div>
            <div className='flex flex wrap border border-gray-200 p-2 my-5'>
              <h1 className='text-md tracking-tight text-light-black'>
                <span className="font-bold">Disclaimer: </span>
                  Credits issued from price matching will be deposited into your account and must be utilized within 24 hours.
                  These credits can only be applied to the trip associated with the price match request.
                  Ensure your price match submission, including screenshots, is within 24 hours of the initial quote to allow for verification against historical flight prices.
                  Note that the price match discount will not exceed 40% of the total trip cost.
              </h1>
            </div>
            <div className='flex flex-col'>
              <div className='flex flex-col lg:grid grid-cols-4 mt-4'>
                <div className='lg:col-span-1 flex items-center'>
                  <label className='text-light-black  text-lg my-4 text-black'>
                    Flight Selected
                  </label>
                </div>
                <div className='w-full lg:col-span-3'>
                  <SingleSelectDropdown
                    props={{
                      value: `${flight.from} - ${flight.to}`,
                      options: [`${flight.from} - ${flight.to}`],
                      dropDownwidth: 'w-full',
                      labelStyle: 'text-[11px] letter-spacing block'
                    }}
                    handleChanges={ () => {}}
                  />
                </div>
              </div>

              <div className='flex flex-col lg:grid grid-cols-4 mt-4'>
                <div className='lg:col-span-1 flex items-center'>
                  <label className='flex flex-col text-light-black  text-lg my-4 text-black'>
                    Current Price
                  </label>
                </div>
                <div className='w-full lg:col-span-3'>
                  <h1 className='flex gap-2 text-lg my-4 text-black'>
                    { totalAmount } $
                  </h1>
                </div>
              </div>
              <div className='flex flex-col lg:grid lg:grid-cols-4 mt-4'>
                <div className='lg:col-span-1 flex items-center'>
                  <label className='flex flex-col text-light-black text-lg mb-4'>
                    New Price
                  </label>
                </div>
                <div className='w-full lg:col-span-3'>
                  <input
                    type="number"
                    value={ generalFormData.suggested_price }
                    onChange={ (e) => { handleInput("setGeneralFormData", "suggested_price", e.target.value); } }
                    className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                  />
                </div>
              </div>
              <div className='flex flex-col gap-2 mt-6'>
                <h1 className='text-md tracking-tight text-black'>Upload any photos or files showing the new price:</h1>
                <div>
                  <FileUpload
                    widthInput={"w-full"}
                    setUploadedFiles={ (files) => handleInput('setGeneralFormData', 'files', files) }
                  />
                </div>
              </div>
            </div>

            <BtnTransparent
              props={{
                text: 'text-white font-semibold w-full mt-6 mb-6',
                bg: 'bg-periwinkle',
                border: 'border-periwinkle',
                buttonName: 'Request price match',
              }}
              handleClick={ () => submitForm() }
            />

          </div>
        </div>
        {isConfirmationVisible &&
          <CustomModal
            type={'price'}
            isVisible={isConfirmationVisible}
            setVisible={setIsConfirmationVisible}
            buttons={buttons['price']}
          />
        }
      </Dialog>
    </div>
  );
};

export default FlightDataSelection;
