import React, { useState } from 'react';
import Layout from './Layout/Layout';
import instaybg1 from 'instaybackground1.png';
import { BtnTransparent } from './UIComponents/buttons';
import { isLoggedIn } from '../common/auth';
import SignupModal from './SignupModal';
import { triggerMixpanelEvent } from '../common/mixpanel';
import icon1 from 'instayicon1.png';
import icon2 from 'instayicon2.png';
import icon3 from 'instayicon3.png';
import icon4 from 'instayicon4.png';
import image1 from 'in_stay_img_1.png';
import image2 from 'in_stay_img_2.png';
import image3 from 'in_stay_img_3.png';
import image4 from 'in_stay_img_4.png';
import image5 from 'in_stay_img_5.png';
import image6 from 'in_stay_img_6.png';

import { Carousel } from 'primereact/carousel';

const InStayPage = () => {
  const backgroundImage = {
    backgroundImage: `url(${instaybg1})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    minHeight: '700px',
  };
  const [isDialogVisible, setDialogVisible] = useState(false);

  const openDialog = () => {
    setDialogVisible(true);
    triggerMixpanelEvent('Sign up for instay button');
  };

  const closeDialog = () => {
    setDialogVisible(false);
  };

  const itemTemplate = (value) => (
    <div className='flex w-full'>
      <img src={value.image} />
    </div>
  );

  return (
    <Layout>
      <div className='flex flex-col w-full'>
        <div className='flex flex-col items-center w-full lg:flex-row lg:p-10 p-3' style={backgroundImage}>
          <div id='left' className='lg:p-10 p-3 w-full lg:w-[800px] flex flex-col justify-center lg:m-12 mt-12'>
            <div>
              <h1 className="lg:text-4xl text-3xl font-normal font-playfair">Explore, Share, & Earn With In Stay</h1>
            </div>
            <div>
              <p className="mt-6 text-base leading-8 font-sans font-normal">Capture and share your real-time experiences, photos, and reviews to help fellow travelers with In Stay. Earn cash rewards as you explore, and use them towards your own adventures</p>
            </div>
            { !isLoggedIn() &&
              <div className='w-full lg:w-72'>
                <BtnTransparent
                  props={{
                    bg: 'bg-periwinkle',
                    border: 'border-periwinkle',
                    buttonName: 'Sign up for in stay',
                    text: 'text-white mt-4 w-full h-[40px] font-semibold',
                  }}
                  handleClick={ openDialog }
                />
              </div>
            }
          </div>
          <div className='w-full lg:w-[250px] lg:h-[100px] lg:m-12 m-3 pt-10 flex justify-center items-center'>
            <Carousel
              autoPlay={ true }
              numScroll={ 1 }
              numVisible={ 1 }
              showNavigators={ false }
              autoplayInterval={ 3000 }
              itemTemplate={ itemTemplate }
              value={ [{ image: image1 }, { image: image2 }, { image: image3 }, { image: image4 }, { image: image5 }, { image: image6 }] }
            />
          </div>
        </div>

        <div className='lg:m-20 lg:p-12 m-3 p-3'>
          <div>
            <h1 className="lg:text-4xl text-3xl font-normal font-playfair">Features</h1>
          </div>
          <div className='flex flex-wrap justify-between lg:mt-14 mt-6'>
            <div className='flex flex-col mx-2 w-72 lg:pb-0 pb-4'>
              <div className='flex justify-center items-center h-[80px] w-[80px] border border-amber-500 rounded-lg bg-gray-50'>
                <img src={icon1} alt="" />
              </div>
              <h1 className='mt-5 leading-8 font-sans text-base font-normal'>Constant updates between individual locations, multiple locations, or an entire day</h1>
            </div>
            <div className='flex flex-col mx-2 w-72 lg:pb-0 pb-4'>
              <div className='flex justify-center items-center h-[80px] w-[80px] border border-amber-500 rounded-lg bg-gray-50'>
                <img src={icon2} alt="" />
              </div>
              <h1 className='mt-5 leading-8 font-sans text-base font-normal'>See restaurant's top ordered items, menus,etc. </h1>
            </div>
            <div className='flex flex-col mx-2 w-72 lg:pb-0 pb-4'>
              <div className='flex justify-center items-center h-[80px] w-[80px] border border-amber-500 rounded-lg bg-gray-50'>
                <img src={icon3} alt="" />
              </div>
              <h1 className='mt-5 leading-8 font-sans text-base font-normal'>Sync with other travelers on the same days based on our recommendation system (COMING SOON)</h1>
            </div>
            <div className='flex flex-col mx-2 w-72 lg:pb-0 pb-4'>
              <div className='flex justify-center items-center h-[80px] w-[80px] border border-amber-500 rounded-lg bg-gray-50'>
                <img src={icon4} alt="" />
              </div>
              <h1 className='mt-5 leading-8 font-sans text-base font-normal'>When you visit an attraction, see top photos, historical facts and things to do nearby</h1>
            </div>
          </div>
        </div>

        <div className='lg:mt-5 lg:mx-10 mx-3 lg:pb-10 flex flex-col'>
          <div id='left' className='lg:p-10 flex flex-col justify-center ml-3 lg:ml-10 mt-3 lg:mt-10'>
            <div><h1 className="mb-[24px] lg:text-3xl text-2xl font-normal font-playfair">Earn $$$ with AI In-Stay</h1></div>
          </div>
          <div id='left' className='lg:pl-10 px-3 lg:pr-10 pb-10 flex flex-col w-full justify-center lg:ml-10'>
            <div><p className="leading-8 font-sans text-base font-normal">All you have to do is:</p></div>
            <div className="flex w-full lg:ml-10 ml-5 leading-8 font-sans text-base font-normal">
              <ul className="list-disc">
                <li>Provide us with ratings and reviews while you travel</li>
                <li>Tell us about your travel experience and send us pictures + pricing</li>
                <li>Upload photos of your trip to socials while you travel and tag WePlan</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <SignupModal
        isVisible={ isDialogVisible }
        onClose={ closeDialog }
        inStay={ true }
      />
    </Layout>
  );
};

export default InStayPage;
