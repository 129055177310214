import React from 'react';
import { Box, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Divider } from 'primereact/divider';
import { PiArrowsLeftRightLight } from "react-icons/pi";
import RadioInput from './RadioInput';
import './ReviewFlight.scss';

const FlightData = ({ flight, updateFlight = null, commonDetail={} }) => {
  const radioInputCss = 'appearance-none w-4 h-4 rounded-full hover:bg-black checked:bg-black checked:focus:bg-black checked:focus:border-black checked:hover:bg-black checked:border-black focus:outline-none';
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${ month }/${ day }/${ year }`;
  };

  return (
    <div className='flex flex-col gap-2 mt-4 mb-2'>
      <div className='flex flex-row justify-between'>
        <div className='flex items-center'>
          <h1 className='text-2xl font-normal font-playfair'>{ flight.flightName }</h1>
        </div>
        {/* <div>
          <Box>
            <FormGroup>
              <FormControlLabel control={<Checkbox onClick = { () => updateFlight(flight.flightName, "skipForNow", !flight.skipForNow) } checked = { flight.skipForNow } sx={{
                '&.Mui-checked': {
                  color: grey[900],
                },
              }} />} label='skip for now' />
            </FormGroup>
          </Box>
        </div> */}
      </div>

      <div className='flex flex-row gap-x-5 my-4'>
        {/* <RadioInput flightObject = { flight } handleChange = { updateFlight } attribute = { "tripType" } labelName = { "Round Trip" } radioInputCss = { radioInputCss } />
        <RadioInput flightObject = { flight } handleChange = { updateFlight } attribute = { "tripType" } labelName = { "One Way" } radioInputCss = { radioInputCss } /> */}
      </div>

      <div className="flex flex-col w-full gap-y-2 lg:flex-row lg:gap-x-2 cursor-pointer">
        <div className="inline-flex flex-1 flex-row border border-gray bg-silver shadow-md shadow-right w-full lg:w-[570px]">
          <div className='flex flex-col gap-2 w-full lg:w-1/2 p-4 '>
            <h1 className='text-light-black text-sm text-normal'>From</h1>
            <h1 className='text-base text-normal'>{ flight.from }</h1>
          </div>

          <Divider layout="vertical" className='mx-auto'>
            <div className='flex items-center justify-center w-6 h-6 rounded-full border border-periwinkle mt-6'>
              <PiArrowsLeftRightLight className='text-periwinkle' />
            </div>
          </Divider>

          <div className='flex flex-col gap-2 w-full lg:w-1/2 p-4 ml-2 '>
            <h1 className='text-light-black text-sm text-normal'>To</h1>
            <h1 className='text-base text-normal'>{ flight.to }</h1>
          </div>
        </div>

        <div className="inline-flex flex-1 flex-row border border-gray bg-silver shadow-md shadow-right w-full lg:w-[460px]">
          <div className='flex flex-col gap-2 p-4 w-full lg:w-1/2'>
            <h1 className={`${ !flight.departure ? 'text-gray-300' : 'text-light-black' } text-sm text-normal` }>Departure</h1>
            <h1 className={`${ !flight.departure ? 'text-gray-300' : '' } text-base text-normal` }>{ flight.departure ? formatDate(flight.departure) : '-' }</h1>
          </div>

          <Divider layout="vertical" className='mx-auto border border-gray' />

          <div className='flex flex-col gap-2 p-4 w-full lg:w-1/2 ml-2'>
            <h1 className = { `${ !flight.return ? 'text-gray-300' : 'text-light-black' } text-sm text-normal` }>Return</h1>
            <h1 className = { `${ !flight.return ? 'text-gray-300' : '' } text-base text-normal` }>{ flight.return ? formatDate(flight.return) : '-' }</h1>
          </div>


        </div>
        <div className="inline-flex flex-row border border-gray bg-silver shadow-md shadow-right lg:w-[202px] sm:w-1/2">
          <div className='flex flex-col gap-2 p-4'>
            <h1 className='text-light-black text-sm text-normal'>Travelers</h1>
            <h1 className='text-base text-normal'>{ commonDetail.travelers }</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightData;
