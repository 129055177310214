import React from 'react';
import Layout from './Layout/Layout';

 const PriceMatch = () => {

  return (
    <Layout>
      <div className="flex justify-center items-start w-full h-screen lg:mt-32 my-20">
        <div className="md:w-[95%] w-full bg-[#C09DDE40] p-6">
          <div className="text-center space-y-4">
            <h2 className="text-3xl font-playfair font-normal">Price match guarantee</h2>
            <div className='flex  md:w-2/5 lg:1/4 w-full mx-auto px-4 justify-center text-center'>
            <p className="px-2 font-sans text-base font-normal">
              Find a better price for an accommodation, flight or activity, complete the price match form and we will honor the price upon verification</p>
            </div>
            <div className="pt-4 font-sans">
              <div className={`flex flex-col justify-between py-2 px-4 mt-2 rounded text-sm md:w-2/5 lg:1/4 w-3/4 mx-auto bg-white border border-light-gray`}>
                <h2 className="text-3xl font-playfair mt-6 font-normal">How it works</h2>
                <div className='px-5'>
                <ol className="list-decimal list-outside space-y-1 font-sans text-left mb-4 mt-6 text-base font-normal">
                  <li>Generate an itinerary.</li>
                  <li>If you find an accommodation, flight or activity cheaper than WePlan, take screenshots.</li>
                  <li>Complete the form.</li>
                  <li>Wait for verification (could take anywhere between 1-5 business days).</li>
                  <li>WePlan will adjust the price.</li>
                </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PriceMatch;
