import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout/Layout';
import { Toast } from 'primereact/toast';
import { BtnTransparent } from './UIComponents/buttons';
import './ReviewFlight.scss';
import FlightData from './FlightData';
import http from '../common/http';
import loadingSpinner from 'loader.png';
import { useDispatch } from 'react-redux';
import { setFlightBooking } from '../redux/slices/flightBooking';
import { useSelector } from 'react-redux';

const ReviewFlight = () => {
  const flights = useSelector(state => state.flightBookingIntake.details);
  const commonDetail = useSelector(state => state.flightBookingIntake.commonDetail);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [flightsData, setFlightsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const showMessage = (toast, severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  useEffect(() => {
    if (!flights.length) {
      navigate('/');
    }
    setFlightsData(flights);
    dispatch(setFlightBooking(flights));
  }, []);

  const showFlightOffers = () => {
    navigate(`/filterflights`);
  };

  const updateFlight = (flightName, attribute, value) => {
    const index = flights.findIndex(flight => flight.flightName === flightName);
    const updatedFlightsData = [...flightsData];
    updatedFlightsData[index] = {
      ...updatedFlightsData[index],
      [attribute]: value
    };
    setFlightsData(updatedFlightsData);
  };

  return (
    <Layout className='flex w-full flex-col'>
      <Toast ref={toast} position="bottom-right" />
      <div className='flex w-full lg:mt-20 mt-20 lg:px-20 px-4'>
        <span
          className='font-bold text-md w-[60px] h-[30px] cursor-pointer py-2'
          onClick={() => navigate(-1) }
        >
          { '< Back' }
        </span>
      </div>
      <div className='flex flex-col w-full lg:mt-14 mt-14 lg:px-20 px-4 min-h-screen'>
        <div>
          <h1 className='text-3xl font-normal font-playfair'>Review flight details</h1>
        </div>

        { flightsData.length ?
          flightsData.map((flight, index) => (
            <div key = { index }>
              <FlightData
                flight={flight}
                updateFlight={updateFlight}
                commonDetail={commonDetail}
              />
            </div>
          ))
          :
          (
            isLoading ? (
              <img
                src={ loadingSpinner }
                alt="spinner"
                className='w-[21px] h-[23px] animate-spin'
              />
            ) : <div className='text-md text-center mt-5'>No flights available</div>
          )
        }

        <div className='flex justify-end mb-6 mt-16'>
          <BtnTransparent
            props={{
              bg: 'bg-periwinkle',
              border: 'border-periwinkle w-56',
              text: 'text-white',
              buttonName: 'Continue'
            }}
            handleClick={() => showFlightOffers()}
          />
        </div>
      </div>
    </Layout>
  );
};

export default ReviewFlight;
