import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FlightDataSelection from '../FlightDataSelection';
import Layout from '../Layout/Layout';
import { reset } from '../../redux/slices/flightInformation';

const FlightsInformation = () => {
  const dispatch = useDispatch();
  const flightsInformation = useSelector(state => state.flightInformation.flightsInformation);
  const [flights, setFlights] = useState([]);
  useEffect(() => {
    setFlights(flightsInformation);
  }, [flightsInformation]);

  return (
    <Layout>
      <div className='flex w-full lg:mt-20 mt-20 lg:px-20 px-4'>
        <span
          className='font-bold text-md w-[60px] h-[30px] cursor-pointer py-2'
          onClick={() => dispatch(reset()) }
        >
          { '< Back' }
        </span>
      </div>
      <div className='lg:mt-20 mt-20 lg:px-20 px-4 min-h-screen'>
        <div className='text-3xl font-normal font-playfair'>
          Flight information
        </div>
        {
          flights.map((flight, index) => (
            <div key={index}>
              <FlightDataSelection
                flightOffer={flight.selectedOffer}
                dictionaries={flight.dictionaries}
                flight={flight}
                number={index}
                calledFrom='flightInformation'
              />
            </div>
          ))
        }
      </div>
    </Layout>
  );
};

export default FlightsInformation;
