import React from 'react';
import { useState, useRef } from 'react';
import http from '../../common/http.js';
import './LoginForm.scss';
import { getUserSession } from './../../common/auth';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { BtnTransparent } from './../UIComponents/buttons.jsx';
import '../../common/common_styles.scss';
import { Toast } from 'primereact/toast';
import { emailRegex, passwordRegex, usernameRegex } from '../../utils/helpers/string.jsx';
import { FaApple } from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa6";

const LoginForm = ({ isOpen, onClose, openSignupForm, redirectRoute = '' }) => {
  if (!isOpen) return null;

  const redBox="2px solid red";
  const navigate = useNavigate();
  const toast = useRef(null);
  const emailInput = useRef(null);
  const passwordInput = useRef(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const footer = () => {
    return (
      <div className='relative flex justify-evenly items-center footer h-24'>
        <div className='mx-4'>
          Don&apos;t have an account?
        </div>
        <div className='mx-4'>
          <BtnTransparent
            props={{
              text: 'text-white font-semibold',
              bg: 'bg-[#484c4c]',
              border: 'border-[#484c4c]',
              buttonName: 'Create an account',
            }}
            handleClick={openSignupForm}
          />
        </div>
      </div>
    );
  };

  const passwordInputChange=()=>{
    passwordInput.current.style.border='';
  };

  const emailInputChange=()=>{
    emailInput.current.style.border='';
  };

  const validateForm = () => {
    if (!email || !password) {

      if (!email) {
        emailInput.current.style.border=redBox;
      }

      if (!password) {
        passwordInput.current.style.border=redBox;
      }

      showMessage('error', 'Error', 'Please fill in all the required fields.', 'bg-red-100 text-red-700');
      return false;
    }

    if (!emailRegex.test(email) && !usernameRegex.test(email)) {
      showMessage('error', 'Error', 'Please enter a valid username or email address.', 'bg-red-100 text-red-700');
      return false;
    }

    if (!passwordRegex.test(password)) {
      showMessage('error', 'Error', 'Password should be atleast 6 digits long.', 'bg-red-100 text-red-700');
      return false;
    }

    return true;
  };

  const submit = () => {
    if (validateForm()) {
      const params = { user: {
        email: email,
        remember_me: true,
        password: password
      } };

      http
        .post('/users/sign_in.json', params)
        .then((res) => {
          if (res.data.id) {
            localStorage.setItem('userId', res.data.id);
            const fullName = `${res.data.first_name} ${res.data.first_name}`;
            localStorage.setItem('fullName', fullName);
            localStorage.setItem('userName', res.data.username);
            if (redirectRoute) {
              window.location.href = redirectRoute;
            } else {
              navigate(0);
            }
          } else {
            getUserSession();
          }
          onClose();
        })
        .catch(() => {
          showMessage('error', 'Error', 'Invalid username or password. Please try again.', 'bg-red-100 text-red-700');
        });
    }
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  return (
    <div>
      <Toast ref={toast} position='bottom-right' />
      <Dialog
        header="Sign in to your account"
        footer={footer}
        headerClassName="text-2xl font-normal font-playfair px-6 pt-8"
        visible={true}
        dismissableMask={true}
        style={{ width: '80%', maxWidth: '533px', backgroundColor: 'white', overflow: 'hidden' }}
        draggable={false}
        closable={true}
        blockScroll={true}
        responsive={true}
        resizable={false}
        onHide={onClose}
      >
        <div>
          <div className='px-6'>
            <div className='mt-8'>
              <label className='text-sm my-4 text-light-black'>
                Username or email
              </label>
              <input
                type="text"
                key='email'
                value={email}
                onChange={emailInputChange}
                ref={emailInput}
                onInput={(e) => setEmail(e.target.value)}
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              />
            </div>
            <div className='mt-4'>
              <label className='text-sm text-light-black'>
                Password
              </label>
              <input
                type="password"
                key='password'
                value={password}
                onChange={passwordInputChange}
                ref={passwordInput}
                onInput={(e) => setPassword(e.target.value)}
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              />
            </div>
            <BtnTransparent
              props={{
                text: 'text-white w-full mt-6 font-semibold',
                bg: 'bg-periwinkle',
                border: 'border-bg-periwinkle',
                buttonName: 'Sign in',
              }}
              handleClick={() => submit()}
            />
            <div className='mt-4 mb-6'>
              <div className='text-center mb-4 text-lg'>
                or sign in with
              </div>
              <div className='flex items-center justify-between m-auto max-w-[240px]'>
                <FcGoogle className='w-14 h-14' />
                <FaFacebook className='text-blue-500 w-14 h-14' />
                <FaApple className='w-14 h-14' />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default LoginForm;
