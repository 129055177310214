import './CustomSlider.scss';

import Slider from "react-slick";
import nextButton from 'next_icon.svg';
import prevButton from 'previous_icon.svg';
import ItineraryForm from '../ItineraryForm';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BtnTransparent } from "./../UIComponents/buttons";
import PlaceholderSkeleton from '../Skeletons/PlaceholderSkeleton';

const SliderComp = ({ props, isMobile }) => {
  const navigate = useNavigate();

  const [cityName, setCityName] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [centerIndex, setCenterIndex] = useState(0);
  const [isDialogVisible , setIsDialogVisible] = useState(false);

  const NextArrow = (props) => {
    const { style, onClick } = props;
    return (
      <div
        className="slick-next"
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: "95%",
          right: isMobile ? "42%" : "48.5%", 
          margin: "0 auto",
        }}
        onClick={onClick}
      >
        <img src={nextButton} />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { style, onClick } = props;
    return (
      <div
        className="slick-prev"
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: "95%",
          margin: "0 auto",
          left: isMobile ? "42%" : "45%",
          zIndex: "9",
        }}
        onClick={onClick}
      >
        <img src={prevButton} />
      </div>
    );
  };

  var settings = {
    className: 'center',
    centerMode: true,
    centerPadding: '0px',
    dots: false,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 4,
    initialSlide: 0,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (_, next) => setCenterIndex(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      {props && props.map((city, index) => (
        <div
          key={index}
          className=' button-container'
          onMouseEnter={() => setIsHovered(index)}
          onMouseLeave={() => setIsHovered(null)}
        >
          <div
            key={index}
            className='projectClasses centerClass'
          >
            <div className='p-4 bg-white slick-image-holder text-xs  '>
              <div className='image-div'>
                { city.imageUrl ? 
                  <img 
                    className='image'
                    src={ city.imageUrl }
                    alt="" 
                  /> :
                  <PlaceholderSkeleton applyClass = { "image" } />
                }
              </div>
              <div className='slideContent'>
                <div className='flex justify-between text-black font-semibold mt-2'>
                  <div>
                    { city.cityName }, { city.countryName }
                  </div>
                  <div>
                    { city.price_level }
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isHovered === index && centerIndex === index && (
            <div className='flex flex-col justify-center items-center absolute inset-0' style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }} >
              <BtnTransparent
                props={{
                  bg: 'bg-periwinkle',
                  border: 'border-periwinkle mb-2 ',
                  buttonName: 'Generate itinerary',
                  text: 'text-white max-w-[225px] w-full'
                }}
                handleClick={() => {
                  setIsDialogVisible(true);
                  setCityName(city.cityName);
                }}
              />

              <BtnTransparent
                props={{
                  bg: 'bg-[#FFFFFFe6] ',
                  buttonName: 'See details',
                  border: 'border-periwinkle',
                  text: 'text-periwinkle max-w-[225px] w-full',
                }}
                handleClick={() => navigate(`/results?city_name=${city.cityName}&is_city_detail=true`)}
              />
            </div>
          )}
        </div>
      ))}

      { isDialogVisible && <ItineraryForm
        city={ cityName }
        isVisible={ isDialogVisible }
        onClose={ () => setIsDialogVisible(false) }
      />}
    </Slider>
  );
};

export default SliderComp;
