import React, { useEffect, useState } from 'react';
import { GrClose } from "react-icons/gr";
import { useLocation } from 'react-router-dom';

import http from '../../common/http';
import SingleSelectDropdown from '../UIComponents/Dropdowns/SingleSelectDropdown';
import SingleSelectDate from '../UIComponents/Dates/SingleSelectDate';
import SatisfactionEmojis from '../UIComponents/SatisfactionEmojis';
import ResidentOptions from './ResidentOptions';
import { LuInfo } from "react-icons/lu";
import { autoOpenCalendarDate } from '../../common/shared/calendarEventsFunctions';

const AccForm = ({ formData1, handleInput1, handleClose, hotelLookUpVisible, props, calledFrom }) => {

  const location = useLocation();
  const routeParams = new URLSearchParams(location.search);
  const [hotels, setHotels] = useState([]);

  const onHotelChange = () => {
    props.hotelRef.current.getElement().style.border = '';
  };

  const onPriceChage = () => {
    props.priceRef.current.style.border = '';
  };

  const onAddressChage = () => {
    props.addressRef.current.style.border = '';
  };

  const onCheckInDateChange = () => {
    props.checkInDateRef.current.getElement().querySelector('input').style.border = '';
  };

  const onCheckOutDateChange = () => {
    props.checkOutDateRef.current.getElement().querySelector('input').style.border = '';
  };

  useEffect(() => {
    let params;
    if ( calledFrom == "tripPlan" ) {
      params = {
        destinations: routeParams.get('destinations'),
      };
    } else {
      params = {
        destinations: `["${routeParams.get('city')}"]`,
      };
    }
    http
      .get('/amadeus_hotels', { params })
      .then((res) => {
        setHotels(res.data.hotels);
      })
      .catch(() => {

      });
  }, []);

  return (
    <div className='pr-8 pl-8 pt-8'>
      <div className='flex justify-between'>
        <h2 className='text-left font-playfair text-2xl pb-4'>
          Enter your accomodation information
        </h2>
        <GrClose className='cursor-pointer' onClick={handleClose} />
      </div>

      <div className='mt-4'>
        <div>
          <div
            ref = { props.typeRef } 
            className="inline-flex flex-wrap gap-3 text-xs">
            <ResidentOptions
              label='Hotel'
              formData={formData1}
              handleInput={handleInput1} 
            />
            <ResidentOptions
              label='Airbnb'
              formData={formData1}
              handleInput={handleInput1 } 
            />
            <ResidentOptions
              label='Other'
              formData={formData1}
              handleInput={handleInput1}
            />
          </div>
          <div className='flex flex-col'>
            {
              hotelLookUpVisible ? 
              <div className='mt-4'>
                <label className='text-sm my-4 text-light-black'>
                  Hotel lookup
                </label>
                <SingleSelectDropdown
                  ref = { props.hotelRef }
                  props={{ options: hotels, value: formData1.hotel }}
                  handleChanges={ (e) => { handleInput1("hotel", e.target.value), onHotelChange(); } } 
                />
              </div> :
              <></>

            }

            <div className='text-sm mt-4'>
              <label className='text-light-black'>
                Address
              </label>
              <input
                ref = { props.addressRef }
                type="text"
                key='lastName'
                value={formData1.address}
                onInput={ (e) => { handleInput1("address", e.target.value), onAddressChage(); } }
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              />
            </div>
          </div>

          <div className='flex flex-col lg:flex-row justify-between gap-x-8'>
            <div className='w-full'>
              <label className='text-sm text-light-black'>
                Check in date
              </label>
              <SingleSelectDate
                ref = { props.checkInDateRef }
                date={formData1.check_in_date && new Date(formData1.check_in_date)}
                minDate={new Date()}
                maxDate={formData1.check_out_date && new Date(formData1.check_out_date)}
                handleDateChange={(val) => {
                    handleInput1("check_in_date", val);
                    onCheckInDateChange();
                    autoOpenCalendarDate(formData1.check_out_date, props.checkOutDateRef);
                }}
              />
            </div>

            <div className='w-full'>
              <label className='text-sm text-light-black'>
                Check out date
              </label>
              <SingleSelectDate
                ref = { props.checkOutDateRef }
                date={formData1.check_out_date && new Date(formData1.check_out_date)}
                minDate={formData1.check_in_date && new Date(formData1.check_in_date) || new Date()}
                handleDateChange={(val) => {
                  handleInput1("check_out_date", val);
                  onCheckOutDateChange();
                }}
              />
            </div>
          </div>

          <div className='flex flex-col lg:flex-row justify-between gap-x-8'>
            <div className='w-full'>
              <label className='text-sm text-light-black'>
                Price per night
              </label>
              <input
              ref = { props.priceRef }
                type="number"
                key='price'
                value={formData1.price}
                onInput={ (e) => { handleInput1("price", e.target.value), onPriceChage(); } }
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              />
            </div>

            <div className='w-full'>
              <div>
                <div className='flex items-center gap-x-2'>
                  <label className='text-sm text-light-black flex gap-x-2'>
                    Price Satisfaction
                  </label>    
                  <div>
                    <LuInfo className='w-5 h-5' />
                  </div>
                </div>
              </div>
              <SatisfactionEmojis handleInput={ handleInput1 }/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccForm;
