import React, { useEffect, useState, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { useSelector, useDispatch } from 'react-redux';
import { setRecommendedHotel, setRecommendedHotelOffer, setRecommendedHotelCity } from '../../redux/slices/recommendedHotel';
import { setHotelBooking } from '../../redux/slices/hotelBooking';
import PlaceholderSkeleton from '../Skeletons/PlaceholderSkeleton';
import CancelationPolicySection from '../CancelationPolicySection/CancelationPolicySection';
import http from '../../common/http';
import loadingSpinner from 'loader.png';
import getSymbolFromCurrency from 'currency-symbol-map';

export default function BookAllRecomendedHotel({ hotelId, recommendedHotel={}, recommendedHotelOffer={}, recommendedHotelCity="", isBookingConfirmation=false, isHotelOffer, setGrandTotal }) {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [checkIn, setCheckIn] = useState({});
  const [checkOut, setCheckOut] = useState({});
  const tripPlan = useSelector(state => state.tripPlan.tripPlan);
  const [travelers, setTravelers] = useState(1);
  const [hotel, setHotel] = useState(recommendedHotel);
  const [hotelRecommendedOffer, setHotelRecommendedOffer] = useState(recommendedHotelOffer);
  const [hotelImages, setHotelImages] = useState([]);
  const [componentRendered, setComponentRendered] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const bookingInfo = useSelector(state => state.hotelBooking.hotelBooking);
  const showMessage = (severity, summary, detail, style) => {
    toast.current?.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };
  const [offerPolicy, setOfferPolicy] = useState(null);
  const [cancellationPolicies] = useState({
    guarantee: "The hotel will save credit card information during booking but not make any charges to the account. In the case of a no-show or out-of-policy cancellation, the hotel may charge penalties to the card.",
    deposit: "At the time of booking or by a given deadline, the hotel will charge the guest a percentage of the total amount of the reservation. The remaining amount is paid by the traveler directly at the hotel.",
    prepay: "The total amount of the reservation fee must be paid by the traveler when making the booking.",
  });
  const cancellationPolicy = () => {
    const paymentType = hotelRecommendedOffer?.policies?.paymentType;
    if (paymentType) {
      setOfferPolicy({ type: paymentType, policy: cancellationPolicies[paymentType] });
    }
  };
  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }

    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let year = date.getFullYear();
    return month + '/' + day + '/' + year;
  };

  const getTripDates = (hotelId, cityName) => {
    if (tripPlan.length > 0 && hotelId) {
      const trip = tripPlan.filter(trip => trip.cityName == cityName)[0];
      setTravelers(trip.travelersCount || '1');
      const [startDateString, endDateString] = trip.date.split("/");
      const newStartDate = new Date(startDateString);
      const newEndDate = new Date(endDateString);

      const startDayValue = String(newStartDate.getDate()).padStart(2, "0");
      const startMonth = String(newStartDate.getMonth() + 1).padStart(2, "0");
      const startYear = String(newStartDate.getFullYear());
      setCheckIn(`${startYear}-${startMonth}-${startDayValue}`);

      const endDayValue = String(newEndDate.getDate()).padStart(2, "0");
      const endMonth = String(newEndDate.getMonth() + 1).padStart(2, "0");
      const endYear = String(newStartDate.getFullYear());
      setCheckOut(`${endYear}-${endMonth}-${endDayValue}`);
    }
  };

  const hotelRoomType = () => {
    if (hotelRecommendedOffer.id) {
      const category = hotelRecommendedOffer?.room?.typeEstimated.category?.toLowerCase();
      const bedType = hotelRecommendedOffer?.room?.typeEstimated.bedType?.toLowerCase();
      if (category && bedType) {
        let name = `${bedType}, ${category}`;
        name = name.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
        return name;
      } else if (bedType) {
        return bedType.charAt(0).toUpperCase() + bedType.substring(1);
      }
      return hotel?.name;
    }
    return hotel?.name;
  };

  const getHotelRecommendedOffer = (offers) => {
    const cheapestOffer = offers.reduce((cheapest, offer) => {
      const currentOfferTotal = parseFloat(offer.price.total);
      const cheapestOfferTotal = parseFloat(cheapest.price.total);
      if (currentOfferTotal < cheapestOfferTotal) {
        return offer;
      } else {
        return cheapest;
      }
    });
    setHotelRecommendedOffer(cheapestOffer);
    isHotelOffer(true);
    setGrandTotal(cheapestOffer?.price?.total);
    dispatch(setRecommendedHotelOffer(cheapestOffer));
    return cheapestOffer;
  };

  const fetchHotelDetail = () => {
    setLoading(true);
    http
      .get(`/hotel/${hotelId}`)
      .then((res) => {
        setHotel(res.data.hotel);
        dispatch(setRecommendedHotel(res.data.hotel));
        dispatch(setRecommendedHotelCity(res.data.city_name));
        getTripDates(res.data.hotel.id, res.data.city_name);
        setHotelImages(res.data.images);
        setComponentRendered(true);
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching hotel detail.', 'bg-red-100 text-red-700');
      });
  };

  const fetchHotelOffers = () => {
    if (!checkIn || !checkOut || !travelers) {
      showMessage('error', 'Error', "Checkin, checkout and travelers count is required.", 'bg-red-100 text-red-700');
      return;
    }
    const params = {
      id: hotel.additional_detail.api_place_id,
      checkin: checkIn,
      checkout: checkOut,
      travelers: travelers,
    };
    http
      .get(`/hotel_offers/`, { params: params })
      .then((res) => {
        const cheapestOffer = getHotelRecommendedOffer(res.data);
        dispatch(setHotelBooking({
          ...bookingInfo,
          'checkIn': checkIn,
          'checkOut': checkOut,
          'offers': [cheapestOffer],
          'bookAll': true,
          'cityInfo': { cityId: hotel.city_id, hotelName: hotel.name }
        }));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        showMessage('error', 'Error', err.response.data.message, 'bg-red-100 text-red-700');
      });
  };

  useEffect(() => {
    if (isBookingConfirmation && recommendedHotelCity) {
      getTripDates(hotel.id, recommendedHotelCity);
    } else {
      fetchHotelDetail();
    }
  }, []);

  useEffect(() => {
    if (componentRendered && !isBookingConfirmation) {
      fetchHotelOffers();
    }
  }, [componentRendered]);

  useEffect(() => {
    cancellationPolicy();
  }, [hotelRecommendedOffer]);

  return (
    <div className='flex flex-col gap-2 mt-2 mb-6 border border-gray bg-silver shadow-md shadow-right p-4'>
      <Toast ref={toast} position='bottom-right' />
      {
        isLoading ? (
          <div className='flex justify-center items-center w-full'>
            <img
              src={ loadingSpinner }
              alt="spinner"
              className='w-[21px] h-[23px] animate-spin'
            />
            <p className='pl-2'>Getting the recommended offer....</p>
          </div>
        ) : (
          <>
            { hotelRecommendedOffer?.price ? (
                <div>
                <div className='my-6'>
                  {
                    hotelImages ?
                      <img
                        src={ hotelImages[0] }
                        className='w-full h-64 object-cover'
                        alt='Card'
                      /> : <PlaceholderSkeleton applyClass={'w-full h-64 object-cover bg-light-gray'} />
                  }
                </div>
                <div>
                  <p className='text-2xl font-semibold'>
                    {hotel.name}
                  </p>
                  <p className='font-semibold'>
                    {`${formatDate(checkIn)} - ${formatDate(checkOut)}`}
                  </p>
                  <p>
                    {hotelRoomType()}
                  </p>
                </div>
                { offerPolicy && <div className='my-4'>
                    <CancelationPolicySection content={offerPolicy}/>
                  </div>
                }
                </div>
              ) : (
                <p> No recommended hotel offer available.</p>
              )
            }
          </>
        )
      }
      { hotelRecommendedOffer?.price && <div className='flex justify-end'>
          <h1 className='text-lg font-bold text-end'>
            Total {`${getSymbolFromCurrency(hotelRecommendedOffer?.price?.currency)}${hotelRecommendedOffer?.price?.total}`}
          </h1>
        </div>
      }
    </div>
  );
}
