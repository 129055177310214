import React, { useEffect, useState } from 'react';
import './ReviewFlight.scss';

import FlightDataSelection from './FlightDataSelection';
import { useSelector } from 'react-redux';

const SelectedFlightsSection = () => {
  const selectedFlights = useSelector(state => state.selectedFlights.selectedFlights);
  const [flightsSData, setFlightsSData] = useState([]);

  useEffect(() => {
    setFlightsSData(selectedFlights);
  }, [selectedFlights]);

  const costTotal = () => {
    let totalCost = flightsSData.reduce((accumulator, flight) => {
      const price = flight.selectedOffer.price;
      return accumulator + parseFloat(price.grandTotal || price.total);
    }, 0);
    return totalCost?.toFixed(2);
  };

  const updateFlight = (flightName, attribute, value) => {
    const index = flightsSData.findIndex(flight => flight.flightName === flightName);
    const updatedFlightsData = [...flightsSData];
    updatedFlightsData[index] = {
      ...updatedFlightsData[index],
      [attribute]: value
    };
    setFlightsSData(updatedFlightsData);
  };

  const deleteFlight = (flightName) => {
    const updatedFlightsData = flightsSData.filter(flight => flight.flightName !== flightName);
    setFlightsSData(updatedFlightsData);
  };

  return (
    <div className='py-2 px-4 mb-2 shadow-md shadow-right border border-gray bg-silver'>
      <h1 className='text-lg font-bold mt-4 ml-4'>Selected flight(s):</h1>

      {flightsSData.length ?
        flightsSData.map((flight, index) => (
          <div key={index}>
            <FlightDataSelection
              flightOffer={flight.selectedOffer}
              dictionaries={flight.dictionaries}
              flight={flight}
              updateFlight={updateFlight}
              deleteFlight={deleteFlight}
              calledFrom='selectedflights'
            />
          </div>
        ))
        : <div className='text-md mt-5 font-bold px-4'>No flights Selected</div>
      }

   {
    selectedFlights.length ? 
        <div className='flex flex-row justify-between gap-2 px-4'>
          <div className='flex justify-start'>
          <h1 className='text-sm'>Flight Total : </h1>
          </div>
          <div className='flex flex-col'>
            <h1 className='text-lg font-bold text-end'>$ { costTotal() }</h1>
          </div>
      </div> :
      <></>
   }
    </div>
  );
};

export default SelectedFlightsSection;
