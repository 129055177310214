import React, { useState } from 'react';
import { BtnTransparent } from '../buttons';
import uploadicon from 'uploadicon.png';

const FileUpload = ({ setUploadedFiles, widthInput }) => {
    const acceptImages = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
  const [thumbnails, setThumbnails] = useState([]);
  const [isDragging, setIsDragging] = useState(false);

  const fileInputRef = React.createRef();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setUploadedFiles(Array.from(selectedFiles));
    setThumbnailReaders(selectedFiles);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false); // Reset dragging state
    const droppedFiles = e.dataTransfer.files;
    setUploadedFiles(Array.from(droppedFiles));
    setThumbnailReaders(droppedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const setThumbnailReaders = (files) => {
    const newThumbnails = [];

    Array.from(files).forEach((file) => {
      if (acceptImages.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = () => {
          newThumbnails.push(reader.result);
          setThumbnails([...newThumbnails]);
        };
        reader.readAsDataURL(file);
      }
    });
  };

  return (
    <div>
      <div
       className={`${widthInput} border-2 border-dashed border-gray-400 p-4 rounded-lg text-center mt-5 ${isDragging?'bg-gray-200':'transparent'}`}
       onDrop={handleDrop}
       onDragOver={handleDragOver}
       onDragLeave={handleDragLeave}
      >
        <div className='w-52 mx-auto my-10'>
          <div className='flex flex-col items-center justify-center'>
            <img src={uploadicon} alt="upload" />
            <h1 className="text-md text-light-black font-serif mt-5">Drag and Drop Files</h1>
            <h1 className="text-md text-light-black font-serif mt-5">or</h1>
          </div>

          <BtnTransparent
            props={{
              bg: 'bg-gray-800',
              border: 'border-gray-800',
              buttonName: 'Browse',
              text: 'text-white mt-4 w-full h-[40px] font-semibold',
            }}
            handleClick={handleButtonClick}
          />
          <input
            type='file'
            ref={fileInputRef}
            className='hidden'
            accept={acceptImages.join(',')}
            onChange={handleFileChange}
            multiple
          />
        </div>
      </div>
      <div className={`flex flex-wrap ${widthInput} p-2 gap-2`}>

      {thumbnails.map((thumbnail, index) => (
          <img
          key={index}
          src={thumbnail}
          alt={`Thumbnail ${index + 1}`}
          style={{ maxWidth: '50px', maxHeight: '50px' }}
          />
          ))}
          </div>
    </div>
  );
};

export default FileUpload;
