import React, { useState } from 'react';
import { useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { BtnTransparent } from '../UIComponents/buttons.jsx';
import { Toast } from 'primereact/toast';
import { useSelector } from 'react-redux';
import HotelBooking from '../HotelBooking/HotelBooking.jsx';

export default function BookAllCheckoutModal({ isCheckoutVisible, onCheckoutClose, isHotelBooking, isFlightBooking }) {
  const toast = useRef(null);
  const hotelBooking = useRef(null);
  const recommendedHotel = useSelector(state => state.recommendedHotel.recommendedHotel);
  const recommendedHotelOffer = useSelector(state => state.recommendedHotel.recommendedHotelOffer);
  const [disableBookingButton, setDisableBookingButton] = useState(true);
  const bookAll = () => {
    if (hotelBooking.current) {
      hotelBooking.current.setTravelersInfo();
      onCheckoutClose();
    }
  };

  return (
    <div className='flex w-full'>
      <Toast ref={ toast } position='bottom-right'/>
      <Dialog
        headerClassName="px-4 pt-4"
        visible={ isCheckoutVisible }
        dismissableMask={ false }
        closable={ true }
        style={ { backgroundColor: 'white', overflow: 'hidden' } }
        className='lg:w-[1000px] w-full'
        onHide={ () => onCheckoutClose() }
      >
        <div className='mt-4'>
          <div className='text-sm mt-4 sm:w-[47%]'>
            <label className='text-2xl px-6 pt-8 font-playfair'>
              Enter details
            </label>
            <p className='font-sans px-6'>Enter your traveler details and card information</p>
          </div>
          <div className='lg:w-[100%] sm:w-[47%]'>
          <HotelBooking
            ref={ hotelBooking }
            isBookAll={ true }
            isFlightBooking={ isFlightBooking }
            isHotelBooking={ isHotelBooking }
            recmdHotelId={ recommendedHotel.id }
            recmdOfferId={ recommendedHotelOffer.id }
            disableBookAll= { setDisableBookingButton }
          />
          </div>
          <BtnTransparent
            props={{
              text: 'text-white font-semibold w-full mb-4',
              bg: 'bg-periwinkle',
              border: 'border-periwinkle',
              buttonName: 'Book all',
            }}
            disabled={ disableBookingButton }
            handleClick={ () => bookAll() }
          />
        </div>
      </Dialog>
    </div>
  );
}
