import Layout from '../Layout/Layout';
import http from './../../common/http';
import CreditCard from '../CreditCard/CreditCard';
import BookingCart from '../BookingCart/BookingCart';
import TravelerInfoForm from '../TravelerInfoForm/TravelerInfoForm';
import CancelationPolicySection from '../CancelationPolicySection/CancelationPolicySection';

import { isEmpty, cloneDeep } from 'lodash';
import { Toast } from 'primereact/toast';
import { object, string, array } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { setTripPlan, setTripPrice } from '../../redux/slices/tripPlan';
import { BtnTransparent } from '../UIComponents/buttons';
import React, { forwardRef, useEffect, useState, useRef, useImperativeHandle } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { emailRegex, cardnumberRegex, nameonCardRegex, cardsecuritycodeRegex } from '../../utils/helpers/string';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { getUserId } from '../../common/auth';
import { setCompleteInfo } from '../../redux/slices/selectedFlightsSlice';

const HotelBooking = forwardRef(({ isBookAll = false, isFlightBooking = false, isHotelBooking = false, recmdHotelId, recmdOfferId, disableBookAll }, ref) => {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userId = getUserId();

  const bookingInfo = useSelector(state => state.hotelBooking.hotelBooking);
  const tripPlanParams = useSelector(state => state.tripPlanParams?.tripPlanParams);
  const tripPlan = useSelector(state => state.tripPlan.tripPlan);
  const hotelSwapping = useSelector(state => state.hotelSwapping.hotelSwapping);
  const saveButtonRef = useSelector(state => state.saveButton.saveButton);
  const priceBreakdown = useSelector(state => state.tripPlan.tripPrice);
  const selectedFlights = useSelector(state => state.selectedFlights.selectedFlights);
  const [hotel, setHotel] = useState({});
  const [confirmationId] = useState(null);
  const [bookingConfirmed] = useState(false);
  const [hotelId, setHotelId] = useState(null);
  const [offerId, setOfferId] = useState(null);
  const [travelersData, setTravelersData] = useState([{}]);
  useImperativeHandle(ref, () => ({
    setTravelersInfo,
  }));

  const [cardData, setCardData] = useState({});

  const cancelPolicy = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum ut tristique et egestas quis ipsum suspendisse ultrices gravida. A iaculis at erat pellentesque adipiscing. Porta nibh venenatis cras sed. ";

  const handleCardDataChange = (labelName, value) => {
    setCardData(prevData => ({
      ...prevData,
      [labelName]: value
    }));
  };

  useEffect(() => {
    if (userId) {
      fetchCardInformation();
    }
  }, []);

  const fetchCardInformation = () => {
    http
      .get('/credit_card/card_info')
      .then((res) => {
        if (res.data) {
          setCardData(
            { 
             ...cardData,
             ...res.data.user_card
            });
        }
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching card.', 'bg-red-100 text-red-700');
      });
  };

  const renderTravelersForm = () => {
    let comps = [];
    if (bookingInfo.travelers) {
      for (let i = 0; i < bookingInfo.travelers; i++) {
        comps.push(
          <TravelerInfoForm
            key={i}
            data={travelersData[i]}
            travelersNumber={i+1}
            onFormDataChange={(newData) => handleTravelerDataChange(i, newData)}
          />
        );
      }
      return comps;
    }
    comps.push(
      <TravelerInfoForm
        key={0}
        travelersNumber={1}
        data={travelersData[0]}
        onFormDataChange={(newData) => handleTravelerDataChange(0, newData)}
      />
    );
    return comps;
  };
  const [inputList, setInputList] = useState(renderTravelersForm());
  
  const onClose = (index) => {
    setInputList(prevItems => {

      let counter = 1;
      const updatedItems = [...prevItems.slice(0, index), ...prevItems.slice(index + 1)];
  
      const updatedInputList = updatedItems.map((traveler) => {
        const updatedTraveler = {
          ...traveler,
          key: counter,
          props:  {
            ...traveler.props,
            travelersNumber: counter
          },
        };
        counter++;  
        return updatedTraveler;
      });
  
      return updatedInputList;
    });
  };

  const handleTravelerDataChange = (index, newData) => {
    setTravelersData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = newData;
      return updatedData;
    });
  };

  useEffect(() => {
    reuiredFieldsFilled();
  }, [travelersData, cardData]);

  const addTravelerForm = () => {
    setInputList(
      inputList.concat(
        <TravelerInfoForm
          key={inputList.length}
          onClose={onClose}
          travelersNumber={inputList.length + 1}
          data={travelersData[inputList.length]}
          onFormDataChange={(newData) => handleTravelerDataChange(inputList.length, newData)}
        />
      )
    );
  };

  const travelersInfoSchema = object({
    travelers_data: array().of(
      object().shape({
        firstName: string().required('First name is required.'),
        lastName: string().required('Last name is required.'),
        gender: string().required('Gender is required.'),
        dateOfBirth: string().required('Date of birth is required.'),
        email: string().required('Email is required.').test({
          name: 'email-validation',
          test: function (value) {
            if (!this.parent.email) return true;
            return emailRegex.test(value);
          },
          message: 'Invalid email.'
        }),
        phoneNumber: string().required('Phone number is required').test({
          name: 'phone-number validation',
          test: function (value) {
            return isPossiblePhoneNumber(value);
          },
          message: 'Please enter valid phone number.'
        })
      })
    ),
    card_data: object({
      name: string().required('Name on card is required.').test({
        name: 'name-on-card-validation',
        test: function (value) {
          if (!this.parent.name) return true;
          return nameonCardRegex.test(value);
        },
        message: 'Invalid Name on Card.'
      }),
      card_number: string().required('Card number is required.').test({
        name: 'card-number-validation',
        test: function (value) {
          if (!this.parent.card_number) return true;
          return cardnumberRegex.test(value);
        },
        message: 'Invalid card number.'
      }),
      expiration_month: string().required('Expiration month is required.'),
      expiration_year: string().required('Expiration year is required.'),
      encrypted_security_code: string().required('Security code of credit card is required.').test({
        name: 'security-code-validation',
        test: function (value) {
          if (!this.parent.encrypted_security_code) return true;
          return cardsecuritycodeRegex.test(value);
        },
        message: 'Invalid Security Code.'
      }),
      address_line_1: string().required('Address is required.'),
      city: string().required('City is required.'),
      state: string().required('State is required.'),
      postal_code: string().required('Postal Code is required.'),
      vendor_code: string().required('Please use a Visa, Mastercard, or American Express card instead.')
    }),
  });

  const vendorCodes = {
    '5': 'CA',
    '4': 'VI',
    '34': 'AX',
    '37': 'AX'
  };

  const [disableBookingButton, setDisableBookingButton] = useState(true);

  const reuiredFieldsFilled = () => {
    let hasEmptyField = false;
  
    for (const obj of travelersData) {
      if (!obj.firstName || !obj.lastName || !obj.gender ||
        !obj.dateOfBirth || !obj.email
      ) {
        hasEmptyField = true;
        break;
      }
    }
    if (!hasEmptyField) {
      if ( !cardData.name || !cardData.card_number || !cardData.expiration_month ||
        !cardData.expiration_year || !cardData.encrypted_security_code || !cardData.address_line_1 ||
        !cardData.city || !cardData.state || !cardData.postal_code
      ) {
        hasEmptyField = true;
      }
    }
  
    setDisableBookingButton(hasEmptyField);
    if (isBookAll) disableBookAll(hasEmptyField);
  };

  const setHotelBookingInfo = (plan, travelersInfo) => {
    plan[hotelSwapping.hotelIndex].hotel = cloneDeep(hotel.hotel);
    plan[hotelSwapping.hotelIndex].travelers_info = cloneDeep(travelersInfo);
    plan[hotelSwapping.hotelIndex].hotel_details = cloneDeep(bookingInfo);
    return plan;
  };

  const setTravelersInfo = async () => {
    try {
      if (cardData) {
        let number = cardData.card_number;
        const prefix = Object.keys(vendorCodes).find(prefix => number.startsWith(prefix));

        if (prefix) {
          cardData.vendor_code = vendorCodes[prefix];
        } else {
          showMessage('error', 'Error', 'Please use a Visa, Mastercard, or American Express card instead.', 'bg-red-100 text-red-700');
          return;
        }
      }
      const travelersInfo = { travelers_data: travelersData, card_data: cardData, offer_id: offerId, isBooked: true };
      await travelersInfoSchema.validate(travelersInfo, { abortEarly: false });

      let plan = cloneDeep(tripPlan);
      let aiSuggestedPrice = null;
      let tripPrice = cloneDeep(priceBreakdown);
      if (isBookAll) {
        plan[hotelSwapping.hotelIndex].is_book_all = true;
        if (isFlightBooking) {
          let offers = cloneDeep(selectedFlights);
          let travelers = cloneDeep(travelersInfo.travelers_data);
          travelers[0]['travelerType'] = 'Adult';
          let obj = { flights: offers, traveler_info: travelers };
          dispatch(setCompleteInfo(obj));
        }

        if (isHotelBooking) {
          plan = setHotelBookingInfo(plan, travelersInfo);
        }
      } else {
        plan = setHotelBookingInfo(plan, travelersInfo);
      }
      const currentCityPlan = tripPlan.filter(plan => plan.cityName === hotel.city_name)[0];
      if (currentCityPlan.hotel.additional_detail) {
        aiSuggestedPrice = currentCityPlan.hotel.additional_detail.ai_suggested_price?.replace('$', '');
      } else if (currentCityPlan.hotel.ai_suggested_price) {
        aiSuggestedPrice = currentCityPlan.hotel.ai_suggested_price?.replace('$', '');
      }
      const roomPrice = bookingInfo?.offers?.find(offer => offer.id === offerId).price.total;
      if (aiSuggestedPrice) {
        tripPrice.accommodation = parseFloat(tripPrice.accommodation - aiSuggestedPrice) + parseFloat(roomPrice) ;
      } else {
        tripPrice.accommodation = parseFloat(roomPrice);
      }
      tripPrice.total = tripPrice.food + tripPrice.activity + tripPrice.accommodation;

      dispatch(setTripPrice(tripPrice));
      dispatch(setTripPlan(plan));
      if (plan.length && plan[0]['id'] && !isBookAll) {
        navigate(`/trip-plan/${plan[0]['id']}`);
      }
      else if (isBookAll && !plan[0]['id']) {
        navigate(`/trip-plan?${tripPlanParams}`);
        setTimeout(() => {
          saveButtonRef.click();
        }, 1000);
      } else if (isBookAll && plan[0]['id']) {
        navigate(`/trip-plan/${plan[0]['id']}`);
        setTimeout(() => {
          saveButtonRef.click();
        }, 1000);
      } else {
        navigate(`/trip-plan?${tripPlanParams}`);
      }
    } catch (error) {
      if (error.inner) {
        const errorMessages = error.inner.map((e) => e.message);

        showMessage('error', 'Error', (
          <ul>
            {errorMessages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        ), 'bg-red-100 text-red-700');
      }
    }
  };


  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const fetchHotelDetail = () => {
    if (!hotelId) return;

    http
      .get(`/hotel/${hotelId}`)
      .then((res) => {
        setHotel(res.data);
      })
      .catch(() => {
        showMessage('error', 'Error', 'There was an error while fetching hotel detail.', 'bg-red-100 text-red-700');
      });
  };

  useEffect(() => {
    if (tripPlan) {
      localStorage.setItem('userTripPlan', tripPlan);
    }

    if (isEmpty(tripPlan)) {
      dispatch(setTripPlan(localStorage.getItem('userTripPlan')));
    } else {
      const params = new URLSearchParams(location.search);
      setHotelId(params.get('hotel_id') || recmdHotelId);
      setOfferId(params.get('offer_id') || recmdOfferId);
    }
  }, []);

  useEffect(() => {
    fetchHotelDetail();
  }, [hotelId]);

  const bookingInfoContent = () => {
    return (
      <div className='flex w-full bg-[#FFFCF9]' >
        <Toast ref={toast} position="bottom-right" />
        <div className={ `flex w-full ${isBookAll ? 'lg:m-10' : 'log:m-20'} mt-24 p-3 flex-col` } >
          { !isBookAll &&
            <div className='flex w-full flex-col' >
              <p
                className='font-bold py-6 cursor-pointer text-sm'
                onClick={ () => navigate(-1) }
              >
                { "< Back" }
              </p>
              <p className='text-3xl font-playfair my-6 font-normal'>
                Traveler details and payment
              </p>
            </div>
          }

          <div className='flex lg:justify-between justify-center lg:flex-row flex-col w-full'>
            <div className={`flex flex-col ${isBookAll ? 'lg:w-[100%]' : 'lg:w-[70%]' } w-full`}>
              {
                inputList
              }
              { !isBookAll &&
                <div
                  className='text-periwinkle text-base font-semibold py-6 cursor-pointer'
                  onClick={addTravelerForm}
                >
                  Add traveler +
                </div>
              }

              {/* <TripInsuranceSection /> */}

              <div className='py-6'>
                <CreditCard formData = { cardData } setFormData = { handleCardDataChange } />
              </div>

              { !isBookAll &&
                <div className='flex w-full mb-14'>
                  <div className='flex w-full justify-center lg:justify-end float-right'>
                    <BtnTransparent
                      props={{
                        text: 'text-white',
                        bg: 'bg-periwinkle',
                        border: 'border-periwinkle',
                        buttonName: 'Complete booking',
                        radius: 'rounded'
                      }}
                      disabled={disableBookingButton}
                      handleClick={() => setTravelersInfo()}
                    />
                  </div>
                </div>
              }
            </div>
            { !isBookAll &&
              <div className='lg:w-[28%]'>
                <BookingCart />
              </div>
            }
          </div>
        </div>
      </div>
    );
  };

  const bookingConfirmedContent = () => {
    return (
      <div className='flex w-full justify-center items-center mb-16'>
        <div className='flex lg:w-3/5 w-full'>
          <div>
            <h1 className='text-3xl'>
              You’re booked!
            </h1>
            <p className='my-4'>
              You will receive an email at rosesmith@gmail.com with the booking info.
            </p>
            <p className='font-semibold'>
              Confirmation ID: { confirmationId }
            </p>
          </div>
          <div className='flex flex-wrap w-full border'>
            <img
              src='https://i.imgur.com/ez2L55U.jpeg'
              alt='hotel picture'
            />
            <div className='m-12'>
              <h1 className='text-2xl'>
                { hotel.name }
              </h1>
              <p className='my-6'>
                06/27/2024 - 06/29/2024
              </p>
              <p className='my-6'>
                King, city view x1
              </p>

              <div className='flex justify-between'>
                <div className='font-semibold'>
                  Total
                </div>
                <div className='font-semibold'>
                  $3509.00
                </div>
              </div>
              <div className='my-4'>
                Paid with credit card ending in 4657
              </div>

              <CancelationPolicySection content={cancelPolicy} />
            </div>
          </div>
          <div className='flex justify-center mt-10'>
            <BtnTransparent
              props={{
                text: 'text-white',
                bg: 'bg-periwinkle',
                border: 'border-periwinkle',
                buttonName: 'Go to itinerary'
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return isBookAll ? (
    <div className='flex w-full'>
      { bookingConfirmed ? bookingConfirmedContent() : bookingInfoContent() }
    </div>
  ) : (
    <Layout>
      <div className='flex w-full'>
        { bookingConfirmed ? bookingConfirmedContent() : bookingInfoContent() }
      </div>
    </Layout>
  );
});

HotelBooking.displayName = 'HotelBooking';
export default HotelBooking;
