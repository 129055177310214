import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import MultiSelectDropdown from '../UIComponents/InputFields/MultiselectDropdown';
import ChipField from '../UIComponents/ChipComponent/ChipComponent';
import http from '../../common/http.js';
import { getUserId, getUserSession } from '../../common/auth';
import { triggerMixpanelEvent } from '../../common/mixpanel';
import SingleSelectDate from './../UIComponents/Dates/SingleSelectDate';
import { Dialog } from 'primereact/dialog';
import Steps from '../UIComponents/steps.jsx';
import { BtnTransparent } from './../UIComponents/buttons.jsx';
import '../../common/common_styles.scss';
import { Toast } from 'primereact/toast';
import Party from 'party.svg';
import SingleSelectDropdown from '../UIComponents/Dropdowns/SingleSelectDropdown';
import { emailRegex, passwordRegex, usernameRegex } from '../../utils/helpers/string.jsx';
import PhoneField from "../UIComponents/PhoneField/PhoneInput.jsx";
import { useLocation } from 'react-router-dom';
import { FaApple } from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa6";
import { validateProfileUrls } from '../../common/shared/profileLinksFunctions.js';

const SignupForm = ({ isOpen, onClose, openLoginForm, referredBy }) => {
  if (!isOpen) return null;

  const initialSocialMediaLinkState = { Instagram: '', TikTok: '', Twitter: '', Pinterest: '' };
  const [showSkipAll, setShowSkipAll] = useState(false);
  const location = useLocation();
  const redBox="2px solid red";
  const userNameInput = useRef(null);
  const emailInput = useRef(null);
  const passwordInput = useRef(null);
  const firstNameInput = useRef(null);
  const lastNameInput = useRef(null);
  const phoneField = useRef(null);
  const toast = useRef(null);
  const inputRefs = useRef([]);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [DOB, setDOB] = useState(null);
  const [username, setUsername] = useState(null);
  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);
  const [preferredCities, setPreferredCities] = useState([]);
  const [preferredActivities, setPreferredActivities] = useState([]);
  const [preferredCountries, setPreferredCountries] = useState([]);
  const [preferredCuisines, setPreferredCuisines] = useState([]);
  const [formScreen, setFormScreen] = useState(true);
  const [phoneScreen, setPhoneScreen] = useState(false);
  const [countryScreen, setCountryScreen] = useState(false);
  const [preferredCountryScreen, setPreferredCountryScreen] = useState(false);
  const [preferredCityScreen, setPreferredCityScreen] = useState(false);
  const [preferredActivityScreen, setPreferredActivityScreen] = useState(false);
  const [preferredCusineScreen, setPreferredCuisineScreen] = useState(false);
  const [socialPlatformScreen, setSocialPlatformScreen] = useState(false);
  const [socialMediaInfo, setSocialMediaInfo] = useState(initialSocialMediaLinkState);
  const [showAccountCreation, setShowAccountCreation] = useState(false);
  const userId = getUserId();

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [countries, setCountries] = useState([]);
  const [citiesArray, setCitiesArray] = useState([]);
  const [countriesArray, setCountriesArray] = useState([]);
  const [suggestedCities, setSuggestedCities] = useState([]);
  const [activities, setActivities] = useState([]);
  const [cuisines, setCuisines] = useState([]);
  const [usernameAvailable, setUsernameAvailable] = useState(false);
  const [customizedUsername, setCustomizedUsername] = useState(false);

  // ++++++++++++++++++++++++++++++
  useEffect(() => {
    location.pathname != "/" ? setShowSkipAll(true) : setShowSkipAll(false);
  }, [location]);
  // ++++++++++++++++++++++++++++++

  useEffect(() => {
    if (firstName && lastName && !usernameAvailable && !customizedUsername) {
      generateUsername();
      userNameInput.current.style.border='';
    }
  }, [firstName, lastName]);

  useEffect(() => {
    if (username && !usernameAvailable) {
      userNameInput.current.style.border=redBox;
    } else if (username && usernameAvailable) {
      userNameInput.current.style.border='';
    }
  }, [username, usernameAvailable]);

  useEffect(() => {
    isUsernameAvailable();
  }, [username]);

  useEffect(() => {
    fetchFieldOptions('cities_array', country, city);
  }, [country]);

  useEffect(() => {
    if(city) {
      fetchFieldOptions('countries_array', country, city);
    }
  }, [city]);

  const generateUsername = () => {
    const randomNumber = Math.floor(100000 + Math.random() * 900000);
    if (firstName && lastName) {
      setUsername(`${firstName}_${lastName}_${randomNumber}`);
    }
  };

  const isUsernameAvailable = () => {
    if (username) {
      const params = { username: username };
      http
        .get('/check_username_availability.json', { params: params })
        .then(() => {
          setUsernameAvailable(true);
        })
        .catch(() => {
          if (!customizedUsername) {
            generateUsername();
          }
          setUsernameAvailable(false);
        });
    }
  };

  const firstNameInputChanged=()=>{
    firstNameInput.current.style.border='';
  };

  const lastNameInputChanged=()=>{
    lastNameInput.current.style.border='';
  };

  const userNameInputChange=()=>{
    userNameInput.current.style.border='';
  };

  const passwordInputChange=()=>{
    passwordInput.current.style.border='';
  };

  const emailInputChange=()=>{
    emailInput.current.style.border='';
  };

  const validateForm = () => {
    if (!firstName || !lastName || !email || !username || !password) {

      if(!firstName){
        firstNameInput.current.style.border=redBox;
      }

      if(!lastName){
        lastNameInput.current.style.border=redBox;
      }

      if(!email){
        emailInput.current.style.border=redBox;
      }

      if(!username){
        userNameInput.current.style.border=redBox;
      }

      if(!password){
        passwordInput.current.style.border=redBox;
      }

      showMessage('error', 'Error', 'Please fill in all the required fields.', 'bg-red-100 text-red-700');
      return false;
    }

    if (!emailRegex.test(email)) {
      showMessage('error', 'Error', 'Please enter a valid email address.', 'bg-red-100 text-red-700');
      return false;
    }

    if (!passwordRegex.test(password)) {
      showMessage('error', 'Error', 'Password should be atleast 6 digits long.', 'bg-red-100 text-red-700');
      return false;
    }

    if (!usernameRegex.test(username)) {
      showMessage('error', 'Error', 'Username can only contain letters, digits, underscores, and dots.', 'bg-red-100 text-red-700');
      return false;
    }

    return true;
  };

  const createAccount = () => {
    if (validateForm()) {
      const params = { user: {
        first_name: firstName,
        last_name: lastName,
        date_of_birth: DOB,
        email: email,
        referred_by: referredBy,
        username: username,
        password: password
      } };

      http
        .post('/users', params)
        .then(() => {
          showMessage('success', 'Success', 'Account created successfully.', 'bg-teal-100 text-teal-500');
          getUserSession();
          setFormScreen(false);
          setPhoneScreen(true);
          const eventName = window.location.pathname.includes('referralprogram/') ? 'Referral Sign Up' : 'Sign Up';
          triggerMixpanelEvent(eventName);
        })
        .catch((err) => {
          if (err.response.status === 422) {
            showMessage('error', 'Error', 'Sorry, this email has already been used. Please choose a different one.', 'bg-red-100 text-red-700');
          } else {
            showMessage('error', 'Error', 'Sorry, there was an error while creating account. Please try again.', 'bg-red-100 text-red-700');
          }
        });
    }
  };

  const fetchFieldOptions = (fieldName, country, city) => {
    const params = { fields: [fieldName], country: country, city: city };
    http
      .get('/field_options.json', { params: params })
      .then((res) => {
        if (fieldName == 'country') {
          setCountries(res.data.country);
        }
        if (fieldName == 'city') {
          setSuggestedCities(res.data.city);
        }
        if (fieldName == 'priority') {
          const priorities = res.data.priority;
          setActivities(priorities.slice(0, 15));
        }
        if (fieldName == 'cuisine') {
          const cuisines = res.data.cuisine;
          setCuisines(cuisines);
        }
        if (fieldName == 'cities_array') {
          setCitiesArray(res.data.cities_array);
        }
        if (fieldName == 'countries_array') {
          setCountriesArray(res.data.countries_array);
        }
      })
      .catch(() => {
        showMessage('error', 'Error', `Sorry, there was an error while fetching ${fieldName} options.`, 'bg-red-100 text-red-700');
      });
  };

  const congratsHeader = () => {
    return (
      <div className='flex items-center gap-x-2'>
        <img src={Party} alt='Hello'/>
        <p className='place-content-center font-playfair'>Congratulations on Joining</p>
      </div>
    );
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const updateUserData = () => {
      setSocialPlatformScreen(false);
      const params = {
        country: country,
        city: city,
        phone: phoneNumber,
        preferred_countries: preferredCountries,
        preferred_cities: preferredCities,
        preferred_activities: preferredActivities,
        preferred_cuisines: preferredCuisines,
        social_media_info: !validateProfileUrls(socialMediaInfo, inputRefs) ? initialSocialMediaLinkState : socialMediaInfo 
      };
      http
        .put(`/users/${ userId || getUserId() }`, params );
  };

  const submitPresonalization = () => {
    if (!validateProfileUrls(socialMediaInfo, inputRefs)) {
      return showMessage('error', 'Error', `Please enter correct social media links.`, 'bg-red-100 text-red-700');
    }

    setSocialPlatformScreen(false);
    const params = {
      country: country,
      city: city,
      phone: phoneNumber,
      preferred_countries: preferredCountries,
      preferred_cities: preferredCities,
      preferred_activities: preferredActivities,
      preferred_cuisines: preferredCuisines,
      social_media_info: socialMediaInfo
    };
    http
      .put(`/users/${userId}`, params )
      .then(() => {
        setShowAccountCreation(true);
        setFormScreen(false);
      })
      .catch(() => {
        showMessage('error', 'Error', `Sorry, there was an error while adding your preferences.`, 'bg-red-100 text-red-700');
      });
  };

  const showLoginForm = () => {
    setFormScreen(false);
    openLoginForm();
  };

  const showNextScreen = () => {
    //TO Store Changes as They Are Made
    if (phoneScreen) {
      setPhoneScreen(false);
      setCountryScreen(true);
    } else if (countryScreen) {
      setCountryScreen(false);
      setPreferredCountryScreen(true);
    } else if (preferredCountryScreen) {
      setPreferredCountryScreen(false);
      setPreferredCityScreen(true);
    } else if (preferredCityScreen) {
      setPreferredCityScreen(false);
      setPreferredActivityScreen(true);
    } else if (preferredActivityScreen) {
      setPreferredActivityScreen(false);
      setPreferredCuisineScreen(true);
    } else if (preferredCusineScreen) {
      setPreferredCuisineScreen(false);
      setSocialPlatformScreen(true);
    }
  };

  const showPreviousScreen = () => {
    if (socialPlatformScreen) {
      setSocialPlatformScreen(false);
      setPreferredCuisineScreen(true);
    } else if (preferredCusineScreen) {
      setPreferredCuisineScreen(false);
      setPreferredActivityScreen(true);
    } else if (preferredActivityScreen) {
      setPreferredActivityScreen(false);
      setPreferredCityScreen(true);
    } else if (preferredCityScreen) {
      setPreferredCityScreen(false);
      setPreferredCountryScreen(true);
    } else if (preferredCountryScreen) {
      setPreferredCountryScreen(false);
      setCountryScreen(true);
    } else if (countryScreen) {
      setCountryScreen(false);
      setPhoneScreen(true);
    }
  };

  const accountFooter = () => {
    return (
      <div className='relative flex justify-between items-center footer h-24 px-6'>
        <div>
          Already have an account?
        </div>
        <div>
          <BtnTransparent
            props={{
              text: 'text-white font-semibold',
              bg: 'bg-[#484c4c]',
              border: 'border-[#484c4c]',
              buttonName: 'Sign in',
            }}
            handleClick={() => showLoginForm()}
          />
        </div>
      </div>
    );
  };

  const showCreationDialog = () => {
    updateUserData();
    setFormScreen(false);
    if (socialPlatformScreen) {
      setSocialPlatformScreen(false);
    } else if (preferredCusineScreen) {
      setPreferredCuisineScreen(false);
    } else if (preferredActivityScreen) {
      setPreferredActivityScreen(false);
    } else if (preferredCityScreen) {
      setPreferredCityScreen(false);
    } else if (preferredCountryScreen) {
      setPreferredCountryScreen(false);
    } else if (countryScreen) {
      setCountryScreen(false);
    }
    setShowAccountCreation(true);
  };

  return (
    <div className='flex w-10/90'>
      <Toast ref={toast} position='bottom-right'/>
      <Dialog
        header="Create an account"
        footer={accountFooter}
        headerClassName="text-2xl font-normal font-playfair px-6 pt-8"
        visible={formScreen}
        dismissableMask={false}
        closable={true}
        style={{ backgroundColor: 'white', overflow: 'hidden' }}
        className='lg:w-[705px] self-center w-90'
        draggable={false}
        blockScroll={true}
        responsive={true}
        resizable={false}
        onHide={() => onClose()}
      >
        <div className='mt-4'>
          <div className='px-6'>
            <div className='flex justify-between lg:flex-row flex-col'>
              <div className='mt-4'>
                <label className='text-sm my-4 text-light-black'>
                  First name*
                </label>
                <input
                  type="text"
                  key='firstName'
                  value={firstName}
                  ref={firstNameInput}
                  onChange={firstNameInputChanged}
                  onInput={(e) => setFirstName(e.target.value)}
                  className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                />
              </div>
              <div className='text-sm mt-4'>
                <label className='text-light-black'>
                  Last name*
                </label>
                <input
                  type="text"
                  key='lastName'
                  value={lastName}
                  ref={lastNameInput}
                  onChange={lastNameInputChanged}
                  onInput={(e) => setLastName(e.target.value)}
                  onBlur={() => { if (!customizedUsername) generateUsername(); }}
                  className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                />
              </div>
            </div>
            <div className='mt-4'>
              <label className='text-sm text-light-black'>
                Date of birth
              </label>
              <SingleSelectDate
                date={DOB}
                maxDate={new Date()}         
                handleDateChange={ (val) => setDOB(val) }
              />
            </div>
            <div className='mt-2'>
              <label className='text-sm my-4 text-light-black'>
                Email*
              </label>
              <input
                type="text"
                key='email'
                value={email}
                onChange={emailInputChange}
                ref={emailInput}
                onInput={(e) => setEmail(e.target.value)}
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              />
            </div>
            <div className='mt-2'>
              <label className='text-sm text-light-black'>
                Create username*
              </label>
              {username && !usernameAvailable &&
                <p className='text-xs text-rose-600'>
                  username already taken
                </p>
              }
              <input
              ref={userNameInput}
                type="text"
                key='username'
                value={username}
                onChange={userNameInputChange}
                onInput={(e) => {
                  setCustomizedUsername(true);
                  setUsername(e.target.value);
                }}
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0`}
              />
            </div>
            <div className='mt-2'>
              <label className='text-sm text-light-black'>
                Create password*
              </label>
              <input
                type="password"
                key='password'
                onChange={passwordInputChange}
                value={password}
                ref={passwordInput}
                onInput={(e) => setPassword(e.target.value)}
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              />
            </div>
            <BtnTransparent
              props={{
                text: 'text-white font-semibold w-full mt-6',
                bg: 'bg-periwinkle',
                border: 'border-periwinkle',
                buttonName: 'Create',
              }}
              disabled={!usernameAvailable}
              handleClick={() => createAccount()}
            />
            <div className='mt-4 mb-6'>
              <div className='text-center mb-4 text-base font-normal font-sans'>
                or sign in with
              </div>
              <div className='flex justify-between m-auto max-w-[240px]'>
                <FcGoogle className='w-14 h-14' />
                <FaFacebook className='text-blue-500 w-14 h-14' />
                <FaApple className='w-14 h-14' />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Phone number"
        headerClassName="text-2xl font-normal font-playfair px-6 pt-8"
        visible={phoneScreen}
        dismissableMask={false}
        style={{ marginLeft:"1rem",marginRight:"1rem", width: '45rem', backgroundColor: 'white' , maxHeight: '100vh' }}
        contentStyle={{ overflowY: 'visible',height:'auto' }}
        closable={false}
        draggable={false}
        blockScroll={true}
        responsive={true}
        resizable={false}
        onHide={() => onClose()}
      >
        <div className='px-6 pb-4'>
          <div className='mt-4'>
            <p className='text-sm mb-2'>1/7</p>
            <Steps totalSteps={7} stepIndex={1} />
          </div>
          <div className='mt-4'>
            <label className='text-[14px] text-[#636363]'>
              Phone number
            </label>
            < PhoneField
              value={phoneNumber}
              handlePhoneChange={(val) => setPhoneNumber(val)}
             />
          </div>
          <div className='flex justify-between items-center mt-6'>
            <div>
            {
              showSkipAll ?
              <p
                className='cursor-pointer font-semibold'
                onClick={() => { 
                  showCreationDialog();
                  triggerMixpanelEvent('Account sign up- Skip all');  
                } }
              >
                Skip all
              </p> : <></>
            }
            </div>
              <BtnTransparent
                props={{
                  text: 'text-white font-semibold',
                  bg: 'bg-periwinkle',
                  border: 'border-periwinkle',
                  buttonName: 'Next',
                }} 
                handleClick={showNextScreen}
              />
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Let us get to know you know better so we can personalize your experience"
        headerClassName="text-2xl font-normal font-playfair px-6 pt-8"
        visible={countryScreen}
        dismissableMask={false}
        style={{ marginLeft:"1rem", marginRight:"1rem", width: '80%', maxWidth: '45rem', backgroundColor: 'white', maxHeight: '100vh' }}
        contentStyle={{ overflowY: 'visible',height:'auto' }}
        draggable={false}
        closable={false}
        blockScroll={true}
        responsive={true}
        resizable={false}
        onShow={() => {
          fetchFieldOptions('countries_array');
        }}
        onHide={() => onClose()}
      >
        <div className='px-6 pb-4'>
          <div className='mt-4'>
            <p className='text-sm mb-2'>2/7</p>
            <Steps totalSteps={7} stepIndex={2} />
          </div>
          <div className='mt-8'>
            What country and city do you live in?
          </div>
          <div className='flex justify-between gap-x-2 mt-4'>
            <div className='w-[290px]'>
              <label className='text-sm font-normal my-4 text-light-black'>
                Select a country
              </label>
              <SingleSelectDropdown
                props={{
                  options: countriesArray,
                  value: country,
                }}
                handleChanges={ (val) => setCountry(val.value)}
                />
            
            </div>
            <div className='w-[290px]'>
              <label className='text-sm font-normal my-4 text-light-black'>
                Select a city
              </label>
              <SingleSelectDropdown
                props={{
                  options: citiesArray,
                  value: city,
                }}
                handleChanges={ (val) => setCity(val.value)}
              />
            </div>
          </div>
          <div className='flex justify-between items-center mt-6'>
            <div>
            {
              showSkipAll? 
            <p
              className='cursor-pointer font-semibold'
              onClick={() => showCreationDialog() }
            >
              Skip all
            </p>
            :<></>
            }  
            </div>
            <div className='flex gap-x-4 my-4'>
              <div>
                <BtnTransparent
                  props={{
                    text: 'text-charcoal font-semibold',
                    bg: 'bg-white',
                    border: 'border-charcoal',
                    buttonName: 'Back',
                  }} 
                  handleClick={showPreviousScreen}
                />
              </div>
              <div>
                <BtnTransparent
                  props={{
                    text: 'text-white font-semibold',
                    bg: 'bg-periwinkle',
                    border: 'border-periwinkle',
                    buttonName: 'Next',
                  }} 
                  handleClick={showNextScreen}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog   
        header="Let us get to know you know better so we can personalize your experience"
        headerClassName="text-2xl font-normal font-playfair px-6 pt-8"
        visible={preferredCountryScreen}
        dismissableMask={false}
        style={{ marginLeft:"1rem", marginRight:"1rem", width: '45rem', backgroundColor: 'white', maxHeight: '100vh' }}
        contentStyle={{ overflowY: 'visible',height:'auto' }}
        draggable={false}
        closable={false}
        blockScroll={true}
        responsive={true}
        resizable={false}
        onShow={() => fetchFieldOptions('country')}
        onHide={() => onClose()}
      >
        <div className='px-6 pb-4'>
        <div className='mt-4'>
            <p className='text-sm mb-2'>3/7</p>
            <Steps totalSteps={7} stepIndex={3} />
          </div>
          <div className='mt-8'>
            What countries would you like to visit?
          </div>
          <div className='mt-4'>
            <MultiSelectDropdown
              key='preferredCountry'
              items={countries}
              chipsColor='yellow-chips-background'
              className={`text-[14px] text-[#636363]`}
              Label={'Select a country'}
              dropdownWidth={'w-[568px]'}
              selectedItems={preferredCountries}
              setSaveData={(val) => setPreferredCountries(val) }
            />
          </div>
          <div className='flex justify-between items-center mt-6'>
            <div>
              <p
                className='cursor-pointer font-semibold'
                onClick={() => showCreationDialog() }
              >
                Skip all
              </p>
            </div>
            <div className='flex gap-x-4 my-4'>
              <div>
                <BtnTransparent
                  props={{
                    text: 'text-charcoal font-semibold',
                    bg: 'bg-white',
                    border: 'border-charcoal',
                    buttonName: 'Back',
                  }} 
                  handleClick={showPreviousScreen}
                />
              </div>
              <div>
                <BtnTransparent
                  props={{
                    text: 'text-white font-semibold',
                    bg: 'bg-periwinkle',
                    border: 'border-periwinkle',
                    buttonName: 'Next',
                  }} 
                  handleClick={showNextScreen}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Let us get to know you know better so we can personalize your experience"
        headerClassName="text-2xl font-normal font-playfair px-6 pt-8"
        visible={preferredCityScreen}
        dismissableMask={false}
        style={{ marginLeft:"1rem", marginRight:"1rem", width: '45rem', backgroundColor: 'white', maxHeight: '100vh' }}
        contentStyle={{ overflowY: 'visible',height:'auto' }}
        draggable={false}
        closable={false}
        blockScroll={true}
        responsive={true}
        resizable={false}
        onShow={() => fetchFieldOptions('city')}
        onHide={() => onClose()}
      >
        <div className='px-6 pb-4'>
          <div className='mt-4'>
            <p className='text-sm mb-2'>4/7</p>
            <Steps totalSteps={7} stepIndex={4} />
          </div>
          <div className='mt-8'>
            What cities would you like to visit?
          </div>
          <div className='mt-4'>
            <div className='my-2 max-h-40 overflow-y-auto'>
              <label className='text-[14px] text-[#636363]'>
                Suggested
              </label>
              <ChipField
                items={suggestedCities}
                chipsColors={{ primary: '#BBABD5', secondary: '#EBDFFF' }}
                selectedItems={preferredCities}
                setSaveData={(val) => setPreferredCities(val) }
              />
            </div>
            <MultiSelectDropdown
              key='preferredCity'
              items={suggestedCities}
              chipsColor='yellow-chips-background'
              className={`text-[14px] text-[#636363]`}
              Label={'Select a city'}
              dropdownWidth={'w-[568px]'}
              selectedItems={preferredCities}
              setSaveData={(val) => setPreferredCities(val) }
            />
          </div>
          <div className='flex justify-between items-center mt-6'>
            <div>
              <p
                className='cursor-pointer font-semibold'
                onClick={() => showCreationDialog() }
              >
                Skip all
              </p>
            </div>
            <div className='flex gap-x-4 my-4'>
              <div>
                <BtnTransparent
                  props={{
                    text: 'text-charcoal font-semibold',
                    bg: 'bg-white',
                    border: 'border-charcoal',
                    buttonName: 'Back',
                  }} 
                  handleClick={showPreviousScreen}
                />
              </div>
              <div>
                <BtnTransparent
                  props={{
                    text: 'text-white font-semibold',
                    bg: 'bg-periwinkle',
                    border: 'border-periwinkle',
                    buttonName: 'Next',
                  }} 
                  handleClick={showNextScreen}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Let us get to know you know better so we can personalize your experience"
        headerClassName="text-2xl font-normal font-playfair px-6 pt-8"
        visible={preferredActivityScreen}
        dismissableMask={false}
        style={{ marginLeft:"1rem", marginRight:"1rem", width: '45rem', backgroundColor: 'white' , maxHeight: '100vh' }}
        contentStyle={{ overflowY: 'visible',height:'auto' }}
        draggable={false}
        closable={false}
        blockScroll={true}
        responsive={true}
        resizable={false}
        onShow={() => fetchFieldOptions('priority')}
        onHide={() => onClose()}
      >
        <div className='px-6 pb-4'>
          <div className='mt-4'>
            <p className='text-sm mb-2'>5/7</p>
            <Steps totalSteps={7} stepIndex={5} />
          </div>
          <div className='mt-8'>
            What activities do you enjoy?
          </div>
          <ChipField
            items={activities}
            chipsColors={{ primary: '#EE9F8D', secondary: '#F0DDDD' }}
            setSaveData= {(val) => setPreferredActivities(val) }
          />
          <div className='flex justify-between items-center mt-6'>
            <div>
              <p
                className='cursor-pointer font-semibold'
                onClick={() => showCreationDialog() }
              >
                Skip all
              </p>
            </div>
            <div className='flex gap-x-4 my-4'>
              <div>
                <BtnTransparent
                  props={{
                    text: 'text-charcoal font-semibold',
                    bg: 'bg-white',
                    border: 'border-charcoal',
                    buttonName: 'Back',
                  }} 
                  handleClick={showPreviousScreen}
                />
              </div>
              <div>
                <BtnTransparent
                  props={{
                    text: 'text-white font-semibold',
                    bg: 'bg-periwinkle',
                    border: 'border-periwinkle',
                    buttonName: 'Next',
                  }} 
                  handleClick={showNextScreen}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Let us get to know you know better so we can personalize your experience"
        headerClassName="text-2xl font-normal font-playfair px-6 pt-8"
        visible={preferredCusineScreen}
        dismissableMask={false}
        style={{ marginLeft:"1rem", marginRight:"1rem", width: '45rem', backgroundColor: 'white' , maxHeight: '100vh' }}
        contentStyle={{ overflowY: 'visible',height:'auto' }}
        draggable={false}
        closable={false}
        blockScroll={true}
        responsive={true}
        resizable={false}
        onShow={() => fetchFieldOptions('cuisine')}
        onHide={() => onClose()}
      >
        <div className='px-6 pb-4'>
          <div className='mt-4'>
            <p className='text-sm mb-2'>6/7</p>
            <Steps totalSteps={7} stepIndex={6} />
          </div>
          <div className='mt-8'>
            What cuisines do you enjoy?
          </div>
          <div className='my-2 max-h-40 overflow-y-auto'>
            <ChipField
              items={cuisines}
              chipsColors={{ primary: '#B3D291', secondary: '#DDF0E1' }}
              setSaveData={(val) => setPreferredCuisines(val) }
            />
          </div>
          <div className='flex justify-between items-center mt-6'>
            <div>
              <p
                className='cursor-pointer font-semibold'
                onClick={() => showCreationDialog() }
              >
                Skip all
              </p>
            </div>
            <div className='flex gap-x-4 my-4'>
              <div>
                <BtnTransparent
                  props={{
                    text: 'text-charcoal font-semibold',
                    bg: 'bg-white',
                    border: 'border-charcoal',
                    buttonName: 'Back',
                  }} 
                  handleClick={showPreviousScreen}
                />
              </div>
              <div>
                <BtnTransparent
                  props={{
                    text: 'text-white font-semibold',
                    bg: 'bg-periwinkle',
                    border: 'border-periwinkle',
                    buttonName: 'Next',
                  }} 
                  handleClick={showNextScreen}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Final step - link your social media profiles"
        headerClassName="text-2xl font-normal font-playfair px-6 pt-8"
        visible={socialPlatformScreen}
        dismissableMask={false}
        style={{ marginLeft:"1rem", marginRight:"1rem", width: '45rem', backgroundColor: 'white' , maxHeight: '100vh' }}
        contentStyle={{ overflowY: 'visible',height:'auto' }}
        draggable={false}
        closable={false}
        blockScroll={true}
        responsive={true}
        resizable={false}
        onHide={() => onClose()}
      >
        <div className='px-6 pb-4'>
          <div className='mt-4'>
            <p className='text-sm mb-2'>7/7</p>
            <Steps totalSteps={7} stepIndex={7} />
          </div>
          <div className='mt-6'>
          {
            Object.keys(socialMediaInfo).map((name, index) => (
              <div key={name}>
                <label>
                  { name }
                </label>
                <input
                  ref={(el) => (inputRefs.current[index] = el)}
                  type='text'
                  key={ name}
                  placeholder={`www.${ name.toLowerCase() }.com/profile/handle`}
                  value={ socialMediaInfo[name] }
                  onInput={(e) => {
                    inputRefs.current[index].style.border='';
                    setSocialMediaInfo((prevData) => ({
                      ...prevData,
                      [name]: e.target.value
                    }));
                  }}
                  className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                />
              </div>
            ))
          }
          </div>
          <div className='flex justify-between items-center mt-6'>
            <div>
              <p
                className='cursor-pointer font-semibold'
                onClick={() => showCreationDialog() }
              >
                Skip all
              </p>
            </div>
            <div className='flex gap-x-4 my-4'>
              <div>
                <BtnTransparent
                  props={{
                    text: 'text-charcoal font-semibold',
                    bg: 'bg-white',
                    border: 'border-charcoal',
                    buttonName: 'Back',
                  }} 
                  handleClick={showPreviousScreen}
                />
              </div>
              <div>
                <BtnTransparent
                  props={{
                    text: 'text-white font-semibold',
                    bg: 'bg-periwinkle',
                    border: 'border-periwinkle',
                    buttonName: 'Confirm',
                  }} 
                  handleClick={submitPresonalization}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header={congratsHeader}
        headerClassName='text-2xl px-8 pt-8 mb-4'
        visible={showAccountCreation}
        dismissableMask={false}
        style={{ marginLeft:"1rem", marginRight:"1rem", width: '45rem', backgroundColor: 'white' }}
        draggable={false}
        closable={false}
        blockScroll={true}
        responsive={true}
        resizable={false}
        onShow={() => {}}
        onHide={() => onClose()}
      >
        <div className='px-8'>
          <div className='text-sm w-full'>
            <div className='pb-4'>
              We are pleased to inform you that your account has been created successfully.
              <br />
              Welcome to our community of achievers and enthusiasts!
            </div>
            <div>
              As a token of appreciation, you&apos;ve earned a $10 bonus. This is our way of expressing
              <br />
              gratitude for choosing WePlan.
            </div>
          </div>
          <div className='flex justify-center lg:justify-end gap-x-4 mb-6 mt-8'>
            <BtnTransparent
              props={{
                text: 'text-white font-semibold w-[177px]',
                bg: 'bg-periwinkle',
                border: 'border-periwinkle',
                buttonName: 'Close',
              }} 
              handleClick={() => onClose()}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default SignupForm;
