import http from '../../common/http';
import Header from '../Header/Header';
import _debounce from 'lodash/debounce';
import filterIcon from 'filter_icon.png';
import RightArrow from 'right_arrow_new.svg';
import HotelCard from '../UIComponents/Cards/HotelCard';
import HotelIndexSkeletonCarousal from '../Skeletons/HotelIndexSkeletonCarousal';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import HotelFilterForm from './HotelFilterForm';
import Paginator from '../UIComponents/Paginator/Paginator';

import { Toast } from 'primereact/toast';
import { useSelector } from 'react-redux';
import { Dialog } from "primereact/dialog";
import { BtnTransparent } from '../UIComponents/buttons';
import { useLocation, useNavigate } from 'react-router-dom';
import AccomodationandFlightInformationForm from '../Forms/AccomodationandFlightInfo';
import Layout from '../Layout/Layout';

const HotelIndex = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const tripPlan = useSelector(state => state.tripPlan.tripPlan);
  const toast = useRef(null);
  const searchParams = new URLSearchParams(location.search);
  const searchInput = useRef(null);

  const [searchText, setSearchText] = useState(null);
  const [recommendedHotels, setRecommendedHotels] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [cityPlan, setCityPlan] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [recommenedationsLoading, setRecommenedationsLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [filterData, setFilterData] = useState({});
  const [isHotelBookedVisible, setHotelBookedVisible] = useState(false);

  const onCloseClick = () => {
    setHotelBookedVisible(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchRecommendedHotels = () => {
    setRecommenedationsLoading(true);
    const params = { city_name: searchParams.get('city') };
    http
      .get('/recommended_hotels', { params: params })
      .then((res) => {
        setRecommendedHotels(res.data.recommenedations);
        setRecommenedationsLoading(false);
      })
      .catch(() => {
        showMessage('error', 'Error', 'There was an error while fetching recommended hotels.', 'bg-red-100 text-red-700');
      });
  };

  const filterExist = (val) => {
    return Object.keys(val).length ? true : false;
  };

  const getFilterData = (val) => {
    return filterExist(val) ? val : null;
  };

  const fetchCityHotels = (val = {}) => {
    let params = {};
    if (filterExist(val) || filterExist(filterData)) {
      const data = getFilterData(val) || getFilterData(filterData);
      setFilterData(data);
      params = { ...data, offset: offset };
    } else {
      params = { city_name: searchParams.get('city'), search_text: searchText, offset: offset };
    }

    setIsLoading(true);
    http
      .get('/hotel', { params: params })
      .then((res) => {
        setHotels(res.data.hotels);
        setIsLoading(false);
        setTotalRecords(res.data.hotels_count);
      })
      .catch(() => {
        showMessage('error', 'Error', 'There was an error while fetching hotels.', 'bg-red-100 text-red-700');
      });
  };

  const fetchFilterOptions = () => {
    http
      .get('/filter_options')
      .then((res) => {
        setFilterOptions(res.data.hotel_filters);
      })
      .catch(() => {
        showMessage('error', 'Error', 'There was an error while fetching hotels.', 'bg-red-100 text-red-700');
      });
  };

  const debouncedHandleSearch = useCallback(_debounce(handleSearch, 600), []);

  function handleSearch(val) {
    const params = { city_name: searchParams.get('city'), search_text: val, offset: offset };
    http
      .get('/hotel', { params: params })
      .then((res) => {
        setTotalRecords(res.data.hotels_count);
        setHotels(res.data.hotels);
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while searching hotels', 'bg-red-100 text-red-700');
      });
  }

  const seeOtherButton = {
    text: 'charcoal font-medium hover:text-white',
    bg: 'bg-transparent hover:bg-black',
    border: 'border-charcoal',
    buttonName: 'Manually add hotel details'
  };

  useEffect(() => {
    const cityName = searchParams.get('city');
    if (tripPlan.length) {
      const currentCityPlan = tripPlan.filter(trip => trip.cityName === cityName)[0];
      setCityPlan(currentCityPlan);
      const [startDateString, endDateString] = currentCityPlan.date.split("/");
      const newStartDate = new Date(startDateString);
      const newEndDate = new Date(endDateString);
      const formattedStartDate = newStartDate.toLocaleDateString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      });
      const formattedEndDate = newEndDate.toLocaleDateString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      });
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
    }
  }, tripPlan);

  useEffect(() => {
    fetchRecommendedHotels();
    fetchCityHotels();
    fetchFilterOptions();
  }, []);

  useEffect(() => {
    fetchCityHotels();
  }, [offset]);

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const itemTemplate = (hotel, index) => {
    return (
      <div
        key={ index }
        className='card bg-white min-w-[324px] max-w-[324px] h-[320px]rounded-lg shadow-md mx-2'
      >
        <HotelCard hotel={hotel} />
      </div>
    );
  };

  const propsForFilter = {
    setShowModal,
    filterOptions,
  };

  const modalContent = isHotelBookedVisible && (
    <div className = { `fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 ${ isHotelBookedVisible ? 'block' : 'hidden' } z-50` }>
      <div className = "bg-white lg:mx-0 mx-2 mt-10 mb-[100px] sm:mb-[0px] sm:max-h-[800px] max-h-[550px] rounded shadow-md overflow-y-auto" >
        <AccomodationandFlightInformationForm 
          isHotelBookedVisible = { isHotelBookedVisible } 
          onCloseClick = { onCloseClick } 
          calledFrom = { "hotelIndex" }
        />
      </div>
    </div>
  );

  return (
  <Layout>
    <div className='flex flex-wrap w-full mb-8'>
      <Toast ref={toast} position="bottom-right" />
      <div className='flex flex-wrap w-full pt-24'>
        <div className='flex flex-col lg:px-16 px-3 lg:py-4 pb-4'>
          <h1 className='font-bold font-sans text-sm cursor-pointer' onClick={() => navigate(-1)}>
            {"< Back"}
          </h1>
          <p className='pt-4 text-base font-normal font-sans'>
            Hotels in
          </p>
          <p className='text-3xl font-normal font-playfair'>
            {cityPlan.cityName}
          </p>
          <span className='text-base font-normal font-sans'>
            {startDate} - {endDate}
            {cityPlan.travelersCount ? (
              <>
                {cityPlan.travelersCount} traveler(s)
              </>
            ) : (
              <></>
            )}
          </span>
        </div>
        <div className='flex flex- items-center w-full bg-semi-trans-purple h-36 lg:my-8'>
          <div className='flex lg:flex-row flex-col items-center justify-center lg:justify-between gap-x-8 lg:px-16 px-3 h-36 lg:h-26 w-full'>
            <div className='flex text-2xl font-playfair font-normal'>
              Did you already book your hotel?
            </div>
            <div className='flex lg:mt-0 mt-6'>
              <BtnTransparent 
                props = { seeOtherButton } 
                handleClick={() => setHotelBookedVisible(true) }
              />  
            </div>
          </div>
          { modalContent }
        </div>
        {
          recommenedationsLoading ? <HotelIndexSkeletonCarousal/> : 
        <div className='flex lg:px-12 px-3 lg:mt-4 mt-16 w-full flex-col'>
          <div className='font-playfair flex text-2xl font-normal'>
            Select from one of our recommended hotels
          </div>
          <div className='sm:px-12 lg:col-span-9 flex sm:flex-wrap sm:gap-y-4 lg:flex-row flex-col mx-auto lg:mx-0'>
            { isMobile && !isLoading && 
              <div className='flex w-full mt-4 px-3 gap-y-5 lg:flex-row flex-col'>
                {
                  recommendedHotels.map((hotel, index) => {
                    return itemTemplate(hotel, index);
                  })
                }
              </div>
            }
            {!isMobile &&
              recommendedHotels?.map((hotel, index) => {
                return (
                  <div key={index} className={'w-full sm:1/2 lg:w-1/4 px-4 mt-4'}>
                    <HotelCard hotel={hotel}/>
                  </div>
                );
              })
            }
          </div>
        </div>
        }
        <div className='flex w-full lg:flex-row flex-col justify-between lg:px-16 px-3 py-4 '>
          <div className='flex items-center font-playfair text-2xl font-normal'>
            Browse hotels
          </div>
          <div className={`flex  items-center lg:gap-x-6 ${isMobile ? "gap-x-4 mt-2" : "justify-end"}`}>
            {
              isMobile ?
                <div
                  className='flex'
                  onClick={() => setShowModal(true)}
                >
                  <img
                    src={filterIcon}
                    width="26"
                    height="18"
                    alt='filter icon'
                  />
                </div> :
                <></>
            }
              <div className={`relative flex-1 w-full ${ isMobile ? "mr-2" : "mr-6" }`}>
                <input
                  type='text'
                  key='email'
                  placeholder='Search for a hotel'
                  value={searchText}
                  ref={searchInput}
                  onInput={(e) => {
                    setSearchText(e.target.value);
                    debouncedHandleSearch(e.target.value);
                  }}
                  className={`form-control-wrapper lg:w-[615px] w-full mt-1 background border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                />
                {
                  !isMobile ?
                  <div className="bg-periwinkle h-10 w-16 p-4 mt-1 flex justify-center rounded-md absolute right-0 top-0">
                    <img src={RightArrow} alt="" />
                  </div> :
                  <></>
                }
              </div>
          </div>
        </div>


        {isLoading ? <HotelIndexSkeletonCarousal /> : <></>}
        {isMobile && !isLoading && <div className='flex w-full mt-4 px-3 gap-y-5 lg:flex-row flex-col items-center'>
        {
            hotels.map((hotel, index) => {
             return itemTemplate(hotel, index);
            })
        }
        </div>}
        {!isMobile && (
          <div className="flex flex-col w-full">
            <div className="flex gap-x-10 w-full">
              <div className="lg:pl-12 pl-3 w-1/4">
                { filterExist(filterOptions) && 
                  <HotelFilterForm
                    props={ propsForFilter }
                    handleFilter={ fetchCityHotels }
                  />
                }
              </div>
              <div className="w-3/4 px-3 lg:col-span-9 sm:col-span-12 flex flex-wrap gap-y-4">
                {hotels?.map((hotel, index) => (
                  <div key={index} className="lg:mx-2 mx-0 lg:w-1/4">
                    <HotelCard hotel={hotel} />
                  </div>
                ))}
              </div>
            </div>
            <div className='mt-6'>
              <Paginator
                rows={ 8 }
                totalRecords={ totalRecords }
                first={ offset }
                onPageChange={ (e) => setOffset(e.first) }
                isMobile = { isMobile }
              />
            </div>
          </div>
        )}
      </div>
      {
        showModal &&
        <Dialog
          modal
          showHeader={false}
          closable={true}
          className="p-2"
          visible={showModal}
          dismissableMask={true}
          contentStyle={{ padding: 0 }}
          onHide={() => setShowModal(false)}
        >
          {<HotelFilterForm props={propsForFilter} />}
        </Dialog>
      }
    </div>
  </Layout>
  );
};

export default HotelIndex;
