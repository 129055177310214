import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  topPriorities: [],
  priorityOptions: []
};
export const prioritiesSlice = createSlice({
  name: "priorities",
  initialState,
  reducers: {
    setPriorities: (state, action) => {
      state.topPriorities = action.payload;
    },
    setPriorityOptions: (state, action) => {
      state.priorityOptions = action.payload;
    },
  },
});

export const { setPriorities, setPriorityOptions } = prioritiesSlice.actions;

export default prioritiesSlice.reducer;
