import './RewardsAndBilling.scss';
import http from './../../common/http';

import { grey } from '@mui/material/colors';
import { Toast } from 'primereact/toast';
import { object, string } from 'yup';
import { BtnTransparent } from '../UIComponents/buttons';
import { FormControlLabel, Checkbox } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { cardnumberRegex, nameonCardRegex, cardsecuritycodeRegex } from '../../utils/helpers/string';
import { getUserId } from '../../common/auth';
import loadingSpinner from 'loader.png';
import RewardHistoryCarousel from './RewardHistoryCarousel';

const RewardsAndBilling = () => {
  const userId=getUserId();
  const toast = useRef(null);
  const [rewardHistory, setRewardHistory] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [enableButton, setButtonEnable] = useState(false);
  const [isLoadingWallet, setIsLoadingWallet] = useState(false);
  const [walletInfo,setWalletInfo]=useState({
    available_balance: 0,
    created_at: "",
    total_balance: 0,
    updated_at: "",
    user_id:"" 
  });
  const [cardInfo, setCardInfo] = useState({
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    postal_code: '',
    name: '',
    card_number: '',
    encrypted_security_code: '',
    default_payment_method: false,
    expiration_month: '',
    expiration_year: '',
  });

  useEffect(()=>{
    if (userId) {
      fetchWalletInfo();
    }
  },[]);

  const fetchWalletInfo = () => {
    http
      .get('/credit_card/information')
      .then((res) => {
        if (res.data) {
          setIsLoadingWallet(true);
          setWalletInfo(res.data.user_wallet);
          setRewardHistory(res.data.reward_history);
        }
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching Wallet information', 'bg-red-100 text-red-700');
      });
  };

  const handleCardInput = (fieldName, newValue) => {
    setButtonEnable(true);
    setCardInfo((prevData) => ({
      ...prevData,
      [fieldName]: newValue,
    }));
  };

  const rewardAndBillingSchema = object({
    card_info: object().shape({
      address_line_1: string().required('Address is required.'),
      city: string().required('City is required.').test({
        name: 'city-validation',
        test: function(value) {
          if (/\d/.test(value)) return false;
          return true;
        },
        message: 'City cannot contain numbers.',
      }),
      state: string().required('State is required.'),
      postal_code: string().required('Postal Code is required.').test({
        name: 'postal-code-validation',
        test: function(value) {
          if (/[a-zA-Z]/.test(value)) return false;
          return true;
        },
        message: 'Postal Code cannot contain letters.',
      }),
      name: string().required('Name on card is required.').test({
        name: 'name-on-card-validation',
        test: function (value) {
          if (!this.parent.name) return true;
          return nameonCardRegex.test(value);
        },
        message: 'Invalid Name on Card.'
      }),
      card_number: string().required('Card number is required.').test({
        name: 'card-number-validation',
        test: function (value) {
          if (!this.parent.card_number) return true;
          return cardnumberRegex.test(value);
        },
        message: 'Invalid card number.'
      }),
      expiration_month: string().required('Expiration month is required.'),
      expiration_year: string().required('Expiration year is required.'),
      encrypted_security_code: string().required('Security code of credit card is required.').test({
        name: 'security-code-validation',
        test: function (value) {
          if (!this.parent.encrypted_security_code) return true;
          return cardsecuritycodeRegex.test(value);
        },
        message: 'Invalid Security Code.'
      }),
    })
  });

  const fetchCardInfo = () => {
    http
      .get('/credit_card.json')
      .then((res) => {
        if (res.data) {
          setCardInfo(res.data);
        }
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching card information', 'bg-red-100 text-red-700');
      });
  };

  const updateCardInfo = async () => {
    try {
      const params = { card_info: cardInfo };
      await rewardAndBillingSchema.validate(params, { abortEarly: false });

      http
        .put('/update_credit_card', params)
        .then((res) => {
          setButtonEnable(false);
          setCardInfo(res.data);
          showMessage('success', 'Success', 'Information updated successfully.', 'bg-teal-100 text-teal-500');
        })
        .catch(() => {
          showMessage('error', 'Error', 'Sorry, there was an error while updating card info.', 'bg-red-100 text-red-700');
        });
    } catch (error) {
      if (error.inner) {
        const errorMessages = error.inner.map((e) => e.message);

        showMessage('error', 'Error', (
          <ul>
            {errorMessages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        ), 'bg-red-100 text-red-700');
      }
    }
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  useEffect(() => {
    fetchCardInfo();
  }, []);

  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  return (
    <div>
      <Toast ref={toast} position='bottom-right' />
      <div className='flex justify-between'>
        <div className='text-2xl font-normal mb-12 font-playfair'>Rewards and billing</div>
      </div>
      <div className='font-bold mb-2 text-base'>
        Rewards
      </div>
      <div className='flex flex-col'>
        <div className='flex gap-2 items-center text-base font-normal'>
          <span className='mr-4'>Available reward credit:</span>
            {isLoadingWallet ? 
              <span className='text-green-600 font-bold text-xl'>$ { walletInfo.available_balance }</span>:
                <img
                  src={ loadingSpinner }
                  alt="spinner"
                  className='w-[21px] h-[23px] animate-spin'
                />
              }
        </div>
        <ul className="list-disc ml-5 text-light-black text-sm">
            <li>Minimum spend for credit usage is $500 and max that can be used would be 20% off</li>
            <li>You can only have a max of $300 in travel credit until you use it</li>
        </ul>
      </div>
      <div className='my-4 cursor-pointer'>
        <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <AccordionTab header="Reward history">
            { rewardHistory.length ? (
              <div className='w-[280px] sm:w-[670px]'>
                <RewardHistoryCarousel rewardHistoryData = {rewardHistory}/>
              </div> ):
            ( <p className='mt-2'>No record</p>) }
          </AccordionTab>
        </Accordion>
      </div>
      <hr className='mt-8 mb-4'/>
      <div>
        <div className='flex justify-between'>
          <div className='font-bold mb-2 text-base font-monsterrat'>Billing address</div>
          {!isMobile && <div className='w-[185px] h-[40px]'>
            <BtnTransparent
              props={{
                text: 'text-white font-semibold w-full h-full',
                bg: 'bg-periwinkle',
                border: 'border-periwinkle',
                buttonName: 'Save changes',
              }}
              disabled={!enableButton}
              handleClick={updateCardInfo}
            />
          </div>}
        </div>
        <div className='mb-8'>
          <div className='w-full'>
            <label className='text-sm my-4'>
              Street Address*
            </label>
            <input
              type="text"
              key='address1'
              value={cardInfo.address_line_1}
              placeholder='Address line 1'
              className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              onChange={(e) => handleCardInput('address_line_1', e.target.value)}
            />
            <input
              type="text"
              key='address2'
              value={cardInfo.address_line_2}
              placeholder='Address line 2'
              className={`form-control-wrapper mt-4 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              onChange={(e) => handleCardInput('address_line_2', e.target.value)}
            />
          </div>
          <div className='flex justify-between mt-4'>
            <div className='w-[30%]'>
              <label className='text-sm my-4'>
                City*
              </label>
              <input
                type="text"
                key='city'
                value={cardInfo.city}
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                onChange={(e) => handleCardInput('city', e.target.value)}
              />
            </div>
            <div className='w-[30%]'>
              <label className='text-sm my-4'>
                State*
              </label>
              <input
                type="text"
                key='state'
                value={cardInfo.state}
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                onChange={(e) => handleCardInput('state', e.target.value)}
              />
            </div>
            <div className='w-[30%]'>
              <label className='text-sm my-4'>
                Postal code*
              </label>
              <input
                type="text"
                key='postalCode'
                value={cardInfo.postal_code}
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                onChange={(e) => handleCardInput('postal_code', e.target.value)}
              />
            </div>
          </div>
        </div>
        <div>
          <div className='flex justify-between'>
            <div className='font-bold text-base font-monsterrat'>Card Information</div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={cardInfo.default_payment_method}
                    onChange={() => handleCardInput('default_payment_method', !cardInfo.default_payment_method)}
                    sx={{
                      '&.Mui-checked': {
                      color: grey[900],
                    }
                  }}/>
                }
                label='Default Payment Method'
                classes='text-sm'
              />
            </div>
          </div>
          <div className='w-full my-2'>
            <label className='text-sm my-4'>
              Name on card*
            </label>
            <input
              type="text"
              key='nameOnCard'
              value={cardInfo.name}
              className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              onChange={(e) => handleCardInput('name', e.target.value)}
            />
          </div>
          {!isMobile && <div className='flex justify-between mb-4'>
            <div className='w-[50%]'>
              <label className='text-sm my-4'>
                Card number*
              </label>
              <input
                type="text"
                key='cardNumber'
                value={cardInfo.card_number}
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                onChange={(e) => handleCardInput('card_number', e.target.value)}
              />
            </div>
            <div className='w-[20%]'>
              <label className='text-sm my-4'>
                Expiration date*
              </label>
              <select
                key='expirationMonth'
                value={cardInfo.expiration_month}
                onChange={(e) => handleCardInput('expiration_month', e.target.value)}
                className="form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2"
              >
                {!cardInfo.expiration_month && <option value="" disabled>Month</option>}
                {Array.from({ length: 12 }, (_, index) => {
                  return <option key={index} value={index+1}>{index+1}</option>;
                })}
              </select>
            </div>
            <div className='w-[20%]'>
              <select
                key='expirationYear'
                value={cardInfo.expiration_year}
                onChange={(e) => handleCardInput('expiration_year', e.target.value)}
                className="form-control-wrapper mt-6 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2"
              >
                {!cardInfo.expiration_year && <option value="" disabled>Year*</option>}
                {Array.from({ length: 21 }, (_, index) => {
                  const currentYear = new Date().getFullYear();
                  const displayYear = currentYear + index;
                  return <option key={displayYear} value={displayYear}>{displayYear}</option>;
                })}
              </select>
            </div>
          </div>}
          {isMobile && <div className='flex justify-between flex-col mb-4'>
            <div className='flex w-full flex-col'>
              <label className='text-sm my-4'>
                Card number*
              </label>
              <input
                type="text"
                key='cardNumber'
                value={cardInfo.card_number}
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                onChange={(e) => handleCardInput('card_number', e.target.value)}
              />
            </div>
            <div className='flex flex-col'>
              <div className='flex w-full flex-col'>
                <label className='text-sm mt-4'>
                  Expiration date*
                </label>
                <div className='flex w-full flex-row items-end'>
                  <select
                    key='expirationMonth'
                    value={cardInfo.expiration_month}
                    onChange={(e) => handleCardInput('expiration_month', e.target.value)}
                    className="form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2"
                  >
                    {!cardInfo.expiration_month && <option value="" disabled>Month</option>}
                    {Array.from({ length: 12 }, (_, index) => {
                      return <option key={index} value={index+1}>{index+1}</option>;
                    })}
                  </select>
                  <select
                    key='expirationYear'
                    value={cardInfo.expiration_year}
                    onChange={(e) => handleCardInput('expiration_year', e.target.value)}
                    className="form-control-wrapper mt-6 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2"
                    >
                    {!cardInfo.expiration_year && <option value="" disabled>Year*</option>}
                    {Array.from({ length: 21 }, (_, index) => {
                      const currentYear = new Date().getFullYear();
                      const displayYear = currentYear + index;
                      return <option key={displayYear} value={displayYear}>{displayYear}</option>;
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>}
          <div>
            <div className='lg:w-[20%] w-1/2'>
              <label className='text-sm my-4'>
                Security code*
              </label>
              <input
                type="text"
                key='securityCode'
                value={cardInfo.encrypted_security_code}
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                onChange={(e) => handleCardInput('encrypted_security_code', e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      {isMobile && <div className='flex w-full mt-4 h-[40px]'>
        <BtnTransparent
          props={{
            text: 'text-white font-semibold w-full h-full',
            bg: 'bg-periwinkle',
            border: 'border-periwinkle',
            buttonName: 'Save changes',
          }}
          disabled={!enableButton}
          handleClick={updateCardInfo}
        />
      </div>}
    </div>
  );
};

export default RewardsAndBilling;
