import React from 'react';
import dateLogo from 'calender';
import warninglogo from 'warning.svg';
import destinationLogo from 'destination';
import warningred from 'wariningred.png';
import warcingicon from 'warningicon1';

import { Dialog } from 'primereact/dialog';
import { BtnTransparent } from '../buttons';
import { useNavigate } from 'react-router-dom';

const customAlertData = {
  emoji : {
    title: 'Emojis are not selected!',
    paragraph: 'if you don’t select satisfaction level, our system won’t be able to do it’s best in generating an itinerary',
  },
  savePlan : {
    title: 'Would you like to save your itinerary',
    paragraph: 'Save your itinerary before you go! If you do not save your itinerary you will lose all of your data. You can access your itinerary anytime from your trips.',
  },
  change: {
    logo: warningred,
    title: 'Exit page without generating itinerary?',
    paragraph: 'Your itinerary has not been generated. Are you sure you would like to exit this page?',
  },
  price: {
    logo: warcingicon,
    title: 'The price for your selected dates has been updated.',
    paragraph: <div className='flex flex-col gap-2'><h1>New Price : <span className='font-bold'>$ 300/night</span></h1> <h1>Old Price : <span className='font-bold'>$ 400/night</span></h1><p>To proceed with the updated price, please confirm by clicking 'Confirm.' If you have any questions or need assistance, click 'Discard’ to contact our support team.</p></div>,
  },
  profile: {
    logo: warninglogo,
    title: 'Exit page without saving profile info?',
    paragraph: 'Your profile has not been updated. Are you sure you would like to exit this page?',
  },
  date: {
    logo: dateLogo,
    title: 'To ensure the most relevant itinerary, please select your preferred dates.',
    paragraph: 'If no specific dates are chosen, our AI system will suggest the optimal dates for your visit based on availability and preferences.',
  },
  destination: {
    logo: destinationLogo,
    title: 'To assist you in finding the perfect destination, please enter your preferred city or explore our inspiration flow.',
    paragraph: 'If you\'re unsure or seeking inspiration, click \'Explore Inspiration Flow\'. Our AI-powered system will suggest incredible destinations based on your preferences and interests.',
  },
  budget: {
    title: 'Budgeting Feature Notice',
    paragraph: 'We\'re excited to introduce our new budgeting feature! Please note that this feature is experimental, and the real cost may vary from the assumed cost. However, we believe this tool will provide you with a helpful estimate of your expenses. Happy budgeting!',
  },
  localExpert: {
    title: 'Thank you for submitting your feedback',
    paragraph: 'Your views will be evaluated by our internal team and if we find it fruitful, we will put credit in your account',
  },
};

const CustomModal = ({ isVisible, setVisible, type, buttons, isMobile }) => {
  const { title = '', paragraph = '', logo = '' } = customAlertData[type] || {};
  const navigate = useNavigate();

  return (
    <Dialog
      visible={isVisible}
      className={`${isMobile ? 'w-[280px]' : `${ type == "savePlan" ? "w-[800px]" : "" } ${ type == "change" ? "w-[620px]" : "w-[570px]" }` } ${isMobile ? 'p-4' : ''}`}
      setVisible={setVisible}
      onHide={() => { 
        setVisible(false);
        if (type === "localExpert") {
          navigate("/");
        }
      }}
      style={{ backgroundColor: "#ffffff", padding: isMobile ? '8px' : '30px' }}
      header= {title && (
        <div className={`flex ${isMobile ? 'px-3' : ''} pr-4`}>
          {logo && <div className={`w-[75px] flex-shrink-0 ${isMobile ? 'pt-2 m-auto h-[75px]' : 'h-[100px]'}`}>
            <img src={logo} className="w-[55px] h-[55px]" alt='Destination logo'/>
          </div>}
          <p className={`${isMobile ? 'text-md pt-3' : 'text-2xl'} font-playfair`}>
            {title}
          </p>
        </div>
      )}
      footer={
        <div className={`flex ${buttons.length > 1 ? `${ type == "savePlan" ? "w-full justify-end gap-2" : "justify-between items-center" }` : 'justify-center' } ${isMobile ? 'flex-col' : `${type=="change"?"w-full gap-2":"w-[510px]"}`} ${isMobile ? 'p-3' : ''}`}>
          {buttons?.map((button, index) =>
            <BtnTransparent
              key={index}
              props={{
                buttonName: button.text,
                bg: index%2 === 1 ? 'bg-periwinkle' : '',
                border: index%2 === 0 ? `${type=="change"?'border-black':'border-periwinkle'}` : 'border-periwinkle',
                text: index%2 === 1 ? `text-white mt-4 h-[40px] font-semibold ${ type == "change" ? 'w-[260px]' : 'w-[246px]' }` : `mt-4 h-[40px] font-semibold ${ type == "change" ? 'w-full h-auto lg:h-[40px] text-black': `${ type == "savePlan" ? "text-black bg-transparent border-transparent" : "w-[246px] text-periwinkle" }` }`,
              }}
              disabled={button?.disabled}
              handleClick={button.onPress}
            />
          )}
        </div>
      }
    >
      <p className={`text-l pt-5 pb-4 text-gray-900 ${isMobile ? 'text-sm px-3' : ''}`}>
        {paragraph}
      </p>
    </Dialog>
  );
};

CustomModal.defaultProps = {
  type: "",
  logo: null,
  button: {},
  isMobile: false,
  isVisible: false,
  onHide: () => {},
};

export default CustomModal;
