import React, { useState, useEffect, useCallback , useRef } from 'react';
import { Rating } from 'primereact/rating';
import SingleSelectDate from './UIComponents/Dates/SingleSelectDate';
import { InputNumber } from 'primereact/inputnumber';
import { selectStyle } from './UIComponents/Style';
import LocationDetail from './LocationDetail';
import http from './../common/http';
import { setHotelBooking } from '../redux/slices/hotelBooking';
import { useDispatch, useSelector } from 'react-redux';
import { BtnTransparent } from './UIComponents/buttons';
import { useNavigate } from 'react-router-dom';
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router-dom';
import loadingSpinner from 'loader.png';
import PlaceholderSkeleton from './Skeletons/PlaceholderSkeleton';
import PriceMatch from './PriceMatch/PriceMatch';
import TripPlanCarousel from './TripPlanCarousel';
import SingleCarousalSkeleton from './Skeletons/SingleCarousalSkeleton';
import { autoOpenCalendarDate } from '../common/shared/calendarEventsFunctions';
import Footer from './Footer/Footer';

export default function DetailPage() {
  const toast = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tripPlan = useSelector(state => state.tripPlan.tripPlan);
  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');
  const [travelers, setTravelers] = useState(1);
  const [hotel, setHotel] = useState({});
  const [roomOffers, setRoomOffers] = useState([]);
  const bookingInfo = useSelector(state => state.hotelBooking.hotelBooking);
  const [, setMap] = useState('');
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [isLoading, setLoading] = useState(false);
  const [images, setHotelImages] = useState([]);
  const { id } = useParams();
  const [isMobile, setIsMobile] = useState(false);
  const [isPriceMatchVisible, setIsPriceMatchVisible] = useState(false);
  const [componentRendered, setComponentRendered] = useState(false);
  const [city, setCity] = useState({});
  const [cards, setCards] = useState(4);
  const [cityLoading, setCityLoading] = useState(false);
  const endDateRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setCards(window.innerWidth <= 768 ? 1 : 4);
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCheckInDate = (value) => {
    autoOpenCalendarDate(checkOut, endDateRef);
    setCheckIn(value);
  };

  const handleCheckOutDate = (value) => {
    setCheckOut(value);
  };

  const getTripDates = (hotelId, cityName) => {
    if (tripPlan.length > 0 && hotelId) {
      const trip = tripPlan.filter(trip => trip.cityName == cityName)[0];
      setTravelers(trip.travelersCount || '1');
      const [startDateString, endDateString] = trip.date.split("/");
      const newStartDate = new Date(startDateString);
      const newEndDate = new Date(endDateString);

      const startDayValue = String(newStartDate.getDate()).padStart(2, "0");
      const startMonth = String(newStartDate.getMonth() + 1).padStart(2, "0");
      const startYear = String(newStartDate.getFullYear());
      setCheckIn(`${startMonth}-${startDayValue}-${startYear}`);

      const endDayValue = String(newEndDate.getDate()).padStart(2, "0");
      const endMonth = String(newEndDate.getMonth() + 1).padStart(2, "0");
      const endYear = String(newStartDate.getFullYear());
      setCheckOut(`${endMonth}-${endDayValue}-${endYear}`);
    } else {
      const date = new Date();
      let nextDay = new Date(date);
      nextDay.setDate(date.getDate() + 1);
      const startDayValue = String(date.getDate()).padStart(2, "0");
      const startMonth = String(date.getMonth() + 1).padStart(2, "0");
      const startYear = String(date.getFullYear());
      setCheckIn(`${startMonth}-${startDayValue}-${startYear}`);

      const endDayValue = String(nextDay.getDate()).padStart(2, "0");
      const endMonth = String(nextDay.getMonth() + 1).padStart(2, "0");
      const endYear = String(nextDay.getFullYear());
      setCheckOut(`${endMonth}-${endDayValue}-${endYear}`);
    }
  };

  const imageCss = 'w-full h-full object-cover';

  const getCityDetails = (cityName) => {
    const params = {
      city_name: cityName,
    };
    http
    .get('/city_detail', { params })
    .then((res) => {
      const cityData = res.data.city;
      setCity(cityData);
    })
    .catch(() => {});
};

  const fetchHotelDetail = () => {
    setCityLoading(true);
    http
      .get(`/hotel/${id}`)
      .then((res) => {
        setHotel(res.data.hotel);
        getTripDates(res.data.hotel.id, res.data.city_name);
        getCityDetails(res.data.city_name);
        setCityLoading(false);
        const additionalDetail = res.data.hotel.additional_detail;
        setHotelImages(res.data.images);
        setCenter({ lat: additionalDetail.latitude, lng: additionalDetail.longitude });
        setComponentRendered(true);
        dispatch(setHotelBooking({
          ...bookingInfo,
          'checkIn': checkIn,
          'checkOut': checkOut,
          'hotel': res.data.hotel,
          'cityInfo': { cityId: hotel.city_id, hotelName: hotel.name }
        }));
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching hotel detail.', 'bg-red-100 text-red-700');
      });
  };

  const fetchHotelOffers = () => {
    if (!checkIn || !checkOut || !travelers) {
      showMessage('error', 'Error', "Checkin, checkout and travelers count is required.", 'bg-red-100 text-red-700');
      return;
    }
    setLoading(true);
    const params = {
      id: hotel.additional_detail.api_place_id,
      checkin: checkIn,
      checkout: checkOut,
      travelers: travelers,
    };
    http
      .get(`/hotel_offers/`, { params: params })
      .then((res) => {
        setRoomOffers(res.data);
        dispatch(setHotelBooking({
          ...bookingInfo,
          'checkIn': checkIn,
          'checkOut': checkOut,
          'offers': res.data,
          'cityInfo': { cityId: hotel.city_id, hotelName: hotel.name }
        }));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setRoomOffers([]);
      });
  };

  const setStoreData = () => {
    dispatch(setHotelBooking({
      'hotel': hotel,
      'offers': roomOffers,
      'checkIn': checkIn,
      'checkOut': checkOut,
      'travelers': travelers,
      'cityInfo': { cityId: hotel.city_id, hotelName: hotel.name }

    }));
  };

  useEffect(() => {
    fetchHotelDetail();
  }, [id]);

  useEffect(() => {
    if (componentRendered) {
      fetchHotelOffers();
    }
  }, [componentRendered]);

  const { isLoaded } = useJsApiLoader({
    id: 'we-plan-google-map-script',
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
  });

  const containerStyle = {
    width: '100%',
    height: '100%'
  };

  const onLoad = useCallback((map) => {
    map.setZoom(12);
    map.setCenter(center);
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const showMessage = (severity, summary, detail, style) => {
    toast.current?.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  return isLoaded ? (
    <>
      <div className='flex flex-col flex-wrap w-ful lg:px-10 px-3'>
        <Toast ref={toast} position='bottom-right' />
        <div className='flex w-full'>
          <p
            className='font-bold py-6 cursor-pointer text-sm'
            onClick={() => navigate(-1) }
          >
            { '< Back' }
          </p>
        </div>
        <div className='flex justify-between w-full lg:flex-row flex-col space-y-2'>
          <div className='flex-row'>
            <div className='text-3xl font-playfair'> { hotel.name } </div>
            <div className='flex gap-x-2'>
              <Rating
                value={ hotel.additional_detail?.rating }
                cancel={ false }
                className='text-yellow-500'
              />
            </div>
          </div>
          {roomOffers.length > 0 &&
            <BtnTransparent
              props={ {
                bg: 'bg-white',
                border: 'border-periwinkle',
                buttonName: 'Request price match',
                text: 'text-periwinkle font-semibold'
              } }
              handleClick={ () => setIsPriceMatchVisible(true) }
            />
          }
        </div>

        {isMobile && <div className='flex flex-wrap mt-2'>
          { images[0] ?
            <img
              className={ imageCss }
              src={ images[0] }
              alt='Image 1'
            /> :
            <PlaceholderSkeleton applyClass = {'bg-light-gray w-full h-[350px]'} />

          }
        </div>}

        {!isMobile && <div className='flex flex-wrap grid grid-cols-4 mt-4 row-span-2'>
          <div className='mb-2 col-span-2 row-span-2 lg:w-[674px] lg:h-[550px]'>
            { images[0] ?
                <img
                  className={ imageCss }
                  src={ images[0] }
                  alt='Image 1'
                /> :
                <PlaceholderSkeleton applyClass = { 'bg-light-gray lg:h-[550px]' } />
            }
          </div>

          <div className='mb-2 mr-4 col-span-1 row-span-1 lg:w-[337px] lg:h-[266px]'>
          { images[1] ? 
              <img
                className={ imageCss }
                src={ images[1] }
                alt='Image 1'
              /> :
              <PlaceholderSkeleton applyClass = { 'bg-light-gray h-[266px]' } />

          }
          </div>

          <div className='mb-2 mr-4 col-span-1 row-span-1 lg:w-[337px] lg:h-[266px]'>
          { images[2] ? 
              <img
                className={ imageCss }
                src={ images[2] }
                alt='Image 1'
              /> :
              <PlaceholderSkeleton applyClass = { 'bg-light-gray h-[266px]' } />

          }
          </div>
          <div className='mb-2 mr-4 col-span-1 row-span-1 lg:w-[337px] lg:h-[266px]'>
          { images[3] ? 
              <img
                className={ imageCss }
                src={ images[3] }
                alt='Image 1'
              /> :
              <PlaceholderSkeleton applyClass = { 'bg-light-gray h-[266px]' } />

          }
          </div>
          <div className='mb-2 mr-4 col-span-1 row-span-1 lg:w-[337px] lg:h-[266px]'>
          { images[4] ? 
              <img
                className={ imageCss }
                src={ images[4] }
                alt='Image 1'
              /> :
              <PlaceholderSkeleton applyClass = { 'bg-light-gray h-[266px]' } />

          }
          </div>
        </div>}

        <div className='flex w-full flex-wrap lg:grid grid-cols-2 gap-2 lg:mt-16 mt-4'>
          <div className='flex flew-wrap'>
            <div>
              <p className='text-2xl font-playfair'>Overview</p>
              <p className='text-base pr-16 py-2 font-sans'>
                { hotel?.additional_detail?.description }
              </p>
            </div>
          </div>

          <div className='col-span-1 h-[496px] w-[711px]'>
            <GoogleMap
              mapContainerStyle={ containerStyle }
              center={ center }
              onLoad={ onLoad }
              onUnmount={ onUnmount }
            >
              <MarkerF position={ center }/>
            </GoogleMap>
          </div>
        </div>

        <div className='flex flex-wrap w-full'>
          <div className='grid grid-cols-4 mt-4 row-span-2'>

          {!isMobile && <div className='mb-2 col-span-2 row-span-2 text-2xl font-playfair font-normal flex lg:items-end items:start'>
              Rooms
            </div> }

            {isMobile && <div className='mb-2 text-2xl font-playfair font-normal flex lg:items-end items:start'>
              Rooms
            </div> }
            <div className='flex sm:mt-0 mt-9 lg:flex-row flex-col mb-2 col-span-2 row-span-2'>
              <div className='w-full mr-3'>
                <label className='text-sm'>Check in</label>
                <small className='flex text-xs text-gray-500'>Enter hotel check in date</small>
                <SingleSelectDate
                  date={ checkIn }
                  minDate={ new Date() }
                  selectedDate={ checkIn }
                  handleDateChange={ handleCheckInDate }
                />
              </div>

              <div className='w-full mr-3'>
                <label className='text-sm'>Check out</label>
                <small className='flex text-xs text-gray-500'>Enter hotel check out date</small>
                <SingleSelectDate
                  ref={ endDateRef }
                  date={ checkOut }
                  minDate={ new Date() }
                  selectedDate={ checkOut }
                  handleDateChange={ handleCheckOutDate }
                />
              </div>

              <div className='w-full mr-3'>
                <label className='text-sm'>Travelers</label>
                <div className='mt-4'>
                  <InputNumber
                    value={ travelers }
                    inputClassName={ selectStyle }
                    onValueChange={ (e) => setTravelers(e.value) }
                  />
                </div>
              </div>
              <div className='w-full items-end'>
                <div className='pt-8 mt-4'>
                  <BtnTransparent
                    props={{
                      text: 'text-white w-full h-[40px] font-semibold',
                      bg: 'bg-periwinkle',
                      buttonName: 'Submit',
                      border: 'border-periwinkle'
                    }}
                    handleClick={fetchHotelOffers}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-wrap'>
          {
            isLoading ? (
              <img
                src={ loadingSpinner }
                alt="spinner"
                className='w-[21px] h-[23px] animate-spin'
              />
              ) : (
              <div className='flex w-full grid lg:grid-cols-3 grid-cols-1'>
                {
                  roomOffers.length ?
                  roomOffers.map((offer, idx) => {
                    return (
                      <div
                        key={idx}
                        className='mx-2'  //'w-[421px] h-[382px]'
                        onClick={() => setStoreData()}
                      >
                        <LocationDetail
                          props={{
                            ...hotel,
                            isHotelRoom: true,
                            imageUrl: images[0],
                            offer: offer,
                            checkIn: checkIn,
                            checkOut: checkOut,
                            travelers: travelers
                          }}
                        />
                      </div>
                    );
                  })
                  :
                  <div>There are no rooms available that meet the specified criteria at this moment.</div>
                }
              </div>
            )
          }
        </div>
        <PriceMatch
          hotel = { hotel }
          roomOffers = { roomOffers }
          isPriceMatchFormVisible = { isPriceMatchVisible }
          setIsPriceMatchFormVisible = { setIsPriceMatchVisible }
        />
        {
          !cityLoading && Object.keys(city).length ? (
          <TripPlanCarousel
            cardsToDisplay={cards}
            showCarousalsNames={ ["city_hotels"] }
            cityNames={ [city.cityName] }
          />
          ) : (
          <SingleCarousalSkeleton />
          )
        }
      </div>
        <Footer isMobile = { isMobile } />
    </>
  ) : <></> ;
}
