import React, { useRef, useState } from 'react';
import { BtnTransparent } from '../UIComponents/buttons';
import { Toast } from 'primereact/toast';


import http from '../../common/http';
import AccForm from './AccForm';
import FlightForm from './FlightForm';
import CustomModal from '../UIComponents/Modal/customModal';

const AccomodationandFlightInformationForm = ({ isHotelBookedVisible, isFlightBookedVisible, onCloseClick, calledFrom }) => {
  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  const toast = useRef(null);
  const [hotelData, setHotelData] = useState({ type: 'Hotel' });
  const [flightData, setFlightData] = useState({});
  const hotelRef = useRef(null);
  const checkInDateRef = useRef(null);
  const checkOutDateRef = useRef(null);
  const priceRef = useRef(null);
  const priceRefFlight = useRef(null);
  const addressRef = useRef(null);
  const airlineRef = useRef(null);
  const flightNumberRef = useRef(null);
  const outboundAirportRef = useRef(null);
  const inboundAirportRef = useRef(null);
  const arrivalTimeRef = useRef(null);
  const departureTimeRef = useRef(null);
  const redBox = "2px solid red";
  const [isVisible, setVisible] = useState(false);
  const [modalType, setModalType] = useState('');

  const buttons = {
    emoji: [
      {
        text: 'Cancel',
        onPress: () => setVisible(false)
      },
      {
        text: 'Continue',
        onPress: () => {
          saveInformation(false);
          // setVisible(false);
        }
      },
    ],
  };

  const hotelLookUpVisible = () => {
    if (hotelData.type == "Hotel") {
      return true;
    } else {
      return false;
    }
  };

  const formValidate = () => {
      if (isHotelBookedVisible && isFlightBookedVisible) {
        let isHotelFormCorrect = hotelDataFormValidation();
        let isFlightFormCorrect = flightDataFormValidation();
        if (isHotelFormCorrect && isFlightFormCorrect) {
          return true;
        } else {
          return false;
        }
      } else if (isHotelBookedVisible) {
        if (hotelDataFormValidation()) {
          return true;
        } else {
          return false;
        }
      } else if (isFlightBookedVisible) {
        if(flightDataFormValidation()) {
          return true;
        } else {
          return false;
        }
      }
  };


  const flightDataFormValidation = () => {
      if (!flightData.airline || !flightData.flight_number || !flightData.outbound_airport || !flightData.inbound_airport || !flightData.arrival_time || !flightData.departure_time || !flightData.price) {
        if (!flightData.airline) {
          airlineRef.current.style.border = redBox;
        }
        if (!flightData.flight_number) {
          flightNumberRef.current.style.border = redBox;
        }
        if (!flightData.airline) {
          airlineRef.current.style.border = redBox;
        }
        if (!flightData.outbound_airport) {
          outboundAirportRef.current.style.border = redBox;
        }
        if (!flightData.inbound_airport) {
          inboundAirportRef.current.style.border = redBox;
        }
        if (!flightData.arrival_time) {
          arrivalTimeRef.current.getElement().querySelector('input').style.border = redBox; 
        }
        if (!flightData.departure_time) {
          departureTimeRef.current.getElement().querySelector('input').style.border = redBox; 
        }
        if (!flightData.price) {
          priceRefFlight.current.style.border = redBox; 
        }
        return false;
      } else {
        return true;
      }
  };
  
  const hotelDataFormValidation = () => {
    if (hotelLookUpVisible()) {
      if ((!hotelData.hotel) || !hotelData.check_in_date || !hotelData.check_out_date || !hotelData.price || !hotelData.address) {
        if (!hotelData.hotel) {
          hotelRef.current.getElement().style.border = redBox;
        }
        applyRedBorder();
      return false;
    } else {
      return true;
    }
    } else if (!hotelLookUpVisible()) {
        if ( !hotelData.check_in_date || !hotelData.check_out_date || !hotelData.price || !hotelData.address) {
          applyRedBorder();
      return false;
    } else {
      return true;
    }
  }
  };

  const applyRedBorder = () => {
    if (!hotelData.address) {
      addressRef.current.style.border = redBox;
    }
    if (!hotelData.check_in_date) {
      checkInDateRef.current.getElement().querySelector('input').style.border = redBox;
    }
    if (!hotelData.check_out_date) {
      checkOutDateRef.current.getElement().querySelector('input').style.border = redBox;
    }
    if (!hotelData.price) {
      priceRef.current.style.border = redBox;
    }
  };
  
  const handleHotelInput = (fieldName, newValue, nestedKey = null) => {
    setHotelData((prevData) => {
      if (nestedKey) {
        return {
          ...prevData,
          [fieldName]: {
            ...prevData[fieldName],
            [nestedKey]: newValue,
          },
        };
      } else {
        return {
          ...prevData,
          [fieldName]: newValue,
        };
      }
    });
  };
  
  const handleFlightInput = (fieldName, newValue, nestedKey = null) => {
    setFlightData((prevData) => {
      if (nestedKey) {
        return {
          ...prevData,
          [fieldName]: {
              ...prevData[fieldName],
              [nestedKey]: newValue,
          },
        };
      } else {
        return {
          ...prevData,
          [fieldName]: newValue,
        };
      }
    });
  };

  const handleClose = () => onCloseClick();

  const saveInformation = (emojiCheck = true) => {
    if (formValidate()) {

      if(emojiCheck) {
        if (!hotelData.price_satisfaction && isHotelBookedVisible) {
          setModalType('emoji');
          setVisible(true);
          return;
        }
        if (!flightData.price_satisfaction && isFlightBookedVisible) {
          setModalType('emoji');
          setVisible(true);
          return;
        }
      }

      http
      .post('/save_accommodation', { hotelData })
      .then(() => {
        showMessage('success', 'Success', `Accomodation information saved successfully.`, 'bg-teal-100 text-teal-500');
        setTimeout(() => {
          handleClose();
        }, 1000);
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error.', 'bg-red-100 text-red-700');
        handleClose();
      });
    } else {
      showMessage('warn', 'Warning', `Please fill in the required fields.`, 'bg-amber-100 text-amber-500');
    }
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  return (
    <div className='h-full mt-11'>
      <Toast ref={toast} position='bottom-right' />
      <div className='bg-white rounded z-10 mb-6 mx-4'>

        { isHotelBookedVisible ?
          <AccForm 
            formData1 = { hotelData } 
            handleInput1 = { handleHotelInput } 
            handleClose = { handleClose } 
            hotelLookUpVisible = { hotelLookUpVisible() }
            isHotelBookedVisible = { isHotelBookedVisible } 
            isFlightBookedVisible = { isFlightBookedVisible }
            props = {{ hotelRef, priceRef, checkInDateRef, checkOutDateRef, addressRef }}
            calledFrom = { calledFrom }
          /> :<></>
        }

        { isHotelBookedVisible && isFlightBookedVisible ?
          <div className='border border-t border-bottom border-gray-100 mt-8 mx-10' /> : <></>
        }

        { isFlightBookedVisible ?
          <FlightForm 
            props = { { airlineRef, flightNumberRef, outboundAirportRef, inboundAirportRef, arrivalTimeRef, departureTimeRef, priceRefFlight } }
            formData2 = { flightData } 
            handleInput2 = { handleFlightInput } 
            handleClose = { handleClose } 
            isHotelBookedVisible = { isHotelBookedVisible } 
            isFlightBookedVisible = { isFlightBookedVisible }
          /> : < ></>
        }

        <BtnTransparent
          props={{
              text: 'text-white font-semibold w-full mt-10',
              bg: 'bg-periwinkle',
              border: 'border-periwinkle',
              buttonName: 'Save',
          }}
          handleClick={() => saveInformation()}
        />
      </div>
      {isVisible &&
        <CustomModal
          type={ modalType }
          isVisible={ isVisible }
          isMobile={ isMobile }
          setVisible={ setVisible }
          buttons={ buttons[modalType] }
        />
      }
    </div>
  );
};

export default AccomodationandFlightInformationForm;
