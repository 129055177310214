import React from 'react';
import PhoneInput from 'react-phone-number-input';
import './PhoneInput.scss';
import '../../../common/common_styles.scss';

const PhoneField = ({ value, handlePhoneChange }) => {
  return (
    <div className='form-control-wrapper'>
      <PhoneInput
        placeholder="Enter phone number"
        value={value}
        onChange={ handlePhoneChange }
      />
    </div>
  );
};

export default PhoneField;
