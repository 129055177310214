import React from 'react';
import Slider from './Slider';

import { useNavigate } from 'react-router-dom';
import { BtnTransparent } from "./../UIComponents/buttons";
import { triggerMixpanelEvent } from '../../common/mixpanel';

const LocationSlider = ({ Title, Description, props, isMobile }) => {
  const navigate = useNavigate();

  const inspirationPage = () => { 
    navigate('/results');
    triggerMixpanelEvent('LP - Trending destinations');
  };

  return (
    <section className={`flex lg:ps-12 md:ps-12 w-full justify-center items-center flex-wrap ${isMobile ? 'mt-24' : 'mb-24 mt-10'}`}>
      <div className="relative flex-wrap max-w-screen-xl mx-auto grid grid-cols-12 gap-x-6 items-center -mt-[128px]">
        <div className="flex col-span-12 lg:col-span-4">
          <div className={`w-full  ${isMobile ? 'flex flex-col items-center' : ''}`}>
            <h3 className={`font-semibold text-3xl ${isMobile ? 'mb-2' : 'pb-4'} md:text-[38px] capitalize`}>{Title}</h3>
            <p className="ps-14 text-[var(--gray)] max-w-[370px] lg:my-5 sm:pt-5 md:px-0 px-2 font-normal text-base">
              {Description}
            </p>
            { !isMobile && <div className='max-w-[370px] mt-4'>
              <BtnTransparent
                props={{
                  text: 'text-charcoal font-semibold w-full',
                  border: 'border-charcoal',
                  bg: 'white',
                  buttonName: 'See more destinations'
                }}
                handleClick={ inspirationPage }
              />
            </div>}
          </div>
        </div>
        {!isMobile && <div className="col-span-12 lg:col-span-8 space-y-8 sm:space-y-6 mt-8">
          <div className='projectsparent'>
            <Slider
              props={ props }
              isMobile={isMobile}
            />
          </div>
        </div>}
      </div>
      { isMobile && <div className='max-w-[370px] mx-24 mt-4'>
        <BtnTransparent
          props={{
            text: 'text-charcoal font-semibold w-full',
            border: 'border-charcoal',
            bg: 'white',
            buttonName: 'See more destinations'
          }}
          handleClick={ inspirationPage }
        />
      </div>}
    </section>
  );
};

export default LocationSlider;
