import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  continents: []
};
export const continentsSlice = createSlice({
  name: "continents",
  initialState,
  reducers: {
    setContinents: (state, action) => {
      state.continents = action.payload;
    },
  },
});

export const { setContinents } = continentsSlice.actions;

export default continentsSlice.reducer;
