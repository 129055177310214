import './Form.scss';

import http from './../../common/http';
import CustomModal from '../UIComponents/Modal/customModal';
import BookingCheckBox from '../UIComponents/InputFields/Checkbox';
import SearchDropdown from '../UIComponents/Dropdowns/SearchDropdown';
import MultiSelectDropdown from '../UIComponents/InputFields/MultiselectDropdown';
import SingleSelectDropdown from '../UIComponents/Dropdowns/SingleSelectDropdown';
import { triggerMixpanelEvent } from '../../common/mixpanel';

import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { setVibes } from '../../redux/slices/vibes';
import { setCities } from '../../redux/slices/cities';
import { useSelector, useDispatch } from 'react-redux';
import { BtnTransparent } from '../UIComponents/buttons';
import React, { useEffect, useState, useRef } from 'react';
import { setOccasions } from './../../redux/slices/occassions';
import { setPriorities } from './../../redux/slices/priorities';
import { setThingsToAvoid } from '../../redux/slices/thingsToAvoid';

const InspirationForm = ({ activeOption, setActiveOption, isMobile }) => {
  const toast = useRef(null);
  const startDateRef = useRef(null);
  const destinationRef = useRef(null);

  const vibeOptions = useSelector(state => state.vibes);
  const vibesValue = vibeOptions.vibes.map(vibe => vibe['name']);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modalType, setModalType] = useState('');
  const [isVisible, setVisible] = useState(false);
  const [formData, setFormData] = useState({ destinations: [] });
  const [fieldOptions, setFieldOptions] = useState({ city: [], priority: [], occasion: [], things_to_avoid: [] });
  const [travelersCout] = useState(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
    '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'
  ]);
  const [spendingValue] = useState(["Budget conscious ($)", "Reasonable ($$)", "Let's splurge a bit ($$$)", "Let's go crazy ($$$$)"]);

  const handleInput = (fieldName, newValue, index = null) => {
    setFormData((prevData) => {
      if (index !== null) {
        const updatedDestinations = [...prevData.destinations];
        const destinationName = updatedDestinations[index];

        return {
          ...prevData,
          dates: {
            ...prevData.dates,
            [destinationName]: {
              ...prevData.dates[destinationName],
              [fieldName]: newValue,
            },
          },
        };
      } else {
        return {
          ...prevData,
          [fieldName]: newValue,
        };
      }
    });
  };

  useEffect(() => {
    const params = { fields: ['city', 'priority', 'vibe', 'things_to_avoid', 'occasion'] };
    http
      .get('/field_options.json', { params: params })
      .then((res) => {
        dispatch(setPriorities(res.data.priority));
        dispatch(setOccasions(res.data.occasion));
        dispatch(setVibes(res.data.vibe));
        dispatch(setThingsToAvoid(res.data.things_to_avoid));
        dispatch(setCities(res.data.city));
        setFieldOptions(res.data);
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching field options.', 'bg-red-100 text-red-700');
      });
  }, []);

  const buttons = {
    date: [
      {
        disabled: true,
        text: 'AI Model Dates',
        onPress: () => setVisible(false)
      },
      {
        text: 'Enter Date',
        onPress: () => {
          setVisible(false);
          startDateRef.current && startDateRef.current.focus();
        }
      },
    ],
    destination: [
      {
        text: 'Inspiration',
        onPress: () => {
          setVisible(false);
          navigate("/results");
        }
      },
      {
        text: 'Enter City',
        onPress: () => {
          setVisible(false);
          destinationRef.current && destinationRef.current.focus();
        }
      },
    ],
    budget: [
      {
        text: 'OK',
        onPress: () => setVisible(false)
      },
    ],
  };

  const paramsPassing = {
    budget: formData?.budget,
    destinations: formData?.destinations,
    occasions: formData?.occasions,
    prioritize: formData?.priorities,
    avoid: formData?.things_to_avoid,
    travelers_count: formData?.travelers_count,
    vibe: formData?.vibe,
  };

  const queryString = Object.entries(paramsPassing)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map(v => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
    })
    .join('&');

    const finalQueryString = `look_for_inspiration=true&${queryString}`;

    const showMessage = (severity, summary, detail, style) => {
      toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
    };

    const applyFilters = () => {
      if (formData.destinations.length === 0) {
        setModalType('destination');
        setVisible(true);
        return;
      }
      navigate(`/results?${finalQueryString}`);
      triggerMixpanelEvent('LP - Show Inspiration button');
  };

  return (
    <div className={`bg-white m-3 ${isMobile ? 'p-3' : 'p-8'} rounded block gap-y-5`}>
      <Toast ref={toast} position="bottom-right" />

      <div className="flex flex-col">
        <div className="flex justify-between items-end w-full pb-3">
          <span
            className={`option pb-3 mt-2 cursor-pointer ${activeOption === 'generate' ? 'active' : ''}`}
            onClick={() => setActiveOption('generate')}
          >
            Generate itinerary
          </span>
          <span
            className={`option cursor-pointer border-periwinkle text-periwinkle z-20 border-b-2 pb-3 mt-2 font-bold ${activeOption === 'inspiration' ? 'active' : ''}`}
            onClick={() => setActiveOption('inspiration')}
          >
            Look for inspiration
          </span>
        </div>
        <hr className={`w-full border line-mrgin ${activeOption === 'generate' ? 'border-gray-400' : 'border-gray-400'}`} />
      </div>

      {isVisible &&
        <CustomModal
          type={ modalType }
          isVisible={ isVisible }
          setVisible={ setVisible }
          buttons={ buttons[modalType] }
          isMobile={ isMobile }
        />
      }

      <div>
        <SearchDropdown
          key='destinations'
          ref={destinationRef}
          props={{
            label: 'Destination',
            options: fieldOptions.city,
            chipsColor: 'blue-chips-background',
            selectedOptions: formData.destinations,
            placeHolder: 'Enter a city',
            dropDownMargin: 'mt-[63px]'
          }}
          setSaveData={ (val) => handleInput('destinations', val) }
        />

        <MultiSelectDropdown
          ranking
          allowDrag
          subCategories
          key='priority'
          Label={ 'Priorities' }
          dropdownWidth='sm:w-[300px]'
          items={fieldOptions['priority']}
          chipsColor='yellow-chips-background'
          setSaveData={ (val) => handleInput('priorities', val) }
        />

        <MultiSelectDropdown
          key='vibe'
          Label={'Vibes'}
          dropdownWidth={'w-[424px]'}
          items={ vibeOptions.vibes }
          chipsColor='pink-chips-background'
          setSaveData={(val) => handleInput('vibe', val) }
        />

        <MultiSelectDropdown
          key='occasion'
          Label={'Occasion'}
          dropdownWidth={'w-[424px]'}
          items={fieldOptions['occasion']}
          chipsColor='pink-chips-background'
          setSaveData={(val) => handleInput('occasions', val) }
        />

        <MultiSelectDropdown
          subCategories
          key='things_to_avoid'
          dropdownWidth={'w-[424px]'}
          Label={'Things I want to avoid'}
          items={fieldOptions['things_to_avoid']}
          chipsColor='violet-chips-background'
          setSaveData={(val) => handleInput('things_to_avoid', val) }
        />

        <div className='flex justify-between gap-x-2'>
          <div className='w-full'>
            <SingleSelectDropdown
              props={{
                label: '# of Travelers',
                options: travelersCout,
                dropDownwidth: 'w-full',
                value: formData.travelers_count,
                labelStyle: 'text-[11px] letter-spacing block'
              }}
              handleChanges={ (val) => handleInput('travelers_count', val.value) }
            />
          </div>

          <div className='w-full'>
            <SingleSelectDropdown
              props={{
                label: 'Budget',
                options: spendingValue,
                value: formData.budget,
                dropDownwidth: 'w-full',
                labelStyle: 'text-[11px] letter-spacing block'
              }}
              handleChanges={ (val) => {
                setModalType('budget');
                setVisible(true);
                handleInput('budget', val.value);
              }}
            />
          </div>
        </div>

        <BookingCheckBox/>

      </div>


        <BtnTransparent
          props={{
            bg: 'bg-periwinkle',
            border: 'border-periwinkle',
            buttonName: 'Look for inspiration',
            text: 'text-white mt-4 w-full h-[40px] font-semibold',
          }}
          handleClick={ applyFilters }
        />
    </div>
  );
};

export default InspirationForm;
