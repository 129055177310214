import React, { useState, useRef, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import MultiSelectDropdown from '../UIComponents/InputFields/MultiselectDropdown';
import { BtnTransparent } from '../UIComponents/buttons';
import AvatarEllipse from 'avatar_ellipse.svg';
import http from '../../common/http';
import { getUserId } from '../../common/auth';
import SingleSelectDropdown from '../UIComponents/Dropdowns/SingleSelectDropdown';
import SingleSelectDate from '../UIComponents/Dates/SingleSelectDate';
import { Toast } from 'primereact/toast';
import DpUploadButton from 'dp_upload_button.png';

const Profile = ({ setIsProfileDirty }) => {
  const toast = useRef(null);
  const [fieldOptions, setFieldOptions] = useState({ country: [], city: [], priority: [], cuisine: [] });
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const fileInputRef = useRef(null);
  const [userAvatar, setUserAvatar] = useState(AvatarEllipse);
  const [imgFile, setImgFile] = useState('');
  const [userData, setUserData] = useState({ phone: '' });
  const userId = getUserId();
  const [enableButton, setButtonEnable] = useState(false);

  useEffect(() => {
    fetchProfileInfo();
  }, []);

  useEffect(() => {
    fetchFieldOptions();
  }, [userData]);

  const updateProfile = () => {
    setIsProfileDirty(false);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const formData = new FormData();
    formData.append('avatar', imgFile);
    let params = { user: userData };

    Object.entries(params.user).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        if (value.length) {
          value.forEach((val) => formData.append(`${key}[]`, val));
        } else {
          formData.append(`${key}[]`, []);
        }
      } else {
        formData.append(key, value);
      }
    });

    http
      .put(`/users/${userId}`, formData, headers)
      .then(() => {
        setButtonEnable(false);
        showMessage('success', 'Success', 'Profile updated successfully.', 'bg-teal-100 text-teal-500');
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while updating your profile.', 'bg-red-100 text-red-700');
      });
  };

  const handleFileChange = (event) => {
    setButtonEnable(true);
    const file = event.target.files[0];
    setImgFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageSrc = e.target.result;
        setUserAvatar(imageSrc);
      };

      reader.readAsDataURL(file);
    }
  };

  const fetchProfileInfo = () => {
    http
      .get(`/profile/${userId}.json`)
      .then((res) => {
        setUserData(res.data);
        setUserAvatar(res.data.avatar_url);
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching profile data.', 'bg-red-100 text-red-700');
      });
  };

  const handleInput = (fieldName, newValue) => {
    setButtonEnable(true);
    setUserData((prevUserData) => ({
      ...prevUserData,
      [fieldName]: newValue,
    }));
  };

  const fetchFieldOptions = () => {
    const params = { fields: ['countries_array', 'cities_array', 'country', 'priority', 'city', 'cuisine'],
                      country: userData.country
                   };

    http
      .get('/field_options.json', { params: params })
      .then((res) => {
        setFieldOptions(res.data);
        if (res.data) {
          setCountries(res.data.countries_array);
          setCities(res.data.cities_array);
        }
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching field options.', 'bg-red-100 text-red-700');
      });
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  return (
    <div className='flex w-full flex-col'>
      <Toast ref={toast} position='bottom-right' />
      <div className='flex justify-between'>
        <div className='text-2xl font-normal mb-8 font-playfair'>Profile</div>
        {!isMobile && <div className='w-[185px] h-[40px]'>
          <BtnTransparent
            props={{
              text: 'text-white font-semibold w-full h-full',
              bg: 'bg-periwinkle',
              border: 'border-periwinkle',
              buttonName: 'Save changes',
            }}
            disabled={!enableButton}
            handleClick={updateProfile}
          />
        </div>}
      </div>
      <div>
        <div className='flex w-full justify-between lg:items-start items-center lg:flex-row flex-col'>
          {isMobile && <div className='lg:justify-start justify-center pb-7'>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={ (e) => { handleFileChange(e), setIsProfileDirty(true); } }
            />
            {
              userAvatar ?
              <Avatar
                alt="User avatar"
                src={userAvatar}
                sx={{ width: 165, height: 165, cursor: 'pointer' }}
                onClick={() => fileInputRef.current.click()}
              /> :
              <img
                src={DpUploadButton}
                alt="Upload Button"
                onClick={() => fileInputRef.current.click()}
                style={{ cursor: 'pointer' }}
              />
            }
          </div>}
          <div className='flex w-full justify-between flex-col lg:w-3/4'>
            <div className='flex w-full flex-col' >
              <div className='text-base mb-2 font-bold font-monsterrat'>
                General Information
              </div>
              <div className='flex gap-x-16 lg:flex-row flex-col'>
                <div className='mt-4 w-100% sm:w-[280px]'>
                  <label className='text-sm font-normal my-4'>
                    First name
                  </label>
                  <input
                    type="text"
                    key='firstName'
                    value={userData.first_name}
                    className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                    onChange={(e) => { handleInput('first_name', e.target.value), setIsProfileDirty(true); } }
                  />
                </div>
                <div className='mt-4 w-100% sm:w-[280px]'>
                  <label className='text-sm font-normal my-4'>
                    Last name
                  </label>
                  <input
                    type="text"
                    key='lastName'
                    value={userData.last_name}
                    className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                    onChange={(e) => { handleInput('last_name', e.target.value), setIsProfileDirty(true); } }
                  />
                </div>
              </div>
              <div className='flex gap-x-16 lg:flex-row flex-col'>
                <div className='mt-4 w-100% sm:w-[280px]'>
                  <label className='text-sm font-normal my-4'>
                    Date of birth
                  </label>
                  <SingleSelectDate
                    maxDate={new Date()}
                    selectedDate={userData.date_of_birth}
                    handleDateChange={(val) => { handleInput('date_of_birth', val.value), setIsProfileDirty(true); } }
                  />
                </div>
                <div className='mt-4 w-100% sm:w-[280px]'>
                  <label className='text-sm font-normal my-4'>
                    Phone number (optional)
                  </label>
                  <input
                    type="text"
                    key='phoneNumber'
                    value={userData.phone==="null"? "": userData.phone}
                    className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                    onChange={(e) => { handleInput('phone', e.target.value), setIsProfileDirty(true); } }
                  />
                </div>
              </div>
              <div className='flex justify-between lg:flex-row flex-col mt-4 w-full lg:w-[624px]'>
                <div className='w-100% sm:w-[280px]'>
                  <label className='text-sm font-normal my-4'>
                    Country of residence
                  </label>
                  <SingleSelectDropdown
                    props={{
                      options: countries,
                      value: userData.country
                    }}
                    handleChanges={ (val) => { handleInput('country', val.value); setIsProfileDirty(true); } }
                  />
                </div>
                <div className='w-100% sm:w-[280px]'>
                  <label className='text-sm font-normal my-4'>
                    City of residence
                  </label>
                  <SingleSelectDropdown
                    props={{
                      options: cities,
                      value: userData.city
                    }}
                    handleChanges={ (val) => { handleInput('city', val.value); setIsProfileDirty(true); } }
                  />
                </div>
              </div>
            </div>
          </div>
          {!isMobile && <div className='lg:justify-start justify-center pt-14'>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={ (e) => { handleFileChange(e), setIsProfileDirty(true); } }
            />
            {
              userAvatar ?
              <Avatar
                alt="User avatar"
                src={userAvatar}
                sx={{ width: 165, height: 165, cursor: 'pointer' }}
                onClick={() => fileInputRef.current.click()}
              /> :
              <img
                src={DpUploadButton}
                alt="Upload Button"
                onClick={() => fileInputRef.current.click()}
                style={{ cursor: 'pointer' }}
              />
            }
          </div>}
        </div>
      </div>
      <div>
        <div className='text-base mt-6 mb-4 font-bold'>
          Interests
        </div>
        <div>
          <MultiSelectDropdown
            key='preferredCountries'
            items={fieldOptions.country}
            selectedItems={userData.preferred_countries}
            chipsColor='violet-chips-background'
            className={`text-sm font-normal`}
            Label={'Countries'}
            setSaveData={(val) => {
              handleInput('preferred_countries', val);
              setIsProfileDirty(true);
            }}
          />
          <MultiSelectDropdown
            key='preferredCities'
            items={fieldOptions.city}
            selectedItems={userData.preferred_cities}
            chipsColor='violet-chips-background'
            className={`text-sm font-normal`}
            Label={'Cities'}
            setSaveData={(val) => { handleInput('preferred_cities', val), setIsProfileDirty(true); } }
          />
          <MultiSelectDropdown
            key='preferredActivites'
            items={fieldOptions.priority}
            selectedItems={userData.preferred_activities}
            chipsColor='violet-chips-background'
            className={`text-sm font-normal`}
            Label={'Activities'}
            setSaveData={(val) => { handleInput('preferred_activities', val), setIsProfileDirty(true); } }
          />
          <MultiSelectDropdown
            key='preferredCuisines'
            items={fieldOptions.cuisine}
            selectedItems={userData.preferred_cuisines}
            chipsColor='violet-chips-background'
            className={`text-sm font-normal`}
            Label={'Cuisines'}
            setSaveData={(val) =>{ handleInput('preferred_cuisines', val), setIsProfileDirty(true); } }
          />
        </div>
      </div>
      {isMobile && <div className='flex w-full mt-4 h-[40px]'>
        <BtnTransparent
          props={{
            text: 'text-white font-semibold w-full h-full',
            bg: 'bg-periwinkle',
            border: 'border-periwinkle',
            buttonName: 'Save changes',
          }}
          disabled={!enableButton}
          handleClick={updateProfile}
        />
      </div>}
    </div>
  );
};

export default Profile;
