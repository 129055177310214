import http from '../common/http';
import React, { useCallback, useEffect, useState } from 'react';
import { BtnTransparent } from './UIComponents/buttons';
import ItineraryForm from './ItineraryForm';
import { useLocation } from 'react-router-dom';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import CityDetailsSkeleton from './Skeletons/CityDetailsSkeleton';
import TripPlanCarousel from './TripPlanCarousel';

const CityDetail = () => {
  const location = useLocation();
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [city, setCity] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [, setMap] = useState(null);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [cards, setCards] = useState(4);


  const { isLoaded } = useJsApiLoader({
    id: 'we-plan-google-map-script',
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    const fetchCityDetails = () => {
      const queryParams = new URLSearchParams(location.search);
      const cityName = queryParams.get('city_name');
      const params = {
        city_name: cityName,
      };

      http
        .get('/city_detail', { params })
        .then((res) => {
          const cityData = res.data.city;
          setCity(cityData);
          setCenter({ lat: cityData.latitude, lng: cityData.longitude });
          setLoading(false);
        })
        .catch(() => {});
    };
    const handleResize = () => {
      setCards(window.innerWidth <= 768 ? 1 : 4);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    fetchCityDetails();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const openDialog = () => {
    setDialogVisible(true);
  };

  const closeDialog = () => {
    setDialogVisible(false);
  };

  const containerStyle = {
    width: '100%',
    height: '100%'
  };

  const onLoad = useCallback((map) => {
    map.setZoom(12);
    map.setCenter(center);
    setMap(map);
  }, [city]);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  return isLoaded ? (
    <div className='flex w-full lg:mt-12 mt-10'>
      { isLoading ? (
        <CityDetailsSkeleton />
      ) : (
        <div className='flex w-full flex-col'>
          <div className='flex items-center lg:flex-row flex-col justify-between h-full bg-[#F4F4F4]'>
            <div className='lg:w-2/5 w-full flex flex-col justify-center items-center'>
              <div className='flex w-full lg:pl-20 flex-col lg:items-start items-center py-10'>
                <h2 className='flex text-4xl font-bold mb-4 font-playfair text-black'>
                  { city.cityName }, { city.countryName } 
                </h2>
                <p className='flex text-base font-normal font-sans text-left text-black mb-5'>
                  { city.shortDescription }
                </p>
                <BtnTransparent
                  props={{
                    text: 'text-white text-bold',
                    bg: 'bg-periwinkle',
                    border: 'border-periwinkle',
                    buttonName: 'Generate Itinerary',
                  }}
                  handleClick={ openDialog }
                />
              </div>
            </div>
            <div className='lg:w-3/5 w-full'>
              <img
                src={ city.imageUrl }
                alt='Image 1'
                className='w-full max-h-[480px] object-cover'
              />
            </div>
          </div>
          <div className='flex w-full flex-col lg:px-7 px-3'>
            <div className='flex flex-col lg:flex-row lg:mx-4 lg:mt-32 mt-10 gap-x-7 lg:h-96'>
              <div className='lg:w-2/5 w-full lg:h-full h-[343px] mb-10'>
                <GoogleMap
                  mapContainerStyle={ containerStyle }
                  center={ center }
                  onLoad={ onLoad }
                  onUnmount={ onUnmount }
                />
              </div>
              <div className='lg:w-3/5 w-full h-full'>
                <h2 className='text-2xl font-normal font-playfair'>Overview</h2>
                <p className='text-gray-700 pt-5 font-sans text-base font-normal'>
                  Welcome to this extraordinary destination, where natural beauty intertwines with vibrant urban life. Whether you find yourself surrounded by towering skyscrapers or immersed in the tranquility of nature, 
                  this place promises a unique and unforgettable experience. Discover a rich tapestry of culture, indulge in diverse culinary delights, and explore the local treasures that make this destination truly special. From bustling streets to serene landscapes, 
                  every corner invites you to be part of its story. Embrace the magic of this captivating locale, where the spirit of adventure and the allure of discovery are woven into the fabric of everyday life. Welcome to a place that transcends boundaries and invites you to create your own remarkable journey.
                </p>
              </div>
            </div>

            <div className='flex bg-white lg:flex-row flex-col lg:mt-10 lg:mb-16 lg:mb-10 border-2 border-gray-100 lg:mx-4'>
              <div className='p-4 rounded-lg w-1/5'>
                <h3 className='text-sm text-gray-600 mb-2 font-normal font-sans'>Currency</h3>
                <p className='font-normal text-base font-sans'>{ city.currency }</p>
              </div>

              <div className='p-4 rounded-lg w-1/5'>
                <h3 className='text-sm text-gray-600 mb-2 font-normal font-sans'>Languages Spoken</h3>
                <p className='font-normal text-base font-sans'>{ city.languages }</p>
              </div>

              <div className='p-4 rounded-lg w-3/5'>
                <h3 className='text-sm text-gray-600 mb-2 font-normal font-sans'>Good for</h3>
                <p className='font-normal text-base font-sans'>
                </p>
              </div>
            </div>

            <TripPlanCarousel
              cardsToDisplay={ cards }
              showCarousalsNames={ ["city_hotels", "city_blogs", "suggested_resturants", "suggested_attractions", "clubs_and_bars", "tours"] }
              cityNames={ [city.cityName] }
            /> 
            <div className='flex lg:my-16 lg:flex-row flex-col space-y-4 lg:space-y-0 gap-x-5 mb-10 items-center justify-center'>
              <div className='font-playfair text-xl font-normal'>Ready to plan your trip?</div>
              <BtnTransparent
                props={{
                  text: 'text-white',
                  bg: 'bg-periwinkle',
                  border: 'border-periwinkle',
                  buttonName: 'Generate Itinerary',
                }}
                handleClick={ openDialog }
              />
            </div>
          </div>
          {isDialogVisible && (
            <ItineraryForm
              isVisible={ isDialogVisible }
              onClose={ closeDialog }
              city={ city.cityName }
            />
          )}
        </div>
      )}
    </div>
  ) : <CityDetailsSkeleton />;
};

export default CityDetail;
