import React, { useEffect, useState, useRef } from 'react';
import http from 'common/http';
import Layout from '../Layout/Layout';
import BlogsCarousel from './BlogsCarousel';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import loadingSpinner from 'loader.png';
import { PiThumbsUpDuotone } from "react-icons/pi";
import { Toast } from 'primereact/toast';
import ItineraryForm from '../ItineraryForm';
import { BtnTransparent } from '../UIComponents/buttons';
import { isLoggedIn } from '../../common/auth';
import { triggerMixpanelEvent } from '../../common/mixpanel';

export default function BlogShow() {
  const [blogTitle, setBlogTitle] = useState([]);
  const [blogLikesCount, setBlogLikesCount] = useState('');
  const [isLiked, setisLiked] = useState(false);
  const [createdSinceDaysCount, setcreatedSinceDaysCount] = useState('');
  const [blogCreator, setBlogCreator] = useState('');
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [userAvatar, setUserAvatar] = useState('');
  const [blogImage, setBlogImage] = useState('');
  const [blogCity, setBlogCity] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setLoading] = useState(true);
  const [isDialogVisible, setDialogVisible] = useState(false);
  const { id } = useParams();
  const toast = useRef(null);

  const closeDialog = () => {
    setDialogVisible(false);
  };

  const openDialog = () => {
    setDialogVisible(true);
    triggerMixpanelEvent('Intinerary - Generate via blog');
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const getValue= (item) => {
    if (Array.isArray(item.value) && item.value.length > 1) {
      return item.value.map((i) => i.charAt(0).toUpperCase() + i.slice(1)).join(', ');
    } else {
      return item.value[0].charAt(0).toUpperCase() + item.value[0].slice(1);
    }
  };

  const handleNavigation = () => {
    const isPreviousUrlDefault = location.key == "default" ? true : false ;
    isPreviousUrlDefault ? navigate("/blogs") : navigate(-1) ; 
  };


  const likeBlog = () => {
    const params = {
      blog_id: id
    };

    http
      .post('/like_blog', params)
      .then((res) => {
        if (res.status === 200) {
          setBlogLikesCount(res.data.likes_count);
          setisLiked(res.data.is_liked);
          const message = res.data.message || 'Blog liked successfully.';
          showMessage('success', 'Success', message , 'bg-teal-100 text-teal-500');
        }
      })
      .catch((e) => {
        showMessage('error', 'Error', `${e.response.data.message}`, 'bg-red-100 text-red-700');
      });
  };


  useEffect(() => {
    const fetchBlogs = () => {
      http
        .get(`/blogs/${id}`)
        .then((res) => {
          setBlogTitle(res.data.blog_title);
          setRelatedBlogs(res.data.related_blogs);
          setBlogLikesCount(res.data.likes_count);
          setisLiked(res.data.is_liked);
          setBlogCity(res.data.blog_city);
          setBlogCreator(res.data.blog_creator);
          setcreatedSinceDaysCount(res.data.created_since);
          setBlogData(res.data.blog_data);
          setUserAvatar(res.data.user_avatar);
          setBlogImage(res.data.blog_image);
          setLoading(false);
        })
        .catch(() => {
          showMessage('error', 'Error', 'Failed to show the blog, Please try again later.', 'bg-red-100 text-red-700');
        });
    };
    fetchBlogs();
  }, [id]);

  return (
    <div className='flex w-full flex-col'>
      {isLoading ? (
        <div className='flex mt-20 mb-10'>
          <div className='flex justify-center items-center w-full'>
            <img
              src={ loadingSpinner }
              alt='spinner'
              className='w-[21px] h-[23px] animate-spin'
            />
          </div>
        </div>
      ) : (
        <Layout>
          <Toast ref={ toast } position="bottom-right" />
          <div className='lg:mt-20 mt-14 mb-10 lg:px-10 px-3'>
            <div>
              <p
                className='font-bold py-6 cursor-pointer'
                onClick={() => handleNavigation() }
              >
                { '< Back' }
              </p>
            </div>
            <div className='flex flex-col w-full'>
              <span className='font-playfair font-bold lg:text-4xl text-3xl mt-2'>{blogTitle}</span>
              <div className='flex gap-2 items-center'>
                <span className='font-sans text-sm mt-2'>3 min read • posted {createdSinceDaysCount} day(s) ago  |  By: {blogCreator ? blogCreator : "Guest"}</span>
                <Avatar src={ userAvatar }/>
              </div>
              <div className='flex items-center gap-2 mt-2'>
                <span className='mt-1'>Total likes: {blogLikesCount}</span>
                {isLoggedIn() &&
                  <div>
                    {isLiked ? (<p>Liked</p>) : (<button onClick={likeBlog}>
                      <PiThumbsUpDuotone className="h-[23px] w-[23px]" />
                      </button>)
                    }
                  </div>
                }
              </div>
              <div className='mt-10'>
                <img
                  className='w-full h-[400px]'
                  src={ blogImage }
                  alt='Blog image'
                />
              </div>
              <div className='mt-10'>
                <div className="overflow-x-auto shadow-md sm:rounded-lg">
                  <table className="min-w-full text-sm divide-y divide-gray-200">
                    <thead className="bg-gray-100">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left font-semibold text-gray-900 uppercase tracking-wider">
                          Category
                        </th>
                        <th scope="col" className="px-6 py-3 text-left font-semibold text-gray-900 uppercase tracking-wider">
                          Details
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {blogData.map((item, index) => {
                        const multiValueDetails = {
                          "Attractions": detailObj => detailObj.attraction,
                          "Restaurants": detailObj => detailObj.restaurant,
                          "Bars And Clubs": detailObj => detailObj.bar,
                          "Local Gems": detailObj => `Type: ${detailObj.placesType} Name: ${detailObj.placesName}`,
                          "Must Dos": detailObj => `Type: ${detailObj.placesType} Name: ${detailObj.placesName}`
                        };

                        if (!multiValueDetails[item.category]) {
                          return (
                            <tr key={item.id || index} className="hover:bg-gray-50">
                              <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-900">{item.key}</td>
                              <td className="px-6 py-4 whitespace-pre-wrap text-black">{getValue(item)}</td>
                            </tr>
                          );
                        }

                        const isString = typeof item.value[0] === 'string';
                        const rowDetails = item.value.map(detail => {
                          const sanitizedDetail = detail.replace(/nil/g, 'null');
                          const Obj = isString ? JSON.parse(sanitizedDetail.replace(/=>/g, ':')) : detail;
                          return multiValueDetails[item.category](Obj);
                        }).join(",     ");

                        return (
                          <tr key={index} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-900">{item.key}</td>
                            <td className="px-6 py-4 whitespace-pre-wrap text-black">{rowDetails}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='flex justify-center lg:flex-row flex-col items-center mt-10 lg:space-y-0 space-y-3 bg-purple-200 w-full lg:h-[100px] h-[140px] gap-x-20'>
                <span className='flex text-3xl font-normal font-playfair'>Ready to go to {blogCity}?</span>
                {/* <span className='text-white bg-periwinkle rounded px-10'>Generate itinerary</span> */}
                <BtnTransparent
                  props={{
                    text: 'text-white',
                    bg: 'bg-periwinkle',
                    border: 'border-periwinkle',
                    buttonName: 'Generate Itinerary',
                  }}
                  handleClick={ openDialog }
                />
              </div>
              <div className='flex w-full'>
                {relatedBlogs.length > 0 && (
                  <div className='flex w-full flex-col'>
                    <div className='mt-5 mb-5 text-2xl pl-8'>Related blogs</div>
                    <BlogsCarousel
                      blogsData={ relatedBlogs }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {isDialogVisible && (
            <ItineraryForm
              isVisible={ isDialogVisible }
              onClose={ closeDialog }
              city={ blogCity }
            />
          )}
        </Layout>
      )}
    </div>
  );
}
