import React from 'react';
import Results from '../components/Results';
import Destinations from '../components/Destinations/Destinations';

const ResultsRoutes = [
  {
    path: "/results",
    element: <Results />,
  },
  {
    path: 'destinations',
    element: <Destinations />
  },
];

export default ResultsRoutes;
