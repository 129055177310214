import './UpcomingTrip.scss';

import http from '../../common/http';

import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import { Box, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PlaceholderSkeleton from '../Skeletons/PlaceholderSkeleton';
import { getUserId } from '../../common/auth';
import WhatToBring from '../WhatToBring';
import { reset } from '../../redux/slices/tripPlan';

const UpcomingTrips = ({ landingPage }) => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const userId = getUserId();
  const dispatch = useDispatch();

  const [trips, setTrips] = useState([]);
  const [whatToBring, setWhatToBring] = useState([]);

  useEffect(() => {
    http
      .get('/upcoming_trips')
      .then((res) => {
        const savedTrips = res.data.filter(trip => trip.saved);
        const upcomingTrips = savedTrips.length === 0 && landingPage ? res.data.filter(trip => !trip.saved) : savedTrips;
        setTrips(upcomingTrips);
        if (upcomingTrips.length) setWhatToBring(upcomingTrips[0].what_to_bring);
        
        setTrips(upcomingTrips);
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching trips.', 'bg-red-100 text-red-700');
      });
  }, []);

  const remainingTime = (time) => {
    const today = new Date();

    const targetDate = new Date(time);

    const difference = targetDate.getTime() - today.getTime();

    const weeks = Math.floor(difference / (1000 * 60 * 60 * 24 * 7));
    const days = Math.floor((difference % (1000 * 60 * 60 * 24 * 7)) / (1000 * 60 * 60 * 24));

    if (weeks > 0) {
      return `${weeks} weeks left`;
    } else if (days > 0) {
      return `${days} days left`;
    } else {
      '3 weeks left';
    }
  };

  const header = (displayText, trip) => (
    <div style={{ position: 'relative', height: '315px' }}>
      { trip?.imageUrl ? 
          <img
            alt='Card'
            src={ trip?.imageUrl }
            className='h-full w-full object-cover hover:bg-black hover:bg-opacity-50 hover:inset-0'
          /> :
          <PlaceholderSkeleton applyClass = { 'h-full w-full object-cover hover:bg-black hover:bg-opacity-50 hover:inset-0 bg-light-gray' } />
      }
      {/* {displayText && <div className='w-full' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#F0DDDD', padding: '20px', zIndex: '1' }}>
        <p className='items-center text-red-600'>{remainingTime(trip?.start_date) || '2 weeks left'} until your trip! Don’t forget to book your [flight/hotel]</p>
      </div>} */}
    </div>
  );

  if (trips.length === 0) {
    if (!landingPage) return null;

    return (
      <section className={`w-full pb-32 mt-24 ${landingPage ? 'lg:px-8' : 'my-16'}`}>
        <div className="flex border relative mx-auto gap-4 border-right justify-between items-center pb-32" style={{ maxWidth: landingPage ? '1320px' : '' }}>
          <div className="pl-5 pt-8" style={{ maxWidth: landingPage ? '1280px' : '' }}>
            <div className="flex justify-between items-center">
              <h3 className="text-3xl font-semibold mb-2 font-playfair">Upcoming Trips</h3>
            </div>
            <h1>No Upcoming Trips!</h1>
          </div>
        </div>
      </section>
    );
  }

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const handleNavigation = (trip) => {
    dispatch(reset());
    navigate(`/trip-plan/${trip.id}`);
  };


  return (
    <section className={`w-full ${landingPage ? 'pb-32 mt-24' : 'my-16'}`} >
      <Toast ref={toast} position="bottom-right" />
      <div className={`flex border relative mx-auto gap-4 border-right justify-between items-center pb-32  ${landingPage ? '' : 'lg:px-8'}`} style={{ maxWidth: landingPage? '1320px' : '', paddingLeft: landingPage? '20px' : '' }}>
      <div className={'pt-8'} style={{ maxWidth: landingPage ? '1280px' : '' }}>
        <div className='flex justify-between items-center'>
          <h1 className='text-3xl font-bold mb-2 font-playfair'>Upcoming Trips</h1>
          <p className='text-l font-bold mb-2 cursor-pointer' onClick={() => navigate(`/profile/${userId}/trips`)} style={{ color: '#C09DDE' }}>See all trips</p>
        </div>
        <div className='flex max-h-[346px] grid grid-cols-4 gap-4'>
          <div className='flex-wrap text-center col-span-2'>
            <div className='card-container relative overflow-hidden border w-30 mt-8'>
              <Link
                onClick={() => handleNavigation(trips[0])}
                className='text-center'
              >
                <Card header={header(true, trips[0])}>
                  <div className='p-3'>
                    <div className='flex justify-between items-center min-h-[48px] text-sm font-semibold'>
                      <div className='flex flex-col items-start'>
                        <div className='text-xl font-bold font-serif'>
                          {trips[0]?.city_name}, {trips[0]?.country_name}
                        </div>
                        <div className='text-gray-400'>
                          { trips[0]?.start_date } - { trips[0]?.end_date }
                        </div>
                      </div>
                      <div className='flex flex-col items-start'>
                        {/* <div className='flex text-l text-gray-700 items-center flex-row pb-1'>
                          <img src={Checkmark} className="h-4 w-auto cursor-pointer pr-2" alt='WePlan logo'/>
                          Flight Booked
                        </div>
                        <div className='flex text-l text-gray-700 items-center'>
                          <img src={Warning} className="h-4 w-auto cursor-pointer pr-2" alt='WePlan logo'/>
                          Hotel not booked
                        </div> */}
                      </div>
                    </div>
                  </div>
                </Card>
              </Link>
            </div>
          </div>
          {trips.length > 1 && <div className='flex flex-wrap text-center h-full'>
            <div className='card-container relative overflow-hidden border w-30 mt-8'>
              <Link
                onClick={() => handleNavigation(trips[1])}
                className='text-center'
              >
                <Card header={header(false, trips[1])}>
                  <div className='p-3'>
                    <div className='flex justify-between items-center min-h-[48px] text-sm font-semibold'>
                    <div className='flex flex-col items-start w-full'>
                      <div className="flex w-full items-start">
                        <div className={`text-xl font-bold font-playfair truncate`} title={`${trips[1]?.city_name}, ${trips[1]?.country_name}`}>
                          {trips[1]?.city_name}, {trips[1]?.country_name}
                        </div>
                      </div>
                      <div className='text-gray-400'>
                        { trips[1]?.start_date } - { trips[1]?.end_date }
                      </div>
                    </div>
                    </div>
                  </div>
                </Card>
              </Link>
            </div>
          </div>}
          {(trips.length === 1 || trips.length === 0) && <div className='flex flex-wrap text-center h-full'>
            <div className='card-container relative overflow-hidden border w-30 mt-8'>
              <Link
                onClick={() => handleNavigation(trips[0])}
                className='text-center'
              >
                <Card header={header(true, trips[0])}>
                  <div className='p-3'>
                    <div className='flex justify-between items-center min-h-[48px] text-sm font-semibold'>
                      <div className='flex flex-col items-start'>
                        <div className={`flex text-xl font-bold font-serif truncate`} title={`10 things to do in ${trips[0].city_name}`}>
                          <h1 className='font-playfair text-base font-semibold'>10 things to do in { trips[0].city_name } </h1>
                        </div>
                        <div className='text-gray-400'>
                        { trips[0]?.start_date } - { trips[0]?.end_date }
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </Link>
            </div>
          </div>}
          <WhatToBring 
            whatToBring={ whatToBring }
            setWhatToBring={ setWhatToBring }
            tripId={ trips[0]?.id }
            cityName={ trips[0].city_name }
            landingPage
          />
        </div>
      </div>
      </div>
    </section>
  );
};

UpcomingTrips.defaultProps = {
  landingPage: false,
};

export default UpcomingTrips;
