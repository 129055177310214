import React, { useState, useEffect, useRef, forwardRef } from 'react';

const SearchDropdown = forwardRef(({ props, setSaveData, removeDestination }, ref) => {
  const inputRef = useRef(null);

  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const isDisabled = props.disabled;

  useEffect(() => {
    setSuggestions(props.options);
  }, [props.options]);

  const maxSelectedValues = 3;

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (value.trim() === '') {
      setFilteredSuggestions([]);
      return;
    }
    const filtered = suggestions.filter(
      (suggestion) =>
        suggestion.name.toLowerCase().includes(value.toLowerCase()) &&
        !props.selectedOptions.some(
          (selected) => selected.toLowerCase() === suggestion.name.toLowerCase()
        )
    );
    setFilteredSuggestions(filtered);
  };

  const handleSuggestionClick = (suggestion) => {
    setSaveData([...props.selectedOptions, suggestion.name]);
    setInputValue('');
    setFilteredSuggestions([]);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      if (props.selectedOptions.length < maxSelectedValues) {
        setInputValue('');
        setFilteredSuggestions([]);
      }
    }
  };

  const handleRemove = (index) => {
    const updatedValues = [...props.selectedOptions];
    const removedDestination = updatedValues.splice(index, 1)[0];
    setSaveData(updatedValues);
    removeDestination(removedDestination);
  };

  const handleOutsideClick = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setFilteredSuggestions([]);
    }
  };

  const placeHolderValue = () => {
    if (props.placeHolder && !props.selectedOptions.length) {
      return props.placeHolder;
    }
    return '';
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (ref) {
      ref.current = {
        focus: () => {
          inputRef.current.focus();
        }
      };
    }
  }, [ref]);

  const renderSelectedOptions = () => {
    const selectedOptions = [...props.selectedOptions];
    const displayedOptions = selectedOptions.slice(0, 2);
    const remainingOptions = selectedOptions.slice(2);
    const tagStyle = 'custom-scrollbar max-h-32 overflow-y-auto absolute bg-black shadow-md z-10 before:content-[""] before:absolute before:top-[-2px] before:left-[40px] before:w-3 before:h-3 before:bg-black before:rotate-45';
    
    const toggleDropdownVisibility = () => {
      setIsDropdownVisible(prev => !prev);
    };
  
    return (
      <>
        {displayedOptions.map((opt, index) => (
          <div
            key={index}
            className={`mt-2 px-1 option-style ${props.chipsColor} flex items-center`}
          >
            <div className='p-2'> {opt} </div>
            {!isDisabled && (
              <div
                key={index}
                className='px-2 select-none rounded cursor-pointer'
                onClick={() => handleRemove(index)}
              >
                <svg
                  width='8'
                  height='8'
                  viewBox='0 0 14 14'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M12.5745 1L1 12.5745'
                    stroke='#000000'
                    strokeWidth='2'
                    strokeLinecap='round'
                  />
                  <path
                    d='M1.00024 1L12.5747 12.5745'
                    stroke='#000000'
                    strokeWidth='2'
                    strokeLinecap='round'
                  />
                </svg>
              </div>
            )}
          </div>
        ))}
  
        {remainingOptions.length > 0 && (
          <div className='mt-2 absolute right-[5px]'>
            <div 
              className='p-2 font-medium cursor-pointer'
              onMouseEnter={ ()=>toggleDropdownVisibility() }
              onMouseLeave={ ()=>toggleDropdownVisibility() }
            >
              {`+${remainingOptions.length} more`}
            </div>
            {isDropdownVisible && (
              <div className='mr-8'>
                <ul
                  className= {tagStyle}
                  onMouseEnter={ ()=>toggleDropdownVisibility() }
                  onMouseLeave={ ()=>toggleDropdownVisibility() }
                > 
                  {remainingOptions.map((opt, index) => (
                    <li 
                      key={index} 
                      className='flex justify-between bg-black text-white items-center px-3 hover:bg-gray-700 relative'
                    >
                      {opt}
                      <span 
                        className='ml-2 cursor-pointer text-white text-2xl right-2'
                        onClick={() => handleRemove(index + displayedOptions.length)}
                      >
                        &times;
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div className='mb-2'>
      <div 
        ref={ inputRef }
        className='mb-4 relative z-10 bg-gray-100'
      >
        { inputValue.trim() !== '' && (
          <ul className={ `absolute left-0 right-0 ${props.dropDownMargin} bg-gray-100 rounded max-h-[300px] overflow-y-auto` }>
            {filteredSuggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={ () => handleSuggestionClick(suggestion) }
                className='cursor-pointer p-2 hover:bg-gray-200'
              >
                {suggestion.name}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className='relative mx-auto text-xs'>
        <label className='text-[14px] letter-spacing mb-2 block'>
          { props.label }
        </label>
        <div
          className={ `pl-1 form-control-wrapper rounded-md flex gap-1 flex-wrap px-3` }
        >
         { props.selectedOptions.length > 0 && renderSelectedOptions() } 
          <div className='flex-1'>
            <div className='flex items-center'>
              <div className='w-[95%]'>
                <input
                  type='text'
                  ref={ inputRef }
                  value={ inputValue }
                  placeholder= { placeHolderValue() }
                  onChange={ handleInputChange }
                  onKeyDown={ handleInputKeyDown }
                  className={ `bg-white w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2` }
                  disabled={isDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

SearchDropdown.displayName = 'SearchDropdown';

export default SearchDropdown;
