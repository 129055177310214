import React from 'react';
import CustomCard from '../UIComponents/Cards/CustomCard';
import { Carousel } from 'primereact/carousel';

export default function BlogsCarousel({ blogsData, cityNames = [] }) {

  const sortByBlogTitle = (blogs) => {
    if(cityNames.length > 1) {
      const [blogsWithTitles, blogsWithoutTitles] = blogs.reduce((acc, blog) => {
        if (blog.topic && blog.topic.trim() !== '') {
          acc[0].push(blog);
        } else {
          acc[1].push(blog);
        }
        return acc;
      }, [[], []]);
    
      const sortedBlogsWithTitles = blogsWithTitles.sort((a, b) => {
        const titleA = a.topic.split(' ')[0].toLowerCase();
        const titleB = b.topic.split(' ')[0].toLowerCase();
        return titleA.localeCompare(titleB);
      });
      
      return sortedBlogsWithTitles.concat(blogsWithoutTitles);
    } else {
      return blogs;
    }
  };
  
  const itemTemplate = (blog, index) => {
    return (
      <div
        key={ index }
        className='card bg-white p-4 min-h-[320px] shadow-md mx-2 mb-5'
      >
        <CustomCard props={ blog } cardType="BlogCard" />
      </div>
    );
  };

  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  const items = () => isMobile ? 1 : 4;

  const showIndicators = (data) => data?.length > items();

  const smallCards = (trips) => trips?.length === 3 ? '75%' : trips?.length === 2 ? '50%' : trips?.length === 1 ? '25%' : '100%';

  return (
    <div className='flex w-full'>
      <Carousel
        numScroll={ 1 }
        value={ sortByBlogTitle(blogsData) }
        itemTemplate={ itemTemplate }
        showNavigators={ showIndicators(blogsData) }
        numVisible={ items() }
        style={{ paddingLeft: blogsData?.length > items() ? '' : '10px', width: items() === 1 ? '100%' : smallCards(blogsData) }}
      />
    </div>
  );
}
