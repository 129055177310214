import React from 'react';
import ReviewFlight from '../components/ReviewFlight';
import ReviewFlightSelection from '../components/ReviewFlightSelections';
import TravelerInformation from '../components/TravelerInformation';
import FilterFlights from '../components/FilterFlights';

const FlightPagesRoutes = [
  {
    path: "/reviewflight",
    element: <ReviewFlight />,
  },
  {
    path: "/reviewflightselection",
    element: <ReviewFlightSelection />,
  },
  {
    path: "/travelersinformation",
    element: <TravelerInformation />,
  },
  {
    path: `/filterflights`,
    element: <FilterFlights />,
  },
];

export default FlightPagesRoutes;
