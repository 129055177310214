import http from '../common/http';
import Layout from './Layout/Layout';
import AllView from './AllView';
import DayView from './DayView';
import ItineraryLoading from './../components/Loading/ItineraryLoading';
import TripPlanCarousel from './TripPlanCarousel';
import ItineraryForm from '../components/Forms/ItineraryForm';
import { Tooltip } from '@mui/material';
import CostBreakdownModal from './UIComponents/Modal/costBreakdownModal';
import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { GoogleMap, useJsApiLoader, MarkerF, InfoWindowF } from '@react-google-maps/api';
import { BtnTransparent, BtnTabs } from './UIComponents/buttons';
import { useSelector } from 'react-redux';
import { getUserId, isLoggedIn } from '../common/auth';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setTripPlanParams } from '../redux/slices/ItineraryPlanParams';
import { setTripPlan, setTripPrice, reset } from '../redux/slices/tripPlan';
import { Toast } from 'primereact/toast';
import { isEmpty } from 'lodash';
import { cloneDeep } from 'lodash';
import CustomModal from './UIComponents/Modal/customModal';
import { setPriceMatch } from '../redux/slices/priceMatch';
import { setFormStateChanged } from '../redux/slices/formChanged';
import { setWhatToBrings } from '../redux/slices/whatToBrings';
import AccomodationandFlightInformationForm from './Forms/AccomodationandFlightInfo';
import ViewsSkeleton from './Skeletons/ViewsSkeleton';
import { setSaveButton } from '../redux/slices/saveButtonSlice';
import { Dialog } from 'primereact/dialog';
import { generateTimeOptions, getDateRangeOptions } from './Common/TripPlanCommon';
import SingleSelectDropdown from './UIComponents/Dropdowns/SingleSelectDropdown';
import TripPlanHelper from './TripPlanHelper';
import IL from 'InstagramIcon.svg';
import TTL from 'TikTokIcon.svg';
import { FaXTwitter } from "react-icons/fa6";
import WhatToBring from './WhatToBring';
import BookAllBookingConfirmationModal from './OneClickBookAll/BookAllBookingConfirmationModal';
import { IoExitOutline } from "react-icons/io5";
import MapCheckBoxes from './MapCheckBoxes';
import LocationDetail from './LocationDetail';
import { mapMarkerSizeHeight, mapMarkerSizeWidth } from '../common/shared/displayIconsInItinerary';
import { triggerMixpanelEvent } from '../common/mixpanel';

const TripPlan = () => {
  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  const priceMatchData = useSelector(state => state.priceMatch.form);
  const formStateChanged = useSelector(state => state.formChanged.formStateChanged);
  const toast = useRef(null);
  let saveButtonRef = useRef(null);
  const tripPlanHelperRef = useRef();
  const dayViewRef = useRef(null);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const tripPlan = useSelector(state => state.tripPlan.tripPlan);
  const tripPrice = useSelector(state => state.tripPlan.tripPrice);
  const oneClickFlight = useSelector(state => state.selectedFlights.completeInfo);
  const locationsCoordination = useSelector(state => state.locationsCoordination.locationsCoordination);
  const fetchItinerary = location.state ? location.state.fetchItinerary : false;

  const [, setMap] = useState(null);
  const [viewType, setViewType] = useState('all');
  const [activeTab, setActiveTab] = useState('');
  const [activeTabIndex, setActiveTabIndex] = useState(1);
  const [itineraryPlan, setItinerary] = useState([]);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [isLoading, setLoading] = useState(true);
  const [hotelsData, setHotelsData] = useState({});
  const [suggestions, setSuggestions] = useState({});
  const [cityPlace, setCityPlace] = useState({});
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cards, setCards] = useState(4);
  const [isSaving, setIsSaving] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [costBreakdown, setCostBreakdown] = useState(false);
  const [isBookeAllHotelBooked, setIsBookAllHotelBooked] = useState(false);
  const [flightConfirmationId, setFlightConfirmationId] = useState('');

  const searchParams = new URLSearchParams(location.search);
  const [isHotelBookedVisible,setHotelBookedVisible] = useState(false);
  const [isFlightBookedVisible,setFlightBookedVisible] = useState(false);
  const suggestedKeys = ['suggested_resturants', 'suggested_attractions'];
  const extraKeys = ['clubs_and_bars', 'city_hotels', 'tours', 'city_blogs'];
  const [fromModify,setFromModify]=useState(true);
  const [modalType, setModalType] = useState('');
  const [isVisible, setVisible] = useState(false);
  const [showConflictDialog, setShowConflictDialog] = useState(false);
  const [currentSwapping, setCurrentSwapping] = useState(null);
  const [timeOptions, setTimeOptions] = useState([]);
  const [activityEndTime, setActivityEndTime] = useState(null);
  const [activityStartTime, setActivityStartTime] = useState(null);
  const [dateOptions, setDateOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState({});
  const [allViewSelectedActivity, setAllViewSelectedActivity] = useState(null);
  const [showTimeDialog, setShowTimeDialog] = useState(false);
  const [currentSwappingView, setCurrentSwappingView] = useState(null);
  const [whatToBring, setWhatToBring] = useState([]);
  const [citiesWeather, setCitiesWeather] = useState({});
  const [priceBreakdown, setPriceBreakdown] = useState({});
  const [isBookAllVisible, setIsBookAllVisible] = useState(false);
  const [tripId, setTripId] = useState('');
  const [isEditItinerary, setIsEditItinerary] = useState(false);
  const [isDayViewCollapse, setIsDayViewCollapse] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [mapPlaceChosen, setMapPlaceChosen] = useState({});
  const [markerLocations, setMarkerLocations]= useState([]);

  const markerProps = {
    viewType,
    selectedMarker,
    setSelectedMarker,
    mapPlaceChosen,
    setMapPlaceChosen
  };

  const onMarkerClick = (marker, coordinates, location) => {
    setSelectedMarker(null);
    setMapPlaceChosen({});
    setSelectedMarker(marker);
    setMapPlaceChosen(location);
  };

  const openBookAllDialog = () => {
    setIsBookAllVisible(true);
  };
  const closeBookAllDialog = () => {
    setIsBookAllVisible(false);
    setLoading(true);
    dispatch(reset());
    navigate(`/trip-plan/${tripId}`);
  };
  let resolveConflict = false;

  const dayViewCollapseChange = () => {
    setIsDayViewCollapse(!isDayViewCollapse);
  };

  const editItineraryProps = {
    isEditItinerary,
    setIsEditItinerary,
    dayViewCollapseChange
  };

  const saveChangesClick = () => {
    setIsEditItinerary(!isEditItinerary);
    saveItinerary();
  };

  const buttons = {
    change: [
      {
        text: 'Exit without generating itinerary',
        onPress: () => {
          if(isLoggedIn()){
            dispatch(setFormStateChanged(true));
          } else {
            dispatch(setFormStateChanged(false));
          }
          setVisible(false);
          setFromModify(true);
          setIsModalOpen(prevState => !prevState);
        }
      },
      {
        text: 'Stay on page',
        onPress: () => {
          setVisible(false);
        }
      },
    ],
  };

  const savePriceMatchForm = (id) => {
    const formData = new FormData();
    formData.append('old_price', priceMatchData.old_price);
    formData.append('suggested_price', priceMatchData.suggested_price);
    formData.append('room_details', JSON.stringify(priceMatchData.room_details));
    formData.append('hotel_id', priceMatchData.hotel_id);
    formData.append('trip_id', id);

    priceMatchData.files.forEach(file => {
      formData.append('uploadedFiles[]', file);
    });

    http
      .post('/price_match_programs', formData)
      .then(() => {
        showMessage('success', 'Success', 'Price Match request submitted successfully!', 'bg-teal-100 text-teal-500');
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while saving price match request.', 'bg-red-100 text-red-700');
      })
      .finally(() => {
        dispatch(setPriceMatch({}));
      });
  };

  useEffect(() => {
    dispatch(setFormStateChanged(false));
}, []);

  useEffect(() => {
    const handleResize = () => {
      setCards(window.innerWidth <= 768 ? 1 : 4);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onCloseClick=()=>{
    setHotelBookedVisible(false);
    setFlightBookedVisible(false);
  };

  useEffect(() => {
    if(saveButtonRef.current) {
      dispatch(setSaveButton(saveButtonRef.current));
    }
  }, [saveButtonRef.current]);

  useEffect(()=>{
    fetchLoadingScreenData();
    const options = generateTimeOptions();
    setTimeOptions(options);
  }, []);

  useEffect(() => {
    if (isEmpty(whatToBring)) return;

    dispatch(setWhatToBrings(whatToBring));
  }, [whatToBring]);

  const getCitiesNamesFromParams = () => {
    const tripParams = getRouteParams(); 
    if( tripParams.destinations?.selectedCities ) {
      return tripParams.destinations.selectedCities;
    } else if (tripPlan.destinations) {
      return tripParams.destinations;
    } else {
      return citiesName("carousel");
    }
  };

  const getRouteParams = () => {
    const params = {};
    for (const [key, value] of searchParams) {
      params[key] = JSON.parse(value);
    }
    return params;
  };

  useEffect(() => {
    if (tripPlan.length) {
      setItinerary(tripPlan);
    }
  }, [tripPlan]);

  useEffect(() => {
    if (showTimeDialog) {
      setActivityStartTime(null);
      setActivityEndTime(null);
      setSelectedDate({});
    }
  }, [showTimeDialog]);

  useEffect(() => {
    setLoading(true);
    if ((id && isEmpty(tripPlan)) || fetchItinerary) {
      fetchTripData();
    } else {
      if (isEmpty(tripPlan)) {
        setHotelBookedVisible(searchParams.get('hotelBooked') == "true");
        setFlightBookedVisible(searchParams.get('flightBooked') == "true");
        fetchItinerarydays();
      } else {
        !id && isLoggedIn() ? dispatch(setFormStateChanged(true)) : dispatch(setFormStateChanged(false));
        setItinerary(tripPlan);
        setPriceBreakdown(tripPrice);
        setLoading(false);
      }
    }
    setIsModalOpen(false);
  }, [location]);

  useEffect(() => {
    itineraryPlan.forEach((obj) => {
      let cityName = obj.cityName;
      let hotel = obj.hotel;
      setHotelsData((prevDate) => ({
        ...prevDate,
        [cityName]: hotel
      }));
    });
  }, [itineraryPlan]);

  const getDaysData = () => {
    let mergedDaysData = {};
    let suggestedPlaces = {};

    itineraryPlan.forEach(obj => {
      const keys = Object.keys(obj.days_data);
      const lastKey = Object.keys(mergedDaysData).length > 0 ? parseInt(Object.keys(mergedDaysData).pop()) : 0;
      const startingKey = lastKey + 1;

      keys.forEach((key, index) => {
        if (suggestedKeys.includes(key)) {
          if (!suggestedPlaces[obj.cityName]) suggestedPlaces[obj.cityName] = {};
          suggestedPlaces[obj.cityName][key] = obj.days_data[key];
        } else if (!extraKeys.includes(key)) {
          mergedDaysData[startingKey + index] = { ...cloneDeep(obj.days_data[key]), 'cityName': obj.cityName, 'isHotelBooked': obj.isHotelBooked, cityDayNumber: parseInt(key) };
        }
      });
    });
    setSuggestions(suggestedPlaces);
    return mergedDaysData;
  };

  const dayViewPlan = useMemo(() => {
    if (Object.keys(itineraryPlan.length)) {
      return getDaysData();
    }
  }, [itineraryPlan]);

  const setLoadingValue = (userAction) => {
    setLoading(true);
    fetchLoadingScreenData(userAction);
  };

  const setStates = (val, index) => {
    switch(index) {
      case 0:
        setCurrentSwappingView(val);
        break;
      case 1:
        setCurrentSwapping(val);
        break;
      case 2:
        setShowConflictDialog(val);
        break;
      case 3:
        setItinerary(val);
        break;
      case 4:
        resolveConflict = val;
        break;
      case 5:
        setAllViewSelectedActivity(val);
        break;
      case 6:
        dispatch(setTripPrice(val));
        setPriceBreakdown(val);
        break;
    }
  };

  const swapTripPlaces = (data, type) => {
    if (type == 'dayView') {
      tripPlanHelperRef.current.handleswapTripPlaces(data, resolveConflict);
    } else if (type == 'allView') {
      let obj = {
        allViewSelectedActivity: allViewSelectedActivity,
        selectedDate: selectedDate,
        activityStartTime: activityStartTime,
        activityEndTime: activityEndTime
      };
      tripPlanHelperRef.current.doAllViewSwap(obj, resolveConflict);
    } else if (type == 'removeActivity') {
      tripPlanHelperRef.current.removeItinearyPlace(data).then((isPlaceRemoved) => {
        if (isPlaceRemoved) modifyPriceBreakdown(data);
      });
    }
  };

  const modifyPriceBreakdown = (data) => {
    let breakdown = { ...priceBreakdown };
    const restaurantTypes = ['Breakfast', 'Lunch', 'Dinner', 'Brunch'];
    const currentActivity = data.currentActivity;
    if ((data.updatedTime && !Object.keys(data.updatedTime).length) || !data.updatedTime) {
      if (restaurantTypes.includes(currentActivity.placeType) || !currentActivity.placeType) {
        breakdown.food -= currentActivity.ai_suggested_price;
      } else {
        breakdown.activity -= currentActivity.ai_suggested_price;
      }
      breakdown.total = breakdown.food + breakdown.activity + breakdown.accommodation;
      dispatch(setTripPrice(breakdown));
      setPriceBreakdown(breakdown);
    }
  };

  const fetchLoadingScreenData = (userAction = '') => {
    let params = {};

    if (isLoading) {
      if (id) {
        params = { id: id };
        if (cityPlace?.hotelId && userAction) {
          params = {
            id: id,
            hotel_id: cityPlace.hotelId,
            user_action: userAction
          };
        }
      } else if (cityPlace?.hotelId && userAction) {
        params = {
          destinations: searchParams.get('destinations'),
          hotel_id: cityPlace.hotelId,
          user_action: userAction,
        };
      } else {
        params = searchParams;
      }
      http
        .get('/get_city_place', { params })
        .then((res) => {
          setCityPlace(res.data);
        })
        .catch(() => {
        });
    }
  };

  const fetchItinerarydays = async () => {
    await http
      .get('/generate_itinerary', { params: searchParams })
      .then((res) => {
          setWhatToBring(res.data.what_to_bring);
          setItinerary(res.data.trip);
          setCitiesWeather(res.data.weather);
          dispatch(setTripPlan(res.data.trip));
          dispatch(setTripPlanParams(searchParams));
          dispatch(setTripPrice(res.data.total_price ));
          setPriceBreakdown(res.data.total_price);
        isLoggedIn() ? dispatch(setFormStateChanged(true)) : dispatch(setFormStateChanged(false)); 
        setLoading(false);
      })
      .catch((e) => {
        showMessage('error', 'Error', e.response.data.message, 'bg-red-100 text-red-700');
      });
  };

  const fetchTripData = () => {
    http
      .get(`/trips/${id}`)
      .then((res) => {
        setWhatToBring(res.data.what_to_bring);
        setItinerary(res.data.trip);
        setPriceBreakdown(res.data.total_price);
        dispatch(setTripPlan(res.data.trip));
        dispatch(setTripPrice(res.data.total_price ));
        setLoading(false);
      })
      .catch((e) => {
        showMessage('error', 'Error', e.response.data.message, 'bg-red-100 text-red-700');
      });
  };

  function saveItinerary() {
    if (itineraryPlan[0].is_book_all) {
      openBookAllDialog();
    }
    setIsSaving(true);
    const userId = getUserId();
    const handleSuccess = (res) => {
      // delete any booking related info to avoid duplicate booking
      let clonedTripPlan = cloneDeep(itineraryPlan);
      clonedTripPlan.map((obj) => {
        delete obj.travelers_info;
        return obj;
      });
      setItinerary(clonedTripPlan);
      dispatch(setTripPlan(clonedTripPlan));
      const bookingErrors = res.data.booking_errors;
      if (bookingErrors.length) {
        bookingErrors.forEach((error) => {
          showMessage('error', 'Error', `Sorry, there was an error while booking the hotel of ${error.city_name}. ${error.message}`, 'bg-red-100 text-red-700');
        });
      }
      const tripId = res.data.trip.id;
      setTripId(tripId);
      if (res.data.is_book_all && tripId) {
        setIsBookAllHotelBooked(res.data.hotel_booked);
        if (res.data.flight_confirmation_ids) {
          setFlightConfirmationId(res.data.flight_confirmation_ids[0]);
        }
        setIsSaving(false);
        return openBookAllDialog();
      }
      const bookingRoute = res.data.hotel_booked ? `/booking/${tripId}` : `/profile/${userId}/trips`;
      setIsSaving(false);
      dispatch(setFormStateChanged(false));
      if (priceMatchData?.hotel_id) { savePriceMatchForm(res.data.trip.id); }
      showMessage('success', 'Success', `Trip Itinerary ${id ? 'updated' : 'saved'} successfully.`, 'bg-teal-100 text-teal-500');
      setTimeout(() => {
        navigate(bookingRoute);
      }, 1000);
    };

    const handleError = (err) => {
      setIsSaving(false);
      showMessage('error', 'Error', err.message || 'Sorry, there was an error, please try again', 'bg-red-100 text-red-700');
    };

    const tripBringItemsParams = {
      what_to_brings_attributes: whatToBring.map(entry => ({
        ...entry,
        items_list: JSON.stringify(entry.items_list),
      })),
    };
    let data = { itinerary_plan: itineraryPlan, trip_bring_items: tripBringItemsParams, weather: citiesWeather };
    let flightsData = null;
    if (!isEmpty(oneClickFlight)) {
      flightsData = JSON.stringify({ flights: oneClickFlight.flights, travelers_info: { travelers_data: oneClickFlight.traveler_info } });
      data['flight_booking'] = flightsData;
    }
    const request = id ? http.put(`/trips/${id}.json`, { itinerary_plan: itineraryPlan, flight_booking: flightsData })
                       : http.post('/trips', data );

    request
      .then(handleSuccess)
      .catch(handleError);
  }

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const tabs = () => {
    let daysTab = [];
    let cityIndex = 0;
    let itemIndex = 1;
    itineraryPlan.forEach(plan => {
      const [startDateString, endDateString] = plan.date.split("/");
      const startDate = new Date(startDateString);
      const endDate = new Date(endDateString);

      const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

      for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
        const dayOfWeek = daysOfWeek[date.getDay()];
        const formattedDate = `${date.getMonth() + 1}/${date.getDate()}`;
        const title = `${dayOfWeek}, ${formattedDate}`;
        daysTab.push({ title : title, cityIndex: cityIndex, itemIndex: itemIndex });
        itemIndex ++ ;
      }
      itemIndex = 1;
      cityIndex ++;
    });
    return daysTab;
  };

  const { isLoaded } = useJsApiLoader({
    id: 'we-plan-google-map-script',
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY
  });

  const tabButtonCss = (value) => {
    if (viewType === value) {
      return {
        text: 'text-white',
        bg: 'bg-periwinkle',
        border: 'border-periwinkle'
      };
    }
    return {
      text: 'text-periwinkle',
      bg: 'bg-transparent',
      border: 'border-periwinkle'
    };
  };

  const calculateBounds = () => {
    if (getLocations().length === 0) return null;

    const bounds = new window.google.maps.LatLngBounds();

    getLocations().forEach((location) => {
      bounds.extend(location.location);
    });

    return bounds;
  };

  const onLoad = useCallback((map) => {
    const bounds = calculateBounds();

    if (bounds) map.fitBounds(bounds, { padding: 50 });

    map.setZoom(3);
    map.setCenter(center);
    setMap(map);
  }, [locationsCoordination]);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const containerStyle = {
    minHeight: '300px',
    width: '100%',
    height: isDayViewCollapse ? '100%' : '60%'
  };

  const allViewButtonClick = () => {
    setMapPlaceChosen({});
    setSelectedMarker(null);
    setViewType('all'); 
    setIsDayViewCollapse(false);
    setIsEditItinerary(false);
  };

  const dayViewButtonClick = () => {
    setMarkerLocations([]);
    setMapPlaceChosen({});
    setSelectedMarker(null);
    setViewType('dayView');
    if (activeTab == '') {
      setActiveTab(tabs()[0].title, 0);
    }
  };

  useEffect(()=> {
    const calculateCenter = () => {
      if (getLocations().length === 0) {
        return { lat: 0, lng: 0 };
      }

      const sumLat = getLocations().reduce((sum, currentPlace) => sum + currentPlace.location.lat, 0);
      const sumLng = getLocations().reduce((sum, currentPlace) => sum + currentPlace.location.lng, 0);

      return {
        lat: sumLat / getLocations().length,
        lng: sumLng / getLocations().length,
      };

    };
    setCenter(calculateCenter());
  }, [locationsCoordination, viewType, activeTabIndex]);

  const handleTabs = (tab, index, type = null, item) => {
    setMarkerLocations([]);
    if (!item) {
      setSelectedMarker(null);
      setMapPlaceChosen({});
    } else {
      setSelectedMarker(parseInt(item.placeIndex) + 1 );
      setMapPlaceChosen(item);
    }
    setActiveTab(tab);
    setActiveTabIndex(index);

    if (type !== null) {
      setViewType(type);
    }
  };

  const getLocations = useCallback(() => {
    if (viewType !== 'dayView') {
      setMarkerLocations(Object.values(locationsCoordination).flat());
      return Object.values(locationsCoordination).flat();
    }

    let count = 1;
    let obj = Object.entries(locationsCoordination).reduce((acc, [, value]) => {
      if (value.length) {
        acc[count++] = value;
      }
      return acc;
    }, {});

    setMarkerLocations(obj[activeTabIndex]);
    return obj[activeTabIndex];
  }, [locationsCoordination, viewType, activeTabIndex]);

  const citiesName = (calledFrom) => {
    if (calledFrom === 'header') {
      if (itineraryPlan.length) {
        return itineraryPlan.map(plan => plan.cityName).join(', ');
      }
    } else if (calledFrom === 'carousel') {
      const cityNamesArray = [];
      itineraryPlan.forEach(plan => {
        cityNamesArray.push(plan.cityName);
      });
      return cityNamesArray;
    }
  };
  

  const tripDates = () => {
    if (itineraryPlan.length) {
      const allDates = itineraryPlan
        .flatMap(plan => {
          const [startDate, endDate] = plan.dateRange.split(' - ');
          return [new Date(startDate), new Date(endDate)];
        })
        .sort((a, b) => a - b);

      const minDate = allDates[0];
      const maxDate = allDates[allDates.length - 1];

      const options = { month: 'short', day: 'numeric', year: 'numeric' };
      const formattedMinDate = minDate.toLocaleDateString(undefined, options);
      const formattedMaxDate = maxDate.toLocaleDateString(undefined, options);

      return <p className='flex flex-shrink-0'>{formattedMinDate} - {formattedMaxDate}</p>;
    }
  };

  const allViewSwapping = (selectedActivity) => {
    setCurrentSwappingView('allView');
    setAllViewSelectedActivity(selectedActivity);
    let clonedTripData = cloneDeep(itineraryPlan);
    let planIndex = clonedTripData.findIndex((plan) => plan.cityName === selectedActivity.cityName);
    let cityPlan = clonedTripData[planIndex];
    const dateRange = cityPlan.dateRange;
    const [startDate, endDate] = dateRange.split(' - ');
    const options = getDateRangeOptions(startDate, endDate);
    setDateOptions(options);
    setShowTimeDialog(true);
  };

  if (isHotelBookedVisible || isFlightBookedVisible) {
    return (
      <div className="w-full min-h-screen flex items-center justify-center bg-light-black bg-opacity-50">
        <div className="rounded-lg flex items-center justify-center ">
          <AccomodationandFlightInformationForm 
            isHotelBookedVisible={isHotelBookedVisible} 
            isFlightBookedVisible={isFlightBookedVisible} 
            onCloseClick={onCloseClick} 
            calledFrom={ "tripPlan" }
          />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <Layout headerCSS={'mb-20'}>
        <div className='mt-4 bg-neutral-100 min-h-screen flex w-full flex-col px-3'>
          <Toast ref={toast} position="bottom-right" />
          <ItineraryLoading
            rating={ cityPlace.rating }
            // price="20$"
            placeName={ cityPlace.name }
            imageUrl={ cityPlace.imageUrl }
            handleLoading={ (userAction) => setLoadingValue(userAction) }
          />
        </div>
      </Layout>
    );
  }

  const buttonName = () => {
    return id ? 'Update' : 'Save';
  };

  const toggleModal = (fromModify) => {
    if (fromModify) {
        if (formStateChanged) {
          setModalType("change");
          setVisible(true);  
        } else {
          isLoggedIn() && dispatch(setFormStateChanged(true));
          setIsModalOpen(prevState => !prevState);
        }
    } else {
      setFromModify(true);
      dispatch(setFormStateChanged(false));
      setIsModalOpen(prevState => !prevState);
    }
  };

  const tripDetails = () => {
    const occasions = JSON.parse(searchParams.get('occasions')) || (searchParams.get('occasion') ? [JSON.parse(searchParams.get('occasion'))] : []);
    const priorities = JSON.parse(searchParams.get('priorities')) || JSON.parse(searchParams.get('prioritize'));
    const things_to_avoid = JSON.parse(searchParams.get('things_to_avoid')) || (searchParams.get('avoid') ? [JSON.parse(searchParams.get('avoid'))] : []);
    const budget = JSON.parse(searchParams.get('budget'));
    const travelers_count = JSON.parse(searchParams.get('travelers_count'));

    const renderTooltipContent = () => (
      <div>
        {travelers_count && travelers_count?.length > 0 && (
          <>
            <h3 className='text-lg'># of Travelers:</h3>
            <p className='text-[14px] ml-3'>{ travelers_count }</p>
          </>
        )}
        {occasions && occasions?.length > 0 && (
          <>
            <h3 className='text-lg'>Occasions:</h3>
            <ul className='lg:pl-3 pl-0'>
              {occasions.map((occasion, index) => (
                <li className='text-[14px]' key={index}>{occasion}</li>
              ))}
            </ul>
          </>
        )}
        {priorities && priorities?.length > 0 && (
          <>
            <h3 className='text-lg'>Priorities:</h3>
            <ul className='pl-3'>
              {priorities.map((priority, index) => (
                <li className='text-[14px]' key={index}>{priority}</li>
              ))}
            </ul>
          </>
        )}
        {things_to_avoid && things_to_avoid?.length > 0 && (
          <>
            <h3 className='text-lg'>Things to avoid:</h3>
            <ul className='pl-3'>
              {things_to_avoid.map((avoidance, index) => (
                <li className='text-[14px]' key={index}>{avoidance}</li>
              ))}
            </ul>
          </>
        )}
      </div>
    );

    return (
      <div className='flex w-full lg:mt-0 mt-3 lg:ml-3 ml-0 lg:text-sm text-xs'>
        <p className='flex w-full'>
          <div className='flex flex-row w-full'>
            {budget && <p className='lg:px-2 px-0 flex-shrink-0'>{ budget }</p>}
            {budget && <p className='lg:pl-2 lg:pr-4 px-1'>●</p>}
            {(occasions?.length > 0 || priorities?.length > 0 || things_to_avoid?.length > 0 || travelers_count?.length > 0) &&
              <Tooltip
                arrow
                open={showTooltip}
                title={renderTooltipContent()}
                onClose={() => setShowTooltip(false)}
              >
                <p
                  onClick={() => setShowTooltip(!showTooltip)}
                  className='cursor-pointer flex-shrink-0 font-semibold'
                >See More</p>
              </Tooltip>
            }
          </div>
        </p>
      </div>
    );
  };

  const modalContent = isModalOpen && (
    <div className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 ${isModalOpen ? 'block' : 'hidden'} z-50`}>
      <div className="bg-white lg:mx-0 mx-2 mt-3 mb-[133px] sm:mb-[0px] min-h-[10%] max-h-[90%] rounded shadow-md overflow-y-auto">
        <ItineraryForm
          fromModify={ fromModify }
          SetFromModify={ setFromModify }
          showInspiration={ false }
          onClose={ toggleModal }
          routeParams={ getRouteParams() }
        />
         {isVisible &&
        <CustomModal
          type={ modalType }
          isVisible={ isVisible }
          isMobile={ isMobile }
          setVisible={ setVisible }
          buttons={ buttons[modalType] }
        />
      }
      </div>
    </div>
  );
  
  const header = (
    <div className='font-normal font-playfair text-[32px]'>
      Time conflict
    </div>
  );

  const timeHeader = (
    <div className='text-[32px] font-playfair'>
      Select a date and time
    </div>
  );

  const footer = (
    <div className='m-6 gap-x-4 flex justify-end'>
      <div className='w-[163px] h-[40px]'>
        <BtnTransparent
          props={{
            bg: 'bg-white w-full font-semibold font-sans',
            border: 'border-charcoal',
            text: 'text-charcoal',
            buttonName: 'No'
          }}
          handleClick={() => setShowConflictDialog(false)}
        />
      </div>
      <div className='w-[163px] h-[40px]'>
        <BtnTransparent
          props={{
            bg: 'bg-periwinkle w-full font-semibold font-sans',
            border: 'border-periwinkle',
            text: 'text-white',
            buttonName: 'Yes'
          }}
          handleClick={() => {
            setShowConflictDialog(false);
            resolveConflict = true;
            if (currentSwappingView == 'dayView') {
              swapTripPlaces(currentSwapping, 'dayView');
            } else {
              swapTripPlaces(null, 'allView');
            }
            triggerMixpanelEvent('Intineray - Save button');
          }}
        />
      </div>
    </div>
  );

  const timeFooter = (
    <div className='m-6 gap-x-4 flex justify-end'>
      <div className='w-[163px] h-[40px]'>
        <BtnTransparent
          props={{
            bg: 'bg-white w-full font-semibold',
            border: 'border-charcoal',
            text: 'text-charcoal',
            buttonName: 'Cancel'
          }}
          handleClick={() => setShowTimeDialog(false)}
        />
      </div>
      <div className='w-[163px] h-[40px]'>
        <BtnTransparent
          props={{
            bg: 'bg-periwinkle w-full font-semibold',
            border: 'border-Ais',
            text: 'text-white',
            buttonName: 'Save'
          }}
          handleClick={() => {
            if (isEmpty(selectedDate) || isEmpty(activityStartTime) || isEmpty(activityEndTime)) {
              showMessage('error', 'Error', 'Start time, end time and date is required.', 'bg-red-100 text-red-700');
            } else {
              setShowTimeDialog(false);
              swapTripPlaces(null, 'allView');
            }
          }}
        />
      </div>
    </div> 
  );

  const locationLat = () => {
    return mapPlaceChosen.placeObj?.location?.lat;
  };

  const locationLng = () => {
    return mapPlaceChosen.placeObj?.location?.lng;
  };

  const isValueExist = () => {
    return Object.keys(mapPlaceChosen).length;
  };

  const doesTripExist = () => !isLoading && tripPlan.length;

  return isLoaded ? (
    <>
      <Layout headerCSS={'mb-20'}>
        <TripPlanHelper
          ref={tripPlanHelperRef}
          handleStates= { (val, index) => setStates(val, index)}
          statesData={{ itineraryPlan: itineraryPlan, priceBreakdown: priceBreakdown }}
          setMarkerLocations = { setMarkerLocations }
        />
        <div className='flex w-full flex-col mt-6 bg-neutral-100'>
          <Toast ref={toast} position="bottom-right" />
          <div className='flex text-[#161616] grid sm:grid-cols-12 gap-x-2'>
            <div className='lg:px-12  bg-semi-trans-purple lg:col-span-8 px-3 sm:col-span-6 ...'>
              <div className='text-sm pt-3'>
                <span className='font-sans text-base font-semibold'>
                  Your trip details
                </span>
                <div className='float-right'>
                  { !id &&
                    <div>
                      <BtnTransparent
                        props={{
                          text: 'text-black font-semibold',
                          buttonName: 'Modify',
                          bg: 'bg-transparent',
                          border: 'border-black'
                        }}
                        handleClick={ () => {
                          toggleModal(false);
                          triggerMixpanelEvent('Itinerary - Modify button');
                        }}
                      />
                      { modalContent }
                    </div>
                  }
                </div>
              </div>
              <div className='mt-2 text-3xl font-semibold font-playfair'>
                { citiesName("header") }
              </div>
              <div className=' flex lg:flex-row lg:items-center flex-col w-full mt-2 mb-5'>
                <p className='text-base font-normal font-sans'>{ tripDates() }</p>
                <p className='text-base font-normal font-sans'>{ tripDetails() }</p>
              </div>
            </div>
            <div className='bg-semi-trans-purple lg:col-span-4 sm:col-span-6 py-3 lg:px-5 px-3 mt-3 md:mt-0'>
              <span className='mt-5 text-base font-semibold font-sans'>
                Trip cost
              </span>
              <div className='text-3xl font-normal font-playfair text-italic'>
                { '$' + priceBreakdown.total }
              </div>
              <div
                className='mt-2 mb-5 text-base cursor-pointer font-monsterrat font-bold'
                onClick={() => {
                  setCostBreakdown(true);
                  triggerMixpanelEvent('Itinerary - See breakdown');
                }}
              >
                See breakdown
              </div>
            </div>
          </div>

          <div className='flex flex-wrap rounded-md bg shadow-sm px-5 my-5 lg:justify-between justify-center w-full space-y-2' role='group'>
            <div className='flex flex-row lg:justify-start justify-center py-10'>
            <BtnTabs 
              props={{
                ...tabButtonCss('all'), 
                rounded: 'rounded-tl-md rounded-bl-md', 
                buttonName: 'All' 
              }} 
              handleViewType={() => { 
                allViewButtonClick();
                triggerMixpanelEvent('Itinerary - All-view button');
              }} 
            />
            <BtnTabs 
              props={{
                ...tabButtonCss('dayView'), 
                rounded: 'rounded-tr-md rounded-br-md', 
                buttonName: 'Day-view' 
              }} 
              handleViewType={() => {
                dayViewButtonClick();
                triggerMixpanelEvent('Itinerary - Day-view button');
              }} 
            />
            </div>

            <div className='flex items-center'>
              {
                isLoggedIn() && <div className='ml-auto px-2'>
                  <BtnTransparent
                    props={{
                      text: 'text-[#161616] w-full',
                      bg: 'bg-transparent',
                      border: 'border-[#161616]',
                      buttonName: buttonName(),
                    }}
                    disabled={ isSaving }
                    isSaving={ isSaving }
                    handleClick={ saveItinerary }
                    forwardRef = { saveButtonRef }
                  />
                </div>
              }
              <div className='flex flex-row gap-x-2 items-center'>
                  <a href="https://www.instagram.com/weplan.ai?igsh=cmV5MGcwZHh6bm80&utm_source=qr" target="_blank" rel="noopener noreferrer">
                    <img src = { IL } alt="IG" className='w-10 h-10' />
                  </a>
                  <a href="https://www.tiktok.com/@weplanai?_t=8jch92YbNQE&_r=1" target="_blank" rel="noopener noreferrer">
                    <img src = { TTL } alt="TT" className='w-10 h-10' />
                  </a>
                  <a href="https://x.com/weplanai?s=21&t=aoqnaoxxDOcdP5KJdtoLWQ" target="_blank" rel="noopener noreferrer">
                  <FaXTwitter className='bg-black text-white w-8 h-8 rounded-md p-2' />
                  </a>
              </div>
            </div>
          </div>

          { viewType === 'dayView' && (
            <div className="flex justify-between px-10 mt-4">
              { Object.keys(itineraryPlan).length && (tabs().map((tab, index) => (
                <div key={ index + 1 }>
                  <div
                    id={ index + 1 }
                    className={ `cursor-pointer p-3
                      ${ index + 1 === activeTabIndex ? 'w-full h-full bg-periwinkle text-white rounded-t' : 'text-black hover:text-periwinkle' }`
                    }
                    onClick={ 
                      () => { 
                        handleTabs(tab.title, index + 1);
                        if (index + 1 !== activeTabIndex) {
                         setIsEditItinerary(false);
                        }
                      }    
                    }
                  >
                    { tab.title }
                  </div>
                </div>
              )))}
            </div>
          )}

          { viewType === 'dayView' && <div className='h-1 w-full bg-periwinkle' /> }

          <section className='grid sm:grid-cols-12'>
            { doesTripExist() ? (
              <div className={ `${ isDayViewCollapse ? "hidden" : "" } lg:col-span-5 sm:col-span-6 lg:max-h-[900px] overflow-y-scroll bg-white` }>
                { viewType === 'all' &&
                  <AllView
                    tabs={ tabs() }
                    handleTabs={ handleTabs }
                    itineraries={ itineraryPlan }
                    markerProps = { markerProps }
                    id={id}
                  />
                }
                { viewType === 'dayView' &&
                  <DayView
                    itinerary={ dayViewPlan[activeTabIndex] }
                    activeTab={ activeTab }
                    activeTabIndex={activeTabIndex}
                    tabs={ tabs() }
                    hotelsData={ hotelsData }
                    suggestions={ suggestions }
                    tripId={ id }
                    swap={ (swappingData) => swapTripPlaces(swappingData, 'dayView') }
                    removePlace={ (removePlaceData) => swapTripPlaces(removePlaceData, 'removeActivity') }
                    editItineraryProps = { editItineraryProps }
                    addToItinerary={allViewSwapping}
                    markerProps = { markerProps }
                    ref = { dayViewRef }
                  />
                }
                { viewType === 'dayView' && isEditItinerary &&
                  <div className='flex w-full mt-6 lg:mt-10'>
                    <BtnTransparent props={{
                      text: 'text-black w-full',
                      bg: 'bg-gray-200 h-12 border-none rounded-none',
                      buttonName: 'Cancel' }}
                      handleClick={ () => setIsEditItinerary(!isEditItinerary) }
                    />
                    <BtnTransparent props={{
                      text: 'text-white w-full',
                      bg: 'bg-periwinkle h-12 border-none rounded-none',
                      buttonName: 'Save Changes' }}
                      handleClick={ () => saveChangesClick() }
                    />
                  </div>
                }
              </div> ) : (
              <div className='lg:col-span-5 sm:col-span-6 lg:h-[700px]'>
                <ViewsSkeleton/>
              </div>)
            }
            <div className={`${ isDayViewCollapse ? "lg:h-screen lg:col-span-12 sm:col-span-12" : "lg:col-span-7 sm:col-span-6" } relative` }>
              <IoExitOutline onClick={() => dayViewCollapseChange()} className={`${isDayViewCollapse ? 'absolute top-3 left-52' : 'hidden'} z-30 w-10 h-10 cursor-pointer text-white hover:scale-105 transition-all ease-in-out duration-300`} />
              <GoogleMap
                mapContainerStyle={ containerStyle }
                center={ center }
                zoom={10}
                onLoad={ onLoad }
                onUnmount={ onUnmount }
              >
                {markerLocations.length && markerLocations.map((location, index) => {
                  return (
                    <MarkerF
                      id={`${index + 1}`}
                      key={index + 1}
                      position={location.location}
                      onClick={() => onMarkerClick(index + 1, location.location, location)}
                      icon={{
                        url: location.iconUrl,
                        scaledSize: new window.google.maps.Size(
                          mapMarkerSizeHeight(location.placeObj.place_type),
                          mapMarkerSizeWidth(location.placeObj.place_type)
                        )
                      }}
                    />
                  );
                })}
              {
                isValueExist && selectedMarker ? (
                  <InfoWindowF
                    position={{ lat: locationLat(), lng: locationLng() }}
                    onCloseClick={() => {
                      setSelectedMarker(null);
                      setMapPlaceChosen({});
                    }}
                  >
                    <LocationDetail
                      props = { mapPlaceChosen.placeObj }
                      placeIndex = { mapPlaceChosen.placeIndex }
                      swap = { dayViewRef.current?.swapActivity }
                      removePlace = { dayViewRef.current?.removeItinearyPlace }
                      markerProps = { markerProps }
                      isEditItinerary = { isEditItinerary }
                    />
                  </InfoWindowF>
                ) : (
                  <></>
                )
              }
              </GoogleMap>
              { viewType === 'dayView' && 
              <div className='flex flex-col w-[90%]'>
                < MapCheckBoxes />
              </div>
              }
            </div>
          </section>
          <div className={`${!isDayViewCollapse ? "lg:px-10 px-2" : "lg:px-10 px-2 mt-0 sm:mt-[130px]"}`}>
            { itineraryPlan.length && 
              <TripPlanCarousel 
                cardsToDisplay={cards} 
                addToItinerary={allViewSwapping} 
                showCarousalsNames={["city_hotels", "city_blogs", "suggested_resturants", "suggested_attractions", "clubs_and_bars", "tours"]}
                cityNames={ getCitiesNamesFromParams() }
              /> }
          </div>
          { !isLoading ? (
            <WhatToBring
              whatToBring={ whatToBring }
              setWhatToBring={ setWhatToBring }
              tripId={ itineraryPlan[0]?.id}
            />
            ): <p> Loading... </p>
          }
        </div>
        { costBreakdown &&
          <CostBreakdownModal
            isVisible={ costBreakdown }
            setVisible={ setCostBreakdown }
            props={ priceBreakdown }
          />
        }
        <Dialog
          header={header}
          footer={footer}
          headerClassName='px-6 pt-6'
          visible={showConflictDialog}
          dismissableMask={true}
          style={{ width: '753px', height: '276px', backgroundColor: 'white', overflow: 'hidden' }}
          draggable={false}
          closable={false}
          blockScroll={true}
          responsive={true}
          resizable={false}
          onHide={() => {}}
        >
          <div className='m-6 font-sans'>
            The time you have selected overlaps with an existing activity. Do you want to continue with the swap?
          </div>
        </Dialog>
        <Dialog
          header={timeHeader}
          footer={timeFooter}
          headerClassName='px-6 pt-6'
          visible={showTimeDialog}
          dismissableMask={true}
          style={{ width: '533px', height: '492px', backgroundColor: 'white', overflow: 'hidden' }}
          draggable={false}
          closable={false}
          blockScroll={true}
          responsive={true}
          resizable={false}
          onHide={() => {}}
        >
          <div className='m-6'>
            <SingleSelectDropdown
              props={{
                dropDownwidth: 'w-full',
                label: 'Start time',
                options: timeOptions,
                value: activityStartTime,
                labelStyle: 'text-[11px] letter-spacing block'
              }}
              handleChanges={(e) => setActivityStartTime(e.value)}
            />
            <SingleSelectDropdown
              props={{
                dropDownwidth: 'w-full',
                label: 'End time',
                options: timeOptions,
                value: activityEndTime,
                labelStyle: 'text-[11px] letter-spacing block'
              }}
              handleChanges={(e) => setActivityEndTime(e.value)}
            />
            <SingleSelectDropdown
              props={{
                dropDownwidth: 'w-full',
                label: 'Date',
                options: dateOptions,
                value: selectedDate.value,
                labelStyle: 'text-[11px] letter-spacing block'
              }}
              handleChanges={(e) => {
                const dayIdx = dateOptions.findIndex((opt) => opt == e.value);
                setSelectedDate({ value: e.value, idx: dayIdx + 1 });
              }}
            />
          </div>
        </Dialog>
      </Layout>
      <BookAllBookingConfirmationModal
        bookingId={ tripId }
        flightConfirmationId={ flightConfirmationId }
        isHotelBooked={ isBookeAllHotelBooked }
        isVisible={ isBookAllVisible }
        onClose={ closeBookAllDialog }
      />
    </>
  ) : <></>;
};

export default TripPlan;
