import React from 'react';
import flightmodallogo from 'flightmodallogo.png';

import { Dialog } from 'primereact/dialog';
import { BtnTransparent } from '../buttons';

const customAlertData = {
  flight: {
    logo: flightmodallogo,
    title: 'Unlock Your Next Adventure!',
    subtitle: "Congratulations on booking your stay with us! Your comfort and satisfaction are our top priorities. As a token of appreciation, we want to offer you an exciting opportunity to earn $100 towards your next trip.",
    paragraph: 'How? It’s simple. By signing up for our in-stay program, you not only enhance your current experience but also unlock exclusive rewards for your future travels. Embrace the benefits of being a part of our community and enjoy savings on your next getaway!',
  },
};

const FlightBookingModal = ({ isVisible, setVisible, type, buttons }) => {
  const { title = '', paragraph = '', logo = '', subtitle = '' } = customAlertData[type] || {};

  return (
    <Dialog
      visible={isVisible}
      className={"lg:w-2/5 w-full mx-5 lg:mx-20 p-4 overflow-y-auto"}
      setVisible={setVisible}
      onHide={() => setVisible(false)}
      style={{ backgroundColor: "#ffffff" }}
      header={title && (
        <div className='flex flex-col gap-y-6 mb-3'>
          <div className='flex flex-col justify-center lg:flex-row lg:justify-between mx-3'>
            <div id='left' className='w-full flex justify-center items-center lg:w-2/6  mr-2'>
              <img className='w-full h-auto' src={flightmodallogo} alt="" />
            </div>
            <div id="right" className='flex-1 flex flex-col justify-center  gap-y-4 items-center '>
              <div className='px-4 text-center text-3xl font-playfair'>{title}</div>
              <div className='px-4 text-l'>{subtitle}</div>
            </div>
          </div>

          <div className='text-l'>
            {paragraph}
          </div>
        </div>
      )}
      footer={
        <div className={`flex ${buttons.length > 1 ? `flex flex-col gap-y-2 lg:flex-row lg:justify-center gap-4 px-20` : 'justify-center'}  `}>
          {buttons?.map((button, index) =>
            <BtnTransparent
              key={index}
              props={{
                buttonName: button.text,
                bg: index % 2 === 1 ? 'bg-periwinkle w-full' : 'w-full',
                border: index % 2 === 0 ? `border-periwinkle` : 'border-periwinkle',
                text: index % 2 === 1 ? `text-white mt-4 h-[40px] font-semibold` : `mt-4 h-[40px] font-semibold`,
              }}
              disabled={button?.disabled}
              handleClick={button.onPress}
            />
          )}
        </div>
      }
    >
    </Dialog>
  );
};

FlightBookingModal.defaultProps = {
  type: "",
  logo: null,
  button: {},
  isMobile: false,
  isVisible: false,
  onHide: () => { },
};

export default FlightBookingModal;
