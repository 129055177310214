import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import http from '../common/http';
import Layout from './Layout/Layout';
import { BtnTransparent } from './UIComponents/buttons';
import MultiSelectDropdown from './UIComponents/InputFields/MultiselectDropdown';
import SearchDropdown from './UIComponents/Dropdowns/SearchDropdown';
import { Toast } from 'primereact/toast';
import { setPriorityOptions } from '../redux/slices/priorities';
import CustomModal from './UIComponents/Modal/customModal';
import cm from "checkmark.png";
import LocalGemsForm from './LocalGemsForm';
import MustDosForm from './MustDosForm';
import AddHotelAirBnb from './AddHotelAirBnb';
import SignupModal from './SignupModal';
import { isLoggedIn, logOut } from '../common/auth';
import ThingsToDoForm from './ThingsToDoForm';
import BarClubForm from './BarClubForm';
import RestaurantFormData from './RestaurantFormData';
import { useNavigate } from 'react-router-dom';

const LocalExpertProgramForm = () => {
  const toast = useRef(null);
  const destinationRef = useRef(null);
  const [fieldOptions, setFieldOptions] = useState({ city: [] });
  const [menuClicked, setMenuClicked] = useState("seasons");
  const dispatch = useDispatch();
  const [isVisible, setIsVisible]= useState(false);
  const [modalType, setModalType] = useState('');
  const [cityData, setCityData] = useState({ destinations: [], cityDescription: "" });
  const [seasonsFormData, setSeasonsFormData] = useState({ category: "seasons", busySeason: "", downSeason: "", localHolidays: "", weather: "" });
  const [placesFormData, setPlacesFormData] = useState({ category: "places", areasToAvoid: "", areasThatAreHappeningRightNow: "", coworkingSpaces: "", placesForFamilies: "", placesForSingles: "" });
  const [whereToStayFormData, setWhereToStayFormData] = useState({ category: "where_to_stay", type: "", suggestion: suggestedHotelAirBnb });
  const [becomingGuideFormData, setBecomingGuideFormData] = useState({ category: "becoming_guide", willYou: "", notInterested: "" });
  // const [thingsToDoFormData, setThingsToDoFormData] = useState({ category: "things_to_do", at1: "", des1: "", at2: "", des2: "", at3: "", des3: "", at4: "", des4: "", at5: "", des5: "", at6: "", des6: "", at7: "", des7: "", at8: "", des8: "", at9: "", des9: "", at10: "", des10: "", r1: "", r2: "", r3: "", r4: "", r5: "", r6: "", r7: "", r8: "", r9: "", r10: "", b1: "", b2: "", b3: "", b4: "", b5: "", b6: "", b7: "", b8: "", b9: "", b10: "", googleLink: "", type: "", name: "", description: "" });
  const [gemPlacesFormData, setGemPlacesFormData] = useState([{}]);
  const [mustDosFormData, setMustDosFormData] = useState([{}]);
  const [suggestedHotelAirBnb, setSuggestedHotelAirBnb] = useState([]);
  const [isSignUpModalVisible, setIsSignUpModalVisible] = useState(false);
  const [isGuestUserSignedUp, setIsGuestUserSignedUp] = useState(false);
  const [thingsToDoFormData, setThingsToDoFormData] = useState([{}]);
  const [barClubFormData, setBarClubFormData] = useState([{}]);
  const [restaurantFormData, setRestaurantFormData] = useState([{}]);
  const [googleLink, setGoogleLink] = useState({ googleLink: "" });
  const navigate = useNavigate();

  const closeSignUpModal = (info) => {
    setIsSignUpModalVisible(false);
    submitForm();
    if (info?.accountCreated) {
      setIsGuestUserSignedUp(true);
      showMessage('success', 'Success', 'Account created successfully. Email has been sent to you.', 'bg-teal-100 text-teal-500');
    }
    setTimeout(() => {
      logOut();
    }, 5000);
  };

  const openSignUpModal = () => {
    if (cityData.destinations.length === 0) {
      return showMessage('error', 'Error', 'Destination must be present.', 'bg-red-100 text-red-700');
    } else if (!isLoggedIn()) {
      setIsSignUpModalVisible(true);
    } else {
      submitForm();
    }
  };

  // Hotel/Air Bnb
  const renderHotelAirBnbInput = () => {
    let hotelAirBnbs = [];
    hotelAirBnbs.push(
      <AddHotelAirBnb
        key={0}
        handleAddMoreInput={(newData, addMoreFunc) => handleAddMoreInput(0, newData, addMoreFunc)}
      />
    );
    return hotelAirBnbs;
  };
  const [hotelAirBnbList, setHotelAirBnbList] = useState(renderHotelAirBnbInput());
  const addHotelAirBnbs = () => {
    setHotelAirBnbList(
      hotelAirBnbList.concat(
        <AddHotelAirBnb
          key={hotelAirBnbList.length}
          handleAddMoreInput={(newData, addMoreFunc) => handleAddMoreInput(hotelAirBnbList.length, newData, addMoreFunc)}
        />
      )
    );
  };

 // Restaurant
 const renderRestaurantForm = () => {
  let rests = [];
  rests.push(
    <RestaurantFormData
      key={0}
      handleAddMoreInput={(newData, addMoreFunc) => handleAddMoreInput(0, newData, addMoreFunc)}
      indexNumber={1}
    />
  );
  return rests;
};

const [restaurantList, setRestaurantList] = useState(renderRestaurantForm());

const addRestaurant = () => {
  setRestaurantList(
    restaurantList.concat(
      <RestaurantFormData
        key={restaurantList.length}
        handleAddMoreInput={(newData, addMoreFunc) => handleAddMoreInput(restaurantList.length, newData, addMoreFunc)}
        indexNumber={restaurantList.length+1}
      />
    )
  );
};

  // BAR CLUB
  const renderBarClubForm = () => {
    let bars = [];
    bars.push(
      <BarClubForm
        key={0}
        handleAddMoreInput={(newData, addMoreFunc) => handleAddMoreInput(0, newData, addMoreFunc)}
      />
    );
    return bars;
  };

  const [barClubList, setBarClubList] = useState(renderBarClubForm());
  
  const addBarClub = () => {
    setBarClubList(
      barClubList.concat(
        <BarClubForm
          key={barClubList.length}
          handleAddMoreInput={(newData, addMoreFunc) => handleAddMoreInput(barClubList.length, newData, addMoreFunc)}
        />
      )
    );
  };

    // Things To DO
    const renderThingsToDoForm = () => {
      let things = [];
      things.push(
        <ThingsToDoForm
          key={0}
          handleAddMoreInput={(newData, addMoreFunc) => handleAddMoreInput(0, newData, addMoreFunc)}
        />
      );
      return things;
    };

    const [thingsToDoList, setThingsToDoList] = useState(renderThingsToDoForm());
    
    const addThingsToDo = () => {
      setThingsToDoList(
        thingsToDoList.concat(
          <ThingsToDoForm
            key={thingsToDoList.length}
            handleAddMoreInput={(newData, addMoreFunc) => handleAddMoreInput(thingsToDoList.length, newData, addMoreFunc)}
          />
        )
      );
    };

  // Must Do's
  const renderMustDosForm = () => {
    let mustDos = [];
    mustDos.push(
      <MustDosForm
        key={0}
        handleAddMoreInput={(newData, addMoreFunc) => handleAddMoreInput(0, newData, addMoreFunc)}
      />
    );
    return mustDos;
  };
  const [mustDosList, setMustDosList] = useState(renderMustDosForm());
  const addMustDos = () => {
    setMustDosList(
      mustDosList.concat(
        <MustDosForm
          key={mustDosList.length}
          handleAddMoreInput={(newData, addMoreFunc) => handleAddMoreInput(mustDosList.length, newData, addMoreFunc)}
        />
      )
    );
  };

  // Local Gems
  const renderLocalGemForm = () => {
    let localGems = [];
    localGems.push(
      <LocalGemsForm
        key={0}
        handleAddMoreInput={(newData, addMoreFunc) => handleAddMoreInput(0, newData, addMoreFunc)}
      />
    );
    return localGems;
  };
  const [localGemsList, setlocalGemsList] = useState(renderLocalGemForm());
  const addLocalGem = () => {
    setlocalGemsList(
      localGemsList.concat(
        <LocalGemsForm
          key={localGemsList.length}
          handleAddMoreInput={(newData, addMoreFunc) => handleAddMoreInput(localGemsList.length, newData, addMoreFunc)}
        />
      )
    );
  };

  const addMoreFunctions = {
    setGemPlacesFormData: setGemPlacesFormData,
    setMustDosFormData: setMustDosFormData,
    setSuggestedHotelAirBnb: setSuggestedHotelAirBnb,
    setThingsToDoFormData: setThingsToDoFormData,
    setBarClubFormData: setBarClubFormData,
    setRestaurantFormData: setRestaurantFormData
  };
  const handleAddMoreInput = (index, newData, addMoreFunc) => {
    addMoreFunctions[addMoreFunc]((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = newData;
      return updatedData;
    });
  };

  const formFunctions = {
    setSeasonsFormData: setSeasonsFormData,
    setPlacesFormData: setPlacesFormData,
    setWhereToStayFormData: setWhereToStayFormData,
    setBecomingGuideFormData: setBecomingGuideFormData,
    setThingsToDoFormData: setThingsToDoFormData,
    setBarClubFormData: setBarClubFormData,
    setRestaurantFormData: setRestaurantFormData,
    setCityData: setCityData,
    setGoogleLink:setGoogleLink
  };

  useEffect(() => {
    const params = { fields: ['city', 'priority', 'vibe', 'things_to_avoid', 'occasion'] };
    http
      .get('/field_options.json', { params: params })
      .then((res) => {
        setFieldOptions(res.data);
        dispatch(setPriorityOptions(res.data.priority_options));
      })
      .catch(() => {
        showMessage('error', 'Error', 'Sorry, there was an error while fetching field options.', 'bg-red-100 text-red-700');
      });
  }, []);

  const button = {
    localExpert: [
      {
        text: 'Ok',
        onPress: () => {
          setIsVisible(false);
          isGuestUserSignedUp && logOut();
          navigate("/");
        }
      },
    ],
  };

  const handleInput = (formDataFunction, fieldName, newValue) => {
    formFunctions[formDataFunction]((prevData) => {
      if (fieldName === "destinations" && cityData['destinations'].length == 1 && newValue.length > 0) {
        showMessage('error', 'Error', 'Select one city as destination.', 'bg-red-100 text-red-700');
        return prevData;
      } else {
        return {
          ...prevData,
          [fieldName]: newValue,
        };
      }
    });
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const submitForm = () => {
    const params = {
      city: cityData.destinations[0],
      city_description: cityData.cityDescription,
      seasons_data: seasonsFormData,
      places_data: placesFormData,
      attractions: thingsToDoFormData,
      where_to_stay: whereToStayFormData,
      becoming_guide: becomingGuideFormData,
      local_gems: gemPlacesFormData,
      must_dos: mustDosFormData,
      suggested_hotel_air_bnb: { category: "suggested_hotel_air_bnb", suggestedHotelAirBnb: suggestedHotelAirBnb },
      bars_and_clubs: barClubFormData,
      restaurants: restaurantFormData,
      google_link: googleLink
    };

    http
      .post('/local_experts.json', params)
      .then((res) => {
        if (res.status === 200) {
          setModalType('localExpert');
          setIsVisible(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          if (isLoggedIn()) {
            return showMessage('error', 'Error', 'Failed to submit, session expired. Please refresh the page', 'bg-red-100 text-red-700');
          } else {
            return showMessage('error', 'Error', 'Failed to submit, session expired. Please login again.', 'bg-red-100 text-red-700');
          }
        }
        return showMessage('error', 'Error', 'Failed to submit, Please try again.', 'bg-red-100 text-red-700');
      });
  };

  const scrollIntoView = (elementId) => {
    document.getElementById(`${elementId}`).scrollIntoView({ behavior: 'smooth' });
  };

  const months = Array.from({ length: 12 }, (item, index) => {
    const month = new Date(0, index).toLocaleString('default', { month: 'long' });
    return { id: month.toLowerCase(), name: month };
  });

  return (
    <Layout className='flex w-full flex-col'>
      <Toast ref={toast} position="bottom-right" />
      <div className='flex flex-wrap w-full flex-col my-10 lg:mx-5 mx-0'>
        <div id='left' className='flex w-full p-10 w-3/5 flex-col justify-center'>
          <div className='flex w-full'><h1 className="flex w-full text-3xl font-playfair font-normal ">Local Expert Form</h1></div>
          <div className='flex w-full' ><p className="flex w-full mt-6 text-base font-normal leading-8 font-sans">Answer the questions below to be considered for our local expert program. There are a total of 10 questions on the form and should take approximately 20 minutes to complete.</p></div>
        </div>

        <div className='flex flex-col w-full border border-gray-200 rounded-md'>
          <div className="flex flex-col lg:grid lg:grid-cols-4 lg:gap-4 p-5">
            <div className='overflow-x-auto p-2 m-5 flex wrap lg:flex-col gap-2 lg:col-span-1 lg:p-5 lg:mx-10 lg:my-3'>
              <div className='flex flex-col items-center lg:flex-row lg:inline-flex lg:gap-2  group' onClick={() => { scrollIntoView("seasons"), setMenuClicked("seasons"); }}>
                <div className="h-[30px] w-[30px]  rounded-full bg-gray-200 group-hover:bg-periwinkle">
                  {
                    menuClicked == "seasons" ?
                    <img style={{ backgroundSize: "cover", backgroundPosition: "bottom", height: "30px", width: "30px" }} src={cm} alt="checkmark" /> : <></>
                  }
                </div>
                <div className="font-sans text-xl cursor-pointer group-hover:text-periwinkle">Seasons</div>
              </div>

              <div className="my-4 ml-3 h-12 border-l-2 border-gray-200"></div>

              <div className='flex flex-col items-center lg:flex-row lg:inline-flex lg:gap-2  group' onClick={() => { scrollIntoView("places"), setMenuClicked("places"); }}>
                <div className="h-[30px] w-[30px] rounded-full bg-gray-200 group-hover:bg-periwinkle">
                {
                  menuClicked == "places" ?
                  <img style={{ backgroundSize: "cover", backgroundPosition: "bottom", height: "30px", width: "30px" }} src={cm} alt="checkmark" /> : <></>
                }
                </div>
                <div className="font-sans text-xl cursor-pointer group-hover:text-periwinkle">Places</div>
              </div>

              <div className="my-4 ml-3 h-12 border-l-2 border-gray-200"></div>

              <div className='flex flex-col items-center lg:flex-row lg:inline-flex lg:gap-2  group' onClick={() => { scrollIntoView("thingsToDo"), setMenuClicked("thingsToDo"); }}>
                <div className="h-[30px] w-[30px] rounded-full bg-gray-200 group-hover:bg-periwinkle">
                  {
                    menuClicked == "thingsToDo" ?
                    <img style={{ backgroundSize: "cover", backgroundPosition: "bottom", height: "30px", width: "30px" }} src={cm} alt="checkmark" /> : <></>
                  }
                </div>
                <div className="font-sans text-xl cursor-pointer group-hover:text-periwinkle">Things to do</div>
              </div>

              <div className="my-4 ml-3 h-12 border-l-2 border-gray-200"></div>

              <div className='flex flex-col items-center lg:flex-row lg:inline-flex lg:gap-2  group' onClick={() => { scrollIntoView("whereToStay"), setMenuClicked("whereToStay"); }}>
                <div className="h-[30px] w-[30px] rounded-full bg-gray-200 group-hover:bg-periwinkle">
                  {
                    menuClicked == "whereToStay" ?
                    <img style={{ backgroundSize: "cover", backgroundPosition: "bottom", height: "30px", width: "30px" }} src={cm} alt="checkmark" /> : <></>
                  }
                </div>
                <div className="font-sans text-xl cursor-pointer group-hover:text-periwinkle">Where to Stay</div>
              </div>

              <div className="my-4 ml-3 h-12 border-l-2 border-gray-200"></div>
              <div className='flex flex-col items-center lg:flex-row lg:inline-flex lg:gap-2  group' onClick={() => { scrollIntoView("becomingAGuide"), setMenuClicked("becomingAGuide"); }}>
                <div className="h-[30px] w-[30px] rounded-full bg-gray-200 group-hover:bg-periwinkle">
                  {
                    menuClicked == "becomingAGuide" ?
                    <img style={{ backgroundSize: "cover", backgroundPosition: "bottom", height: "30px", width: "30px" }} src={cm} alt="checkmark" /> : <></>
                  }
                </div>
                <div className="font-sans text-xl cursor-pointer group-hover:text-periwinkle">Becoming a guide</div>
              </div>
            </div>
            <div className='lg:col-span-3 lg:p-5 lg:mx-10 '>
              <div id='destinations' className='flex flex-col'>
                <div className='w-full lg:w-1/2 lg:flex-1'>
                  <label className='text-sm font-sans'>
                    Enter destination
                  </label>
                  <SearchDropdown
                    key='destinations'
                    ref={destinationRef}
                    props={{
                      options: fieldOptions.city,
                      chipsColor: 'blue-chips-background',
                      selectedOptions: cityData.destinations,
                      placeHolder: 'Enter a city'
                    }}
                    setSaveData={(val) => handleInput('setCityData', 'destinations', val)}
                  />
                </div>
                <div className='flex gap-10 '>
                  <div className='mt-5 flex-1'>
                    <label className='text-sm font-sans'>
                      One line description of city
                    </label>

                    <input
                      type="text"
                      value={cityData.cityDescription}
                      onInput={(e) => handleInput("setCityData", "cityDescription", e.target.value)}
                      className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                    />
                  </div>
                </div>
              </div>
              <div id='seasons' className='flex flex-col'>
                <h1 className="text-2xl font-bold">Seasons</h1>
                <div className='flex gap-10 '>
                  <div className='mt-5 flex-1'>
                    <label className='text-sm font-sans'>
                      Busy Season
                    </label>
                    <MultiSelectDropdown
                      key='month-selector'
                      dropdownWidth={'w-full'}
                      items={months}
                      chipsColor='blue-chips-background'
                      selectedItems={ seasonsFormData.busySeason }
                      setSaveData={(val) => handleInput('setSeasonsFormData', 'busySeason', val)}
                    />
                  </div>
                  <div className='mt-5 flex-1'>
                    <label className='text-sm font-sans'>
                      Down Season
                    </label>
                    <MultiSelectDropdown
                      key='month-selector'
                      dropdownWidth={'w-full'}
                      items={months}
                      chipsColor='blue-chips-background'
                      selectedItems={ seasonsFormData.downSeason }
                      setSaveData={(val) => handleInput('setSeasonsFormData', 'downSeason', val)}
                    />
                  </div>
                </div>
                <div className='flex gap-10 '>
                  <div className='mt-5 flex-1'>
                    <label className='text-sm font-sans'>
                      Local Holidays
                    </label>

                    <input
                      type="text"
                      value={seasonsFormData.localHolidays}
                      onInput={(e) => handleInput("setSeasonsFormData", "localHolidays", e.target.value)}
                      className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                    />
                  </div>
                </div>
                <div className='flex gap-10 '>
                  <div className='mt-5 w-full lg:w-1/2'>
                    <label className='text-sm font-sans'>
                      Weather
                    </label>

                    <input
                      type="text"
                      key='text'
                      value={seasonsFormData.weather}
                      onInput={(e) => handleInput("setSeasonsFormData", "weather", e.target.value)}
                      className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                    />
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-200 my-5"></div>

              <div id='places' className='flex flex-col'>
                <h1 className="text-2xl font-bold">Places</h1>

                <div className='flex gap-10 '>
                  <div className='mt-5 w-full'>
                    <label className='text-sm font-sans'>
                      Areas to avoid
                    </label>

                    <input
                      type="text"
                      key='text'
                      value={placesFormData.areasToAvoid}
                      onInput={(e) => handleInput("setPlacesFormData", "areasToAvoid", e.target.value)}
                      className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                    />
                  </div>
                </div>
                <div className='flex gap-10 '>
                  <div className='mt-5 w-full'>
                    <label className='text-sm font-sans'>
                      Areas that are happening right now
                    </label>

                    <input
                      type="text"
                      key='text'
                      value={placesFormData.areasThatAreHappeningRightNow}
                      onInput={(e) => handleInput("setPlacesFormData", "areasThatAreHappeningRightNow", e.target.value)}
                      className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                    />
                  </div>
                </div>
                <h2 className='text-lg font-bold mt-2'>Local Gems</h2>
                { localGemsList }
                <h4
                  className='my-7 text-periwinkle font-bold font-sans cursor-pointer'
                  onClick={addLocalGem}
                >
                  Add a local gem +
                </h4>
                <div className='flex gap-10 '>
                  <div className='mt-5 w-full'>
                    <label className='text-sm font-sans'>
                      Coworking spaces
                    </label>

                    <input
                      type="text"
                      key='text'
                      value={placesFormData.coworkingSpaces}
                      onInput={(e) => handleInput("setPlacesFormData", "coworkingSpaces", e.target.value)}
                      className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                    />
                  </div>
                </div>
                <div className='flex gap-10 '>
                  <div className='mt-5 w-full'>
                    <label className='text-sm font-sans'>
                      Places for families
                    </label>

                    <input
                      type="text"
                      key='text'
                      value={placesFormData.placesForFamilies}
                      onInput={(e) => handleInput("setPlacesFormData", "placesForFamilies", e.target.value)}
                      className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                    />
                  </div>
                </div>
                  <div className='flex gap-10 '>
                    <div className='mt-5 w-full'>
                      <label className='text-sm font-sans'>
                        Places for singles
                      </label>

                      <input
                        type="text"
                        key='text'
                        value={placesFormData.placesForSingles}
                        onInput={(e) => handleInput("setPlacesFormData", "placesForSingles", e.target.value)}
                        className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                      />
                    </div>
                  </div>
              </div>

              <div className="border-t border-gray-200 my-5"></div>
              <div id='thingsToDo' className='flex flex-col'>
                <h1 className="text-2xl font-bold">Things to do</h1>
                <h3 className="text-lg font-bold mt-5">Top Attractions</h3>

                { thingsToDoList }
                <h4
                  className = 'my-5 text-periwinkle font-bold font-sans cursor-pointer'
                  onClick = { addThingsToDo }
                >
                  Add attraction +
                </h4>

                <h3 className='text-lg font-bold mt-2'>Best Food</h3>
                <div className='grid grid-cols-2 gap-x-10'>
                  { restaurantList.map((item, index) => (
                    <div key = { index } className = { `col-span-1 ${ index % 2 === 0 ? '' : 'col-start-2' }` }>
                      { item }
                    </div>
                  ))}
                </div>
                <h4
                  className='my-5 text-periwinkle font-bold font-sans cursor-pointer'
                  onClick = { addRestaurant }
                >
                  Add restaurant +
                </h4>
                <div className='flex gap-10 '>
                  <div className='mt-5 w-full'>
                    <label className='text-sm font-sans'>
                      Link to a google map (optional)
                    </label>

                    <input
                      type="text"
                      value={thingsToDoFormData.googleLink}
                      onInput={(e) => handleInput("setGoogleLink", "googleLink", e.target.value)}
                      className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                    />
                  </div>
                </div>

                <h3 className='text-lg font-bold mt-5'>Must Do's for first time visitors</h3>
                { mustDosList }
                <h4
                  className='my-5 text-periwinkle font-bold font-sans cursor-pointer'
                  onClick={addMustDos}
                >
                  Add a must do +
                </h4>
                <h3 className='text-lg font-bold mt-5'>Best bars and clubs</h3>
                <div className='grid grid-cols-2 gap-x-10'>
                  { barClubList.map((item, index) => (
                    <div key = { index } className = { `col-span-1 ${ index % 2 === 0 ? '' : 'col-start-2' }` }>
                      { item }
                    </div>
                  ))}
                </div>
                <h4
                  className='my-5 text-periwinkle font-bold font-sans cursor-pointer'
                  onClick = { addBarClub }
                >
                  Add bar/club +
                </h4>

                <div className="border-t border-gray-200 my-7"></div>
                <div id='whereToStay' className='flex flex-col'>
                  <h1 className="text-2xl font-bold">Where To Stay</h1>

                  <div className='flex flex-col '>
                    <div className='mt-10 flex gap-10'>
                      <div>
                        <input
                          type='radio'
                          value={whereToStayFormData.type}
                          checked={whereToStayFormData.type === "Hotel"}
                          onChange={() => handleInput("setWhereToStayFormData", "type", "Hotel")}
                        />
                        <label className='text-sm ml-1 font-sans'>
                          Hotel
                        </label>
                      </div>
                      <div>
                        <input
                          type='radio'
                          value={whereToStayFormData.type}
                          checked={whereToStayFormData.type === "Airbnb"}
                          onChange={() => handleInput("setWhereToStayFormData", "type", "Airbnb")}
                        />
                        <label className='text-sm ml-1 font-sans'>
                          Airbnb
                        </label>
                      </div>
                      <div>
                        <input
                          type='radio'
                          value={whereToStayFormData.type}
                          checked={whereToStayFormData.type === "Other"}
                          onChange={() => handleInput("setWhereToStayFormData", "type", "Other")}
                          />
                          <label className='text-sm ml-1 font-sans'>
                            Other
                          </label>
                        </div>
                    </div>
                    <div className='mt-10 w-full lg:w-1/2'>
                      <label className='text-sm font-sans'>
                        Suggested hotel/airbnb
                      </label>
                      { hotelAirBnbList }
                    </div>
                  </div>
                  <h4
                    className='my-5 text-periwinkle font-bold font-sans cursor-pointer'
                    onClick={addHotelAirBnbs}
                  >
                    Add a hotel/Airbnb +
                  </h4>
                </div>
                <div className="border-t border-gray-200 my-5"></div>
                <div id='becomingAGuide' className='flex flex-col'>

                  <h1 className="text-2xl font-bold">Becoming a guide</h1>

                  <div className='flex flex-col'>
                      <div className='mt-7'>
                        <p className='font-sans'>We’re looking for guides to help show travelers around, greet them, translate for them and be their guide to all
                          things in your city. You can decide how involved you’d like to be! You will be compensated for your time.</p>
                      </div>
                      <div className='mt-7'>
                        <p className='font-sans'>Would you be interested in becoming a guide?</p>
                      </div>
                  </div>

                  <div className='flex flex-col gap-2 mt-5'>
                    <div className='flex gap-1'>
                      <input
                        type='radio'
                        checked={becomingGuideFormData.willYou === "Yes"}
                        onChange={() => handleInput("setBecomingGuideFormData", "willYou", "Yes")}
                      />
                      <label className='text-sm font-sans'>
                        Yes, sign me up!
                      </label>
                    </div>

                    <div className='flex gap-1'>
                      <input
                        type='radio'
                        value={becomingGuideFormData.willYou}
                        checked={becomingGuideFormData.willYou === "No"}
                        onChange={() => handleInput("setBecomingGuideFormData", "willYou", "No")}
                      />
                      <label className='text-sm font-sans'>
                        No thanks
                      </label>
                    </div>
                  </div>

                  <div className='mt-5'>
                    <label className='text-sm font-sans'>
                      In a few sentences, could you tell us why you are not interested?
                    </label>
                    <input
                      type="text"
                      value={becomingGuideFormData.notInterested}
                      onInput={(e) => handleInput("setBecomingGuideFormData", "notInterested", e.target.value)}
                      className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div>
            <BtnTransparent
              props={{
                bg: 'bg-periwinkle',
                border: 'border-periwinkle',
                buttonName: 'Save',
                text: 'text-white mt-4 w-full h-[40px] font-semibold',
              }}
            handleClick={ openSignUpModal }
            />
          </div>
        </div>
      </div>
      {isVisible &&
        <CustomModal
          type={ modalType }
          isVisible={ isVisible }
          setVisible={ setIsVisible }
          buttons={ button[modalType] }
        />
      }
      <SignupModal
        isVisible={ isSignUpModalVisible }
        onClose={(info) => closeSignUpModal(info) }
        inStay={ false }
        localExpertForm={ true }
      />
    </Layout>
  );
};

export default LocalExpertProgramForm;
