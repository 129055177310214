import BackgroundImage from 'hero1.png';

import ItineraryForm from '../Forms/ItineraryForm';
import InpirationForm from '../Forms/InspirationForm';

import React, { useState } from 'react';

const Hero = ({ isMobile }) => {
  const [activeOption, setActiveOption] = useState('generate');

  const backgroundImage = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    minHeight: '1310px',
  };

  return (
    <section className="flex flex-col">
      <div className="relative isolate flex flex-col sm:flex-row items-center pb-32 sm:px-0 pt-14 lg:px-8" style={backgroundImage}>
        <div className="w-full sm:w-3/5">
          <div className="py-20 sm:py-20 lg:py-56">
            <div className={`text-left ${isMobile ? 'px-4' : 'pl-4' }`}>
              <h1 className={`font-bold tracking-tight ${isMobile ? 'text-3xl' : 'text-4xl'} text-white font-playfair`}>Dream it. Book it. Live it.</h1>
              <div className="max-w-[615px]">
                <p className={`mt-6 text-gray-600 text-white font-normal ${isMobile ? 'text-xl' : 'text-2xl leading-8'}`}>Artificial intelligence meets trip planning. We take away all the hassle of trip planning. You tell us what you want, and we&apos;ll take care of the rest. WePlan, You Live.</p>
              </div>
            </div>
          </div>
        </div>

        <div className='w-full sm:w-2/5 sm:mb-2'>
          <div className='mx-auto max-w-lg'>
            {activeOption === "generate" &&
              <ItineraryForm
                isMobile={isMobile}
                activeOption={activeOption}
                setActiveOption={setActiveOption}
              />
            }
            {activeOption !== "generate" &&
              <InpirationForm
                isMobile={isMobile}
                activeOption={activeOption}
                setActiveOption={setActiveOption}
              />
            }
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
