export const formatDate = ( dateString ) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return formattedDate;
};
