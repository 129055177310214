import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formStateChanged: false,
};

const formDataSlice = createSlice({
  name: 'formStateChanged',
  initialState,
  reducers: {
    setFormStateChanged: (state, action) => {
      state.formStateChanged = action.payload;
    },
  }
});

export const { setFormStateChanged } = formDataSlice.actions;
export default formDataSlice.reducer;
