import React, { useState } from 'react';
import vs from 'vs.png';
import mts from 'mts.png';
import s from 's.png';
import lts from 'lts.png';
import naas from 'naas.png';

export default function SatisfactionEmojis({ handleInput }) {
  const pricsSatisfactionCss =
    'cursor-pointer transition-transform duration-300 transform ';

  const [clickedIndex, setClickedIndex] = useState(null);

  const handleSatisfaction = (value, index) => {
    handleInput('price_satisfaction', value);
    setClickedIndex(index);
    // setTimeout(() => setClickedIndex(null), 1000);
  };

  return (
    <div className='flex flex-wrap w-full mt-3 content-center gap-2'>
      {[
        { src: vs, alt: 'verySatisfied' },
        { src: mts, alt: 'moreThanSatisfied' },
        { src: s, alt: 'satisfied' },
        { src: lts, alt: 'lessThanSatisfied' },
        { src: naas, alt: 'notAtAllSatisfied' },
      ].map((emoji, index) => (
        <img
          key={index}
          className={`${pricsSatisfactionCss} ${index === clickedIndex ? 'scale-125' : ''}`}
          onClick={() => handleSatisfaction(emoji.alt, index)}
          src={emoji.src}
          alt={emoji.alt}
        />
      ))}
    </div>
  );
}
