import mixpanel from 'mixpanel-browser';
import { getUserId } from "./auth";

export const triggerMixpanelEvent = (eventName, data = {}) => {
  try {
    mixpanel.init(process.env.MIXPANEL_TOKEN, { debug: true, track_pageview: true, persistence: 'localStorage' });
    mixpanel.identify(getUserId());
    mixpanel.track(eventName, data);
  } catch (error) {
    console.error('Event Name:', eventName);
    console.error('Event Data:', data);
    console.error('Error initializing Mixpanel:', error);
  }
};
