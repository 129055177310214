import React from 'react';
import Logo from 'we_plan_purple.png';
import { Rating } from 'primereact/rating';
import { Carousel } from 'primereact/carousel';

const Review = ({ review, isMobile }) => {
  const itemTemplate = (review) => {
    return (
      <div className='flex justify-between text-sm mt-8 w-full'>
        <div className='flex gap-x-2'>
          {
            review.profile_photo_url ? 
            <img src={review.profile_photo_url} alt='icon' className='w-[40px] h-[40px] rounded-full' /> :
            <div className='w-[40px] h-[40px] rounded-full bg-gray-300' ></div>
          }
          
          <div className='flex flex-col'>
            <span className='text-base font-sans font-normal'>{review.author_name}</span>
            <p className='text-light-black text-sm font-sans font-normal'>{review.date}</p>
            <Rating
              value={review.rating}
              cancel={false}
              className='text-yellow-500'
            />
            <p className='text-base font-normal font-sans py-4 h-32 overflow-y-auto'>
              {review.comment}
            </p>
          </div>
        </div>
        {!isMobile && <img src={Logo} alt='icon' className='h-5' />}
      </div>
    );
  };

  if (!isMobile) { return itemTemplate(review); }

  return (
    <div className='flex'>
      { review && (
        <Carousel
          numScroll={1}
          value={review}
          numVisible={1}
          showNavigators={true}
          itemTemplate={itemTemplate}
        />
      )}
    </div>
  );
};

export default Review;
