import http from '../common/http';
import React, { useEffect, useState, useRef } from 'react';
import SurveyCard from './Survey';
import Inspiration from './Inspiration';
import HotelDetailPage from './DetailPage';
import HotelBooking from './HotelBooking/HotelBooking';
import Layout from './Layout/Layout';
import CityDetail from './CityDetail';
import { Toast } from 'primereact/toast';
import { useDispatch, useSelector } from 'react-redux';
import { setPriorities } from '../redux/slices/priorities';
import { setOccasions } from '../redux/slices/occassions';
import { setVibes } from '../redux/slices/vibes';
import { setThingsToAvoid } from '../redux/slices/thingsToAvoid';
import { useLocation } from 'react-router-dom';
import { setCities } from '../redux/slices/cities';
import { setTopCountries } from '../redux/slices/countries';
import { setContinents } from '../redux/slices/continents';

const Results = () => {
  const toast = useRef(null);

  const dispatch = useDispatch();
  const location = useLocation();
  const state = useSelector((state) => state);
  const cityDetail = useSelector((state) => state.cityDetail.cityDetail);

  const [isHotelShow, setIsHotelShow] = useState(false);
  const [isHotelBooking, setIsHotelBooking] = useState(false);
  const [offerId, setOfferId] = useState(null);
  const [hotelId, setHotelId] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isCityDetils, setCityDetail] = useState(false);

  const stateFieldMap = [
    { state: 'priorities', field: 'priority', fieldState: 'topPriorities', action: setPriorities },
    { state: 'occasions', field: 'occasion', fieldState: 'topOccasions', action: setOccasions },
    { state: 'vibes', field: 'vibe', fieldState: 'vibes', action: setVibes },
    { state: 'thingsToAvoid', field: 'things_to_avoid', fieldState: 'avoids', action: setThingsToAvoid },
    { state: 'cities', field: 'city', fieldState: 'cities', action: setCities },
    { state: 'countries', field: 'country', fieldState: 'topCountries', action: setTopCountries },
    { state: 'continents', field: 'survey_continents_array', fieldState: 'continents', action: setContinents },
  ];

  useEffect(() => {
    fetchFieldsOption();
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {
    resetStates();
    setRouteParams();
  }, [location.search]);

  const fetchFieldsOption = () => {
    const fieldOptions = { fields: stateFieldMap.filter(({ state: stateKey }) => Object.keys(state[stateKey]).length > 0)
                                                .map(({ field }) => field),
    };

    if (fieldOptions.fields.length > 0) {
      http
        .get('/field_options.json', { params: fieldOptions })
        .then((res) => {
          stateFieldMap.forEach(({ state: stateKey, field, fieldState, action }) => {
            if (state[stateKey][fieldState]) {
              dispatch(action(res.data[field]));
            }
          });
        })
        .catch(() => {
          showMessage('error', 'Error', 'Sorry, there was an error while fetching field options.', 'bg-red-100 text-red-700');
        });
    }
  };

  const setRouteParams = () => {
    const params = new URLSearchParams(location.search);
    const indexFromQuery = parseInt(params.get('activeIndex'));

    if (!isNaN(indexFromQuery)) {
      setActiveIndex(indexFromQuery);
    }

    if (params.get('show_hotel')) {
      setIsHotelShow(params.get('show_hotel'));
    } else if (params.get('show_booking')) {
      setIsHotelBooking(params.get('show_booking'));
      setOfferId(params.get('offer_id'));
      setHotelId(params.get('hotel_id'));
    } else if (params.get('is_city_detail')) { 
      setCityDetail(true);
    }
  };

  const resetStates = () => {
    setIsHotelShow(false);
    setIsHotelBooking(false);
    setCityDetail(false);
    setActiveIndex(0);
  };

  const renderComponent = () => {
    if (isCityDetils) {
      return ( <CityDetail props={ cityDetail } /> );
    } else if (isHotelBooking) {
      return ( <HotelBooking hotelId={hotelId} offerId={offerId} /> );
    } else if (isHotelShow) {
      return ( <HotelDetailPage /> );
    } else {
      return ( <Inspiration isMobile={isMobile} /> );
    }
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  return (
    <Layout headerCSS={'bg-[#FFFCF9] mb-20'}>
      <div className='bg-[#FCFCFC] flex flex-wrap w-full'>
        <section className='flex flex-wrap w-full mt-10'>
          { activeIndex === 0 && renderComponent() }
          { activeIndex === 2 && <SurveyCard /> }
        </section>
      </div>
    </Layout>
  );
};

export default Results;
