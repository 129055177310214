import axios from 'axios';

const axiosInstance = axios.create({
  headers: {
    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
    'X-Key-Inflection': 'camel',
  }
});

export default axiosInstance;
