import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const HotelIndexSkeletonCarousal = () => {
  const timesToRender = 4;
  return (
    <>
      <div className='flex flex-col lg:flex-row gap-2 p-5 mx-2'>
      { Array.from({ length: timesToRender }).map((_, index) => (
        <div className='w-full lg:w-5/12 h-[400px] flex flex-col card-container shadow-md p-2' key = { index }>
        <Skeleton variant="rectangular" width={"w-full"} height={'100%'} />
        <div className='flex justify-between '>
          <div className='flex flex-col m-1 gap-1 w-[300px]'>
            <div>
              <Skeleton className='m-1' variant="rectangular" width={'w-full'} height={20} />
            </div>
            <div>
              <Skeleton className='m-1' variant="rectangular" width={'w-full'} height={20} />
            </div>
          </div>
          <Skeleton className='mr-1 mt-2' variant="rectangular" width={50} height={20} />
        </div>
      </div>
      )) }
      </div>
    </>
  );
};

export default HotelIndexSkeletonCarousal;
