import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { calculateDistances } from './Common/TripPlanCommon';
import { setTripPlan } from '../redux/slices/tripPlan';
import { setLocationsCoordination, resetState } from '../redux/slices/locationsCoordination';
import { Toast } from 'primereact/toast';
import { getIconToDisplay } from '../common/shared/displayIconsInItinerary';

const TripPlanHelper = forwardRef(({ handleStates, statesData, setMarkerLocations }, ref) => {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const checkDayViewTimeConflict = (data) => {
    const currentActivity = cloneDeep(data.currentActivity);
    const newTimeSlot = cloneDeep(data.updatedTime);

    const clonedTripData = cloneDeep(statesData.itineraryPlan);
    const planIndex = clonedTripData.findIndex((plan) => plan.cityName === data.cityName);
    const cityPlan = clonedTripData[planIndex];
    const dayPlan = cityPlan.days_data[data.day];

    const conflict = dayPlan.places.find((place) => {
      if (place.name == currentActivity.name) {
        return false;
      }
      const [startTime, endTime] = place.time.split(' - ');
      const prevTimeSlot = { startTime, endTime };
      return compareTimes(newTimeSlot, prevTimeSlot);
    });
    if (conflict) {
      handleStates(false, 4);
    }
    return conflict;
  };

  const setCoordinates = (data) => {
    dispatch(resetState());
    data.forEach((itinerary) => {
      const locations = {};
    
      Object.entries(itinerary.days_data).forEach(([key, obj]) => {
        locations[key] = [];
        if (obj.places) {
          locations[key].push(...obj.places.map((place, index) => ({ iconUrl: getIconToDisplay(place.place_type), placeObj: place, placeIndex: index, placeName: place.name, location: place.location })));
        }
      });
      dispatch(setLocationsCoordination(locations));
    });
  };

  const calculatePrice = async (data, originalDay) => {
    const missingInData = [];
    const missingInOriginal = [];
    let breakDown = { ...statesData.priceBreakdown };
    const restaurantTypes = ['Breakfast', 'Lunch', 'Dinner', 'Brunch'];

    originalDay.forEach((place) => {
      const foundInData = data.some(item => item.name === place.name);
      if (!foundInData) {
          missingInData.push(place);
      }
    });

    data.forEach((place) => {
      const foundInOriginal = originalDay.some(item => item.name === place.name);
      if (!foundInOriginal) {
          missingInOriginal.push(place);
      }
    });

    missingInData.forEach((place) => {
      if (restaurantTypes.includes(place.placeType) || !place.placeType) {
          breakDown.food -= place.ai_suggested_price;
      } else {
          breakDown.activity -= place.ai_suggested_price;
      }
    });

    missingInOriginal.forEach((place) => {
      if (restaurantTypes.includes(place.placeType) || !place.placeType) {
          breakDown.food += place.ai_suggested_price;
      } else {
          breakDown.activity += place.ai_suggested_price;
      }
    });
    breakDown.total = breakDown.food + breakDown.activity + breakDown.accommodation;

    handleStates(breakDown, 6);
  };


  const parseTimeString = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(str => parseInt(str));
    let adjustedHours = hours % 12;
    if (timeString.includes('PM')) {
      adjustedHours += 12;
    }
    return new Date(0, 0, 0, adjustedHours, minutes);
  };
  
  const compareTimes = (timeRangeA, timeRangeB) => {
    const startTimeA = parseTimeString(timeRangeA.startTime);
    const endTimeA = parseTimeString(timeRangeA.endTime);
    const startTimeB = parseTimeString(timeRangeB.startTime);
    const endTimeB = parseTimeString(timeRangeB.endTime);
    return (
      (startTimeA >= startTimeB && endTimeA <= endTimeB) ||
      (startTimeA <= startTimeB && endTimeA >= endTimeB ) ||
      (startTimeA < startTimeB && endTimeA > startTimeB && endTimeA < endTimeB) ||
      (startTimeB < startTimeA && endTimeB > startTimeA && endTimeB < endTimeA) ||
      (startTimeA > startTimeB && endTimeA < endTimeB) ||
      (startTimeB > startTimeA && endTimeB < endTimeA)
    );
  };

  const checkAllViewTimeConflict = (data) => {
    const allViewSelectedActivity = data.allViewSelectedActivity;
    const selectedDate = data.selectedDate;
    const activityStartTime = data.activityStartTime;
    const activityEndTime = data.activityEndTime;
    let clonedTripData = cloneDeep(statesData.itineraryPlan);
    let planIndex = clonedTripData.findIndex((plan) => plan.cityName === allViewSelectedActivity.cityName);
    let cityPlan = clonedTripData[planIndex];
    let dayPlan = cityPlan.days_data[selectedDate.idx];

    let conflict = dayPlan.places.find((place) => {
      if (place.name == allViewSelectedActivity.name) {
        return false;
      }
      let newTimeSlot = { startTime: activityStartTime, endTime: activityEndTime };
      const [startTime, endTime] = place.time.split(' - ');
      const prevTimeSlot = { startTime, endTime };
      return compareTimes(newTimeSlot, prevTimeSlot);
    });
    if (conflict) {
      handleStates(false, 4);
    }
    return conflict;
  };

  const sortPlacesTime = (places) => {
    let sortedPlaces = places.sort((a, b) => {
      const timeA = a.time.split(' - ')[0];
      const timeB = b.time.split(' - ')[0];

      return Date.parse('1970/01/01 ' + timeA) - Date.parse('1970/01/01 ' + timeB);
    });
    return sortedPlaces;
  };

  useImperativeHandle(ref, () => ({
    async handleswapTripPlaces(data, resolveConflict) {
      setMarkerLocations([]);
      handleStates('dayView', 0);
      handleStates(data, 1);
      if (
        data.updatedTime.startTime &&
        data.updatedTime.endTime &&
        !resolveConflict &&
        checkDayViewTimeConflict(data)
      ) {
        handleStates(true, 2);
        return;
      }

      let currentActivity = data.currentActivity;
      let newActivity = cloneDeep(data.newActivity);
      let clonedTripData = cloneDeep(statesData.itineraryPlan);
      let planIndex = clonedTripData.findIndex((plan) => plan.cityName === data.cityName);
      let cityPlan = clonedTripData[planIndex];
      let dayPlan = cityPlan.days_data[data.day];
      let clonedDayPlan = cloneDeep(dayPlan.places);
      let activtyNotPresent = true;
      if (isEmpty(newActivity) && data.updatedTime.startTime && data.updatedTime.endTime) {
        // when only updating the time or when add new Activity
        let updatedPlaces = dayPlan.places.map((place) => {
          const [startTime, endTime] = place.time.split(' - ');
          const prevTimeSlot = { startTime, endTime };
          const newTimeSlot = { startTime: data.updatedTime.startTime, endTime: data.updatedTime.endTime };
          if (place.name != currentActivity.name && compareTimes(newTimeSlot, prevTimeSlot)) {
            return null;
          }
          if (place.name == currentActivity.name) {
            let activity = cloneDeep(currentActivity);
            activity.time = `${data.updatedTime.startTime} - ${data.updatedTime.endTime}`;
            activtyNotPresent = false;
            return activity;
          }
          return place;
        });
        updatedPlaces = updatedPlaces.filter(obj => obj !== null);
        if (activtyNotPresent) {
          let activity = cloneDeep(currentActivity);
          activity.time = `${data.updatedTime.startTime} - ${data.updatedTime.endTime}`;
          updatedPlaces.push(activity);
        }
        updatedPlaces = sortPlacesTime(updatedPlaces);
        dayPlan.places = updatedPlaces;
      } else if (data.updatedTime.startTime && data.updatedTime.endTime) {
        // when swapping activity along with new timings
        let swapped = false;
        let updatedPlaces = dayPlan.places.map((place) => {
          const [startTime, endTime] = place.time.split(' - ');
          const prevTimeSlot = { startTime, endTime };
          const newTimeSlot = { startTime: data.updatedTime.startTime, endTime: data.updatedTime.endTime };
          if (place.name == currentActivity.name) return null;

          if (place.name == newActivity.name || compareTimes(newTimeSlot, prevTimeSlot)) {
            if (swapped) return null;

            newActivity.time = `${data.updatedTime.startTime} - ${data.updatedTime.endTime}`;
            swapped = true;
            return newActivity;
          }
          return place;
        });
        if (!swapped) {
          let activity = cloneDeep(newActivity);
          activity.time = `${data.updatedTime.startTime} - ${data.updatedTime.endTime}`;
          updatedPlaces.push(activity);
        }
        updatedPlaces = updatedPlaces.filter(obj => obj !== null);
        updatedPlaces = sortPlacesTime(updatedPlaces);
        dayPlan.places = updatedPlaces;
      } else {
        // when only swapping activites
        newActivity.time = data.currentActivity.time;
        let swapped = false;
        let updatedPlaces = dayPlan.places.map((place) => {
          if (place.name == currentActivity.name && !swapped) {
            swapped = true;
            return newActivity;
          }
          return place;
        });
    
        if (swapped) {
          updatedPlaces = sortPlacesTime(updatedPlaces);
          dayPlan.places = updatedPlaces;
        }
      }
      let places = await calculateDistances(dayPlan.places);
      await calculatePrice(dayPlan.places, clonedDayPlan);
      dayPlan.places = places;
      cityPlan.days_data[data.day] = dayPlan;
      clonedTripData[planIndex] = cityPlan;
      handleStates(clonedTripData, 3);
      dispatch(setTripPlan(clonedTripData));
      setCoordinates(clonedTripData);
      handleStates(true, 4);
    },

    async doAllViewSwap(data, resolveConflict) {
      const allViewSelectedActivity = data.allViewSelectedActivity;
      const selectedDate = data.selectedDate;
      const activityStartTime = data.activityStartTime;
      const activityEndTime = data.activityEndTime;
      if (!resolveConflict && checkAllViewTimeConflict(data)) {
        handleStates(true, 2);
        return;
      }
  
      let clonedTripData = cloneDeep(statesData.itineraryPlan);
      let planIndex = clonedTripData.findIndex((plan) => plan.cityName === allViewSelectedActivity.cityName);
      let cityPlan = clonedTripData[planIndex];
      let dayPlan = cityPlan.days_data[selectedDate.idx];
      let activtyNotPresent = true;
      let updatedPlaces = dayPlan.places.map((place) => {
        const [startTime, endTime] = place.time.split(' - ');
        const prevTimeSlot = { startTime, endTime };
        const newTimeSlot = { startTime: activityStartTime, endTime: activityEndTime };
        if (place.name != allViewSelectedActivity.name && compareTimes(newTimeSlot, prevTimeSlot)) {
          return null;
        }
        if (place.name == allViewSelectedActivity.name) {
          activtyNotPresent = false;
          let activity = cloneDeep(allViewSelectedActivity);
          activity.time = `${activityStartTime.replace(/\s/g, '')} - ${activityEndTime.replace(/\s/g, '')}`;
          return activity;
        }
        return place;
      });
      updatedPlaces = updatedPlaces.filter(obj => obj !== null);
      let insertIndex = 0;
      handleStates({ ...allViewSelectedActivity, time: `${activityStartTime} - ${activityEndTime}` }, 5);
      if (activtyNotPresent) {
        for (let i = 0; i < updatedPlaces.length; i++) {
          const timeSlot = updatedPlaces[i].time;
          const startTime = new Date('2024-01-01 ' + timeSlot.split(' - ')[0]);
          const startTimeA = new Date('2024-01-01 ' + activityStartTime);
          if (startTimeA > startTime) {
            insertIndex = i + 1;
          }
        }
        updatedPlaces.splice(insertIndex, 0, { ...allViewSelectedActivity, time: `${activityStartTime.replace(/\s/g, '')} - ${activityEndTime.replace(/\s/g, '')}` });
      }
      updatedPlaces = sortPlacesTime(updatedPlaces);
      let places = await calculateDistances(updatedPlaces);
      dayPlan.places = places;
  
      cityPlan[selectedDate.dayIdx] = dayPlan;
      clonedTripData[planIndex] = cityPlan;
      handleStates(clonedTripData, 3);
      dispatch(setTripPlan(clonedTripData));
      setCoordinates(clonedTripData);
      resolveConflict = true;
    },

    async removeItinearyPlace(data) {
      let currentActivity = data.currentActivity;
      let clonedTripData = cloneDeep(statesData.itineraryPlan);
      let planIndex = clonedTripData.findIndex((plan) => plan.cityName === data.cityName);
      let cityPlan = clonedTripData[planIndex];
      let dayPlan = cityPlan.days_data[data.day];

      if (dayPlan.places.length > 1) {
        setMarkerLocations([]);
      }
  
      if (dayPlan.places.length <= 1) {
        showMessage('error', 'Error', 'You cannot delete the last activity. Please consider swapping it with another activity instead.', 'bg-red-100 text-red-700');
        return false;
      }
      let removed = false;
      let updatedPlaces = dayPlan.places.filter((place) => {
        if (place.name === currentActivity.name && !removed) {
          removed = true;
          return false;
        }
        return true;
      });
      if (updatedPlaces.length > 1) {
        updatedPlaces = await calculateDistances(updatedPlaces);
      }
      dayPlan.places = updatedPlaces;
      cityPlan.days_data[data.day] = dayPlan;
      clonedTripData[planIndex] = cityPlan;
  
      handleStates(clonedTripData, 3);
      dispatch(setTripPlan(clonedTripData));
      setCoordinates(clonedTripData);
      return true;
    }
  }));

  return (
    <div>
      <Toast ref={toast} position="bottom-right" />
    </div>
  );
});

TripPlanHelper.displayName = 'TripPlanHelper';

export default TripPlanHelper;
