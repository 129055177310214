import http from '../common/http';
import loadingSpinner from 'loader.png';
import ItineraryForm from './ItineraryForm';
import TripCard from './UIComponents/Cards/Card';
import FeaturedDestinations from './FeaturedDestinations/FeaturedDestinations';
import MultiSelectDropdown from './UIComponents/InputFields/MultiselectDropdown';
import SingleSelectDropdown from './UIComponents/Dropdowns/SingleSelectDropdown';

import { debounce } from 'lodash';
import { Toast } from 'primereact/toast';
import { useSelector } from 'react-redux';
import { Dialog } from "primereact/dialog";
import { useLocation } from 'react-router-dom';
import { IoSearchSharp } from "react-icons/io5";
import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect, useRef } from 'react';
import { BtnTransparent } from './UIComponents/buttons';
import { isLoggedIn } from '../common/auth';
import InspirationCardsSkeleton from './Skeletons/InspirationCardsSkeleton';
import { triggerMixpanelEvent } from '../common/mixpanel';
import Paginator from './UIComponents/Paginator/Paginator';

export default function Inspiration({ isMobile }) {
  const toast = useRef(null);

  const location = useLocation();
  const vibeOptions = useSelector(state => state.vibes);
  const citiesOption = useSelector(state => state.cities.cities);
  const continentsOption = useSelector(state => state.continents.continents);
  const vibesValue = vibeOptions.vibes.map(vibe => vibe['name']);
  const thingsToAvoid = useSelector(state => state.thingsToAvoid.avoids);
  const priorities = useSelector(state => state.priorities.topPriorities);
  const countriesOption = useSelector(state => state.countries.topCountries);

  const [cityName, setCityName] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [interests, setInterests] = useState([]);
  const [selectedVibe, setSelectedVibe] = useState('');
  const [enableButton, setButtonEnable] = useState(false);
  const [openFilterModal, setOpenFilters] = useState(false);
  const [recommendations, setRecommendation] = useState([]);
  const [selectedClimate, setSelectedClimate] = useState('');
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedPriorities, setSelectedPriorities] = useState([]);
  const [selectedAvoidThings, setSelectedAvoidThings] = useState([]);
  const [selectedDestinations, setSelectedDestinations] = useState([]);
  const [selectedContinents, setSelectedContinents] = useState([]);
  const [featuredDestination, setFeaturedDestinationVisible] = useState(true);
  const [offset, setOffset] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [allowFilter, setAllowFilter] = useState(false);

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  useEffect(() => {
    const fetchRecommendations = () => {
      setLoading(true);
      let params = setRouteParams();
      params = { ...params, offset: offset };
        http
          .get('/get-recommendations', { params : params })
          .then((res) => {
            setRecommendation(res.data.recommendations);
            setTotalRecords(res.data.total_records);
          })
          .catch((err) => {
            console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    if (isLoggedIn()) {
      http
        .get('/interests')
        .then((res) => {
          setInterests(res.data.interested_cities);
        })
        .catch(() => {
          showMessage('error', 'Error', 'Sorry, unable to load favorite cities.', 'bg-red-100 text-red-700');
        });
    }

    if (filterEmpty()) {
      fetchRecommendations();
    } else {
      applyFilters();
    }
  }, [offset]);

  useEffect(() => {
    setFilterBtnStatus();
  }, [selectedContinents, selectedCountries, selectedPriorities,
    selectedAvoidThings, selectedVibe, selectedClimate]
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
      if (params.get('look_for_inspiration')) {
        const lookForInspirationValue = params.get('look_for_inspiration').includes('true');
  
        if (lookForInspirationValue) {
          setFeaturedDestinationVisible(false);
        }
      }
    }, [location.search]);

  const setRouteParams = () => {
    const params = new URLSearchParams(location.search);
    if (params.size > 0) {
      if (params.get('from_survey') == 'true') {
        if (params.getAll('destinations').length) {
          const parsedValues = JSON.parse(params.getAll('destinations')[0]);
          setSelectedCountries(parsedValues.selectedCountires || []);
          setSelectedContinents(parsedValues.selectedContinents || []);
        }
      } else {
        setSelectedDestinations(parseUrlParams(params.getAll('destinations')));
      }
      setSelectedPriorities(parseUrlParams(params.getAll('prioritize')));
      setSelectedAvoidThings(parseUrlParams(params.getAll('avoid')));
      setSelectedVibe(parseUrlParams(params.getAll('vibe')));
      return {
        priorities: params.get('prioritize') !== 'undefined' ? [parseUrlParams(params.get('prioritize'))] : '',
        destinations: params.get('destinations') !== 'undefined' ? [parseUrlParams(params.get('destinations'))] : '',
        countries: params.get('countries') !== 'undefined' ? [parseUrlParams(params.get('countries'))] : '',
        continents: params.get('continents') !== 'undefined' ? [parseUrlParams(params.get('continents'))] : '',
        from_survey: params.get('from_survey'),
      };
    }
  };

  const parseUrlParams = (param) => {
    try {
      return JSON.parse(param);
    } catch (error) {
      return param || [];
    }
  };

  const setFilterBtnStatus = () => {
    if (filterEmpty()) {
        setButtonEnable(false);
    } else {
      setButtonEnable(true);
    }
  };

  const filterEmpty = () => {
    if (selectedContinents.length === 0 && selectedCountries.length === 0 &&
      selectedPriorities.length === 0 && selectedAvoidThings.length === 0 &&
      !selectedVibe && !selectedClimate) {
        return true;
      }
      return false;
  };

  const handleDestinationsChange = (values) => {
    setSelectedDestinations(values);
  };

  const handleCountriesChange = (values) => {
    setSelectedCountries(values);
  };

  const handlePrioritesChange = (values) => {
    setSelectedPriorities(values);
  };

  const handleAvoidThingsChange = (values) => {
    setSelectedAvoidThings(values);
  };

  const handleVibeChange = (values) => {
    setSelectedVibe(values);
  };

  const handleClimateChange = (event) => {
    setSelectedClimate(event.target.value);
  };

  const handleContinentChange = (value) => {
    setSelectedContinents(value);
  };

  const applyFilters = (from = null) => {
    from && setOffset(0);
    if (inspirationParams.searchParam) {
      setOffset(0);
      for (const key in inspirationParams) {
          if (key !== 'searchParam') {
              delete inspirationParams[key];
          }
      }
  }
    setLoading(true);
    http
      .get('/get-recommendations', { params:  { ...inspirationParams, offset: offset } })
      .then((res) => {
        setRecommendation(res.data.recommendations);
        setTotalRecords(res.data.total_records);
        setOpenFilters(false);
        setAllowFilter(true);
        triggerMixpanelEvent('Inspiration - apply filters button');
      })
      .catch((err) => {
        console.log(err);
      })
      .finally( ()=> {
        setLoading(false);
      });
  };

  const cardOuterCss = 'flex-shrink-0 lg:col-span-4 sm:col-span-6 mb-4 w-full sm:w-1/2 lg:w-1/3 px-4';

  const debouncedSearchItems = debounce((searchValue) => {
    inspirationParams['searchParam'] = searchValue;
    applyFilters();
  }, 1000);

  const searchItems = (e) => {
    debouncedSearchItems(e.target.value);
  };

  const openDialog = (cityName) => {
    setCityName(cityName);
    setDialogVisible(true);
  };

  const closeDialog = () => {
    setDialogVisible(false);
  };

  const inspirationParams = {
    destinations: selectedDestinations,
    countries: selectedCountries,
    priorities: selectedPriorities,
    thingsToAvoid: selectedAvoidThings,
    vibe: selectedVibe,
    climate: selectedClimate,
    continents: selectedContinents,
  };

  const inspirationPaginator = (rowCount, totalRecords, offset, setOffset, isMobile ) => (
    <Paginator
     rows={rowCount}
     totalRecords={totalRecords}
     first={offset}
     onPageChange={(e) => setOffset(e.first)}
     isMobile = {isMobile}
    />
  );

  const filterForm = () => (
    <div className={`${isMobile ? 'flex' : ''} lg:col-span-3 sm:col-span-12 space-y-2`}>
      <div className={`border border-gray-300 bg-white ${isMobile ? 'p-8' : 'p-3'}`}>
        {!isMobile && <div className='font-semibold text-xl my-3'>Filters</div>}
        {isMobile &&
          <div className='flex flex-between'>
            <div className='font-semibold text-xl my-3'>Filters</div>
            <div
              className='absolute right-6 top-0 mt-10 font-semibold text-xl my-3'
              onClick={() => setOpenFilters(false) }
            >X</div>
          </div>
        }
        <div className='flex flex-col  flex-grow'>
          <div className='py-3'>
            {/* <div>
              Price range
              <PriceSlider />
            </div> */}
          </div>
          <div className=' pb-0'>
          <MultiSelectDropdown
            subCategories
            key='continents'
            items={ continentsOption }
            chipsColor='yellow-chips-background'
            Label={ 'Continents' }
            selectedItems={ selectedContinents }
            setSaveData={ handleContinentChange }
            dropdownWidth='sm:w-[300px]'
          />
          </div>
          <div className='flex flex-col '>
          <MultiSelectDropdown
            key='countries'
            items={ countriesOption }
            chipsColor='yellow-chips-background'
            Label={ 'Country' }
            selectedItems={ selectedCountries }
            setSaveData={ handleCountriesChange }
            dropdownWidth='sm:w-[300px]'
          />
          </div>
          <div className='flex flex-col'>
            <MultiSelectDropdown
              subCategories
              Label='Priorities'
              chipsColor='yellow-chips-background'
              items={ priorities }
              selectedItems={ selectedPriorities }
              setSaveData={ handlePrioritesChange }
            />
          </div>
          <div className='flex flex-col'>
            <MultiSelectDropdown
              subCategories
              Label='Things to avoid'
              items={ thingsToAvoid }
              chipsColor='yellow-chips-background'
              selectedItems={ selectedAvoidThings }
              setSaveData={ handleAvoidThingsChange }
            />
          </div>
          <div className='flex flex-col pb-3'>
            <MultiSelectDropdown
                Label='Vibes'
                items={ vibeOptions.vibes }
                chipsColor='yellow-chips-background'
                selectedItems={ selectedVibe }
                setSaveData={ handleVibeChange }
              />
          </div>
          <div className='flex flex-col pb-3 mb-6'>
            <SingleSelectDropdown props={{ label: 'Climate', options: ['Summer', 'Winter', 'Cold'], value: selectedClimate }} handleChanges={ handleClimateChange } />
          </div>
            <BtnTransparent
              props={{
                text: 'text-periwinkle hover:text-white',
                bg: 'bg-transparent hover:bg-periwinkle',
                border: 'border-periwinkle',
                buttonName: 'Apply filters'
              }}
              disabled={(!enableButton && !allowFilter)}
              handleClick= { () => applyFilters('button') }
            />
        </div>
      </div>
    </div>
  );

  return (
    <div className={`w-full flex-col flex-wrap space-y-4 ${isMobile ? 'px-3' : 'px-14'} mt-16`}>
      <Toast ref={toast} position="bottom-right" />

      { featuredDestination && <FeaturedDestinations
          isMobile={isMobile}
          interests={interests}
          setInterests={setInterests}
        /> }

      <div className={`${isMobile ? 'flex justify-center text-2xl' : 'text-3xl'} font-semibold font-playfair`}>Destinations</div>
      <div className='grid sm:grid-cols-12 gap-x-2 gap-y-4'>
        <span className='lg:col-span-5 sm:col-span-6 font-normal text-base font-sans'>
          Need a little inspiration? Browse our destinations and filter by your wants.
        </span>

        <div className='lg:col-span-5 lg:col-start-8 sm:col-span-6'>
          <span className='flex relative'>
            <IoSearchSharp className='absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500' />
            <InputText
              onChange={ searchItems }
              placeholder='Search by city'
              onFocus={() => { 
                isMobile && setOpenFilters(true);
                triggerMixpanelEvent('Inspiration - search for mobile button');
              }}
              className='w-full rounded border border-gray-300 pl-10 p-2 text-sm'
            />
          </span>
        </div>
      </div>

      <div className={`flex flex-wrap w-full ${isMobile ? 'pb-7' : 'py-7'} gap-4`}>
        <div className='flex w-full flex-wrap grid sm:grid-cols-12'>
          {!isMobile && filterForm()}

          { isLoading ? (
            <div className='lg:col-span-9 sm:col-span-12 flex flex-wrap justify-center mt-2 gap-2 px-4'>
              <InspirationCardsSkeleton isMobile = { isMobile } />
              { inspirationPaginator(6, totalRecords, offset, setOffset, isMobile ) }
            </div>
    
          ) : (
            <div className='lg:col-span-9 sm:col-span-12 flex justify-center flex-wrap mt-4'>
              {recommendations.length ? (
                recommendations.map((city, index) => (
                  <div
                    key={ index }
                    className={ cardOuterCss }
                  >
                    <TripCard props={ city } handleClick={ openDialog } interests={interests} setInterests={setInterests} />
                  </div>
                ))
              ) : (<p className='self-center pt-11'> Nothing found. </p>)}
              { 
                totalRecords > 6 ?
                <div className='mt-2 w-full'>
                 { inspirationPaginator(6, totalRecords, offset, setOffset, isMobile ) }
              </div> :
              <></>
              }
            </div>
          )}
          { isDialogVisible && <ItineraryForm
            city={ cityName }
            isVisible={ isDialogVisible }
            onClose={ closeDialog }
          />}
        </div>
      </div>
      {
        openFilterModal &&
        <Dialog
          modal
          closable={true}
          className="p-2"
          dismissableMask={true}
          visible={openFilterModal}
          contentStyle={{ padding: 0 }}
          onHide={() => setOpenFilters(false)}
        >
          {filterForm()}
        </Dialog>
      }
    </div>
  );
}
