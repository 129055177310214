import './Trips.scss';

import http from '../../common/http';
import redPointer from 'red-pointer.svg';
import bluePointer from 'blue-pointer.svg';

import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import Destinations from '../Destinations/Destinations';
import { Carousel } from 'primereact/carousel';
import CustomCard from '../UIComponents/Cards/CustomCard';
import { reset } from '../../redux/slices/tripPlan';
import { useDispatch } from 'react-redux';

const Trips = () => {
  const navigate = useNavigate();
  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  const [trips, setTrips] = useState([]);
  const [isDestinationsVisible, setIsDestinationsVisible] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    http
      .get('/trips')
      .then((res) => {
        setTrips(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
      
      if(!favorites.length) {
        http
          .get('/favorites')
          .then((res) => {
            setFavorites(res.data.favorite_cities);
          })
          .catch((err) => {
            console.log(err);
          });
      } 
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: 'we-plan-google-map-script',
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
  });

  const containerStyle = {
    width: '100%',
    height: '400px'
  };

  const center = (trips.length>0 && trips[0]?.city_location) || {
    lat: 38.9072,
    lng: 77.0369
  };

  const iconSize = {
    width: 25,
    height: 25,
  };

  const DestinationsVisible = (value) => {
    return (
      <div className='flex justify-end'>
        <h1
          className='lg:text-xl text-md font-bold mb-4 mt-4 cursor-pointer text-color'
          onClick={ () => setIsDestinationsVisible((prevValue) => !prevValue) }
        >
          { value }
          
        </h1>
      </div>
    );
  };

  const renderCarousel = (title, data, itemTemplate, items, itemWidth) => (
    <>
      {data.length > 0 && (
        <div className='mt-4'>
          <div className={`text-xl font-bold mb-4 mt-4`}>{title}</div>
          <Carousel
            value={ data }
            numScroll={ 1 }
            className='lg:mb-16 mb-8'
            itemTemplate={ itemTemplate }
            showNavigators={ data?.length > items }
            numVisible={ data?.length < items ? data?.length : items }
            style={{ paddingLeft: data?.length > items ? '' : '10px', width: itemWidth }}
          />
        </div>
      )}
    </>
  );

  const favoritesTemplate = (trip, index) => (
    <div
      key={ index }
      className='card bg-white p-4 border mx-2 cursor-pointer'
      onClick={() => navigate(`/results?city_name=${trip.city_name}&is_city_detail=true`)}
    >
      <CustomCard
        props={ trip }
        detailButton={ false }
        cardType="CityPlaceCard"
      />
    </div>
  );

  const travelHistory = () => (
    <div>
      <div className='text-base font-bold mb-4'>Travel History</div>
      <div className='flex flex-row mb-6'>
        <div className='flex flex-row items-cenetr'>
          <img src={redPointer} alt='icon' />
          <div className='text-sm ml-3'>Upcoming trip</div>
        </div>
        <div className='flex flex-row items-center justify-center ml-20'>
          <img src={bluePointer} alt='icon' />
          <div className='text-sm ml-3'>Planned trip</div>
        </div>
      </div>
      <div className='lg:col-span-7 sm:col-span-6'>
        {isLoaded && (
          <GoogleMap
            zoom={2}
            mapContainerStyle={containerStyle}
            center={trips?.first?.city_location || center}
          >
            {trips.map(city => (
              <MarkerF
                key={city.city_name}
                position={city.city_location}
                icon={{
                  url: new Date(city.start_date) > new Date() ? redPointer : bluePointer,
                  scaledSize: iconSize,
                }}
              />
            ))}
          </GoogleMap>
        )}
      </div>
    </div>
  );

  const handleNavigation = (id) => {
    dispatch(reset());
    navigate(`/trip-plan/${id}`);
  };

  const myDestinations = () => {
    if (trips.length) {
      return DestinationsVisible('See more upcoming trips >');
    }
  };
  
  return (
    <div className='flex w-full flex-col'>
      {
        isDestinationsVisible ? (
          <>
            <Destinations fromTripsSection = { true } setIsDestinationsVisible = { setIsDestinationsVisible } />
          </>
        ) : (
          <>

            <div className='flex flex-row font-playfair font-normal justify-between items-center text-2xl mb-8'>
              My Destinations { !isMobile && myDestinations() }
            </div>
            {trips.length ? (
              <>
                <ul>
                  <div className='flex w-full flex-col '>
                    { travelHistory() }
                  </div>
                </ul>
                { isMobile && <div className='flex flex-row justify-end text-xl mb-8'>{ myDestinations() }</div> }
                <div className='flex flex-wrap md:justify-start justify-center mt-6'>
                  { trips.map((city, index) => (
                    <button
                      onClick={() => handleNavigation(city.id)}
                      key={ index }
                      className={ `bg-periwinkle h-10 ${city.city_name.length > 16 ? 'min-w-[160px]' : 'min-w-[128px]'} mt-2 flex items-center justify-center rounded-md text-white px-1` }
                      style={{ flexBasis: '10%', margin: '4px 2%' }}
                      >
                      { city.city_name }
                    </button>
                 ))}
                </div>
              </>
            ) : (
              <p className='pl-1'> No trips </p>
            )}
          </>
        )
      }
      {renderCarousel('Favorites', favorites, favoritesTemplate, isMobile ? 1 : 4, isMobile ? '100%' : '100%' )}
    </div>
  );
};

export default Trips;
