import React, { useState } from 'react';
import { selectStyle } from '../Style';

const AdditionalInfo = ({ onRespond, currentResponse }) => {
  const [selectedOption, setSelectedOption] = useState(currentResponse);

  const handleTextareaChange = (event) => {
    setSelectedOption(event.target.value);
    onRespond(event.target.value);
  };

  return (
    <div>
      <p> Anything else you’d like us to know? </p>
      <div className='mt-3 mb-10'>
        <textarea
          id='additionalInfoTextarea'
          className={ `${selectStyle} h-44` }
          value={ selectedOption }
          placeholder='Enter text'
          onChange={ handleTextareaChange }
        />
      </div>
    </div>
  );
};

export default AdditionalInfo;
