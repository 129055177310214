import React, { useRef, useState } from 'react';
// New Imports
import l_e_p from 'local_expert_program.png';
import i_s from 'in_stay.png';
import r_p from 'referral_program.png';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../Login/LoginForm';
import { Toast } from 'primereact/toast';
import CustomModal from '../UIComponents/Modal/customModal';
import { useDispatch, useSelector } from 'react-redux';
import { setFormStateChanged } from '../../redux/slices/formChanged';
import { handleNavigate } from '../../common/shared/navigationFunctions';
import { useLocation } from 'react-router-dom';

const JoinSection = ({ isMobile }) => {
  const formStateChanged = useSelector(state => state.formChanged.formStateChanged);
  const toast = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [isVisible, setVisible] = useState(false);
  const [toUrl, setToUrl] = useState('');
  const location = useLocation();

  const paramObject = {
    toast, setOpen, setToUrl, setModalType, setVisible, navigate, formStateChanged, location
  };

  const buttons = {
    savePlan: [
      {
        text: "Don't Save",
        onPress: () => {
          dispatch(setFormStateChanged(false));
          setVisible(false);
          navigate(`${toUrl}`);
        }
      },
      {
        text: 'Save Itinerary',
        onPress: () => {
          setVisible(false);
        }
      },
    ],
    change: [
      {
        text: 'Exit without generating itinerary',
        onPress: () => {
          dispatch(setFormStateChanged(false));
          navigate(`${toUrl}`);
          setVisible(false);
        }
      },
      {
        text: 'Stay on page',
        onPress: () => {
          setVisible(false);
        }
      },
    ],
  };

  const handleClose = () => setOpen(false);
  return (
    <section className={`flex w-full ${isMobile ? 'p-4' : ''}  pt-20 flex-wrap`}>
      <Toast ref={ toast } position="bottom-right" className='w-[325px]'/>
      <div className="relative flex-wrap max-w-screen-xl mx-auto grid grid-cols-12 gap-x-4 flex justify-between">
        <div className="col-span-12 lg:col-span-5">
          <div className={`mb-4 ml-8 flex flex-col ${isMobile ? 'items-center' : ''}`}>
            <h1 className="text-3xl font-semibold mb-3 font-playfair">Earn $$$, Travel, Repeat!</h1>
            <p className='font-sans font-normal text-base'>WePlan offers several programs that allow you to earn cash towards your trips. Learn more about any of our 3 programs now</p>
          </div>
        </div>

        <div className={`flex flex-wrap col-span-12 lg:col-span-7 flex justify-center ${isMobile ? 'flex-col space-y-8' : 'space-x-28' } font-bold`} >
          <div
            className='text-center cursor-pointer'
            onClick={ () => { navigate("/localexpertprogram"); } }
          >
            <p className=' font-semibold text-base font-sans'>Local Expert <br /> Program</p>
            {/* Image Added */}
            <div className="rounded-full w-32 mt-2 h-32 bg-gray-300 mx-auto" style={{ backgroundImage: `url(${l_e_p})` , backgroundPosition: 'center center', backgroundSize: 'cover' }}>        
            </div>
          </div>
          <div 
            className='text-center cursor-pointer'
            onClick={ () => { navigate("/in-stay"); } }
          >
            <p className='mb-8 font-semibold text-base font-sans'>In Stay</p>
            {/* Image Added */}
            <div className="rounded-full w-32 mt-2 h-32 bg-gray-300 mx-auto" style={{ backgroundImage: `url(${i_s})` , backgroundPosition: 'center center', backgroundSize: 'cover' }}>
            </div>
          </div>
          <div
            className='text-center cursor-pointer'
            onClick={ () => { handleNavigate("/referralprogram","referral program", paramObject); } }
          >
            {/* Image Added */}
            <p className='mb-8 font-semibold text-base font-sans'>Referral Program <br /></p>
            <div className="rounded-full w-32 mt-2 h-32 bg-gray-300 mx-auto cursor-pointer"
              style={{ backgroundImage: `url(${r_p})` ,
              backgroundPosition: 'center center',
              backgroundSize: 'cover' }}
            >
            </div>
          </div>
        </div>
      </div>
      <LoginForm isOpen={open} onClose={handleClose} />
      { isVisible &&
        <CustomModal
          type={ modalType }
          isVisible={ isVisible }
          isMobile={ isMobile }
          setVisible={ setVisible }
          buttons={ buttons[modalType] }
        />
      }
    </section>
  );
};

export default JoinSection;
