import http from '../common/http';
import Layout from './Layout/Layout';
import Footer from './Footer/Footer';
import HeroSection from './Hero/HeroSection';
import JoinSection from './Join/JoinSection';
import SurveySection from './Survey/SurveySection';
import LocationSliderSection from './Slider/LocationSlider';
import UpcomingTrips from '../components/Upcoming/UpcomingTrips';
import BlogsCarousel from './Blogs/BlogsCarousel';

import { getUserId } from '../common/auth';
import React, { useEffect, useState } from 'react';
import { clearUserSession } from './../common/auth';
import SingleCarousalSkeleton from './Skeletons/SingleCarousalSkeleton';

const LandingPage = ({ openLoginForm }) => {
  const userId = getUserId();

  const [isMobile, setIsMobile] = useState(false);
  const [trendingLocations, setTrendingLocations] = useState([]);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {
    const fetchTrendingLocation = () => {
      http
        .get('/trending_locations')
        .then((res) => {
          setTrendingLocations(res.data.locations);
        })
        .catch((err) => {
          console.log(err);

        });
    };

    if (openLoginForm) clearUserSession();

    fetchTrendingLocation();
  }, []);

  useEffect(() => {
    const fetchBlogs = () => {
      http
        .get('/trending_blogs')
        .then((res) => {
          setBlogs(res.data.blogs);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchBlogs();
  }, []);

  return (
    <Layout className='flex flex-wrap'>
      <div className='bg-[#FFFCF9] flex flex-col w-full'>
        <HeroSection isMobile={isMobile} />
        {!isMobile && userId && <UpcomingTrips landingPage />}
        <LocationSliderSection
          isMobile={isMobile}
          Title='Trending Locations'
          Description='We keep track of what cities are on the rise and which ones are falling so you can always stay ahead of the curve!' 
          props={ trendingLocations }
        />
        <JoinSection isMobile={isMobile} />
        <SurveySection isMobile={isMobile} />
        { blogs.length ? (
          <section className='flex w-full flex-col'>
            <div className='font-playfair font-semibold mt-10 mb-5 text-3xl lg:px-12 px-3'>
              Check out our trending blogs
            </div>
            <BlogsCarousel
              isMobile={isMobile}
              blogsData={ blogs }
            />
          </section>
        ) : (
          <SingleCarousalSkeleton skeletonFor = { 'blogs' } />
        )}
      </div>
    </Layout>
  );
};

export default LandingPage;
