import React, { useRef, useEffect, useState } from 'react';
import Layout from './Layout/Layout';
import { MdContentCopy } from "react-icons/md";
import { Toast } from 'primereact/toast';
import { getUserName } from '../common/auth';
import { triggerMixpanelEvent } from '../common/mixpanel';
import { useNavigate } from 'react-router-dom';

export default function ReferralProgram() {
  const toast = useRef(null);

  const navigate = useNavigate();

  const userName = getUserName();

  const [isDisabled, setIsDisabled] = useState(false);

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  useEffect(() => {
    if (userName === null) {
      navigate('/users/sign_in');
    }
  }, [userName, navigate]);

  const copyReferralLink = async (text) => {
    try {
      setIsDisabled(true);
      await navigator.clipboard.writeText(text);
      showMessage('success', 'Success', 'Referral link copied to clipboard', 'bg-teal-100 text-teal-500');
      setTimeout(() => setIsDisabled(false), 3000);
      triggerMixpanelEvent('Referal link copy button');
    } catch (err) {
      showMessage('error', 'Error', 'Failed to copy', 'bg-red-100 text-red-700');
      setIsDisabled(false);
    }
  };

  const referralLink = `weplan.ai/referralprogram/${userName}`;

  return userName !== null ? (
    <Layout>
      <Toast ref={ toast } position="bottom-right" />
      <div className="flex justify-center items-start w-full h-screen lg:mt-32 mt-20">
        <div className="md:w-[95%] w-full bg-[#C09DDE40] p-6">
          <div className="text-center space-y-4">
            <h2 className="text-3xl font-playfair font-normal">Refer a friend</h2>
            <p className="text-2xl font-playfair pt-6 font-normal">Give $25, get $100</p>
            <p className="px-2 font-sans text-base font-normal">
              For every friend who makes an account and books a trip through WePlan, get $100 credited to your account in 3 easy steps:
            </p>
            <ol className="list-decimal list-inside space-y-1 text-base font-normal font-sans pt-6">
              <li>Share your unique referral link with as many friends as you'd like.</li>
              <li>Make sure they sign up using your referral link for the WePlan. Both of you will get $25.</li>
              <li>When booking their first trip within 30 days, you will receive $75 credited to your account.</li>
            </ol>
            <div className="pt-6 font-sans">
              <p className="text-xs">Your special referral link</p>
              <div className={`flex justify-between items-center border-dashed border-2
                ${isDisabled ? 'border-gray-200 bg-gray-100' : 'border-gray-400 bg-white'}
                py-2 px-4 mt-2 rounded text-sm md:w-1/3 lg:w-1/4 w-full mx-auto
                ${isDisabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
              >
                <span className="flex-1 min-w-0">
                  <span className="block overflow-hidden overflow-ellipsis whitespace-nowrap">
                    {referralLink}
                  </span>
                </span>
                <MdContentCopy className={`text-lg ml-4 ${isDisabled ? '' : 'cursor-pointer'}`} onClick={() => !isDisabled && copyReferralLink(referralLink)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  ) : <div></div>;
}
