import React from 'react';
import LandingPage from '../components/LandingPage';
import InStayInItinerary from '../components/InStayInItinerary';

const LandingPageRoutes = [
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/users/sign_in",
    element: <LandingPage openLoginForm={true} />
  },
  {
    path: "/referralprogram/:username",
    element: <LandingPage />
  },
  {
    path: "/instayinitinerary",
    element: <InStayInItinerary/>
  },
];


export default LandingPageRoutes;
