import http from '../../common/http';

export const generateTimeOptions = () => {
  const options = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      let hour12 = hour;
      let meridiem = 'AM';
      if (hour12 === 0) {
        hour12 = 12;
      } else if (hour12 >= 12) {
        meridiem = 'PM';
        if (hour12 > 12) {
          hour12 -= 12;
        }
      }
      const hourStr = hour12.toString().padStart(2, '0');
      const minuteStr = minute.toString().padStart(2, '0');
      options.push(`${hourStr}:${minuteStr} ${meridiem}`);
    }
  }
  return options;
};

export const generateTimeSlots = () => {
  const slots = [];
  for (let hour = 0; hour < 24; hour++) {
    const startHour = hour;
    const endHour = (hour + 1) % 24;
    const startHour12 = (startHour === 0) ? 12 : (startHour > 12) ? startHour - 12 : startHour;
    const endHour12 = (endHour === 0) ? 12 : (endHour > 12) ? endHour - 12 : endHour;
    const meridiem = (startHour < 12) ? 'AM' : 'PM';
    const startHourStr = startHour12.toString().padStart(2, '0');
    const endHourStr = endHour12.toString().padStart(2, '0');
    const slot = `${startHourStr}:00 ${meridiem} - ${endHourStr}:00 ${meridiem}`;
    slots.push(slot);
  }
  return slots;
};

export const getDateRangeOptions = (startDate, endDate) => {
  let options = [];
  const currentDate = new Date(startDate);
  const end = new Date(endDate);

  while (currentDate <= end) {
    const format = { weekday: 'long', year: 'numeric', month: 'short', day: '2-digit' };
    const date = new Date(currentDate).toLocaleDateString('en-US', format);
    options.push(date);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return options;
};

export const calculateDistances = async (data) => {
  let places = null;
  let activityData = data.map((obj) => {
    return { name: obj.name, location: obj.location };
  });
  const params = { places: activityData };

  await http
    .get('/calculate_distance.json', { params: params })
    .then((res) => {
      places = res.data.places;
    });
  
  data = data.map((obj) => {
    const rec = places.find((place) => place.name == obj.name);
    obj['distance'] = rec.distance;
    return obj;
  });
  return data;
};
