import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  flightBooking: []
};

export const flightBookingSlice = createSlice({
  name: 'flightBooking',
  initialState,
  reducers: {
    setFlightBooking: (state, action) => {
      state.flightBooking = action.payload;
    }
  }

});

export const { setFlightBooking } = flightBookingSlice.actions;
export default flightBookingSlice.reducer;
