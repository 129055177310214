import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const SingleCarousalSkeleton = ({ skeletonFor = '' }) => {
  const timesToRenderSkeleton = window.innerWidth <= 768 ? 1 : 4;

  const miniSkeleton = () => (
    <div className='flex justify-between items-center'>
      <div className={ `${ skeletonFor == "blogs" ? "flex flex-row justify-between w-full" : "" }` }>
        <div className='flex flex-col m-1 gap-1 w-[200px]'>
          <div>
            <Skeleton className='m-1' variant="rectangular" width={'w-full'} height={20} />
          </div>
          <div>
            <Skeleton className='m-1' variant="rectangular" width={'w-full'} height={20} />
          </div>
        </div>
        <div className={ `${ skeletonFor == "blogs" ? "" : "hidden" }` }>
          <Skeleton className='m-1' variant="circular" width={50} height={50} />
        </div>
      </div>
    </div>
  );

  return (
    <div className={`flex flex-col w-full ${ skeletonFor == "blogs" ? "mb-2" : "pt-10 mt-10" }`}>
      <div className={`flex flex-col lg:flex-row gap-2 ${ skeletonFor == "blogs" ? "" : "p-5" } mx-5`}>
        { Array.from({ length: timesToRenderSkeleton }).map((_, index) => (
          <div className={`w-full lg:w-1/2 ${ skeletonFor == "blogs" ? "h-[380px]" : "h-[400px]" } flex flex-col card-container shadow-md p-2`} key = { index } >
            <Skeleton variant="rectangular" width={"w-full"} height={'100%'} />
            { miniSkeleton() }
          </div>
        )) }
      </div>
    </div>
  );
};

export default SingleCarousalSkeleton;
