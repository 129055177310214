import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  locationsCoordination: {},
};

const locationsCoordination = createSlice({
  name: 'locationsCoordination',
  initialState,
  reducers: {
    resetState: () => initialState,

    setLocationsCoordination: (state, action) => {
      const newState = { ...state.locationsCoordination };

      let lastDay = 0;
      if (Object.keys(newState).length > 0) {
        lastDay = Math.max(...Object.keys(newState));
      }

      let currentDay = lastDay + 1;

      Object.keys(action.payload).forEach((day) => {
        newState[currentDay] = action.payload[day];
        currentDay++;
      });

      state.locationsCoordination = newState;
    }
  }
});

export const { setLocationsCoordination, resetState } = locationsCoordination.actions;
export default locationsCoordination.reducer;
