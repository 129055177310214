import React from 'react';
import { Skeleton } from '@mui/material';

const FeaturedDestCardSkeleton = ({ isMobile }) => {
  return (
    <div
      className={`flex w-full ${ isMobile ? 'flex-col items-center' : 'flex-row' } px-5`}
    >
      <div className={`flex flex-col justify-center gap-5 ${ isMobile ? 'items-center' : 'w-2/5' }` } >
        <Skeleton variant="rectangular" width={200} height={20} />
        <Skeleton variant="rectangular" width={400} height={30} />
        <Skeleton variant="rectangular" width={300} height={20} />
        <div className={`flex gap-2 ${ isMobile ? 'flex-col space-y-2 mb-4' : 'flex-row items-center' }` } >
          <Skeleton variant="rectangular" width={500} height={30} />
          {!isMobile?<Skeleton variant="circular" width={50} height={50} /> : <></>}
        </div>
      </div>

      { isMobile &&
        <div className='flex'>
          <Skeleton variant="rectangular" width={328} height={258} />
        </div>
      }

      { !isMobile && <div className='flex w-3/5 justify-end p-3'>
        <div className='flex mr-10'>
          <Skeleton variant="rectangular" width={438} height={419} />
        </div>
        <div className='flex flex-col  justify-between'>
          <Skeleton variant="rectangular" width={248} height={190} />
          <Skeleton variant="rectangular" width={248} height={190} />
        </div>
      </div> }
    </div>
  );
};

export default FeaturedDestCardSkeleton;
