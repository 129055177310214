import React from 'react';
import { BtnTransparent } from '../UIComponents/buttons';

const SeatsForm = ({ className }) => {
  return (
    <div className = { `border p-6 mb-6 bg-white ${ className }` }>
      <p className = 'text-2xl font-normal'>
        Seats
      </p>
      <p className = 'mt-4 text-base font-normal'>
        Seat selections are not guaranteed. Blah blah some copy.
      </p>
      <div className='flex mt-4'>
        <BtnTransparent
          props={{
            text: 'text-black',
            bg: 'bg-transparent',
            border: 'border-black',
            buttonName: 'Select seats',
            radius: 'rounded'
          }}
        />
      </div>
    </div>
  );
};

export default SeatsForm;
