import React from 'react';
import Referralprogram from '../components/ReferralProgram';

const ReferralRoutes = [
  {
    path: "/referralprogram",
    element: <Referralprogram />,
  }
];

export default ReferralRoutes;
