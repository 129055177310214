import React from 'react';
import { capitalizeFirstLetter } from '../../utils/helpers/string.jsx';

const CancelationPolicySection = ({ content }) => {

  return (
    <div className='border border-red-500 p-4 text-red text-red-500 font-normal'>
      <div className='text-2xl mb-4 font-playfair'>
        {content?.type && `${capitalizeFirstLetter(content.type)} Policy`}
      </div>
      <div className='text-base'>
        { content?.policy }
      </div>
    </div>
  );
};

export default CancelationPolicySection;
