import React, { useState, useEffect } from 'react';

import { Card } from 'primereact/card';
import { Rating } from 'primereact/rating';
import { BtnTransparent } from '../buttons';
import { useSelector, useDispatch } from 'react-redux';
import { setHotelSwapping } from '../../../redux/slices/hotelSwapping';
import ActivityModal from '../../ActivityModal';
import { useLocation, useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import PlaceholderSkeleton from '../../Skeletons/PlaceholderSkeleton';
import SingleSelectDropdown from '../Dropdowns/SingleSelectDropdown';
import { generateTimeSlots } from '../../Common/TripPlanCommon';
import { triggerMixpanelEvent } from '../../../common/mixpanel';
import './Card.scss';

const CustomCard = ({ props, cardType, detailButton, addToItinerary, lateNightEats=false, setLateActivity }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [isHovered, setIsHovered] = useState(false);
  const [isDialogVisible, setDialogVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showTimeField, setShowTimeField] = useState(false);
  const [timeOptions, setTimeOptions] = useState([]);
  const [activityTime, setActivityTime] = useState('');
  const tripPlan = useSelector(state => state.tripPlan.tripPlan);

  const openDialog = () => {
    setDialogVisible(true);
  };

  const closeDialog = () => {
    setDialogVisible(false);
  };

  const showBlog = () => {
    navigate(`/blogs/show/${props.blogId}`);
  };

  const isRestaurant = () => {
    return props.place_type === 'Breakfast' || props.place_type === 'Dinner';
  };

  const placeInfo = {
    id: props.id,
    isRestaurant: props.is_restaurant,
    placeType: props.place_type,
  };

  const imageSrc = () => {
    if (props.imageUrl) {
      return props.imageUrl;
    } else if (props.images?.length) {
      return props.images[0];
    }
  };

  const showActivityModal = () => {
    if (cardType === "BlogCard") {
      showBlog();
      triggerMixpanelEvent('LP - Blogs click');

    } else if (cardType === 'hotelCard') {
      let hotelIndex = tripPlan.findIndex((plan) => {
        return plan.cityName == props.cityName;
      });
      dispatch(setHotelSwapping({ hotelSwapping: true, hotelIndex: hotelIndex }));
      triggerMixpanelEvent('Itineray - Activity click');
      return navigate(`/trip-plan/show-hotel/${props.id}`);
    }
    openDialog();
  };
  const handleOnDrag = (e, activity) => {
    e.dataTransfer.setData('newActivity', JSON.stringify(activity));
  };

  const handleCardType = () => {
    return ['CityPlaceCard', 'BlogCard', 'hotelCard', 'suggestedRestaurantCard', 'suggestedAttractionCard'].includes(cardType);
  };

  const isPlaceCard = () => {
    return ['CityPlaceCard', 'hotelCard', 'suggestedRestaurantCard', 'suggestedAttractionCard'].includes(cardType);
  };

  const isCityDetail = () => searchParams.get('is_city_detail');

  useEffect(() => {
    if (activityTime) {
      setLateActivity(props, activityTime);
    }
  }, [activityTime]);

  useEffect(() => {
    if (showTimeField) {
      const options = generateTimeSlots();
      setTimeOptions(options);
    }
  }, [showTimeField]);

  const header = (
    handleCardType() ? (
      <div
        className='relative'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        { imageSrc() ?
          <img
            src={ imageSrc() }
            className='w-full h-64 object-cover'
            alt='Card'
          /> :
          <PlaceholderSkeleton applyClass = { 'w-full h-64 object-cover bg-light-gray' } />
        }

        {(isHovered && detailButton) && (
          <div className={`flex flex-col justify-center items-center absolute inset-0 bg-black bg-opacity-50`}>
            { lateNightEats &&
              (showTimeField
              ? <div className='max-w-[225px] w-full'>
                  <SingleSelectDropdown
                    className={`form-control-wrapper mt-1 background w-[400px] border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
                    props={{
                      label: 'Select time',
                      options: timeOptions,
                      value: activityTime,
                      dropDownwidth: 'w-full',
                    }}
                    handleChanges={(e) => setActivityTime(e.target.value)}
                  />
              </div>
              : <BtnTransparent
                props={{
                  text: 'text-white max-w-[225px] w-full my-3',
                  bg: 'bg-periwinkle ',
                  border: 'border-periwinkle',
                  buttonName: 'Add to itinerary'
                }}
                handleClick={()=> setShowTimeField(true) }
              />
              )
            }
            <BtnTransparent
              props={{
                text: 'text-white max-w-[225px] w-full',
                bg: 'bg-transparent ',
                buttonName: 'See details'
              }}
              handleClick={ showActivityModal }
            />
          </div>
        )}
        <div className={`${isRestaurant() ? 'bg-[#F0DDDD]' : 'bg-purple-100'} absolute top-0 right-0 rounded-full m-3 font-monsterrat`}>
            <p className='text-sm px-3'>{props.place_type}</p>
        </div>
      </div>
    ) : ( props.imageUrl ? 
          <img
            alt="Card"
            className='h-[213px] w-full object-cover'
            src={ props.imageUrl }
          />
          :
          <PlaceholderSkeleton applyClass = { 'h-[213px] w-full object-cover bg-light-gray' } />
      
    )
  );
  
  const showPrice = () => {
    if(props?.price_level !== null) {
      if (props.price_level === '$0') return 'Free';
      return props.price_level;
    }
    return '-';
  };

  const title =
    isPlaceCard() ? (
      <div
        className='pt-4 pb-2 text-sm flex flex-row justify-between'
        draggable
        onDragStart={(e) => handleOnDrag(e, props)}
      >
        <div className='font-semibold'>
          {
            `${props?.name}${ tripPlan.length > 1 ? ` - ${props?.city_name || props?.cityName}` : ''}`
          }
        </div>
        <div>{ showPrice() }</div>
      </div>
    ) : (
      <div className='py-1'>
        <div className='py-1 text-xs'>{props?.readTime || '3 min read'}</div>
        <div className='pt-1 text-sm font-semibold flex justify-between pb-4 '>
          <div className='overflow-y-auto h-10'>{ props?.topic }</div>
          <Avatar src={ props.userAvatar }/>
        </div>
      </div>
    );

  const ratingSection =
    isPlaceCard() ? (
      <Rating
        cancel={false}
        value={props?.rating || 5}
        className='text-yellow-500 pb-1 mt-1'
      />
    ) : null;

  const preferencesSection =
    isPlaceCard() ? (
      <div className='mb-3'>{ props?.preferences }</div>
    ) : null;

  return (
    <div className="card flex justify-content-center">
      <Card title={title} header={header} className="md:w-25rem w-full">
        {ratingSection}
        {preferencesSection}
      </Card>

      {isDialogVisible && (
        <ActivityModal
          isVisible={ isDialogVisible }
          props= { placeInfo }
          onClose={ closeDialog }
          addToItinerary={ addToItinerary }
          cardType = { cardType }
          isCityDetail= { isCityDetail() }
        />
      )}
    </div>
  );
};

CustomCard.defaultProps = {
  detailButton: true,
};

export default CustomCard;
