import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tripPlan: [],
  tripPrice: {},
};

export const tripPlanSlice = createSlice({
  name: 'tripPlan',
  initialState,
  reducers: {
    reset: () => initialState,

    setTripPlan: (state, action) => {
      state.tripPlan = action.payload;
    },
    setTripPrice: (state, action) => {
      state.tripPrice = action.payload;
    },
    updateTripPlan: (state, action) => {
      const newData = action.payload;
      state.tripPlan.forEach((item, index) => {
        if (newData[index]) {
          for (const key in newData[index]) {
              state.tripPlan[index].days_data[key] = newData[index][key];
          }
        }
      });
    },
  }
});

export const { setTripPlan, setTripPrice, reset, updateTripPlan } = tripPlanSlice.actions;
export default tripPlanSlice.reducer;
