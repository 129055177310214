import React from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout/Layout';
import { BtnTransparent } from './UIComponents/buttons';
import { triggerMixpanelEvent } from '../common/mixpanel';
import bg1 from 'localexpertprogrambackground1.png';
import decisionmanagement from 'decisionmanagement.png';
import trippana1 from 'trippana1.png';
import trippana2 from 'trippana2.png';
import trippana3 from 'trippana3.png';

const LocalExpertProgram = () => {
  const navigate = useNavigate();
  const backgroundImage = {
    backgroundImage: `url(${bg1})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    minHeight: '500px',
  };

  const navigateToForm = () => {
    navigate('/localexpertprogram/form');
    triggerMixpanelEvent('Local exprt - start form button');
  };

  return (
    <Layout>
      <div className='flex w-full flex-col'>
        <div className='flex w-full lg:px-14 pt-20 px-16' style={ backgroundImage }>
          <div className='flex flex-wrap w-full justify-center lg:justify-between'>
            <div className='w-full lg:w-[400px] flex flex-col justify-center lg:m-28'>
              <div><h1 className="lg:text-4xl text-3xl font-playfair font-normal ">Local Expert Program</h1></div>
              <div><p className="mt-6 text-base font-normal leading-8 font-sans">Earn cash rewards for every valuable tip, recommendation, and local secret you share</p></div>
              <div>
                <BtnTransparent
                  props={{
                    bg: 'bg-periwinkle',
                    border: 'border-periwinkle',
                    buttonName: 'Start form',
                    text: 'text-white mt-4 w-full h-[40px] font-semibold',
                  }}
                  handleClick={ navigateToForm }
                />
              </div>
            </div>
            <div className='mt-10 pb-14 lg:pb-2 max-w-[540px]  max-h-[560px] lg:mx-20 flex justify-center'>
              <img src={ decisionmanagement } alt="Dots" />
            </div>
          </div>
        </div>
        <div className='mt-20 px-4 mx-8 lg:px-18 flex flex-wrap justify-between'>
          <div id='left' className='p-5 w-full lg:w-5/12 flex flex-col justify-center'>
            <div><h1 className="lg:text-4xl text-3xl font-normal font-playfair">About the program</h1></div>
            <div><p className="mt-6 text-base font-normal leading-8 font-sans">Share your local insider knowledge for popular destinations as a WePlan Local Expert and earn rewards to explore new destinations yourself! We welcome all applicants, but membership is subject to approval from the WePlan team. </p></div>
          </div>
          <div id='right' className='border border-gray-200 rounded-lg p-5 w-full lg:w-6/12 flex flex-col justify-center mt-4 sm:mt-0 '>
            <div><h1 className="lg:text-4xl text-3xl font-normal font-playfair ">Become a Local Expert in 3 Easy Steps:</h1></div>
            <div className="p-4 text-base font-normal leading-8 font-sans">
              <ol className="list-decimal">
                <li>Apply Now: Complete the local expert form and share your local insider knowledge with us</li>
                <li>Get approved: Our team is eager to hear from you! We review all applications within 3-5 business days</li>
                <li>Start Sharing & Start Earning: Once approved, start sharing your local insider tips and start earning cash rewards towards your next travel experience</li>
              </ol>
            </div>
          </div>
        </div>
        <div className='m-20'>
          <div><h1 className="mx-12 lg:text-4xl text-3xl font-playfair font-normal">Perks of becoming a local expert</h1></div>
          <div className='flex flex-wrap justify-around mx-12 mt-10'>
            <div className='flex flex-col items-center text-center my-3'>
              <div className='h-[150px] w-[150px]'>
                <img src={ trippana1 } alt="" />
              </div>
              <h1 className='mt-2 text-base font-normal leading-8 font-sans'>$100 in travel credit</h1>
            </div>
            <div className='flex flex-col items-center text-center my-3'>
              <div className='h-[150px] w-[150px]'>
                <img src={ trippana2 } alt="" />
              </div>
              <h1 className='mt-2 text-base font-normal leading-8 font-sans'>Help travelers see the beauty in your city</h1>
            </div>
            <div className='flex flex-col items-center text-center my-3'>
              <div className='h-[150px] w-[150px]'>
                <img src={ trippana3 } alt="" />
              </div>
              <h1 className='mt-2 text-base font-normal leading-8 font-sans'>Earn a local expert badge</h1>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LocalExpertProgram;
