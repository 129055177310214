import React, { useEffect, useState, useRef } from 'react';
import http from '../common/http';
import Layout from './Layout/Layout';
import { getUserId } from '../common/auth';
import SingleSelectDropdown from './UIComponents/Dropdowns/SingleSelectDropdown';
import { BtnTransparent } from './UIComponents/buttons';
import FileUpload from './UIComponents/FileHandling/FileUpload';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './CustomSlider.scss';

const InStayInItinerary = () => {
  const location = useLocation();
  const toast = useRef(null);
  const navigate = useNavigate();
  const userId = getUserId();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [tripId, setTripId] = useState('');
  const [hotel, setHotel] = useState({});
  const [generalFormData, setGeneralFormData] = useState({ overallRanking: "50", busyNessMeter: "50", priceLevel: "", vibe: "", neighbourhoodQuality: "", stay: "" });
  const [amenititesFormData, setAmenititesFormData] = useState({ topAmenititesAtHotel: "", amenitiesThatWereMissing: "", favoriteAmenity: "" });
  const [priceLevels] = useState(["Budget conscious ($)", "Reasonable ($$)", "Let’s splurge a bit ($$$)", "Let’s go crazy ($$$$)"]);
  // TODO: Need to make the hotelAmenities dynamic after fetching it from API
  const [hotelAmenities] = useState(["Toiletries", "Personal care", "Coffee Kit", " Tissue box", "Bathrobes and slippers"]);
  const vibeOptions = [
    'Relaxed',
    'Adventurous',
    'Cultural',
    'Romantic',
    'Vibrant',
    'Nature-centric',
    'Artistic',
    'Family-friendly',
    'Wellness',
    'Educational'
  ];
  const formFunctions = {
    setGeneralFormData: setGeneralFormData,
    setAmenititesFormData: setAmenititesFormData
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const hotelDataString = JSON.parse(queryParams.get('hotel'));
    setTripId(queryParams.get('tripId'));
    setHotel(hotelDataString);
  }, [location]);

  const handleInput = (formDataFunction, fieldName, newValue, index = null) => {
    formFunctions[formDataFunction]((prevData) => {
      if (index !== null) {
        const updatedDestinations = [...prevData.destinations];
        const destinationName = updatedDestinations[index];
        return {
          ...prevData,
          dates: {
            ...prevData.dates,
            [destinationName]: {
              ...prevData.dates[destinationName],
              [fieldName]: newValue,
            },
          },
        };
      } else {
        return {
          ...prevData,
          [fieldName]: newValue,
        };
      }
    });
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const onSaveClick = () => {
    const inStayFormData = new FormData();
    uploadedFiles.forEach(file => {
      inStayFormData.append('uploadedFiles[]', file);
    });
    inStayFormData.append('userId', userId);
    inStayFormData.append('tripId', tripId);
    inStayFormData.append('hotelId', hotel.id);
    inStayFormData.append('generalInfo', JSON.stringify(generalFormData));
    inStayFormData.append('amenititesInfo', JSON.stringify(amenititesFormData));

    http
    .post('/in_stays', inStayFormData)
    .then((res) => {
      if (res.status === 200) {
        showMessage('success', 'Success', 'Feedback submitted successfully', 'bg-teal-100 text-teal-500');
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    })
    .catch(() => {
      return showMessage('error', 'Error', 'Failed to submit, Please try again.', 'bg-red-100 text-red-700');
    });

  };

  return (
    <Layout>
      <Toast ref={toast} position="bottom-right" className='w-[325px]' />
      <div className='w-full mt-18 p-10 lg:p-20'>
        <div>
          <h1 className="text-lg font-bold font-monsterrat cursor-pointer mt-5" onClick={() => { navigate(-1); }}>&lt; Back to itinerary</h1>
          <h1 className="text-lg font-monsterrat mt-10">In Stay: Hotel</h1>
          <h1 className="text-4xl font-playfair mt-2">{hotel ? hotel.name : null}</h1>
        </div>
        <div className='mt-10'>
          <h1 className="text-2xl font-bold">General</h1>
          <div className='flex flex-col lg:flex-row lg:flex-1 gap-5 mt-5 '>
            <div className='flex flex-col flex-1 gap-5'>
              <div>
                <h1 className="text-md tracking-tight">Overall ranking</h1>
              </div>
              <div>
                <input
                  type="range"
                  id="customSlider"
                  name="customSlider"
                  value={generalFormData.overallRanking}
                  min="0"
                  max="100"
                  step="1"
                  className="appearance-none w-full bg-semi-trans-purple rounded-md outline-none h-3"
                  onChange={(e) => { handleInput("setGeneralFormData", "overallRanking", e.target.value); }}
                />
                <span className="text-sm text-gray-600 mt-2">
                  {generalFormData.overallRanking}%
                </span>
              </div>
            </div>
            <div className='flex flex-col flex-1 gap-5'>
              <div>
                <h1 className="text-md tracking-tight">Busy-ness meter</h1>
              </div>
              <div>
                <input
                  type="range"
                  id="customSlider"
                  name="customSlider"
                  value={generalFormData.busyNessMeter}
                  min="0"
                  max="100"
                  step="1"
                  className="appearance-none w-full bg-semi-trans-purple rounded-md outline-none h-3"
                  onChange={(e) => { handleInput("setGeneralFormData", "busyNessMeter", e.target.value); }}
                />
                <span className="text-sm text-gray-600 mt-2">
                  {generalFormData.busyNessMeter}%
                </span>
              </div>
            </div>
          </div>

          <div className='flex flex-col lg:flex-row gap-2 mt-10'>
            <div className='flex flex-col flex-1'>
              <label className='text-sm'>Price level</label>
              <SingleSelectDropdown
                props={{
                  dropDownwidth: 'w-full',
                  options: priceLevels,
                  value: generalFormData.priceLevel,
                  labelStyle: 'text-[11px] letter-spacing block'
                }}
                handleChanges={(val) => handleInput('setGeneralFormData', 'priceLevel', val.value)}
              />
            </div>
            <div className='flex flex-col flex-1'>
              <label className='text-sm'>Vibe</label>
              <SingleSelectDropdown
                props={{
                  dropDownwidth: 'w-full',
                  options: vibeOptions,
                  value: generalFormData.vibe,
                  labelStyle: 'text-[11px] letter-spacing block'
                }}
                handleChanges={(val) => handleInput('setGeneralFormData', 'vibe', val.value)}
              />
            </div>
            <div className='flex  flex-col flex-1'>
              {/* TODO: Need to ask it from client */}
              <label className='text-sm'>Neighbourhood</label>
              <input
                type="text"
                value={ generalFormData.neighbourhoodQuality }
                onChange={ (e) => { handleInput("setGeneralFormData", "neighbourhoodQuality", e.target.value); } }
                className={`form-control-wrapper mt-2 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              />
            </div>
          </div>
          <div className='mt-10'>
            <h1 className="text-lg font-sans mt-10">Would you stay here again?</h1>
            <div className='flex flex-col mt-5 gap-2'>
              <div className='flex gap-2'>
                <input
                  type='radio'
                  value={generalFormData.stay}
                  checked={generalFormData.stay === "Yes"}
                  onChange={() => handleInput("setGeneralFormData", "stay", "Yes")}
                />
                <label className='text-sm'>
                  Yes
                </label>
              </div>
              <div className='flex gap-2'>
                <input
                  type='radio'
                  value={generalFormData.stay}
                  checked={generalFormData.stay === "No"}
                  onChange={() => handleInput("setGeneralFormData", "stay", "No")}
                />
                <label className='text-sm'>
                  No
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-200 my-10"></div>

        <div>
          <h1 className="text-2xl font-bold ">Amenities</h1>
          <div className='flex flex-col lg:flex-row flex-wrap flex-1 gap-5 mt-7'>
            <div className='flex flex-col flex-1'>
              <label className='text-sm'>Top amenitites at hotel</label>
              <SingleSelectDropdown
                props={{
                  dropDownwidth: 'w-full',
                  options: hotelAmenities,
                  value: amenititesFormData.topAmenititesAtHotel,
                  labelStyle: 'text-[11px] letter-spacing block'
                }}
                handleChanges={(val) => handleInput('setAmenititesFormData', 'topAmenititesAtHotel', val.value)}
              />
            </div>
            <div className='flex flex-col flex-1'>
              <label className='text-sm'>Amenities that were missing</label>
              <input
                type="text"
                value={amenititesFormData.amenitiesThatWereMissing}
                onChange={(e) => { handleInput("setAmenititesFormData", "amenitiesThatWereMissing", e.target.value); }}
                className={`form-control-wrapper mt-2 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              />
            </div>
          </div>
          <div className='mt-5'>
            <div className='flex flex-col flex-1 lg:w-1/2 w-full'>
              <label className='text-sm'>Favorite amenity</label>
              <input
                type="text"
                value={amenititesFormData.favoriteAmenity}
                onChange={(e) => { handleInput("setAmenititesFormData", "favoriteAmenity", e.target.value); }}
                className={`form-control-wrapper mt-1 background w-full border-0 focus:border-0 focus:outline-none focus:ring-0 p-2`}
              />
            </div>
          </div>
        </div>

        <div className="border-t border-gray-200 my-10"></div>

        <div>
          <div className='flex flex-col gap-5'>
            <h1 className="text-2xl font-bold  mt-5">Photos</h1>
            <h1 className="text-lg font-sans mt-5">Upload photos</h1>
          </div>
          <FileUpload setUploadedFiles={setUploadedFiles} widthInput={"w-full lg:w-1/2"} />
        </div>
        <div className='flex flex-col lg:flex-row items-center lg:justify-end lg:items-center gap-2 lg:gap-5 mt-10 lg:mt-20'>
          <button
            className='mt-4  text-sm font-semibold leading-6 text-gray-900'
          >
            Cancel
          </button>

          <BtnTransparent
            props={{
              buttonName: "Save Changes",
              bg: 'bg-transparent',
              border: 'border-periwinkle',
              text: 'text-periwinkle mt-4  w-full lg:w-[500px] lg:h-[40px] font-semibold',
            }}
          />
          <BtnTransparent
            props={{
              buttonName: "Submit Form",
              bg: 'bg-periwinkle',
              border: 'border-periwinkle',
              text: 'text-white mt-4 w-full lg:w-[500px] lg:h-[40px] font-semibold',
            }}
            handleClick={onSaveClick}
          />
        </div>
      </div>
    </Layout>
  );
};

export default InStayInItinerary;
