import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { flightsSelectionData } from '../customData';
import { useSelector } from 'react-redux';
import http from '../../common/http.js';
import BookAllRecomendedFlight from './BookAllRecomendedFlight.jsx';
import BookAllRecomendedHotel from './BookAllRecomendedHotel.jsx';
import loadingSpinner from 'loader.png';

export default function BookAllBookingConfirmationModal({ bookingId, isVisible, onClose, isHotelBooked, flightConfirmationId }) {
  const toast = useRef(null);
  const [flightData, setFlightData] = useState({});
  const hotel = useSelector(state => state.recommendedHotel.recommendedHotel);
  const recommendedHotelOffer = useSelector(state => state.recommendedHotel.recommendedHotelOffer);
  const recommendedHotelCity = useSelector(state => state.recommendedHotel.recommendedHotelCity);
  const [bookingInfos, setBookingInfos] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const recommendedOffer = useSelector(state => state.recommendedFlightOffer.recommendedFlightOffer);
  useEffect(() => {
    setFlightData(flightsSelectionData[0]);
  }, []);

  useEffect(() => {
    if (bookingId) {
      fetchBookingInformation();
    }
  }, [bookingId]);

  const fetchBookingInformation = () => {
    http
      .get(`/booking/${bookingId}.json`)
      .then((res) => {
        setLoading(false);
        setBookingInfos(res.data);
      })
      .catch(() => {
        showMessage('error', 'Error', 'Failed to get booking details, Please try again.', 'bg-red-100 text-red-700');
      });
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  return (
    <div className='flex w-full'>
      <Toast ref={ toast } position='bottom-right'/>
      <Dialog
        headerClassName="px-4 pt-4"
        visible={ isVisible }
        dismissableMask={ false }
        closable={ true }
        style={ { backgroundColor: 'white', overflow: 'hidden' } }
        className={ `${bookingId ? 'lg:w-[1000px]' : 'lg:w-[400px]' } w-full` }
        onHide={ () => onClose() }
      >
        {
          isLoading ? (
            <div className='flex flex-row justify-center items-center lg:w-[400px] lg:h-[200px] w-full'>
              <img
                src={ loadingSpinner }
                alt="spinner"
                className='w-[21px] h-[23px] animate-spin'
              />
              <p className='pl-2'>Booking in progress....</p>
            </div>
          ) : (
        <div className='mt-4'>
          <div className='text-sm mt-4 lg:w-[100%] sm:w-[47%]'>
            <label className='text-2xl px-6 pt-8 font-playfair'>
              You’re booked!
            </label>
            <p className='font-sans px-6 pt-2'>
              You will receive an email at { bookingInfos[0]?.email } with the booking info.
            </p>
          </div>
          <div className='px-6'>
            { flightConfirmationId?.length >= 1 &&
              <div className='text-sm mt-4 lg:w-[100%] sm:w-[47%]'>
                <div>
                  <label className='text-2xl font-playfair'>Flight</label>
                  <p className='font-semibold pt-2'>
                    Confirmation ID: { flightConfirmationId }
                  </p>
                </div>
                { recommendedOffer?.id ?
                    <div>
                      <BookAllRecomendedFlight
                        isBookingConfirmation={ true }
                        recommendedOffer={ recommendedOffer }
                      />
                    </div>
                  : <div className='text-md text-center mt-5'>No flight Details available</div>
                }
              </div>
            }
            { isHotelBooked &&
              <div className='text-sm lg:w-[100%] mt-4 sm:w-[47%]'>
                <div>
                  <label className='text-2xl font-playfair'>Accomodation</label>
                  <p className='font-semibold pt-2'>
                    Confirmation ID: { bookingInfos[0]?.confirmation_id }
                  </p>
                </div>
                { hotel ?
                  <div>
                    <BookAllRecomendedHotel
                      recommendedHotel={ hotel }
                      recommendedHotelOffer={ recommendedHotelOffer }
                      recommendedHotelCity={ recommendedHotelCity }
                      isBookingConfirmation={ true }
                    />
                  </div> : <div className='text-md text-center mt-5'>No hotel Details available</div>
                }
              </div>
            }
          </div>
        </div>
        )}
      </Dialog>
    </div>
  );
}
