import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  avoids: [],
};

export const thingsToAvoidSlice = createSlice({
  name: "avoids",
  initialState,
  reducers: {    
    setThingsToAvoid: (state, action) => {
      state.avoids = action.payload;
    },
  },
});

export const { setThingsToAvoid } = thingsToAvoidSlice.actions;

export default thingsToAvoidSlice.reducer;
