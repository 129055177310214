import React, { useState, useRef } from 'react';
import AdditionalInfo from './UIComponents/SurveyBody/AdditionalInfo';
import Avoid from './UIComponents/SurveyBody/Avoid';
import Occasion from './UIComponents/SurveyBody/Occasion';
import Dates from './UIComponents/SurveyBody/Dates';
import Destination from './UIComponents/SurveyBody/Destination';
import Prioritize from './UIComponents/SurveyBody/Prioritize';
import Vibe from './UIComponents/SurveyBody/Vibe';
import Steps from './UIComponents/steps';
import LoginForm from './Login/LoginForm';
import SignupForm from './SignupForm/SignupForm';
import { BtnTransparent } from './UIComponents/buttons';
import { useLocation, useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../common/auth';
import { GrClose } from "react-icons/gr";
import { Toast } from 'primereact/toast';
import { useDispatch } from 'react-redux';
import { reset } from '../redux/slices/tripPlan';

const SurveyCard = () => {
  const toast = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [isCity, setIsCity] = useState(false);
  const [redirectRoute, setredirectRoute] = useState('');
  const [isSignupFormOpen, setIsSignupFormOpen] = useState(false);
  const [currentSurveyIndex, setCurrentSurveyIndex] = useState(0);
  const [responses, setResponses] = useState({});
  const [showSurveyBody, setShowSurveyBody] = useState(true);
  const location = useLocation();
  
  const closeSignupForm = () => {
    setIsSignupFormOpen(false);
    if (isLoggedIn() && redirectRoute) window.location.href = redirectRoute;
  };

  const surveyBodies = [
    Destination,
    Occasion,
    Prioritize,
    Vibe,
    Avoid,
    AdditionalInfo,
    Dates,
  ];

  const surveyNames = [
    'destinations',
    'occasion',
    'prioritize',
    'vibe',
    'avoid',
    'additional_info',
    'dates',
  ];

  const backButtonCss = () => {
    let backButtonProps = {
      bg: 'bg-white w-full',
      buttonName: 'Back',
    };

    if (currentSurveyIndex === 0) {
      backButtonProps['text'] = 'text-[#D9D9D9]';
      backButtonProps['border'] = 'border-[#D9D9D9]';
      return backButtonProps;
    }
    backButtonProps['text'] = 'text-black';
    backButtonProps['border'] = 'border-black';
    return backButtonProps;
  };

  const handleNextClick = () => {
    const surveyBodiesLength = surveyBodies.length - 1;
    if (currentSurveyIndex < surveyBodiesLength) {
      setCurrentSurveyIndex((prevIndex) => prevIndex + 1);
    } else {
      if (isCity && 'destinations' in responses && Array.isArray(responses.destinations['selectedCities']) && responses.destinations['selectedCities'].length > 0) {
        const hasDates = 'dates' in responses && 'start_date' in responses.dates && 'end_date' in responses.dates;
        if (!hasDates) {
          showMessage('error', 'Error', 'Both start date and end date are required', 'bg-red-100 text-red-700');
          return;
        }
      }
      let queryString = Object.keys(responses)
                              .map((key) => `${key}=${encodeURIComponent(JSON.stringify(responses[key]))}`)
                              .join('&');
      
      queryString += '&from_survey=true';
      if (isCity) {
        dispatch(reset());
        navigate(`/trip-plan?${queryString}`);
      } else {
        navigate(`/results?${queryString}&activeIndex=0`);
      }
      // Commenting this code for the current requirements
      // if (!isLoggedIn()) {
      //   setredirectRoute(`/results?${queryString}&activeIndex=0`);
      //   setShowSurveyBody(false);
      //   setIsSignupFormOpen(true);
      // } else {
      //   isCity ? navigate(`/trip-plan?${queryString}`) : navigate(`/results?${queryString}&activeIndex=0`);
      // }
    }
  };

  const handleBackClick = () => {
    setCurrentSurveyIndex(
      (prevIndex) => (prevIndex - 1 + surveyBodies.length) % surveyBodies.length
    );
  };

  const handleSurveyResponse = (response) => {
    setResponses((prevResponses) => {
      const surveyName = surveyNames[currentSurveyIndex];
      const updatedResponses = { ...prevResponses, [surveyName]: response };
      return updatedResponses;
    });
  };

  const SurveyBody = surveyBodies[currentSurveyIndex];

  const openSignupForm = () => {
    setOpen(false);
    setIsSignupFormOpen(true);
  };

  const handleClose = () => {
    setShowSurveyBody(true);
    setOpen(false);
  };

  const showMessage = (severity, summary, detail, style) => {
    toast.current.show({ severity, summary, detail, life: 3000, className: style, contentClassName: 'p-2.5' });
  };

  const buttonName = () => {
    if (currentSurveyIndex === surveyBodies.length - 1) {
      return 'Finish';
    }
    return 'Next';
  };

  const handleNavigation = () => {
    const isPreviousUrlDefault = location.key == "default" ? true : false ;
    isPreviousUrlDefault ? navigate("/") : navigate(-1) ; 
  };


  return (
    <div className='w-full h-full flex items-start justify-center mb-7 mx-8 min-h-screen py-8'>
      <Toast ref={toast} position="bottom-right" />
      <div className='flex fixed top-0 left-0 w-full h-full bg-black opacity-50'></div>
      { showSurveyBody && <div className='flex lg:w-1/2 w-full flex-col bg-white lg:p-8 p-3 rounded shadow-md z-10'>
        <div className='flex justify-between'>
          <h2 className='text-left font-montserrat text-2xl pb-4'>Survey</h2>
          <GrClose className='cursor-pointer' onClick={() => { handleNavigation(); }} />
        </div>
        <div className='font-montserrat text-base leading-6 tracking-normal text-left'>
          Answer a few questions, and we’ll give you trip suggestions based on
          your responses.
        </div>
        <div className='my-7 text-sm'>
          {`${currentSurveyIndex + 1}/${surveyBodies.length}`}
          <div className='mt-2'>
            <Steps
              totalSteps={ surveyBodies.length }
              stepIndex={ currentSurveyIndex + 1 }
            />
          </div>
        </div>
        <div>
          <SurveyBody
            setIsCity={setIsCity}
            onRespond={ handleSurveyResponse }
            currentResponse={ responses[surveyNames[currentSurveyIndex]] }
          />
        </div>
        <div className='flex w-full justify-between'>
          <div className='lg:w-2/5'>
            <button
              className='text-sm font-semibold leading-6 text-gray-900'
              onClick={ handleNextClick }
            >
              Skip
            </button>
          </div>
          <div className='flex lg:w-3/5 gap-x-2'>
            <BtnTransparent
              props={ backButtonCss() }
              handleClick={ handleBackClick }
              disabled={ currentSurveyIndex === 0 }
            />

            <BtnTransparent
              props={{
                text: 'text-white w-full',
                bg: 'bg-periwinkle',
                border: 'border-periwinkle',
                buttonName: buttonName(),
              }}
              handleClick={ handleNextClick }
            />
          </div>
        </div>
      </div> }
      <LoginForm
        isOpen={ open }
        onClose={ handleClose }
        openSignupForm={ openSignupForm }
        redirectRoute={ redirectRoute }
      />
      <SignupForm
        isOpen={ isSignupFormOpen }
        onClose={ () => {
          closeSignupForm();
          setShowSurveyBody(true);
        } }
        openLoginForm={ () => {
          setIsSignupFormOpen(false);
          setOpen(true);
        }}
      />
    </div>
  );
};

export default SurveyCard;
