import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedFlights: [],
  completeInfo: {},
};

export const selectedFlightsSlice = createSlice({
  name: "selectedFlights",
  initialState,
  reducers: {
    addSelectedFlight: (state, action) => {
      state.selectedFlights= [...state.selectedFlights, action.payload];
    },
    deleteSelectedFlight: (state, action) => {
      state.selectedFlights = state.selectedFlights.filter(flight => flight.flightName !== action.payload);
    },
    emptySelectedFlights: (state) => {
      state.selectedFlights = [];
    },
    setSelectedFlights: (state, action) => {
      state.selectedFlights = action.payload;
    },
    setCompleteInfo: (state, action) => {
      state.completeInfo = action.payload;
    }
  },
});

export const { addSelectedFlight, deleteSelectedFlight, emptySelectedFlights, setSelectedFlights, setCompleteInfo } = selectedFlightsSlice.actions;

export default selectedFlightsSlice.reducer;
