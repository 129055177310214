import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recommendedFlightOffer: {},
};

export const recommendedFlightOfferSlice = createSlice({
  name: 'recommendedFlightOffer',
  initialState,
  reducers: {
    setRecommendedFlightOffer: (state, action) => {
      state.recommendedFlightOffer = action.payload;
    },
  }
});

export const { setRecommendedFlightOffer } = recommendedFlightOfferSlice.actions;
export default recommendedFlightOfferSlice.reducer;
