import React from 'react';
import ProfilePage from '../components/ProfilePage';
import Trips from '../components/Trips/Trips';
import Profile from '../components/Profile/Profile';
import ResetUsernameAndPassword from '../components/ResetUsernamePassword/ResetUsernamePassword';
import RewardsAndBilling from '../components/RewardsAndBilling/RewardsAndBilling';
import SocialMediaPlatform from '../components/SocialMedia/SocialMediaPlatforms';
import AIInStay from '../components/AIInsStay/AIInStay';
import MessagePreference from '../components/MessagePreference/MessagePreference';
import Support from '../components/Support/Support';

const ProfilePageRoutes = [
  {
    path: '/profile/:id/',
    element: <ProfilePage />,
    children: [
      {
        path: '',
        element: <Profile />
      },
      {
        path: 'trips',
        element: <Trips />
      },
      {
        path: 'username',
        element: <ResetUsernameAndPassword />
      },
      {
        path: 'rewards',
        element: <RewardsAndBilling />
      },
      {
        path: 'social',
        element: <SocialMediaPlatform />
      },
      {
        path: 'in-stay',
        element: <AIInStay />
      },
      {
        path: 'support',
        element: <Support />
      },
      {
        path: 'preferences',
        element: <MessagePreference />
      },
    ]
  },
];

export default ProfilePageRoutes;
