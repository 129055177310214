import React from 'react';
import InStayPage from '../components/InStayPage';

const InStayPageRoutes = [
  {
    path: "/in-stay",
    element: <InStayPage />,
  },
];

export default InStayPageRoutes;
